import { TONE_COLOR_THEME, PREFIX_TONE_COLOR_THEME } from "@common/content/theme/color/tone";
import { BRIGHT_COLOR_THEME, PREFIX_BRIGHT_COLOR_THEME } from "@common/content/theme/color/bright";
import {
    OPACITY_COLOR_THEME,
    PREFIX_OPACITY_COLOR_THEME,
} from "@common/content/theme/color/opacity";
import { COLOR_THEME, PREFIX_COLOR_THEME } from "@common/content/theme/color";
import { FACE_FONT_THEME, PREFIX_FACE_FONT_THEME } from "@common/content/theme/font/face";
import { SIZE_FONT_THEME, PREFIX_SIZE_FONT_THEME } from "@common/content/theme/font/size";
import { WEIGHT_FONT_THEME, PREFIX_WEIGHT_FONT_THEME } from "@common/content/theme/font/weight";
import { STYLE_FONT_THEME, PREFIX_STYLE_FONT_THEME } from "@common/content/theme/font/style";
import { ALIGN_FONT_THEME, PREFIX_ALIGN_FONT_THEME } from "@common/content/theme/font/align";
import { DECO_FONT_THEME, PREFIX_DECO_FONT_THEME } from "@common/content/theme/font/deco";
import { SPACE_BOX_THEME, PREFIX_SPACE_BOX_THEME } from "@common/content/theme/box/space";
import {
    RADIUS_BORDER_BOX_THEME,
    PREFIX_RADIUS_BORDER_BOX_THEME,
} from "@common/content/theme/box/border/radius";
import {
    STYLE_BORDER_BOX_THEME,
    PREFIX_STYLE_BORDER_BOX_THEME,
} from "@common/content/theme/box/border/style";
import {
    TRANSFORM_FONT_THEME,
    PREFIX_TRANSFORM_FONT_THEME,
} from "@common/content/theme/font/transform";
import {
    WORD_WRAP_FONT_THEME,
    PREFIX_WORD_WRAP_FONT_THEME,
} from "@common/content/theme/font/wrap-word";
import {
    SPACE_WRAP_FONT_THEME,
    PREFIX_SPACE_WRAP_FONT_THEME,
} from "@common/content/theme/font/wrap-space";

export const DATA_THEME = {
    default: {
        ...flattenTheme(TONE_COLOR_THEME, PREFIX_TONE_COLOR_THEME),
        ...flattenTheme(BRIGHT_COLOR_THEME, PREFIX_BRIGHT_COLOR_THEME),
        ...flattenTheme(TONE_COLOR_THEME, PREFIX_TONE_COLOR_THEME),
        ...flattenTheme(OPACITY_COLOR_THEME, PREFIX_OPACITY_COLOR_THEME),
        ...flattenTheme(COLOR_THEME, PREFIX_COLOR_THEME),
        ...flattenTheme(FACE_FONT_THEME, PREFIX_FACE_FONT_THEME),
        ...flattenTheme(SIZE_FONT_THEME, PREFIX_SIZE_FONT_THEME),
        ...flattenTheme(WEIGHT_FONT_THEME, PREFIX_WEIGHT_FONT_THEME),
        ...flattenTheme(STYLE_FONT_THEME, PREFIX_STYLE_FONT_THEME),
        ...flattenTheme(ALIGN_FONT_THEME, PREFIX_ALIGN_FONT_THEME),
        ...flattenTheme(DECO_FONT_THEME, PREFIX_DECO_FONT_THEME),
        ...flattenTheme(TRANSFORM_FONT_THEME, PREFIX_TRANSFORM_FONT_THEME),
        ...flattenTheme(WORD_WRAP_FONT_THEME, PREFIX_WORD_WRAP_FONT_THEME),
        ...flattenTheme(SPACE_WRAP_FONT_THEME, PREFIX_SPACE_WRAP_FONT_THEME),
        ...flattenTheme(SPACE_BOX_THEME, PREFIX_SPACE_BOX_THEME),
        ...flattenTheme(RADIUS_BORDER_BOX_THEME, PREFIX_RADIUS_BORDER_BOX_THEME),
        ...flattenTheme(STYLE_BORDER_BOX_THEME, PREFIX_STYLE_BORDER_BOX_THEME),
    },
};

export function flattenTheme<Data extends Record<string, unknown>, Prefix extends string>(
    data: Data,
    prefix: Prefix,
) {
    type Keys = keyof Data;
    type Vals = Data[Keys];
    return (Object.entries(data) as [Keys, Vals][]).reduce(
        (acc, [key, val]) => ({ ...acc, [`${prefix}-${String(key)}`]: val }),
        {} as { [K in Keys & string as `${Prefix}-${K}`]: Data[K] },
    );
}
