import React from "@platform/react";
import { CreateProviderImagePlatform } from "./_Provider";
import { CreateConsumerImagePlatform } from "./_Consumer";
import type {
    ArgCommonImagePlatform,
    ArgImagePlatform,
    GetterImagePlatform,
    StateImagePlatform,
} from "./types";
import { ERROR_PROVIDER_IMAGE } from "./types";

export function CreateImagePlatform<G extends GetterImagePlatform>(arg: ArgImagePlatform<G>) {
    const Context = React.createContext<StateImagePlatform<G>>(undefined);

    const common: ArgCommonImagePlatform<G> = { Context, ...arg };

    const ProviderImagePlatform = CreateProviderImagePlatform<G>(common);

    const ImagePlatform = CreateConsumerImagePlatform<G>(common);

    return {
        ContextImage: Context,
        ProviderImagePlatform,
        ImagePlatform,
        useImagePlatform,
    };

    function useImagePlatform() {
        const context = React.useContext(Context);
        if (!context) throw new Error(ERROR_PROVIDER_IMAGE.NO_PROVIDER);
        return context;
    }
}
