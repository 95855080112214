import React from "@platform/react";
import {
    createBrowserRouter,
    createHashRouter,
    RouterProvider,
} from "@platform/react-storybook/Component/Router";
import { IS_MOBILE } from "@platform/utils-react/mobile";
import { OpenBrowserInApp } from "@platform/utils-react/mobile/inappbrowser";

import { ErrorCatcher } from "@front/components/Element/ErrorCatcher";
import { ProviderTracker } from "@front/components/Element/Tracker";

import { WrapperApp } from "./_Wrapper";
import { DEF_ROUTER, UUID_ROUTER } from "./types";
import type { PropRouter, PropTypesRouter } from "./types";
import { LogicGlobal } from "./_LogicGlobal";

export { Outlet } from "@platform/react-storybook/Component/Router";
export type { OutletProps as PropOutlet } from "@platform/react-storybook/Component/Router";

export const VERSION_APP = String(process.env.REACT_APP_VERSION);

export function App(props: PropRouter) {
    const { uuid, routes } = props as PropTypesRouter;

    if (!UUID_ROUTER.includes(uuid)) throw new Error("<Router />: Expecting prop: uuid");

    const { routerCreate, gtmID } = {
        user: {
            routerCreate: createHashRouter,
            gtmID: process.env.REACT_APP_USER_METRICS_GTM,
        },
        www: {
            routerCreate: createBrowserRouter,
            gtmID: process.env.REACT_APP_WWW_METRICS_GTM,
        },
    }[uuid];

    const onMobileDeviceReady = React.useCallback(handleMobileDeviceReady, []);

    // Logs routing information to console when in development mode.
    React.useEffect(handleRouterDebug, [routes, gtmID]);

    // Executes custom logic when the device is ready in mobile.
    // TODO:  This was added in a hurry to fix the support links provided raw html in text:
    //        "faq-question-10-desc"
    //        "faq-question-11-desc"
    //        Provide a mechanism to open links without relying on this global override.
    // author: @emartinez-mastercracks
    React.useEffect(() => {
        if (IS_MOBILE) {
            document.addEventListener("deviceready", onMobileDeviceReady);
        }
        return () => {
            if (!IS_MOBILE) return;
            document.removeEventListener("deviceready", onMobileDeviceReady);
        };
    }, [onMobileDeviceReady]);

    return (
        <WrapperApp>
            <ProviderTracker gtmID={gtmID}>
                <LogicGlobal uuid={uuid}>
                    <RouterProvider router={routerCreate(routes)} />
                </LogicGlobal>
            </ProviderTracker>
        </WrapperApp>
    );

    function handleRouterDebug() {
        /* eslint-disable no-console */
        if (process.env.NODE_ENV !== "production") {
            console.groupCollapsed("ROUTES");
            console.table(routes.map(({ id, path }) => ({ id, path })));
            console.groupEnd();
        }
        /* eslint-enable no-console */
    }

    function handleMobileDeviceReady() {
        const oldOpen = window.open;
        window.open = function (url, target, features) {
            if (target === undefined || target?.includes("self")) {
                return oldOpen(url, target, features);
            }
            OpenBrowserInApp(url as string);
            return null;
        };
    }
}
ErrorCatcher.defaultProps = DEF_ROUTER;
