import { PathCreate } from "@platform/react-storybook/Component/Router/utils";

import { paramCrack } from "../../params/landing";

export function PathGetCustomLanding<R extends string>(...args: [R]) {
    const [ROOT] = args;
    return {
        ROOT,

        get CUSTOM_LANDING() {
            const PATH_CRACK = PathCreate(ROOT, "signup");
            const PATH_CRACK_ONE = PathCreate(PATH_CRACK, paramCrack);

            return {
                ROOT: PATH_CRACK,
                PARAM: PATH_CRACK_ONE,
            };
        },
    };
}
