import { CreateActions } from "@platform/react/src/store";
import type { UuidVideoAsset } from "@common/content/asset/video";
import { NAME_SLICE_HISTORY_VIDEO } from "./_namespace";
import type { NSSliceHistoryVideo } from "./_namespace";

export const ActionSliceHistoryVideo = CreateActions<{
    /** Sets a give property errors */
    set: NSSliceHistoryVideo.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSliceHistoryVideo.ArgClear | null;
    /** Video history set */
    setHistoryVideo: NSSliceHistoryVideo.HistoryVideos;
    /** Set of the last video of video */
    setLastVideo: NSSliceHistoryVideo.CurrentVideo;
    /** Save the time of where you have to weigh the video */
    setCurrentTime: { [key in UuidVideoAsset]: number };
}>(NAME_SLICE_HISTORY_VIDEO, ["set", "clear", "setHistoryVideo", "setLastVideo", "setCurrentTime"]);
