// NOTE: if this file is not being interpreted correctly, make sure @platform/react/.cracorc.js
//       is enabling this module as a valid dependency.
import React from "@platform/react";
import type { Reqs, Opts, KeysOfType } from "@platform/types/record";

type Prop = Record<string, unknown>;

type KeyElementBase = "children" | "key" | "className" | "id" | "style" | "onClick";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RecordComponentBase = Record<string, (props: any) => unknown>;

export type RecordComponent<R extends RecordComponentBase> = {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    [K in keyof R]: (prop: Parameters<R[K]>[0]) => ReturnType<R[K]>;
};

/** A function providing you with the available components for provided Section. */
export type PropCallback<
    R extends RecordComponent<RecordComponentBase>,
    Name extends string = "children",
> = {
    [K in Name]: (Section: R) => React.ReactNode;
};

export type KeyHTMLText =
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "span"
    | "p"
    | "b"
    | "i"
    | "strong"
    | "u"
    | "em"
    | "blockquote"
    | "pre"
    | "code"
    | "label"
    | "li"
    | "dt"
    | "dd"
    | "mark"
    | "ins"
    | "del"
    | "sup"
    | "sub"
    | "small";

export const TYPE = {
    ROOT: "Root",
    CONTAINER: "Container",
    PAGE: "Page",
    SECTION: "Section",
    ELEMENT: "Element",
    PROVIDER: "Provider",
    BUNDLE: "Bundle",
} as const;

/** These are the common props that every Component should allow */
export type PropElement<E = HTMLElement> = Pick<React.HTMLProps<E>, KeyElementBase>;

/** Validates that given PropTypes have a set of defaultProps. */
export type PropDefaults<P extends Prop, T = Omit<P, KeyElementBase>> = Partial<
    Record<Reqs<T>, never>
> &
    Pick<Required<T>, Opts<T>>;

/**
 * Merges propTypes with a defaultProps
 * @arg Object P - A propTypes.
 * @arg Object D - The defaultProps.
 */
export type PropTypes<
    /** propTypes */
    P,
    /** defaultProps */
    D,
    /** work only with actual props sent */
    R = Omit<D, NonNullable<KeysOfType<D, undefined>>>,
> = Omit<P, keyof R> & R;

/* eslint-disable react/require-default-props */
export type PropComponent = {
    /** How to uniquely identify this component across the App   */
    name: string;
    /** In most cases, it should be a Section, Main, Page or Element, but a custom one can be set. */
    type: string;
    /** The HTML tag to use when rendering. default: <div /> */
    tag?: string;
    /** The native `name` tag */
    "html-name"?: React.HTMLProps<HTMLElement>["name"];
    /** The native `type` tag */
    "html-type"?: React.HTMLProps<HTMLElement>["type"];
} & Omit<React.HTMLProps<HTMLElement>, "type" | "name">;
/* eslint-enable react/require-default-props */

// eslint-disable-next-line @typescript-eslint/ban-types
export function isJSX(target: {} | null | undefined): target is JSX.Element {
    return React.isValidElement(target);
}

/** An easy way to obtain a component's selector */
export function getSelector({ name, type }: { name: string; type: string }) {
    return `[data-name=${name}][data-type=${type}]`;
}

export function getClassName(...args: (string | undefined | false | null)[]) {
    return args.filter(Boolean).join(" ");
}

export const Component = React.forwardRef((props: PropComponent, ref) => {
    const {
        tag = "section",
        name: dName,
        type: dType,
        children,
        "html-type": type,
        "html-name": name,
        ...rest
    } = props;
    return React.createElement(
        tag,
        {
            ref,
            "data-name": dName,
            "data-type": dType,
            name,
            type,
            ...rest,
        },
        children,
    );
});

export type ArgUseMergedRef<
    T extends HTMLElement,
    R extends React.MutableRefObject<T> | React.RefObject<T>,
> = {
    refElement: R;
    refForwarded: React.ForwardedRef<T>;
};

export function useCombinedRefs<
    T extends HTMLElement,
    R extends React.MutableRefObject<T> | React.RefObject<T>,
>({ refForwarded, refElement }: ArgUseMergedRef<T, R>): React.Ref<T> {
    const refCombined = React.createRef<T>();
    React.useEffect(() => {
        [refElement, refForwarded].forEach((refCurr) => {
            if (!refCurr) return;
            const current = refCombined.current || null;
            if (typeof refCurr !== "function") {
                Object.defineProperty(refCurr, "current", { value: current });
            } else {
                refCurr(current);
            }
        });
    }, [refCombined, refElement, refForwarded]);
    return refCombined;
}
