import React from "@platform/react";

import { CreateProviderThemePlatform } from "./_Provider";
import { CreateConsumerThemePlatform } from "./_Consumer";
import { ERROR_THEMEPLATFORM } from "./types";
import type { StateThemePlatform, ArgThemePlatform, GetterThemePlatform } from "./types";

export function CreateThemePlatform<G extends GetterThemePlatform>(arg: ArgThemePlatform<G>) {
    const { getter } = arg;
    const Context = React.createContext<StateThemePlatform<G>>(undefined);
    const common = { Context, getter };

    const ProviderThemePlatform = CreateProviderThemePlatform(common);
    const ThemePlatform = CreateConsumerThemePlatform(common);

    return {
        ContextThemePlatform: Context,
        ProviderThemePlatform,
        ThemePlatform,
        useThemePlatform,
    };

    function useThemePlatform() {
        const context = React.useContext(Context);
        if (!context) throw new Error(ERROR_THEMEPLATFORM.NO_PROVIDER);
        return context;
    }
}
