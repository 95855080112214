import { CreateActions } from "@platform/react/src/store";
import { NAME_SLICE_SESSION } from "./_namespace";
import type { NSSliceSession } from "./_namespace";

export const ActionSliceSession = CreateActions<{
    /** Sets a give property errors */
    set: NSSliceSession.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSliceSession.ArgClear | null;
}>(NAME_SLICE_SESSION, ["set", "clear"]);
