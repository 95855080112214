import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

type CreateAppComponent = React.FunctionComponent | JSX.Element | Element;

/**
 * Creates a React instance.
 * @param component - Either a React root element or a Promise returning a root element.
 * @param element - A DOM element where the instance is goin
 */
export function CreateApp(
    component: CreateAppComponent | Promise<CreateAppComponent>,
    element: HTMLElement | null,
) {
    if (!element) throw new Error("Expecting a valid DOMElement");
    const promise = component instanceof Promise ? component : Promise.resolve(component);
    promise
        .catch((err) => (
            <React.Fragment>
                <h1>{err instanceof Error ? err.message : String(err)}</h1>
                <small>
                    <code>
                        {err instanceof Error
                            ? err.stack
                            : "Error thrown in @platform/react, if you're reading this you should catch."}
                    </code>
                </small>
            </React.Fragment>
        ))
        .then((root) => {
            ReactDOM.render(<React.StrictMode>{root}</React.StrictMode>, element);
        });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(
    process.env.NODE_ENV !== "production"
        ? // eslint-disable-next-line no-console
          ({ name, delta }) => console.warn(name, delta)
        : undefined,
);

export default React;
