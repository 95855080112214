import type { Join, TupleHead, TupleTail } from "@platform/types/array";

import type { PathRoot } from "./types";
import { PARAM_PREFIX, PATH_ROOT } from "./types";

export function isExternalLink(
    link: string | { pathname: string; search: string; hash: string },
): boolean {
    if (typeof link !== "string") return false;
    const externalLink = /^#|https?:/;
    const telLink = /^#|tel?:/;
    return externalLink.test(link) || telLink.test(link);
}

type PathJoined<
    Parts extends string[],
    PartHead = TupleHead<Parts>,
    PartTail = TupleTail<Parts>,
> = PartHead extends PathRoot
    ? `${PathRoot}${Join<PartTail, PathRoot>}`
    : PartHead extends `${PathRoot}${string}`
    ? Join<Parts, PathRoot>
    : `${PathRoot}${Join<Parts, PathRoot>}`;

export function PathCreate(): PathRoot;
export function PathCreate<Parts extends string[], R extends PathJoined<Parts>>(...parts: Parts): R;
export function PathCreate<Parts extends string[], R extends PathJoined<Parts>>(...parts: Parts) {
    if (!parts.length) return PATH_ROOT;
    const [partFirst, ...partRest] = parts;
    if (partFirst === PATH_ROOT) return ["", ...partRest].join(PATH_ROOT);
    if (partFirst.startsWith(PATH_ROOT)) return parts.join(PATH_ROOT);
    return ["", ...parts].join(PATH_ROOT) as R;
}

export type ArgParamCreate<N extends string, V extends null | readonly string[]> = {
    name: N;
    values: V;
};

export function ParamCreate<N extends string, V extends null | readonly string[]>(
    arg: ArgParamCreate<N, V>,
) {
    const { name, values } = arg;
    const STR_INI = 0;
    const STR_END = 1;
    const Name = name.charAt(STR_INI).toUpperCase() + name.slice(STR_END);

    type CN = Capitalize<N>;
    const param = `${PARAM_PREFIX}${name}` as const;
    const RX_PARAM = new RegExp(param, "g");

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {
        [`param${Name}`]: param,
        [`useParam${Name}`]: hook,
    } as {
        [param in `param${CN}`]: typeof param;
    } & {
        [hook in `useParam${CN}`]: () => {
            [param in `param${CN}`]: typeof param;
        } & {
            [guard in `isParam${CN}`]: typeof guard;
        } & {
            [replacer in `replaceParam${CN}`]: typeof replacer;
        };
    };

    type ParamValue<T extends null | readonly string[]> = T extends null
        ? string
        : T extends readonly string[]
        ? T[number]
        : never;

    function hook() {
        return {
            [`param${Name}`]: param,
            [`isParam${Name}`]: guard,
            [`replaceParam${Name}`]: replacer,
        } as const;
    }

    function guard<R extends V>(entry: unknown): entry is ParamValue<R> {
        if (!values) return Boolean(typeof entry === "string" && entry.length);
        if (typeof entry !== "string") return false;
        return ((values.length ? values : []) as NonNullable<V>).includes(entry);
    }

    function replacer<R extends V>(str: string, repl: ParamValue<R>): string {
        return str.replace(RX_PARAM, repl);
    }
}
