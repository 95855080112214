/** Utility type for Object.entries() */
export type Entries<T> = [keyof T, T[keyof T]][];

/* eslint-disable @typescript-eslint/ban-types */

/** Filters out all required props from given type  */
export type Reqs<T> = {
    [K in keyof T]-?: {} extends Pick<T, K> ? never : K;
}[keyof T];

/** Filters out all optional props from given type */
export type Opts<T> = {
    [K in keyof T]-?: {} extends Pick<T, K> ? K : never;
}[keyof T];

/* eslint-enable @typescript-eslint/ban-types */

/** Returns the key names of given type */
export type KeysOfType<S, Type> = {
    [K in keyof S]: S[K] extends Type ? K : never;
}[keyof S];

/** Given an Object value, retrieve the name of its key. */
export function KeyFromValue(o: Record<string, unknown>, target: unknown) {
    const result = Object.entries(o).find((entry) => entry[1] === target);
    if (!result) throw new Error("Key could not be found");
    return result[0];
}

// is entry an object?
export function isRecord<T = unknown>(entry: unknown): entry is Record<string, T> {
    try {
        Object.getPrototypeOf(entry);
        return true;
    } catch (err) {
        return false;
    }
}
