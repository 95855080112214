export const NAME_SLICE_APP = "app";

export declare namespace NSSliceApp {
    /** The currently running version for each app **/
    export type version = string;

    /** Counts how many times this app has been run on device */
    export type runs = number;

    export type hasBeenRun = boolean;

    /** Keeps a track of the user navigation */
    export type HistoryItem = { pathname: string; date: string };

    /** Determines if the uses is logged in, onlu for user-app */
    export type isLoggedIn = boolean;

    export type State = {
        version: version;
        runs: runs;
        isLoggedIn: isLoggedIn;
        history: HistoryItem[];
        hasBeenRun: hasBeenRun;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: string;
    };
}

export const INIT_SLICE_PERSIST: NSSliceApp.State = {
    version: "0.0.0",
    runs: 0,
    isLoggedIn: false,
    history: [],
    hasBeenRun: false,
};
