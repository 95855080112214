import React from "@platform/react";

import { getSelector } from "../../Component";
import {
    ERROR_TRACKERPLATFORM,
    ELEMENT_TRACKERPLATFORM,
    NAME_PROVIDER_TRACKERPLATFORM,
    DEF_PROVIDER_TRACKERPLATFORM,
    TYPE_PROVIDER_TRACKERPLATFORM,
} from "./types";
import type {
    ArgProviderTrackerPlatform,
    PropProviderTrackerPlatform,
    PropTypesProviderTrackerPlatform,
} from "./types";

export function CreateProviderTrackerPlatform(args: ArgProviderTrackerPlatform) {
    const { Context } = args;

    function ProviderTrackerPlatform(props: PropProviderTrackerPlatform) {
        const { children, gtmID, gtmURL } = props as PropTypesProviderTrackerPlatform;

        // Create the GTM Tag on first load.
        React.useEffect(() => {
            // Enable tracking code
            const elGTM = document.querySelector(
                getSelector({
                    name: NAME_PROVIDER_TRACKERPLATFORM,
                    type: TYPE_PROVIDER_TRACKERPLATFORM,
                }),
            );
            if (elGTM) return;
            // Make sure there's a head element to put the script tag in.
            const elHead = document.querySelector(ELEMENT_TRACKERPLATFORM.HEAD);
            if (!elHead) throw new Error(ERROR_TRACKERPLATFORM.NO_HEAD);
            // initialize the dataLayer and create the element
            window.dataLayer = [
                ...(window.dataLayer || []),
                {
                    event: "gtm.js",
                    "gtm.start": new Date().getTime(),
                },
            ];
            const elScript = document.createElement(ELEMENT_TRACKERPLATFORM.SCRIPT);
            elScript.async = true;
            elScript.src = `${gtmURL}?id=${gtmID}`;
            elScript.dataset.name = NAME_PROVIDER_TRACKERPLATFORM;
            elScript.dataset.type = TYPE_PROVIDER_TRACKERPLATFORM;
            // create the element
            // Make sure the script is added before to the other scripts on head.
            const elSibling = elHead.querySelector(
                `${ELEMENT_TRACKERPLATFORM.SCRIPT}:first-of-type`,
            );
            if (elSibling) {
                elSibling.parentNode?.insertBefore(elScript, elSibling);
            } else {
                elHead.appendChild(elScript);
            }
        }, [gtmID, gtmURL]);

        // TODO: Provide a mechanism so the user can send a custrom tracker function.
        return <Context.Provider value={undefined}>{children}</Context.Provider>;
    }
    ProviderTrackerPlatform.displayName = NAME_PROVIDER_TRACKERPLATFORM;
    ProviderTrackerPlatform.defaultProps = DEF_PROVIDER_TRACKERPLATFORM;

    return ProviderTrackerPlatform;
}
