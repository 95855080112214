import type React from "react";
import type {
    PropProviderTextPlatform,
    PropConsumerTextPlatform,
} from "@platform/react-storybook/Bundle/Text/types";

import type {
    PropDefaults,
    PropTypes,
    KeyHTMLText,
    PropElement,
} from "@platform/react-storybook/Component";
import Namespace from "./_index.scss";
import type { getter } from "./data";

export const { NAME_TEXT, TYPE_TEXT } = Namespace;

export type GetterText = typeof getter;

export const UUID_PRESET_TEXT = [
    "pachuca",
    "changeme",
    "tijuana",
    "torreon",
    "cancun",
    "campeche",
    "puebla",
    "arica",
    "formosa",
    "merlo",
    "lima",
    "quito",
    "porto",
    "cali",
    "calama",
    "reynosa",
    "lanus",
    "oruro",
    "lampa",
    "concepcion",
    "osorno",
    "acapulco",
    "mazatlan",
    "morelia",
    "mexicali",
    "ovalle",
    "paine",
    "parana",
    "pichilemu",
    "sucre",
    "yacuiba",
    "juarez",
    "habana",
    "trujillo",
    "tarija",
    "merida",
    "callao",
    "angol",
    "monterrey",
    "tampico",
] as const;

export type UuidPresetText = typeof UUID_PRESET_TEXT[number];

export const TAG_PRESET_TEXT: { readonly [K in UuidPresetText]: KeyHTMLText } = {
    pachuca: "span",
    changeme: "code",
    tijuana: "h2",
    torreon: "h2",
    cancun: "h2",
    campeche: "h3",
    puebla: "h3",
    arica: "h3",
    formosa: "h3",
    merlo: "h4",
    lima: "h4",
    quito: "h4",
    porto: "h4",
    cali: "h4",
    calama: "h4",
    reynosa: "h5",
    lanus: "p",
    oruro: "p",
    lampa: "strong",
    concepcion: "p",
    osorno: "span",
    acapulco: "h6",
    mazatlan: "h6",
    morelia: "h6",
    mexicali: "h6",
    ovalle: "span",
    paine: "span",
    parana: "span",
    pichilemu: "span",
    sucre: "strong",
    yacuiba: "strong",
    juarez: "strong",
    habana: "strong",
    trujillo: "strong",
    tarija: "strong",
    merida: "p",
    callao: "p",
    angol: "p",
    monterrey: "strong",
    tampico: "strong",
};

export type UuidText = PropConsumerTextPlatform<GetterText>["uuid"];

export type PropProviderText = Omit<PropProviderTextPlatform<GetterText>, "language">;

type _PropTextPlatform = Omit<PropConsumerTextPlatform<GetterText>, "uuid">;

export type PropStyleText = {
    /** An `uuid` for one of the visual presets for a text. */
    preset: UuidPresetText;
    /** Allow the use of `html` content in the corresponding translation. */
    raw?: boolean;
};

export type PropContentText =
    | {
          /**
           * A unique identifier that will be replaced for a text in the current language.
           * If omitted, you will have to provide `children`. */
          uuid: UuidText;
          children?: never;
      }
    | {
          /** Tell the component to use this information instead of the `uuid` property. */
          children: React.ReactNode;
          /** When `children` is present, this will be ignored. */
          uuid?: never;
      };

export type PropText = _PropTextPlatform &
    PropStyleText &
    PropContentText & {
        /**
         * Whether the component should strip HTML entities (if any found) from the resulting text.
         * NOTE: if `raw` is sent, this will be ignored.
         */
        strip?: boolean;
    };

export const DEF_TEXT: PropDefaults<
    Omit<PropText, "replace" | "fallback" | "uuid" | "preset" | "strip">
> = {
    raw: false,
};

export type PropTypesText = PropTypes<PropText, typeof DEF_TEXT>;

export const ERROR_TEXT = {
    NO_UUID: `${NAME_TEXT}: Expecting an uuid tag`,
};

export type PropVariantText = PropElement & {
    tag: KeyHTMLText;
};
