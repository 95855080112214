import type {
    InAppPurchasesStore,
    StoreProduct,
    StoreError,
} from "@platform/utils-react/mobile/purchase";
import type { InAppPurchaseInfo } from "@common/content/purchases/purchases";

export const NAME_SLICE_PURCHASES = "purchases";

export declare namespace NSSlicePurchases {
    /** Determines the store used for in app purchases */
    export type inAppStore = InAppPurchasesStore | undefined;
    /** Determines the in app purchases */
    export type products = StoreProduct[];

    export type productsInfo = InAppPurchaseInfo[];

    export type shouldShowChooseYourPlan = boolean;

    export type State = {
        products: products;
        productsInfo: productsInfo;
        ownedProduct: StoreProduct;
        error: StoreError;
        shouldShowChooseYourPlan: shouldShowChooseYourPlan;
        productToBePurchased: string | undefined;
        isLoading: boolean;
        isSilentModeEnabled: boolean;
        shouldOpenSubscriptions: boolean;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_PURCHASES: NSSlicePurchases.State = {
    products: [],
    productsInfo: [],
    ownedProduct: undefined,
    error: undefined,
    shouldShowChooseYourPlan: false,
    productToBePurchased: undefined,
    isLoading: false,
    isSilentModeEnabled: true,
    shouldOpenSubscriptions: false,
};
