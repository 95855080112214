import type { UnPromise } from "@platform/types";
import type React from "@platform/react";

import Namespace from "./_index.scss";
import type { KeyHTMLText, PropElement } from "../../Component";

export const MATCHER_TEXTPLATFORM = "%" as const;

export type DataTextPlatform = { [language: string]: { [uuid: string]: string } };
//                            ↑D  ↑KL             ↑VL    ↑KT       ↑VT

export type GetterTextPlatform<D = DataTextPlatform> = () => Promise<D>;

/**
 * NOTE: these are shortcuts referencing the shape of the retrieved data.
 *       @see DataTextPlatform for reference.
 *       - D  = DataText
 *       - KL = KeyLangText
 *       - VL = ValLangText
 *       - KT = KeyText
 *       - VT = ValText
 */
export type DataText<G extends GetterTextPlatform> = UnPromise<ReturnType<G>>;
export type KeyLangText<G extends GetterTextPlatform> = keyof DataText<G>;
export type ValLangText<G extends GetterTextPlatform> = DataText<G>[KeyLangText<G>];
export type KeyText<G extends GetterTextPlatform> = keyof ValLangText<G>;
export type ValText<G extends GetterTextPlatform> = ValLangText<G>[KeyText<G>];

/** A key/value dictionary describing all available languages and their corresponding names */
export type LanguageTextPlatform<G extends GetterTextPlatform> = {
    [language in KeyLangText<G>]: string;
};

export type ArgTextPlatform<G extends GetterTextPlatform> = {
    /** A method that resolves data used as dictionary */
    getter: G;
    /** A language dictionary */
    languages: LanguageTextPlatform<G>;
    /** An unique identifier that will be used to retrieve data from the storage */
    storageName?: string;
    /** A character that will be used for replacement whenever necessary */
    matcher?: string;
};

export type StateTextPlatform<G extends GetterTextPlatform> =
    | undefined
    | {
          /** A dictionary of data to work with */
          textData: DataText<G>;
          /** The currently enabled language */
          textLanguage: KeyLangText<G>;
          /** A dictionary of available languages */
          textLanguages: LanguageTextPlatform<G>;
          /** Returns a text from the dictionary */
          textGet: (arg: {
              /** An unique identifier corresponding to a text value to retrieve */
              uuid: KeyText<G>;
              /** If the resulting text contains one or more REPLACERS, apply these replacements */
              replace?: string | string[];
              /** In case the provided uuid was not found, return this value instead */
              fallback?: string;
              /** The desired language to retrieve the value with, defaults to current language */
              language?: KeyLangText<G>;
          }) => ValText<G> | string;
          /** Resets the context to the original values and removes it from storage */
          textClear: () => Promise<void>;
          /** Sets the current language */
          textSetLanguage: (language: KeyLangText<G>) => void;
      };

export type ArgCommonTextPlatform<G extends GetterTextPlatform> = Omit<
    ArgTextPlatform<G>,
    "matcher" | "uuid"
> & {
    matcher: NonNullable<ArgTextPlatform<G>["matcher"]>;
    storageName: NonNullable<ArgTextPlatform<G>["storageName"]>;
} & { Context: React.Context<StateTextPlatform<G>> };

export type ArgProviderTextPlatform<G extends GetterTextPlatform> = ArgCommonTextPlatform<G>;

export type PropProviderTextPlatform<G extends GetterTextPlatform> = {
    /** Components where the Consumer will be available */
    children: NonNullable<PropElement["children"]>;
    /** The language that will be enabled by default */
    language: KeyLangText<G>;
    /** What to show while the getter is being loaded */
    loading?: null | JSX.Element;
    /** By default errors will be silent, unless you send a method here where you could catch them */
    onError?: (err: Error) => void;
};

export type ArgConsumerTextPlatform<G extends GetterTextPlatform> = ArgCommonTextPlatform<G>;

export type PropConsumerTextPlatform<G extends GetterTextPlatform> = Omit<
    PropElement,
    "children"
> & {
    /** an unique identifier for the text element to display. */
    uuid: KeyText<G>;
    /** Which HTML element should be used for rendering?  */
    tag?: KeyHTMLText;
    /** A string (or an array of strings) that will be used as replacements on the text. */
    replace?: string | string[];
    /** What to display in case the uuid was not found. if nothing sent, it will use `*${uuid}*`. */
    fallback?: string;
    children?: string | React.ReactNode;
};

export const {
    NAME_TEXTPLATFORM,
    TYPE_TEXTPLATFORM,
    NAME_CONSUMER_TEXTPLATFORM,
    TYPE_CONSUMER_TEXTPLATFORM,
    NAME_PROVIDER_TEXTPLATFORM,
    TYPE_PROVIDER_TEXTPLATFORM,
} = Namespace;

export const ERROR_TEXTPLATFORM = {
    NO_PROVIDER: `${NAME_TEXTPLATFORM}: Expecting a <${NAME_PROVIDER_TEXTPLATFORM} /> to be available.`,
    INVALID_RESPONSE: `${NAME_TEXTPLATFORM}: Invalid response from getter.`,
    INVALID_DATA: `${NAME_TEXTPLATFORM}: current data could not be used.`,
    INVALID_LANG: `${NAME_TEXTPLATFORM}: provided languages is not available.`,
} as const;
