import React from "@platform/react";

import { TYPE } from "@platform/react-storybook/Component";
import { PREFIX_BITMAP_ASSET } from "@common/content/asset/bitmap";
import { PREFIX_VECTOR_ASSET } from "@common/content/asset/vector";
import type { PropDefaults, PropElement, PropTypes } from "@platform/react-storybook/Component";

import { ProviderVectorAsset, useVectorAsset } from "./Vector";
import { ProviderIconAsset } from "./Icon";
import { ProviderBitmapAsset, useBitmapAsset } from "./Bitmap";

export const NAME_PROVIDER_ASSET = "ProviderAsset";
export const TYPE_PROVIDER_ASSET = TYPE.PROVIDER;

export type PropProviderAsset = {
    children: NonNullable<PropElement["children"]>;
    loading?: JSX.Element;
};

export const DEF_PROVIDER_ASSET: PropDefaults<Omit<PropProviderAsset, "loading">> = {};

export function ProviderAsset(props: PropTypes<PropProviderAsset, typeof DEF_PROVIDER_ASSET>) {
    const { children, loading } = props;
    return (
        <ProviderVectorAsset loading={loading}>
            <ProviderIconAsset loading={loading}>
                <ProviderBitmapAsset loading={loading}>
                    <Wrapper>{children}</Wrapper>
                </ProviderBitmapAsset>
            </ProviderIconAsset>
        </ProviderVectorAsset>
    );
}
ProviderAsset.defaultProps = DEF_PROVIDER_ASSET;

function Wrapper(props: { children: NonNullable<PropElement["children"]> }): JSX.Element {
    const { children } = props;

    const { imageData: vectorData } = useVectorAsset();
    const { imageData: bitmapData } = useBitmapAsset();

    React.useEffect(() => {
        if (vectorData) setCSS(PREFIX_VECTOR_ASSET, Object.entries(vectorData));
        if (bitmapData) setCSS(PREFIX_BITMAP_ASSET, Object.entries(bitmapData));
        function setCSS(prefix: string, entries: [string, string][]) {
            entries.forEach(([uuid, url]) =>
                document.documentElement.style.setProperty(`--${prefix}-${uuid}`, `url(${url})`),
            );
        }
    }, [vectorData, bitmapData]);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{children}</React.Fragment>;
}
