export const NAME_SLICE_PAYMENT_TRIAL = "PaymentTrial";

export declare namespace NSSlicePaymentTrial {
    export type State = {
        isOpen: boolean;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear =
        | undefined
        | {
              /** Which property of the slice should be cleared. */
              key: keyof State;
          };
}

export const INIT_SLICE_PAYMENT_TRIAL: NSSlicePaymentTrial.State = {
    isOpen: false,
};
