/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "@platform/react";
import type { EventRegisterMobilePush } from "@platform/utils-react/mobile/push-notification";
import {
    useMobilePush,
    CODE_MOBILE_PUSH,
    RESPONSE_MOBILE_PUSH,
} from "@platform/utils-react/mobile/push-notification";

import { ROUTE_USER } from "@common/content/user";

import { Store, useSelector, useDispatch } from "@front/store";
import { ActionSliceApp, SelectorSliceApp } from "@front/store/slices/app";
import { SelectorSliceSession, PROVIDERS } from "@front/store/slices/session";

import { getPlatform } from "@platform/utils-react";
import type {
    StoreProduct,
    StoreError,
    InAppPurchasesStore,
} from "@platform/utils-react/mobile/purchase";
import { setupMobileInAppPurchasesStore } from "@platform/utils-react/mobile/purchase";
import {
    iosInAppPurchasesInfo,
    androidInAppPurchasesInfo,
    iosInAppPurchaseSetupObject,
    androidInAppPurchaseSetupObject,
} from "@common/content/purchases/purchases";
import { ActionSlicePurchases, SelectorSlicePurchases } from "@front/store/slices/purchases";
import { IS_MOBILE } from "@platform/utils-react/mobile";

import { isPremiumB2B } from "@common/content/landing/uuid";

import type { PropRouter } from "./types";

const VERSION_APP = String(process.env.REACT_APP_VERSION);

const RUN_UNIT = 1;

type PropLogicGlobal = Pick<PropRouter, "uuid"> & {
    children: NonNullable<React.ReactNode>;
};

export function LogicGlobal(props: PropLogicGlobal) {
    const { children, uuid } = props;

    const runs = useSelector(SelectorSliceApp.runs);
    const user = useSelector(SelectorSliceSession.user);

    const onUserRegister = React.useCallback(handleUserDeviceRegister, [user]);
    const onVoid = React.useCallback(() => {}, []); // nothing

    const dispatch = useDispatch();
    // const user = useSelector(SelectorSliceSession.user);
    const provider = useSelector(SelectorSliceSession.provider);
    const productToBePurchased = useSelector(SelectorSlicePurchases.productToBePurchased);
    const transactionID = useSelector(SelectorSliceSession.transactionID);
    const shouldOpenSubscriptions = useSelector(SelectorSlicePurchases.shouldOpenSubscriptions);
    const userOrigin = useSelector(SelectorSliceSession.origin);
    const store = React.useRef<InAppPurchasesStore>(null);

    const [platform, setPlatform] = React.useState<string>("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onMobileDeviceReady = React.useCallback(setupInAppPurchases, [platform, user]);

    React.useEffect(() => {
        (async function () {
            const _platform = await getPlatform();
            setPlatform(_platform);
        })();
    }, []);

    React.useEffect(() => {
        if (!productToBePurchased) return;

        purchaseProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productToBePurchased]);

    React.useEffect(() => {
        if (!shouldOpenSubscriptions) return;

        handleOpenSubscriptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldOpenSubscriptions]);

    React.useEffect(() => {
        if (IS_MOBILE) {
            document.addEventListener("deviceready", onMobileDeviceReady);
        }
        return () => {
            if (!IS_MOBILE) return;
            document.removeEventListener("deviceready", onMobileDeviceReady);
        };
    }, [onMobileDeviceReady]);

    useMobilePush({ onRegister: uuid === "user" ? onUserRegister : onVoid });

    // Sets the app version and run count.
    React.useEffect(
        handlePersistSet,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return children as JSX.Element;

    function handlePersistSet() {
        Store.dispatch(ActionSliceApp.set({ runs: runs + RUN_UNIT, version: VERSION_APP }));
    }

    function handleUserDeviceRegister(
        ...args: Parameters<EventRegisterMobilePush>
    ): ReturnType<EventRegisterMobilePush> {
        const [error, data] = args;
        // when not in cordova, or push notifications are denied, do nothing.
        if (error instanceof RESPONSE_MOBILE_PUSH[CODE_MOBILE_PUSH.NOT_MOBILE]) return;
        if (error) {
            if (error instanceof Error) throw error;
            throw new Error(String(error));
        }
        fetch(`${process.env.REACT_APP_MS_USER_ROOT}${ROUTE_USER.DEBUG_INFO_PUT_ONE}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...data, user }),
        });
    }

    async function setupInAppPurchases() {
        function isAppleDevice() {
            const formattedPlatform = platform.toLocaleLowerCase();
            return formattedPlatform === "ios" || formattedPlatform === "ipados";
        }

        function handleSuccessfulPurchase(product: StoreProduct) {
            // console.log("InAppPurchasesStore: Purchase successful", product?.id);
            dispatch(
                ActionSlicePurchases.set({
                    ownedProduct: product,
                    error: undefined,
                    productToBePurchased: undefined,
                    isLoading: false,
                }),
            );
        }

        function handleErrorPurchase(error: StoreError) {
            // console.log("InAppPurchasesStore: Purchase error", JSON.stringify(error));
            dispatch(
                ActionSlicePurchases.set({
                    ownedProduct: undefined,
                    error,
                    productToBePurchased: undefined,
                    isLoading: false,
                }),
            );
        }

        function handleGeneralError(error: StoreError) {
            // console.log("InAppPurchasesStore: General error", JSON.stringify(error));
            dispatch(
                ActionSlicePurchases.set({
                    ownedProduct: undefined,
                    error: undefined,
                    isLoading: false,
                }),
            );
        }

        function handleOnCancelPurchase(product: StoreProduct) {
            // console.log("InAppPurchasesStore: Purchase cancelled", product?.id);
            dispatch(
                ActionSlicePurchases.set({
                    ownedProduct: undefined,
                    error: undefined,
                    productToBePurchased: undefined,
                    isLoading: false,
                }),
            );
        }

        function handleOnRequestPurchase(product: StoreProduct) {
            // console.log("InAppPurchasesStore: Purchase cancelled", product?.id);
            dispatch(
                ActionSlicePurchases.set({
                    ownedProduct: undefined,
                    error: undefined,
                    productToBePurchased: undefined,
                    isLoading: true,
                }),
            );
        }

        function handleOnProductUpdated(product: StoreProduct) {
            if (!product) return;

            if (product.owned) {
                if (product.transaction.id && transactionID !== product.transaction.id) {
                    dispatch(
                        ActionSlicePurchases.set({
                            ownedProduct: product,
                            error: undefined,
                            productToBePurchased: undefined,
                            isLoading: false,
                        }),
                    );
                }
                // console.log("InAppPurchasesStore: product.id owned", product.id);
            }
        }

        if (platform === "" || !user || provider !== PROVIDERS.INAPP || isPremiumB2B(userOrigin))
            return;

        if (IS_MOBILE) {
            const setupObject = isAppleDevice()
                ? iosInAppPurchaseSetupObject
                : androidInAppPurchaseSetupObject;

            const productsInfo = isAppleDevice()
                ? iosInAppPurchasesInfo
                : androidInAppPurchasesInfo;

            const { inAppIds, validatorId } = setupObject;

            store.current = await setupMobileInAppPurchasesStore({
                inAppIds,
                validatorId,
                onSuccessfulPurchase: handleSuccessfulPurchase,
                onErrorPurchase: handleErrorPurchase,
                onGeneralError: handleGeneralError,
                onCancelPurchase: handleOnCancelPurchase,
                onProductUpdated: handleOnProductUpdated,
                onRequestPurchase: handleOnRequestPurchase,
            });

            if (!store || !store.current) return;

            const currentInAppPurchases: StoreProduct[] = [];
            setupObject.inAppIds.forEach((item) => {
                currentInAppPurchases.push(store.current?.get(item));
            });

            // Load information about products and purchases
            dispatch(
                ActionSlicePurchases.set({
                    productsInfo,
                    products: currentInAppPurchases,
                    productToBePurchased: undefined,
                }),
            );
            store.current.refresh();
        }
    }

    function purchaseProduct() {
        if (!productToBePurchased || !store || !store.current) return;
        // console.log("InAppPurchasesStore: order", productToBePurchased);
        store.current.order(productToBePurchased);
    }

    function handleOpenSubscriptions() {
        if (!store || !store.current) return;
        store.current.manageSubscriptions();
        dispatch(ActionSlicePurchases.set({ shouldOpenSubscriptions: false }));
    }
}
