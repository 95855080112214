import React from "@platform/react";
import { Component, getClassName, useCombinedRefs } from "@platform/react-storybook/Component";
import { PREFIX_BITMAP_ASSET } from "@common/content/asset/bitmap";
import { PREFIX_VECTOR_ASSET } from "@common/content/asset/vector";

import { IconAsset } from "@front/components/Element/Asset/Icon";
import { getVar } from "@front/components/Theme";
import Rules from "./_rules.module.scss";
import {
    ERROR_BOX,
    NAME_BOX,
    TYPE_BOX,
    DEF_BOX,
    NAME_ASSET_BOX,
    TYPE_ASSET_BOX,
    NAME_CONTENT_BOX,
    TYPE_CONTENT_BOX,
    NAME_COVER_BOX,
    TYPE_COVER_BOX,
    NAME_DISABLED_BOX,
    TYPE_DISABLED_BOX,
} from "./types";
import type { PropTypesBox } from "./types";

/**
 * It defines the external appearance through its margin, border and padding properties,
 * the background through its bg-type property and the availability with a special element
 * that can locked the use of the content underneath.
 */
export const Box = React.forwardRef(
    (props: PropTypesBox, refForwarded: React.ForwardedRef<HTMLDivElement>) => {
        const { preset, bitmap, vector, children, locked, blocked, ...rest } = props;

        const refElement = React.useRef<HTMLDivElement>(null);
        const refCombined = useCombinedRefs({ refForwarded, refElement });

        React.useEffect(() => {
            const { current: element } = refElement;
            if (!element) return;
            if (bitmap && vector) {
                // eslint-disable-next-line no-console
                console.error(new Error(ERROR_BOX.DUPE_ASSET));
                return;
            }
            if (bitmap) {
                element.style.setProperty("--bitmap", getVar(`${PREFIX_BITMAP_ASSET}-${bitmap}`));
            }
            if (vector) {
                element.style.setProperty("--vector", getVar(`${PREFIX_VECTOR_ASSET}-${vector}`));
            }
        }, [bitmap, vector]);

        const hasAsset = bitmap || vector;
        const className = getClassName(preset ? Rules[preset] : "", rest.className);

        return (
            <Component
                ref={refCombined}
                name={NAME_BOX}
                type={TYPE_BOX}
                {...rest}
                className={className}
                tag="section">
                {hasAsset && <figure data-name={NAME_ASSET_BOX} data-type={TYPE_ASSET_BOX} />}
                <div data-name={NAME_CONTENT_BOX} data-type={TYPE_CONTENT_BOX}>
                    {children}
                </div>
                {locked && (
                    <aside data-name={NAME_COVER_BOX} data-type={TYPE_COVER_BOX}>
                        <IconAsset fill="chile" uuid="locked" />
                    </aside>
                )}
                {blocked && <aside data-name={NAME_DISABLED_BOX} data-type={TYPE_DISABLED_BOX} />}
            </Component>
        );
    },
);
Box.defaultProps = DEF_BOX;
