/// <reference types="cordova-plugin-purchase" />

import { MobileInfo } from ".";

type ArgMobileInApp = {
    inAppIds?: string[];
    validatorId?: string;
    onSuccessfulPurchase?: (product: StoreProduct) => void;
    onErrorPurchase?: (error: StoreError) => void;
    onGeneralError?: (error: StoreError) => void;
    onCancelPurchase?: (product: StoreProduct) => void;
    onProductUpdated?: (product: StoreProduct) => void;
    onRequestPurchase?: (product: StoreProduct) => void;
};

export type InAppPurchasesStore = IapStore.IStore | null;
export type StoreProduct = IapStore.IStoreProduct | undefined;
export type StoreError = IapStore.IError | undefined;

export async function setupMobileInAppPurchasesStore(
    arg: ArgMobileInApp
): Promise<null | InAppPurchasesStore> {
    const info = await MobileInfo();
    if (!info) return null;

    const {
        inAppIds,
        validatorId,
        onSuccessfulPurchase,
        onErrorPurchase,
        onGeneralError,
        onCancelPurchase,
        onProductUpdated,
        onRequestPurchase,
    } = arg;

    if (!inAppIds || !validatorId) return null;

    const inApps = inAppIds?.flatMap((item) => ({ id: item, type: store.PAID_SUBSCRIPTION }));

    if (!inApps) return null;

    // We should first register all our products or we cannot use them in the app.
    store.register(inApps);

    // Error Handler
    store.error((e) => {
        onGeneralError?.(e);
    });

    // Setup the receipt validator service.
    store.validator = validatorId;

    store.verbosity = store.INFO;
    store.autoFinishTransactions = true;

    // Check subscription state
    store
        .when("subscription")
        .updated((product) => {
            onProductUpdated?.(product);
        })
        .approved((product) => {
            product.verify();
        })
        .verified((product) => {
            product.finish();
        })
        .owned((product) => {
            onSuccessfulPurchase?.(product);
        })
        .cancelled((product) => {
            onCancelPurchase?.(product);
        })
        .error((error) => {
            onErrorPurchase?.(error);
        })
        .requested((product) => {
            onRequestPurchase?.(product);
        });

    return store;
}
