import type { PropDefaults, PropElement, PropTypes } from "@platform/react-storybook/Component";

import Namespace from "./_index.scss";

export const { NAME_SECTION_ERROR_INTERNAL, TYPE_SECTION_ERROR_INTERNAL } = Namespace;

export type PropSectionErrorInternal = PropElement;

export const DEF_SECTION_ERROR_INTERNAL: PropDefaults<PropSectionErrorInternal> = {};

export type PropTypesSectionErrorInternal = PropTypes<
    PropSectionErrorInternal,
    typeof DEF_SECTION_ERROR_INTERNAL
>;
