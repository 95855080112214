import type {
    CalendarTraining,
    DayType,
    Program,
    PropRoutine,
    PropRoutineTask,
    WeekCalendarItem,
    WeekType,
} from "@common/content/crack/programs";
import { CreateReducer } from "@platform/react/store";
import { ActionSlicePrograms } from "./_actions";
import type { NSSlicePrograms } from "./_namespace";
import { INIT_SLICE_PROGRAMS } from "./_namespace";
import { getTimeFromSecondsFormatted } from "./_utils";

const NEXT = 1;
const DEFAULT_CYCLE = 0;
const PLUS_CYCLE = 1;

export const ReducerSlicePrograms = CreateReducer(INIT_SLICE_PROGRAMS, ({ addCase }) => {
    addCase(ActionSlicePrograms.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSlicePrograms.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_PROGRAMS;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });

    addCase(ActionSlicePrograms.setTimer, (state, { payload }) => {
        state.timer = payload;
    });

    addCase(ActionSlicePrograms.setTaskCyclesComplete, (state, { payload: { uuidWorkout } }) => {
        if (!uuidWorkout || !state.currentCrackProgram || !state.training) return;
        const training = getTraining(state.training, state.day, state.week);
        if (!training) return;
        const workout = training.workout.find((work) => work["uuid-workout"] === uuidWorkout);
        if (!workout) return;
        workout.tasks.map((task) => {
            task.hasFinished = true;
            return task;
        });
        state.training = [...state.training];
        state.tasks = INIT_SLICE_PROGRAMS.tasks;
    });

    addCase(ActionSlicePrograms.setRoutineComplete, (state) => {
        if (!state.training) return;
        const training = getTraining(state.training, state.day, state.week);
        if (!training) return;
        training.hasFinished = true;
        training.workout.map((_workout) => {
            _workout.time = state.timerForWorkout[_workout["uuid-workout"]];
            return _workout;
        });
        training.time = calculateTrainingTime(state);
        state.training = [...state.training];
        state.calendar = enabledDays(state.calendar, state.day, state.week);
    });

    addCase(ActionSlicePrograms.saveSurvey, (state, { payload: { physical, emotional } }) => {
        if (!state.training) return;
        const training = getTraining(state.training, state.day, state.week);
        if (!training) return;
        const trainingIdx = getTrainingIndex(state.training, state.day, state.week);
        training.emotionalSurvey = emotional;
        training.physicalSurvey = physical;
        state.training[trainingIdx] = training;
        state.training = [...state.training];
    });

    addCase(ActionSlicePrograms.nextTraining, (state) => {
        if (!state.training) return;
        state.day = nextDay(
            state.day,
            state.week,
            getLastDayAvailable(state.calendar, state.week),
            getLastWeekAvailable(state.calendar),
        );
        state.week = weekDay(state.week, state.day, getLastWeekAvailable(state.calendar));
        state.calendar = enabledDays(state.calendar, state.day, state.week);
        // clean flags for program manage
        state.isLastTask = INIT_SLICE_PROGRAMS.isLastTask;
        state.isLastWorkout = INIT_SLICE_PROGRAMS.isLastWorkout;
        state.activeTask = INIT_SLICE_PROGRAMS.activeTask;
        state.activeVideo = INIT_SLICE_PROGRAMS.activeVideo;
        state["uuid-workout"] = INIT_SLICE_PROGRAMS["uuid-workout"];
        state.nameNextTask = INIT_SLICE_PROGRAMS.nameNextTask;
        state.lastCycle = INIT_SLICE_PROGRAMS.lastCycle;
        state.cycleForWorkout = INIT_SLICE_PROGRAMS.cycleForWorkout;
        state.timerForWorkout = INIT_SLICE_PROGRAMS.timerForWorkout;
        state.timer = INIT_SLICE_PROGRAMS.timer;
        state.startTime = INIT_SLICE_PROGRAMS.startTime;
        state.partialTime = INIT_SLICE_PROGRAMS.partialTime;
        state.endTime = INIT_SLICE_PROGRAMS.endTime;
    });

    addCase(ActionSlicePrograms.cleanTasks, (state) => {
        cleanTasks(state.tasks);
    });

    addCase(ActionSlicePrograms.setRoutineStartTime, (state) => {
        state.startTime = state.timer;
    });

    addCase(ActionSlicePrograms.setRoutinePartialTime, (state) => {
        state.partialTime = [...state.partialTime, state.timer];
    });

    addCase(ActionSlicePrograms.setRoutineEndTime, (state, { payload: { uuidWorkout } }) => {
        state.endTime = state.timer;
        if (state.timerForWorkout && state.timerForWorkout[uuidWorkout]) return;
        state.timerForWorkout = {
            ...state.timerForWorkout,
            [uuidWorkout]: calculateWorkoutTime(state),
        };
        state.startTime = state.timer;
    });

    addCase(ActionSlicePrograms.setTaskComplete, (state, { payload }) => {
        if (!payload || !state.tasks) return;
        const mappedTasks = state.tasks.map((task) => {
            if (task["uuid-task"] !== payload["uuid-task"]) return task;
            task.hasFinished = true;
            return task;
        });
        if (!mappedTasks) return;
        state.tasks = mappedTasks;
    });

    addCase(
        ActionSlicePrograms.setActualVideo,
        (state, { payload: { activeTask, activeVideo } }) => {
            if (!activeVideo || !activeTask) return;
            state.activeVideo = activeVideo;
            state.isShowMeHow = false;
            const idxActualTask = getTaskIndex(state.tasks, state.activeTask);
            const tasksLength = state.tasks.length - NEXT;
            const isLastTask = tasksLength === idxActualTask;
            state.isLastTask = isLastTask;
            state.activeTask = {
                ...activeTask,
                hasFinished: activeTask.taskType === "task" ? true : false,
            };
            state.nameNextTask = getNextTaskName(isLastTask, state.tasks, activeTask);
        },
    );

    addCase(ActionSlicePrograms.setFirstWorkout, (state, { payload: { uuidWorkout } }) => {
        if (!uuidWorkout || !state.training) return;
        const currentWorkout = getTraining(state.training, state.day, state.week);
        const workout = getWorkout(currentWorkout, uuidWorkout);
        if (!workout) return;
        const activeTasks = workout.tasks;
        const activeTask = workout.tasks[0];
        const firstProgramVideo = workout.tasks[0]["uuid-video"];

        state.activeVideo = firstProgramVideo;
        state.isShowMeHow = false;
        state.tasks = activeTasks;
        state.activeTask = {
            ...activeTask,
            hasFinished: activeTask.taskType === "task" ? true : false,
        };
        state["uuid-workout"] = uuidWorkout;
        state.workoutType = workout.workoutType;
        state.nameNextTask = getNextTaskName(false, state.tasks, activeTask);
    });

    addCase(ActionSlicePrograms.setNextVideo, (state, { payload }) => {
        if (!state.training) return;
        let nextTask: PropRoutineTask | null = null;
        let nextWorkout: PropRoutine | null = null;
        let isLastCycle = false;

        const currentWorkout = getTraining(state.training, state.day, state.week);
        const workout = getWorkout(currentWorkout, state["uuid-workout"]);
        if (!currentWorkout || !workout) return;
        const uuidWorkout = workout["uuid-workout"];

        const idxActualTask = getTaskIndex(state.tasks, state.activeTask);
        const tasksLength = state.tasks.length - NEXT;
        const isLastTask = tasksLength === idxActualTask;
        nextTask = getNextTask(state.tasks, idxActualTask);
        state.isLastTask = isLastTask;

        const indexWorkout = getWorkoutIndex(currentWorkout, state["uuid-workout"]);
        nextWorkout = currentWorkout.workout[indexWorkout + NEXT];

        state.nameNextTask = getNextTaskName(isLastTask, state.tasks, nextTask);

        if (nextTask) {
            handleNextTask(state, nextTask, Boolean(payload?.isFullScreen));
        } else {
            state.cycleForWorkout = setCycleWorkout(state.cycleForWorkout, uuidWorkout);
            state.progressTask[uuidWorkout] = {
                [uuidWorkout]: undefined,
            };
            isLastCycle = getLastCycle(state.cycleForWorkout, workout);
            if (isLastCycle) workout.endTime = state.timer;
            if (!isLastCycle) cleanTasks(state.tasks);

            if (nextWorkout || !isLastCycle) {
                handleNextWorkout(state, isLastCycle, nextWorkout, workout);
                state.nameNextTask = getNextCycleTaskName(isLastCycle, state.tasks, nextWorkout);
            }
            if (!nextWorkout && isLastCycle) currentWorkout.hasFinished = true;
        }
    });

    addCase(ActionSlicePrograms.setNextWorkout, (state, { payload }) => {
        const { "uuid-workout": uuidClickWorkout, hasFinishedAMRAP } = payload;
        if (!state.training) return;

        const currentWorkout = getTraining(state.training, state.day, state.week);

        if (!currentWorkout) return;

        const indexWorkout = getWorkoutIndex(currentWorkout, state["uuid-workout"]);

        const { totalCycles } = currentWorkout.workout[indexWorkout];

        const nextWorkout = currentWorkout.workout[indexWorkout + NEXT];
        const isLastCycle =
            totalCycles === state.cycleForWorkout[uuidClickWorkout] &&
            state.workoutType !== "AMRAP";

        if (nextWorkout || !isLastCycle || hasFinishedAMRAP) {
            handleNextWorkout(
                state,
                isLastCycle || hasFinishedAMRAP,
                nextWorkout,
                currentWorkout.workout[indexWorkout],
            );
            state.nameNextTask = getNextCycleTaskName(false, state.tasks, nextWorkout);
        }
        if (!nextWorkout && isLastCycle) currentWorkout.hasFinished = true;
    });

    addCase(ActionSlicePrograms.setTaskTimeProgress, (state, { payload }) => {
        const { uuid, progress, cycleForWorkout, uuidWorkout } = payload;
        state.progressTask[uuidWorkout] = {
            ...state.progressTask[uuidWorkout],
            cycleForWorkout,
            [uuid]: progress,
        };
    });
});

function nextDay(
    day: DayType,
    week: WeekType,
    lastDayAvailable?: number,
    lastWeekAvailable?: number,
): DayType {
    const isLastDayAvailable = day === String(lastDayAvailable);
    const isLastWeekAvailable = week === String(lastWeekAvailable);
    if (isLastDayAvailable && isLastWeekAvailable) return day;
    if (day === String(lastDayAvailable)) return "1";
    if (day === "7") return "1";
    const NEXT_DAY = 1;
    return `${Number(day) + NEXT_DAY}` as DayType;
}

function weekDay(week: WeekType, day: DayType, lastWeekAvailable?: number): WeekType {
    if (week === String(lastWeekAvailable)) return week;
    // TODO: i think we can delete this line, the upon one cached it
    if (week === "8") return "8";
    if (day === "1") {
        const NEXT_WEEK = 1;
        return `${Number(week) + NEXT_WEEK}` as WeekType;
    }
    return week;
}

function enabledDays(
    calendar: WeekCalendarItem[],
    day: DayType,
    week: WeekType,
): WeekCalendarItem[] {
    if (day === "1") {
        calendar[Number(week)].isEnabled = true;
    }
    const daysItems = calendar[Number(week)]["days-items"];
    if (!daysItems) return calendar;
    daysItems[Number(day)].isEnabled = true;
    return calendar;
}

function getLastWeekAvailable(calendar: WeekCalendarItem[]) {
    const ONE = 1;
    if (!calendar) return undefined;
    return calendar?.length - ONE;
}

function getLastDayAvailable(calendar: WeekCalendarItem[], week: WeekType): number | undefined {
    const ONE = 1;
    const days = calendar[Number(week)]["days-items"];
    if (!days) return undefined;
    return days.length - ONE;
}

function getTraining(training: Program["calendar-training"], day: DayType, week: WeekType) {
    return training.find((_training) => _training.day === day && _training.week === week);
}

function getTrainingIndex(training: Program["calendar-training"], day: DayType, week: WeekType) {
    return training.findIndex((_training) => _training.day === day && _training.week === week);
}

function getTaskIndex(tasks: PropRoutineTask[], activeTask: PropRoutineTask | undefined) {
    return tasks.findIndex((task) => task["uuid-task"] === activeTask?.["uuid-task"]);
}

function getNextTask(tasks: PropRoutineTask[], idxActualTask: number) {
    return tasks[idxActualTask + NEXT];
}

function getNextTaskName(isLastTask: boolean, tasks: PropRoutineTask[], nextTask: PropRoutineTask) {
    const index = getTaskIndex(tasks, nextTask);
    const proNextTask = tasks[index + NEXT];

    if (isLastTask || !proNextTask)
        return {
            title: "crack-program-round-complete",
        };
    return {
        amount: proNextTask.amount,
        title: proNextTask.title,
    };
}

function getNextCycleTaskName(
    isLastCycle: boolean,
    tasks: PropRoutineTask[],
    nextWorkout: PropRoutine,
) {
    if (!tasks.length || !tasks[1])
        return {
            amount: tasks[0].amount,
            title: tasks[0].title,
        };
    if (!isLastCycle || !nextWorkout)
        return {
            amount: tasks[1].amount,
            title: tasks[1].title,
        };
    const next = nextWorkout.tasks[0];
    return {
        amount: next.amount,
        title: next.title,
    };
}

function getWorkout(calendar: CalendarTraining | undefined, uuidWorkout: string) {
    return calendar?.workout.find((w) => w["uuid-workout"] === uuidWorkout);
}

function getWorkoutIndex(calendar: CalendarTraining, uuidWorkout: string) {
    return calendar.workout.findIndex((w) => w["uuid-workout"] === uuidWorkout);
}

function setCycleWorkout(cycleForWorkout: { [key: string]: number }, uuidWorkout: string) {
    return {
        ...cycleForWorkout,
        [uuidWorkout]: (cycleForWorkout[uuidWorkout] || DEFAULT_CYCLE) + PLUS_CYCLE,
    };
}

function getLastCycle(cycleForWorkout: { [key: string]: number }, workout: PropRoutine) {
    const cycleCurrent =
        (cycleForWorkout && cycleForWorkout[workout["uuid-workout"]]) || DEFAULT_CYCLE;
    const totalCycle = workout.totalCycles;

    return cycleCurrent === totalCycle && workout.workoutType !== "AMRAP";
}

function getActiveTask(isLastCycle: boolean, nextWorkout: PropRoutine, tasks: PropRoutineTask[]) {
    return isLastCycle ? nextWorkout.tasks[0] : tasks[0];
}

function getTasks(
    isLastCycle: boolean,
    nextWorkout: PropRoutine,
    tasks: PropRoutineTask[],
): PropRoutineTask[] {
    if (!tasks[0]) return nextWorkout.tasks;
    tasks[0].hasFinished = tasks[0]?.taskType === "task" ? true : false;
    return isLastCycle ? nextWorkout.tasks : tasks;
}

function getActiveVideo(isLastCycle: boolean, nextWorkout: PropRoutine, tasks: PropRoutineTask[]) {
    return isLastCycle
        ? nextWorkout.tasks[0] && nextWorkout.tasks[0]["uuid-video"]
        : tasks[0] && tasks[0]["uuid-video"];
}

function getActiveWorkout(isLastCycle: boolean, nextWorkout: PropRoutine, uuidWorkout: string) {
    return isLastCycle ? nextWorkout["uuid-workout"] : uuidWorkout;
}

function getWorkoutType(isLastCycle: boolean, nextWorkout: PropRoutine, workout: PropRoutine) {
    return isLastCycle ? nextWorkout.workoutType : workout.workoutType;
}

function cleanTasks(tasks: PropRoutineTask[]) {
    if (!tasks) return;
    const mappedTasks = tasks.map((task) => {
        task.hasFinished = false;
        return task;
    });
    if (!mappedTasks) return;
    tasks = mappedTasks;
}

function setHasFinished(taskType: PropRoutineTask["taskType"], isFullScreen: boolean) {
    if (taskType === "task") return true;
    if (isFullScreen && (taskType === "time" || taskType === "break")) return true;
    return false;
}

function handleNextWorkout(
    state: Partial<NSSlicePrograms.State>,
    isLastCycle: boolean,
    nextWorkout: PropRoutine,
    currentWorkout: PropRoutine,
) {
    if (!state.tasks || !state["uuid-workout"]) return;

    state.tasks = getTasks(isLastCycle, nextWorkout, state.tasks);
    state.activeTask = getActiveTask(isLastCycle, nextWorkout, state.tasks);
    state.activeVideo = getActiveVideo(isLastCycle, nextWorkout, state.tasks);
    state.isShowMeHow = false;
    state["uuid-workout"] = getActiveWorkout(isLastCycle, nextWorkout, state["uuid-workout"]);
    state.workoutType = getWorkoutType(isLastCycle, nextWorkout, currentWorkout);
}

function handleNextTask(
    state: Partial<NSSlicePrograms.State>,
    nextTask: PropRoutineTask,
    isFullScreen: boolean,
) {
    state.activeTask = {
        ...nextTask,
        hasFinished: setHasFinished(nextTask.taskType, Boolean(isFullScreen)),
    };

    state.activeVideo = nextTask["uuid-video"];
    state.isShowMeHow = false;
}

function calculateWorkoutTime(state: Partial<NSSlicePrograms.State>): NSSlicePrograms.timer {
    if (!state.timer || !state.endTime || !state.startTime) return INIT_SLICE_PROGRAMS.timer;
    const {
        days: endDays,
        hours: endHours,
        minutes: endMinutes,
        seconds: endSeconds,
    } = state.endTime;
    const {
        days: startDays,
        hours: startHours,
        minutes: startMinutes,
        seconds: startSeconds,
    } = state.startTime;
    return {
        days: endDays - startDays,
        hours: endHours - startHours,
        minutes: endMinutes - startMinutes,
        seconds: endSeconds - startSeconds,
    };
}

function calculateTrainingTime(state: Partial<NSSlicePrograms.State>): string {
    if (!state.timer || !state.timerForWorkout) return "00:00";
    const values = Object.values(state.timerForWorkout);
    const INIT_VALUE = 0;
    // if (!values) return "00:00";
    const daysSum = values.map((a) => a.days)?.reduce((a, b) => a + b, INIT_VALUE);
    const hoursSum = values.map((a) => a.hours)?.reduce((a, b) => a + b, INIT_VALUE);
    const minutesSum = values.map((a) => a.minutes)?.reduce((a, b) => a + b, INIT_VALUE);
    const secondsSum = values.map((a) => a.seconds)?.reduce((a, b) => a + b, INIT_VALUE);
    const timer = getTimeFromSecondsFormatted({
        days: daysSum,
        hours: hoursSum,
        minutes: minutesSum,
        seconds: secondsSum,
    });
    const { days, hours, minutes, seconds } = timer;
    if (!daysSum && !hoursSum) return `${minutes}:${seconds}`;
    if (!daysSum) return `${hours}:${minutes}:${seconds}`;
    return `${days}:${hours}:${minutes}:${seconds}`;
}
