import { CreateReducer } from "@platform/react/store";
import { ActionSliceSecret } from "./_actions";
import { INIT_SLICE_SECRET } from "./_namespace";

export const ReducerSliceSecret = CreateReducer(INIT_SLICE_SECRET, ({ addCase }) => {
    addCase(ActionSliceSecret.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSliceSecret.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_SECRET;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });

    addCase(ActionSliceSecret.setProgram, (state, { payload }) => {
        state.program = payload;
        if (!payload) return;
        const { content } = payload;
        if (!content) return;
        if (payload?.hasFinished || content.some((c) => c.hasFinished)) {
            state.isFirstTime = false;
            state.currentDay = String(content.find((c) => !c.hasFinished)?.day) ?? "1";
        }
    });

    addCase(ActionSliceSecret.clearDailyTraining, (state) => {
        state.shouldUpdateProgram = true;
        state.currentDayTraining = [];
    });

    addCase(ActionSliceSecret.setDailyTraining, (state, { payload }) => {
        state.currentDayTraining = [...state.currentDayTraining, payload];
    });

    addCase(ActionSliceSecret.setNewDay, (state) => {
        if (!state.program) return;
        const NEXT_DAY = 1;
        const newDay = Number(state.currentDay) + NEXT_DAY;
        const { duration } = state.program;
        const newCurrentDay = newDay > duration ? duration : newDay;
        state.currentDay = String(newCurrentDay);
        state.currentDayTraining = [];
    });

    addCase(ActionSliceSecret.startsProgram, (state) => {
        state.isFirstTime = false;
    });

    addCase(ActionSliceSecret.setDailyFinished, (state) => {
        const newProgram = state.program;
        if (!newProgram) return;
        const PLUS = 1;
        const { content } = newProgram;
        if (!content) return;
        const newProgramDaily = content[Number(state.currentDay) - PLUS];
        if (!newProgramDaily) return;
        newProgramDaily.hasFinished = true;
        state.program = { ...newProgram };
    });

    addCase(ActionSliceSecret.setHasViewedTips, (state) => {
        state.hasSeenTips = true;
    });

    addCase(ActionSliceSecret.clearHasViewedTips, (state) => {
        state.hasSeenTips = false;
    });
});
