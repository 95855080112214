import { PathGetWww } from "./www";
import { PathGetUser } from "./user";
import { PathGetCommon } from "./common";

export const WILD = "*" as const;
export const ROOT = "/" as const;
export const ROOT_USER = ROOT;
export const ROOT_WWW = ROOT;

const { COMMON } = PathGetCommon(ROOT);
const { WWW } = PathGetWww(ROOT_WWW);
const { USER } = PathGetUser(ROOT_USER);

export const PATH = {
    // Base
    ROOT,
    WILD,
    COMMON,
    WWW: { ...WWW, ROOT: ROOT_WWW },
    USER: { ...USER, ROOT: ROOT_USER },
};
