import { CreateReducer } from "@platform/react/store";
import { ActionSliceApp } from "./_actions";
import { INIT_SLICE_PERSIST } from "./_namespace";

export const ReducerSliceApp = CreateReducer(INIT_SLICE_PERSIST, ({ addCase }) => {
    addCase(ActionSliceApp.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSliceApp.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_PERSIST;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });
});
