import { CreateReducer } from "@platform/react/store";
import { ActionSlicePaymentTrial } from "./_actions";
import { INIT_SLICE_PAYMENT_TRIAL } from "./_namespace";

export const ReducerSlicePaymentTrial = CreateReducer(INIT_SLICE_PAYMENT_TRIAL, ({ addCase }) => {
    addCase(ActionSlicePaymentTrial.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSlicePaymentTrial.clear, (state, { payload }) => {
        if (!payload) return INIT_SLICE_PAYMENT_TRIAL;
        const { key } = payload;
        return { ...state, [key]: INIT_SLICE_PAYMENT_TRIAL[key] };
    });
});
