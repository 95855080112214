import React from "@platform/react";
import { CreateImagePlatform } from "@platform/react-storybook/Bundle/Image";
import { GROUP_ICON_ASSET } from "@common/content/asset/icon";
import { getVar, Theme } from "@front/components/Theme";
import { Box } from "@front/components/Element/Box";
import { Text, useText } from "@front/components/Element/Text";
import { Component } from "@platform/react-storybook/Component";
import type { PropDefaults, PropTypes } from "@platform/react-storybook/Component";
import type { UuidBoxTheme } from "@common/content/theme/box";
import type { UuidPresetText, UuidText } from "@front/components/Element/Text/types";
import type { UuidIconAsset } from "@common/content/asset/icon";
import type { UuidColorTheme } from "@common/content/theme/color";
import type {
    PropConsumerImagePlatform,
    PropProviderImagePlatform,
} from "@platform/react-storybook/Bundle/Image/types";

import { GetterAsset } from "..";
import Namespace from "./_index.scss";
import "./_rules.scss";

export async function getterIconAsset() {
    const assets = await GetterAsset();
    const bitmaps = assets.reduce((acc, { type, url, uuid }) => {
        if (type !== GROUP_ICON_ASSET) return acc;
        return { ...acc, [uuid]: url };
    }, {} as { [K in UuidIconAsset]: string });
    return Promise.resolve(bitmaps);
}

export type GetterIconAsset = typeof getterIconAsset;

export const { NAME_ICON_ASSET, TYPE_ICON_ASSET } = Namespace;

export const { ProviderImagePlatform, ImagePlatform, useImagePlatform } = CreateImagePlatform({
    getter: getterIconAsset,
});

export type PropProviderIcon = PropProviderImagePlatform;
export const ProviderIconAsset = ProviderImagePlatform;

export function useIconAsset() {
    return useImagePlatform();
}

type PropPlatform = Omit<
    PropConsumerImagePlatform<GetterIconAsset>,
    "onInject" | "evalScripts" | "renumerateIRIElements" | "caption" | "tag"
>;

export type PropIconAsset = PropPlatform & {
    /** A color to fill the icon with */
    fill?: UuidColorTheme;
    /** A color to use for icon strokess */
    stroke?: UuidColorTheme;
    /** A text that describes what the icon represents */
    caption?: UuidText;
    /** A preset for the caption text */
    "caption-preset"?: UuidPresetText;
    /** A box preset to apply to only the icon */
    box?: UuidBoxTheme;
};

export type UuidIcon = PropIconAsset["uuid"];
export const DEF_ICON_ASSET: PropDefaults<
    Omit<PropIconAsset, "caption" | "fill" | "stroke" | "caption-preset" | "box">
> = {};

export type PropTypesIconAsset = PropTypes<PropIconAsset, typeof DEF_ICON_ASSET>;

export function IconAsset(props: PropTypesIconAsset) {
    const {
        uuid,
        fill,
        stroke,
        box = "none",
        caption: captionUuid,
        "caption-preset": captionPreset,
        ...rest
    } = props;
    const { textGet } = useText();

    const content = !captionUuid ? undefined : (
        <figcaption>{textGet({ uuid: captionUuid })}</figcaption>
    );
    const caption = !captionPreset ? content : <Text preset={captionPreset}>{content}</Text>;

    const theme: Record<string, string> = {};
    if (fill) theme.fill = getVar(`color-${fill}`);
    if (stroke) theme.stroke = getVar(`color-${stroke}`);

    return (
        <Theme vars={theme}>
            <Component name={NAME_ICON_ASSET} type={TYPE_ICON_ASSET} {...rest} tag="figure">
                <Box {...rest} preset={box}>
                    <ImagePlatform tag="div" {...rest} uuid={uuid} />
                </Box>
                {caption}
            </Component>
        </Theme>
    );
}
IconAsset.defaultProps = DEF_ICON_ASSET;
