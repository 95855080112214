import { CreateTrackerPlatform } from "@platform/react-storybook/Bundle/Tracker";
import {
    DEF_CONSUMER_TRACKERPLATFORM,
    DEF_PROVIDER_TRACKERPLATFORM,
    TYPE_TRACKERPLATFORM,
    TYPE_PROVIDER_TRACKERPLATFORM,
} from "@platform/react-storybook/Bundle/Tracker/types";
import type { PropConsumerTrackerPlatform } from "@platform/react-storybook/Bundle/Tracker/types";

const { TrackerPlatform, ProviderTrackerPlatform, useTrackerPlatform } = CreateTrackerPlatform();

export const NAME_TRACKER = "Tracker";
export const TYPE_TRACKER = TYPE_TRACKERPLATFORM;

export type PropTracker = PropConsumerTrackerPlatform;
export const DEF_TRACKER = DEF_CONSUMER_TRACKERPLATFORM;

export const Tracker = TrackerPlatform;
Tracker.displayName = NAME_TRACKER;
Tracker.defaultProps = DEF_CONSUMER_TRACKERPLATFORM;

export const NAME_PROVIDER_TRACKER = `Provider${NAME_TRACKER}`;
export const TYPE_PROVIDER_TRACKER = TYPE_PROVIDER_TRACKERPLATFORM;

export const ProviderTracker = ProviderTrackerPlatform;
ProviderTracker.displayName = NAME_PROVIDER_TRACKER;
ProviderTracker.defaultProps = DEF_PROVIDER_TRACKERPLATFORM;

export const useTracker = useTrackerPlatform;
