import { CreateActions, CreateReducer } from "@platform/react/store";
import type { StoreState } from "..";

export const NAME = "fetching";

export declare namespace NSSliceFetching {
    type fetching = boolean;

    export type State = fetching;
}

export const INIT_SLICE_FETCHING: NSSliceFetching.State = false;

export const SelectorSliceFetching: (store: StoreState) => NSSliceFetching.State = (store) =>
    store[NAME];

export const ActionSliceFetching = CreateActions<{
    /** Sets fetching to either true or false */
    set: NSSliceFetching.State;
    /** setsh fetching to the inverse of its current state */
    toggle: never;
}>(NAME, ["set", "toggle"]);

export const ReducerSliceFetching = CreateReducer(INIT_SLICE_FETCHING, ({ addCase }) => {
    addCase(ActionSliceFetching.set, (state, { payload }) => payload);

    addCase(ActionSliceFetching.toggle, (state) => !state);
});

export const SliceFetching = { [NAME]: ReducerSliceFetching };
