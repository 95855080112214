import { CreateSelector } from "@platform/react/store";

import { DATA_CRACK } from "@common/content/crack";

import { NAME_SLICE_SECRET } from "./_namespace";
import type { NSSliceSecret } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSliceSecret(store: StoreState): NSSliceSecret.State {
    return store[NAME_SLICE_SECRET];
}

SelectorSliceSecret.id = CreateSelector(SelectorSliceSecret, (state) => state.id);
SelectorSliceSecret.program = CreateSelector(SelectorSliceSecret, (state) => state.program);

SelectorSliceSecret.isFirstTime = CreateSelector(SelectorSliceSecret, (state) => state.isFirstTime);

SelectorSliceSecret.progress = CreateSelector(SelectorSliceSecret, (state) => state.progress);

SelectorSliceSecret.lastLocation = CreateSelector(
    SelectorSliceSecret,
    (state) => state.lastLocation,
);

SelectorSliceSecret.shouldUpdateProgram = CreateSelector(
    SelectorSliceSecret,
    (state) => state.shouldUpdateProgram,
);

SelectorSliceSecret.crack = CreateSelector(SelectorSliceSecret, (state) => {
    const player = DATA_CRACK.find((o) => o.uuid === state.program?.crack);
    return player;
});

SelectorSliceSecret.currentDay = CreateSelector(
    SelectorSliceSecret,
    (state) => state.currentDay || "1",
);

SelectorSliceSecret.hasFinishedDaily = CreateSelector(SelectorSliceSecret, (state) => {
    const content = state.program?.content;
    if (!content) return false;
    return content[Number(state.currentDay)]?.hasFinished;
});

SelectorSliceSecret.currentDayTraining = CreateSelector(
    SelectorSliceSecret,
    (state) => state.currentDayTraining,
);

SelectorSliceSecret.hasSeenTips = CreateSelector(SelectorSliceSecret, (state) => state.hasSeenTips);
