import { CreateActions } from "@platform/react/src/store";
import { NAME_SLICE_ENROLLMENT } from "./_namespace";
import type { NSSliceEnrollment } from "./_namespace";

export const ActionSliceEnrollment = CreateActions<{
    /** Sets a give property errors */
    set: NSSliceEnrollment.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSliceEnrollment.ArgClear | null;
}>(NAME_SLICE_ENROLLMENT, ["set", "clear"]);
