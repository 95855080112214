import type React from "react";
import type { UnPromise } from "@platform/types";
import type { PropElement } from "../../Component";
import Namespace from "./_index.scss";

export type DataImagePlatform = { [uuid: string]: string };
//                             ↑D  ↑KL           ↑VL

export type GetterImagePlatform<D = DataImagePlatform> = () => Promise<D>;

/**
 * NOTE: these are shortcuts referencing the shape of the retrieved data.
 *       @see DataTextPlatform for reference.
 *       - D  = _Data
 *       - KT = _KeyImage
 *       - VT = _ValImage
 */
type _Data<G extends GetterImagePlatform> = UnPromise<ReturnType<G>>;
type _KeyImage<G extends GetterImagePlatform> = keyof _Data<G>;
type _ValImage<G extends GetterImagePlatform> = _Data<G>[_KeyImage<G>];

export type ArgImagePlatform<G extends GetterImagePlatform> = {
    /** A method that resolves data uses as dictionary */
    getter: G;
};

export type StateImagePlatform<G extends GetterImagePlatform> =
    | undefined
    | {
          /** A dictionary of data to work with */
          imageData: _Data<G>;
          /** What will be shown when an image is being loaded */
          imageLoader?: null | JSX.Element;
          /** Resets provider back to initial state */
          imageReset: () => void;
          /** Returns a value from the data dictionary */
          imageGet: (uuid: _KeyImage<G>) => _ValImage<G>;
          /** Sets a new  value inside the data dictionary */
          imageSet: (arg: { uuid: string; value: string }) => void;
      };

export type ArgCommonImagePlatform<G extends GetterImagePlatform> = ArgImagePlatform<G> & {
    Context: React.Context<StateImagePlatform<G>>;
};

export type ArgProviderImagePlatform<G extends GetterImagePlatform> = ArgCommonImagePlatform<G>;

export type PropProviderImagePlatform = {
    /** Components where the Consumer will reside */
    children: NonNullable<PropElement["children"]>;
    /** A loader to show while an image is being loaded */
    loadingImage?: null | JSX.Element;
    /** What to show while the getter is being loaded */
    loading?: null | JSX.Element;
    /** By default errors will be silent, unless you send a method here where you could catch them */
    onError?: (err: Error) => void;
};

export const EVALSCRIPT_IMAGEPLATFORM = {
    ALWAYS: "always",
    ONCE: "once",
    NEVER: "never",
} as const;

export type ArgConsumerImagePlatform<G extends GetterImagePlatform> = ArgCommonImagePlatform<G>;

export type PropConsumerImagePlatform<
    G extends GetterImagePlatform,
    Data = NonNullable<StateImagePlatform<G>>["imageData"],
> = Omit<PropElement, "children"> & {
    /** An unique identifier from an asset listed by via getter. */
    uuid: keyof Data;
    /** Function to run after SVG injection has finished. */
    onInject?: (svg: unknown) => void;
    /** Whether to run script elements declared inside the SVG element. */
    evalScripts?: typeof EVALSCRIPT_IMAGEPLATFORM[keyof typeof EVALSCRIPT_IMAGEPLATFORM];
    /**
     * Whether IRI addressable elements inside the SVG should be remunerated.
     * @see https://www.w3.org/TR/SVG11/linking.html#IRIandURI
     */
    renumerateIRIElements?: boolean;
    /** What to use as caption for the image */
    caption?: string | JSX.Element;
    /** Replace the default html tag to use as wrapper*/
    tag?: string;
};

export const {
    NAME_IMAGEPLATFORM,
    TYPE_IMAGEPLATFORM,
    NAME_CONSUMER_IMAGEPLATFORM,
    TYPE_CONSUMER_IMAGEPLATFORM,
    NAME_PROVIDER_IMAGEPLATFORM,
    TYPE_PROVIDER_IMAGEPLATFORM,
} = Namespace;

export const EXTS_IMAGEPLATFORM = {
    SVG: "svg",
    PNG: "png",
    JPG: "jpg",
} as const;

export const ERROR_PROVIDER_IMAGE = {
    NO_PROVIDER: `${NAME_IMAGEPLATFORM}: Expecting a <${NAME_PROVIDER_IMAGEPLATFORM} /> to be available.`,
    DATA_INVALID: `${NAME_IMAGEPLATFORM}: Invalid data received.`,
    EXTENSION_INVALID: `${NAME_IMAGEPLATFORM}: An extension must be provided`,
} as const;
