import type { PropDefaults, PropTypes } from "@platform/react-storybook/Component";
import type { RouteObject } from "react-router-dom";

import Namespace from "./_index.scss";

export const { NAME_APP, TYPE_APP } = Namespace;

export const UUID_ROUTER = ["user", "www"] as const;

export type UuidRouter = typeof UUID_ROUTER[number];

export type PropRouter = {
    uuid: UuidRouter;
    routes: RouteObject[];
};

export const DEF_ROUTER: PropDefaults<PropRouter> = {};

export type PropTypesRouter = PropTypes<PropRouter, typeof DEF_ROUTER>;
