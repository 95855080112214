export const GROUP_ICON_ASSET = "icons" as const;

export type GroupIconAsset = typeof GROUP_ICON_ASSET;

export const EXT_ICON_ASSET = ".svg" as const;

export type ExtIconAsset = typeof EXT_ICON_ASSET;

export const PREFIX_ICON_ASSET = "ico" as const;

export type PrefixIconAsset = typeof PREFIX_ICON_ASSET;

export const UUID_ICON_ASSET = [
    "arrow-down",
    "arrow-back",
    "arrow-double",
    "arrow-revert",
    "arrow-zoom",
    "arrow-zoom-out",
    "avatar-ball",
    "fill-ball",
    "avatar-cup",
    "avatar-glove",
    "avatar-jersey",
    "avatar-mastercracks",
    "avatar-shoe",
    "category-fitness",
    "category-mindset",
    "category-skills",
    "category-stories",
    "challenge-edit",
    "challenge-not",
    "challenge-ok",
    "challenge-take",
    "device-desktop",
    "device-smartphone",
    "device-tablet",
    "device-tv",
    "fill-captions",
    "fill-challenges",
    "fill-dislike",
    "fill-favorites",
    "fill-flipcam",
    "fill-home",
    "fill-layout-horizontal",
    "fill-layout-vertical",
    "fill-like",
    "fill-location",
    "fill-programs",
    "fill-save",
    "fill-share",
    "fill-star",
    "fill-user",
    "help-conditions",
    "help-faq",
    "help-guidelines",
    "help-payment",
    "help-privacy",
    "line-achieve",
    "line-camera",
    "line-captions",
    "line-cat-programs",
    "line-challenges",
    "line-dislike",
    "line-favorites",
    "line-flipcam",
    "line-home",
    "line-layout-horizontal",
    "line-layout-vertical",
    "line-like",
    "line-location",
    "line-programs",
    "line-save",
    "line-settings",
    "line-share",
    "line-user",
    "locked",
    "login-apple",
    "login-facebook",
    "login-google",
    "media-pause",
    "media-play",
    "media-player",
    "media-stream",
    "media-vol-high",
    "media-vol-low",
    "media-vol-mid",
    "media-vol-mute",
    "menu-about",
    "menu-close",
    "menu-help",
    "menu-language",
    "menu-login",
    "menu-open",
    "menu-search",
    "menu-user",
    "password-hide",
    "password-show",
    "programs-emotional-1",
    "programs-emotional-2",
    "programs-emotional-3",
    "programs-emotional-4",
    "programs-emotional-5",
    "programs-physical-1",
    "programs-physical-2",
    "programs-physical-3",
    "programs-physical-4",
    "programs-physical-5",
    "security-lock",
    "social-copy",
    "social-download",
    "social-facebook",
    "social-instagram",
    "social-linkedin",
    "social-tiktok",
    "social-twitter",
    "social-verified",
    "tag-check",
    "tag-check-fill",
    "tag-intensity-high",
    "tag-intensity-low",
    "tag-intensity-mid",
    "tag-performance",
    "tag-schedule",
    "tag-secret",
    "tag-benefit",
    "tag-teams",
    "tag-time",
    "training-field",
    "training-home",
    "training-street-a",
    "training-street-b",
    "payment-method-amex",
    "payment-method-visa",
    "payment-method-mastercard",
    "error-conection",
    "error-alert",
    "error-flag",
    "secret-mental-emotional-training",
    "secret-nutrition",
    "secret-dream",
    "secret-team",
    "secret-habits",
    "secret-resignation-failure",
    "secret-leadership",
    "secret-financial-health",
    "secret-movement",
    "secret-purpose",
    "secret-visualization",
    "forms",
    "personalized",
    "arrow-complete",
] as const;

export type UuidIconAsset = typeof UUID_ICON_ASSET[number];

export type FilenameIconAsset<Uuid extends string> =
    `/${GroupIconAsset}/${PrefixIconAsset}_${Uuid}${ExtIconAsset}`;

export const ICON_ASSET = UUID_ICON_ASSET.reduce(
    (acc, uuid) => ({
        ...acc,
        [uuid]: ["", GROUP_ICON_ASSET, `${PREFIX_ICON_ASSET}_${uuid}${EXT_ICON_ASSET}`].join("/"),
    }),
    {} as { readonly [K in UuidIconAsset]: FilenameIconAsset<K> },
);

export type IconAsset = typeof ICON_ASSET;
export type ValueIconAsset = IconAsset[UuidIconAsset];

export function GetterIconAsset(url: string) {
    return UUID_ICON_ASSET.reduce(
        (acc, uuid) => ({ ...acc, [uuid]: `${url}${ICON_ASSET[uuid]}` as const }),
        {} as { [K in UuidIconAsset]: `${typeof url}${FilenameIconAsset<K>}` },
    );
}
