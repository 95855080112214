import { PathCreate } from "@platform/react-storybook/Component/Router/utils";
import { paramCategory } from "../../params/category";

export function PathGetCategory<R extends string>(ROOT: R) {
    return {
        ROOT,
        get CATEGORY() {
            const ROOT_CATEGORY = PathCreate(ROOT, "category");
            return {
                ROOT: ROOT_CATEGORY,
                PARAM: PathCreate(ROOT_CATEGORY, paramCategory),
            };
        },
    };
}
