import type { UuidVideoAsset } from "@common/content/asset/video";
import type { UuidCrack } from "@common/content/crack/uuid";

export const NAME_SLICE_HISTORY_VIDEO = "historyVideo";

export declare namespace NSSliceHistoryVideo {
    export type CurrentVideo = {
        "uuid-video": UuidVideoAsset;
        "time-video": number;
        autoLoop: boolean;
        finish: boolean;
        email: string;
        progress?: number;
        crack?: UuidCrack;
        section?: "mindset" | "stories" | "skills" | "fitness";
    };
    export type HistoryVideos = CurrentVideo & {
        date: string;
        duration: number;
    };

    export type State = {
        "last-video"?: Partial<{ [key in UuidVideoAsset]: CurrentVideo }>;
        history: { [k in string]: Partial<{ [key in UuidVideoAsset]: HistoryVideos }> };
        time?: { [key in UuidVideoAsset]: number };
        playing: boolean;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_HISTORY_VIDEO: NSSliceHistoryVideo.State = {
    history: {},
    playing: false,
};
