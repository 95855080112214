import { ParamCreate } from "@platform/react-storybook/Component/Router/utils";
import { UUID_VIDEO_ASSET } from "@common/content/asset/video";
import type { UuidVideoAsset } from "@common/content/asset/video";

export type ParamValueProfile = UuidVideoAsset;

export const { paramVideo, useParamVideo } = ParamCreate({
    name: "video",
    values: UUID_VIDEO_ASSET,
});
