import type { RecordCrack } from ".";

export const DATA_PAVEL_PARDO: RecordCrack<"pavel-pardo"> = {
    uuid: "pavel-pardo",
    bio: "crack-bio-pavel-pardo",
    country: "MEX",
    nameAlias: '"El Comandante"',
    nameFirst: "Pável",
    nameLast: "Pardo",
    position: "crack-position-1",
    stats: [
        {
            preset: "round",
            title: "crack-stats-pavel-pardo-title-1",
            desc: "crack-stats-pavel-pardo-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-pavel-pardo-title-2",
            desc: "crack-stats-pavel-pardo-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-pavel-pardo-title-3",
            desc: "crack-stats-pavel-pardo-body-3",
            icon: "line-achieve",
        },
    ],
};

export const DATA_JO_CANALES: RecordCrack<"jo-canales"> = {
    uuid: "jo-canales",
    bio: "crack-bio-jo-canales",
    country: "MEX",
    nameAlias: '"Jo"',
    nameFirst: "Coach Jo",
    nameLast: "Canales",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-jo-canales-title-1",
            desc: "crack-stats-jo-canales-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-jo-canales-title-2",
            desc: "crack-stats-jo-canales-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-jo-canales-title-3",
            desc: "crack-stats-jo-canales-body-3",
            icon: "line-achieve",
        },
    ],
};

export const DATA_RAFA_MARQUEZ: RecordCrack<"rafa-marquez"> = {
    uuid: "rafa-marquez",
    bio: "crack-bio-rafa-marquez",
    country: "MEX",
    nameAlias: '"El Káiser"',
    nameFirst: "Rafael",
    nameLast: "Márquez",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-rafa-marquez-title-1",
            desc: "crack-stats-rafa-marquez-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-rafa-marquez-title-2",
            desc: "crack-stats-rafa-marquez-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-rafa-marquez-title-3",
            desc: "crack-stats-rafa-marquez-body-3",
            icon: "line-achieve",
        },
    ],
};

export const DATA_CARLOS_SALCIDO: RecordCrack<"carlos-salcido"> = {
    uuid: "carlos-salcido",
    bio: "crack-bio-carlos-salcido",
    country: "MEX",
    nameAlias: '"Sa Sa Salcita"',
    nameFirst: "Carlos",
    nameLast: "Salcido",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-carlos-salcido-title-1",
            desc: "crack-stats-carlos-salcido-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-carlos-salcido-title-2",
            desc: "crack-stats-carlos-salcido-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-carlos-salcido-title-3",
            desc: "crack-stats-carlos-salcido-body-3",
            icon: "line-achieve",
        },
    ],
};

export const DATA_NORMA_PALAFOX: RecordCrack<"norma-palafox"> = {
    uuid: "norma-palafox",
    bio: "crack-bio-norma-palafox",
    country: "MEX",
    nameAlias: "Norma",
    nameFirst: "Norma",
    nameLast: "Palafox",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-norma-palafox-title-1",
            desc: "crack-stats-norma-palafox-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-norma-palafox-title-2",
            desc: "crack-stats-norma-palafox-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-norma-palafox-title-3",
            desc: "crack-stats-norma-palafox-body-3",
            icon: "line-achieve",
        },
    ],
};

export const DATA_OSWALDO_SANCHEZ: RecordCrack<"oswaldo-sanchez"> = {
    uuid: "oswaldo-sanchez",
    bio: "crack-bio-oswaldo-sanchez",
    country: "MEX",
    nameAlias: '"San Oswaldo"',
    nameFirst: "Oswaldo",
    nameLast: "Sánchez",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-oswaldo-sanchez-title-1",
            desc: "crack-stats-oswaldo-sanchez-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-oswaldo-sanchez-title-2",
            desc: "crack-stats-oswaldo-sanchez-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-oswaldo-sanchez-title-3",
            desc: "crack-stats-oswaldo-sanchez-body-3",
            icon: "line-achieve",
        },
    ],
};

export const DATA_CHARLYN_CORRAL: RecordCrack<"charlyn-corral"> = {
    uuid: "charlyn-corral",
    bio: "crack-bio-charlyn-corral",
    country: "MEX",
    nameAlias: "Charlyn",
    nameFirst: "Charlyn",
    nameLast: "Corral",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-charlyn-corral-title-1",
            desc: "crack-stats-charlyn-corral-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-charlyn-corral-title-2",
            desc: "crack-stats-charlyn-corral-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-charlyn-corral-title-3",
            desc: "crack-stats-charlyn-corral-body-3",
            icon: "line-achieve",
        },
    ],
};

export const DATA_JARED_BORGETTI: RecordCrack<"jared-borgetti"> = {
    uuid: "jared-borgetti",
    bio: "crack-bio-jared-borgetti",
    country: "MEX",
    nameAlias: '"El Zorro del Desierto"',
    nameFirst: "Jared",
    nameLast: "Borgetti",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-jared-borgetti-title-1",
            desc: "crack-stats-jared-borgetti-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-jared-borgetti-title-2",
            desc: "crack-stats-jared-borgetti-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-jared-borgetti-title-3",
            desc: "crack-stats-jared-borgetti-body-3",
            icon: "line-achieve",
        },
    ],
};
export const DATA_GIANLUIGI_BUFFON: RecordCrack<"gianluigi-buffon"> = {
    uuid: "gianluigi-buffon",
    bio: "crack-bio-gianluigi-buffon",
    country: "MEX",
    nameAlias: '"Gigi"',
    nameFirst: "Buffon",
    nameLast: "Academy",
    position: "crack-position-2",
    stats: [
        {
            preset: "round",
            title: "crack-stats-gianluigi-buffon-title-1",
            desc: "crack-stats-gianluigi-buffon-body-1",
            icon: "tag-performance",
        },
        {
            preset: "round",
            title: "crack-stats-gianluigi-buffon-title-2",
            desc: "crack-stats-gianluigi-buffon-body-2",
            icon: "tag-teams",
        },
        {
            preset: "round",
            title: "crack-stats-gianluigi-buffon-title-3",
            desc: "crack-stats-gianluigi-buffon-body-3",
            icon: "line-achieve",
        },
    ],
};
export const DATA_CRACK = [
    DATA_PAVEL_PARDO,
    DATA_JO_CANALES,
    DATA_RAFA_MARQUEZ,
    DATA_CARLOS_SALCIDO,
    DATA_NORMA_PALAFOX,
    DATA_OSWALDO_SANCHEZ,
    DATA_CHARLYN_CORRAL,
    DATA_JARED_BORGETTI,
    DATA_GIANLUIGI_BUFFON,
];
