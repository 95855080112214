import React from "@platform/react";
import { TYPE } from "..";
import type { PropTypes } from "..";
import Namespace from "./_index.scss";

const { NAME_MENU } = Namespace;

export type PropMenuProvider = {
    children: React.ReactNode;
};

export type ContextMenuProvider = {
    show: boolean;
    /** Changes the state to false  */
    close: () => void;
    /** Changes the state to true  */
    open: () => void;
    /** Changes the state value  */
    toggle: () => void;
};

export const NAME_MENU_PROVIDER = `${TYPE.PROVIDER}${NAME_MENU}`;
export const TYPE_MENU_PROVIDER = TYPE.PROVIDER;

export const ContextMenuProvider = React.createContext<ContextMenuProvider | null>(null);

export const DEF_MENU_PROVIDER = {};

export function ProviderMenu(props: PropTypes<PropMenuProvider, typeof DEF_MENU_PROVIDER>) {
    const { children } = props;
    const [state, setState] = React.useState(false);

    const close = React.useCallback(() => {
        setState(false);
    }, []);
    const open = React.useCallback(() => {
        setState(true);
    }, []);
    const toggle = React.useCallback(() => {
        setState(!state);
    }, [state]);

    const context: ContextMenuProvider = React.useMemo(
        () => ({
            show: state,
            close,
            open,
            toggle,
        }),
        [state, close, open, toggle],
    );
    return <ContextMenuProvider.Provider value={context}>{children}</ContextMenuProvider.Provider>;
}
ProviderMenu.displayName = NAME_MENU_PROVIDER;
ProviderMenu.defaultProps = DEF_MENU_PROVIDER;
