import { PREFIX_BORDER_BOX_THEME } from "@common/content/theme/box/border";

export const PREFIX_RADIUS_BORDER_BOX_THEME = `${PREFIX_BORDER_BOX_THEME}_radius` as const;

export const RADIUS_BORDER_BOX_THEME = {
    none: "0",
    techno: "0.375em",
    country: "0.563em",
    blues: "0.625em",
    rock: "25%",
    jazz: "50%",
} as const;

export type RadiusBorderBoxTheme = typeof RADIUS_BORDER_BOX_THEME;

export type UuidRadiusBorderBoxTheme = keyof RadiusBorderBoxTheme;

export type ValueRadiusBorderBoxTheme = RadiusBorderBoxTheme[UuidRadiusBorderBoxTheme];

export const UUID_RADIUS_BORDER_BOX_THEME = Object.keys(
    RADIUS_BORDER_BOX_THEME,
) as readonly UuidRadiusBorderBoxTheme[];
