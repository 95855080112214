import { CreateReducer } from "@platform/react/store";
import { ActionSlicePurchases } from "./_actions";
import { INIT_SLICE_PURCHASES } from "./_namespace";

export const ReducerSlicePurchases = CreateReducer(INIT_SLICE_PURCHASES, ({ addCase }) => {
    addCase(ActionSlicePurchases.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSlicePurchases.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_PURCHASES;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });
});
