export const GROUP_VECTOR_ASSET = "vectors" as const;

export type GroupVectorAsset = typeof GROUP_VECTOR_ASSET;

export const EXT_VECTOR_ASSET = ".svg" as const;

export type ExtVectorAsset = typeof EXT_VECTOR_ASSET;

export const PREFIX_VECTOR_ASSET = "vec" as const;

export type PrefixVectorAsset = typeof PREFIX_VECTOR_ASSET;

export const UUID_VECTOR_ASSET = [
    "logo-mc-icon",
    "logo-mc-short",
    "logo-mc-text",
    "logo-mc-round-a",
    "logo-mc-round-b",
    "logo-akron",
    "logo-comune",
    "logo-elmister",
    "logo-friedman",
    "logo-goat",
    "logo-gobgdl",
    "logo-juanfutbol",
    "logo-olympic-committee",
    "logo-uconsulting",
    "pattern-a",
    "pattern-a-double",
    "pattern-b",
    "pattern-c",
    "pattern-d",
    "lines-circle",
    "lines-cross",
    "lines-curve",
    "lines-diagonal",
    "lines-wave-1",
    "lines-wave-2",
    "lines-wave-3",
    "landing-business-steren",
    "landing-sign-rafael-marquez",
    "landing-sign-pavel-pardo",
    "landing-sign-jared-borgetti",
    "landing-sign-jo-canales",
    "landing-sign-oswaldo-sanchez",
    "landing-sign-norma-palafox",
    "landing-sign-charlyn-corral",
    "landing-sign-carlos-salcido",
    "landing-sign-buffon-academy",
] as const;

export type UuidVectorAsset = typeof UUID_VECTOR_ASSET[number];

export type InferUuidLogoVectorAsset<S extends string> = S extends `logo-${infer U}`
    ? `logo-${U}`
    : never;

export type LogoUuidVectorAsset = InferUuidLogoVectorAsset<UuidVectorAsset>;

export type FilenameVectorAsset<Uuid extends string> =
    `/${GroupVectorAsset}/${PrefixVectorAsset}_${Uuid}${ExtVectorAsset}`;

export const VECTOR_ASSET = UUID_VECTOR_ASSET.reduce(
    (acc, uuid) => ({
        ...acc,
        [uuid]: ["", GROUP_VECTOR_ASSET, `${PREFIX_VECTOR_ASSET}_${uuid}${EXT_VECTOR_ASSET}`].join(
            "/",
        ),
    }),
    {} as { readonly [K in UuidVectorAsset]: FilenameVectorAsset<K> },
);

export type VectorAsset = typeof VECTOR_ASSET;

export type ValueVectorAsset = VectorAsset[UuidVectorAsset];

export function GetterVectorAsset(url: string) {
    return UUID_VECTOR_ASSET.reduce(
        (acc, uuid) => ({ ...acc, [uuid]: `${url}${VECTOR_ASSET[uuid]}` as const }),
        {} as { [K in UuidVectorAsset]: `${typeof url}${FilenameVectorAsset<K>}` },
    );
}
