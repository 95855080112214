import React from "@platform/react";
import { Component } from "@platform/react-storybook/Component";

import "./_rules.module.scss";
import { Text } from "@front/components/Element/Text";
import { IconAsset } from "@front/components/Element/Asset/Icon";
import type { PropTypesSectionErrorNetwork, PropSectionErrorNetwork } from "./types";

import {
    DEF_SECTION_ERROR_NETWORK,
    NAME_SECTION_ERROR_NETWORK,
    TYPE_SECTION_ERROR_NETWORK,
} from "./types";

export function SectionErrorNetwork(props: PropSectionErrorNetwork) {
    const { ...rest } = props as PropTypesSectionErrorNetwork;

    return (
        <Component {...rest} name={NAME_SECTION_ERROR_NETWORK} type={TYPE_SECTION_ERROR_NETWORK}>
            <IconAsset fill="argentina" uuid="error-conection" />

            <header>
                <Text preset="campeche" uuid="error-conection-title" />
            </header>
            <section>
                <Text preset="lanus" uuid="error-conection-desc" />
            </section>
        </Component>
    );
}
SectionErrorNetwork.defaultProps = DEF_SECTION_ERROR_NETWORK;
