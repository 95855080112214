import { isRecord } from "@platform/types/record";
import type { UuidUserAffiliation } from "@common/content/user/affiliation";

export const ROUTE_ENROLLMENT = {
    ENROLLMENT_GENERATE_CODE: "/generateCodeInvitation",
    ENROLLMENT_REGISTER_USER_INTENT: "/registerUserIntent",
    ENROLLMENT_VERIFY_USER_INVITATION: "/verifyUserInvitation",
    ENROLLMENT_VERIFY_EMAIL: "/verifyEmail",
    ENROLLMENT_REGISTER_PASSWORD: "/registerPassword",
    ENROLLMENT_REGISTER_AVATAR: "/registerAvatar",
    ENROLLMENT_APP_REGISTER_MAIL: "/appRegisterMail",
    ENROLLMENT_APP_REGISTER_PASSWORD: "/appRegisterPassword",
    ENROLLMENT_APP_REGISTER_BIRTHDAY_AND_GENDER: "/appRegisterBirthdayAndGender",
    ENROLLMENT_APP_REGISTER_PHONE_NUMBER: "/appRegisterPhoneNumber",
    ENROLLMENT_APP_REGISTER_AVATAR: "/appRegisterAvatar",
} as const;

export const ROUTE_LOGIN = {
    LOGIN: "/login",
    FLOW_BUSINESS_SURVEY: "/business-survey",
} as const;

export const ROUTE_USER = {
    DEBUG_USERS_GET_ALL: "/debug-users-get-all",
    DEBUG_USERS_GET_ONE: "/debug-users-get-one/:email",
    DEBUG_USERS_PUT_ONE: "/debug-users-put-one",
    DEBUG_USERS_PUT_MANY: "/debug-users-put-many",
    DEBUG_USERS_DEL_ONE: "/debug-users-del-one",
    DEBUG_USERS_GET_BY_ORIGIN: "/debug-users-get-by-origin/:origin",

    DEBUG_WHITELIST_USERS_GET_ALL: "/debug-whitelist-users-get-all",
    DEBUG_WHITELIST_USERS_GET_ONE: "/debug-whitelist-users-get-one/:email",
    DEBUG_WHITELIST_USERS_PUT_ONE: "/debug-whitelist-users-put-one",
    DEBUG_WHITELIST_USERS_DEL_ONE: "/debug-whitelist-users-del-one",

    DEBUG_CODES_GET_ALL: "/debug-codes-get-all",
    DEBUG_CODES_GET_ONE: "/debug-codes-get-one/:email",
    DEBUG_CODES_PUT_ONE: "/debug-codes-put-one",
    DEBUG_CODES_DEL_ONE: "/debug-codes-del-one",

    DEBUG_INFO_GET_ALL: "/debug-infos-get-all",
    DEBUG_INFO_GET_ONE: "/debug-infos-get-one/:tokenPush",
    DEBUG_INFO_PUT_ONE: "/debug-infos-put-one",
    DEBUG_INFO_DEL_ONE: "/debug-infos-del-one",

    STATUS: "/users/status/:email",
    DELETE_POST: "/users/delete",
    RESET_PASSWORD: "/users/resetPassword",
    GET_ONE: "/users/:idUser",
} as const;

export const ROUTE_TRIAL_PREMIUM = {
    TRIAL_PREMIUM_INTENT: "/trial-premium",
    TRIAL_PREMIUM_BY_USER: "/trial-premium/:userName",
};

export const ROUTE_BLOG = {
    BLOG: "/blog",
    LAST_BLOG: "/blog/last",
    SEO: "/blog/:locale/seo/:id",
};

export const ROUTE_HOME_PAGE_USER = {
    HOME: "/home-page",
    HOME_BUILD: "/home-page/build",

    HOME_HARD: "/home-page/hard",
    HOME_BY_UUID: "/home-page/:uuid",
};
export const ROUTE_SHORT = {
    SHORT: "/short",
    SHORT_BY_USER: "/short-user",
};
export const ROUTE_CONTACT = {
    CONTACT_MC: "/contact/mc",
};
// TODO: Replace this enum with a const.

/** Enumeration with Status User values. */
/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum STATUS_USER {
    PENDING_CONFIRM = 0,
    WAITING_LIST = 1,
    PRE_ACTIVATION = 2,
    ACTIVE = 3,
    DELETE = 4,
    UNRESPONSIVE = 6,
}

/* Type of Invitation codes */
export type InvitationCodes = {
    /** valid email   */
    email: string;
    /** generate uuid code */
    invitationCode: string;
    /** send email to customer with generate code. */
    emailSent?: boolean;
};

/* Database type of user data*/
export type User = {
    /* user identification generated */
    id: string;
    /* name of the user */
    name: string;
    /* last name of the user*/
    lastName: string;
    /* email of the user */
    email: string;
    /* numeric status of the user, use Enumeration. */
    status: number;
    /* Operation System name: iphone or android  */
    os: string;
    /* has invitation */
    invitation: boolean;
    /* password cypher*/
    password?: string;
    /* avatar id */
    avatar?: string;
    /* Date of birth */
    dob?: string;
    /* Gender of the user */
    gender?: string;
    /* Phone code */
    phoneCode?: string;
    /* Phone number */
    phoneNumber?: string;
    /** An optional affiliation */
    affiliation?: UuidUserAffiliation;
    /* TODO: [HOTFIX] This is due to a FUCK-UP */
    recovered?: "PENDING" | "HAS_PWD";
    /** The type of subscription a user has */
    membership?: "NONE" | "TRIAL" | "PREMIUM" | "TRIAL_EXPIRED";
    /** The date a user accepted a trial subscription */
    dateTrial?: string;
    /** The date a user finish the signup process */
    dateSignup: string;
    /** The date user confirmed their email */
    dateConfirmed?: string;
    /** The date a user finished the enrollment process */
    dateEnrollment?: string;
    /** The date the user did login  */
    dateLogin?: string;
    /** The date a user was deemed unresponsive */
    dateUnresponsive?: string;
    /** Specifies which lading the user comes from */
    origin?: string;
    /** Intended for business users */
    answers?: { [id: string]: number };
    /** Intended for business users */
    isBusinessSurveyCompleted?: boolean;
    /** Intended for business users */
    additionalFields?: { [id: string]: string };
};

/** Created temporarily for beta invitation */
export type WhitelistUser = {
    email: string;
    nameFirst: string;
    nameLast: string;
};

/* Tipo que guarda la razón borrar usuario*/
export type UserDeleted = User & {
    /* numeric razon type*/
    reason: number;
    /*text explanation about delete account driver. */
    explanation: string;
    /* delete date human readable format */
    dateDeleted: string;
};

// TODO: These properties are repeated where should they live?
export type Info = {
    tokenPush: string;
    user: string | null;
    osName: string;
    osVersion: string;
    brand: string;
    model: string;
    isSimulator: boolean;
    name?: string;
    dateUpdated: string;
};

export type Blog = {
    id: string;
    title: {
        "es-mx": string;
        "en-us": string;
    };
    desc: {
        "es-mx": string;
        "en-us": string;
    };
    date: string;
    "url-img": string;
    html: {
        "es-mx": string;
        "en-us": string;
    };
    status: string;
    lastUpdate: string;
};
export function isBodyLogin(entry: unknown): entry is RecordBodyLogin {
    if (!isRecord(entry)) return false;
    const MIN_PROPS = 2;
    const keysValid = ["email", "password"];
    const keysEntry = Object.keys(entry as Record<string, unknown>);
    // is a property set?  an empty object is not a valid email body;
    if (keysEntry.length < MIN_PROPS) return false;
    // do not allow properties that are not valid.
    if (keysEntry.filter((key) => !keysValid.includes(key)).length) {
        return false;
    }
    const body = entry as RecordBodyLogin;
    if (typeof body.email !== "string" || !body.email.length) return false;
    if (typeof body.password !== "string" || !body.password.length) return false;
    return true;
}
/** REquest type of login */
export type RecordBodyLogin = {
    /* user email */
    email: string;
    /* password plain text*/
    password: string;
};

/** USER PHONE OPERATING SYSTEM */
export const USER_PHONE_OS = {
    android: "android",
    ios: "ios",
} as const;

export const DEFAULT_ORIGIN = "generic";

export type Contact = {
    email: string;
    message: string;
    date: string;
};

export type ShortByUser = {
    uuid: string;
    "uuid-user": string;
    "uuid-short": string;
    date: string;
};

export type Short = {
    uuid: string;
    "created-date": string;
    "updated-date"?: string;
    crack: string;
    asset: string;
    status: string;
};
