import type { UuidBrightColorTheme } from "./bright";
import type { UuidOpacityColorTheme } from "./opacity";
import type { UuidToneColorTheme } from "./tone";

export const PREFIX_COLOR_THEME = "color" as const;

export const COLOR_THEME = get({
    none: { tone: "lynx", bright: "default", opacity: "none" },
    haiti: { tone: "lynx", bright: "poland", opacity: "jupiter" },
    nicaragua: { tone: "lynx", bright: "poland", opacity: "earth" },
    chile: { tone: "lion", bright: "default", opacity: "default" },
    brazil: { tone: "lynx", bright: "default", opacity: "jupiter" },
    cuba: { tone: "lynx", bright: "default", opacity: "default" },
    venezuela: { tone: "leopard", bright: "default", opacity: "jupiter" },
    argentina: { tone: "leopard", bright: "default", opacity: "default" },
    jamaica: { tone: "tiger", bright: "default", opacity: "jupiter" },
    colombia: { tone: "tiger", bright: "default", opacity: "default" },
    guyana: { tone: "puma", bright: "default", opacity: "jupiter" },
    mexico: { tone: "puma", bright: "default", opacity: "default" },
    ecuador: { tone: "jaguar", bright: "default", opacity: "default" },
    uruguay: { tone: "cheetah", bright: "default", opacity: "jupiter" },
    guatemala: { tone: "cheetah", bright: "default", opacity: "default" },
    peru: { tone: "ocelot", bright: "default", opacity: "default" },
    panama: { tone: "lynx", bright: "belgium", opacity: "default" },
    bolivia: { tone: "lynx", bright: "poland", opacity: "default" },
    honduras: { tone: "lynx", bright: "spain", opacity: "default" },
    paraguay: { tone: "serval", bright: "default", opacity: "default" },
    salvador: { tone: "panther", bright: "default", opacity: "default" },
    tobago: { tone: "cougar", bright: "default", opacity: "default" },
});

export type ColorTheme = typeof COLOR_THEME;

export type UuidColorTheme = keyof ColorTheme;

export type ValueColorTheme = ColorTheme[UuidColorTheme];

function get<T>(decl: {
    [K in keyof T]: {
        tone: UuidToneColorTheme;
        bright: UuidBrightColorTheme;
        opacity: UuidOpacityColorTheme;
    };
}) {
    return decl;
}
