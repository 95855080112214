import type { PropDefaults, PropElement, PropTypes } from "@platform/react-storybook/Component";

import Namespace from "./_index.scss";

export const { NAME_SECTION_ERROR_NETWORK, TYPE_SECTION_ERROR_NETWORK } = Namespace;

export type PropSectionErrorNetwork = PropElement;

export const DEF_SECTION_ERROR_NETWORK: PropDefaults<PropSectionErrorNetwork> = {};

export type PropTypesSectionErrorNetwork = PropTypes<
    PropSectionErrorNetwork,
    typeof DEF_SECTION_ERROR_NETWORK
>;
