import React from "@platform/react";
import { CreateThemePlatform } from "@platform/react-storybook/Bundle/Theme";
import {
    ERROR_THEMEPLATFORM,
    NAME_THEMEPLATFORM,
} from "@platform/react-storybook/Bundle/Theme/types";

import { getter } from "./data";
import { NAME_THEME, DEF_THEME } from "./types";
import type { PropProviderTheme } from "./types";

// rules that have to be included in order for the Theme to work
import "./Font/_rules.scss";

const { ThemePlatform, ProviderThemePlatform, useThemePlatform } = CreateThemePlatform({
    getter,
});

export function useTheme() {
    try {
        return useThemePlatform();
    } catch (error) {
        throw new Error(ERROR_THEMEPLATFORM.NO_PROVIDER.replace(NAME_THEMEPLATFORM, NAME_THEME));
    }
}

export const Theme = ThemePlatform;
Theme.displayName = NAME_THEME;
Theme.defaultProps = DEF_THEME;

export function ProviderTheme(props: PropProviderTheme) {
    const { children, ...rest } = props;
    return (
        <ProviderThemePlatform shouldInjectOnLoad theme="default" {...rest}>
            {children}
        </ProviderThemePlatform>
    );
}

export const getVar = (name: string) => `var(--${name})`;
