import { PREFIX_FONT_THEME } from ".";

export const PREFIX_TRANSFORM_FONT_THEME = `${PREFIX_FONT_THEME}_transform` as const;

export const TRANSFORM_FONT_THEME = {
    default: "none",
    sirloin: "capitalize",
    ribeye: "uppercase",
    kobe: "lowercase",
} as const;

export type TransformFontTheme = typeof TRANSFORM_FONT_THEME;

export type UuidTransformFontTheme = keyof TransformFontTheme;

export type ValueTransformFontTheme = TransformFontTheme[UuidTransformFontTheme];

export const UUID_TRANSFORM_FONT_THEME = Object.keys(
    TRANSFORM_FONT_THEME,
) as readonly UuidTransformFontTheme[];
