import { DEF_BUTTONPLATFORM } from "@platform/react-storybook/Component/Button";
import type React from "@platform/react";
import type { UuidIconAsset } from "@common/content/asset/icon";
import type { PropDefaults, PropElement, PropTypes } from "@platform/react-storybook/Component";
import type { PropButtonPlatform } from "@platform/react-storybook/Component/Button";
import type { UuidText } from "@front/components/Element/Text/types";
import Namespace from "./_index.scss";

export const { NAME_BUTTON, TYPE_BUTTON } = Namespace;

export const PRESET_BUTTON = [
    "nigeria",
    "egypt",
    "kenya",
    "sudan",
    "morocco",
    "angola",
    "ghana",
    "namibia",
    "mali",
    "gabon",
    "camerun",
    "somalia",
    "malawi",
    "zambia",
    "chad",
    "benin",
    "togo",
] as const;

export type PresetButton = typeof PRESET_BUTTON[number];

type _PropParent = Omit<PropButtonPlatform, keyof PropElement | "onClick">;

export type ClickEventButton = null | React.MouseEventHandler<HTMLButtonElement>;

export type PropButton = _PropParent &
    Omit<PropElement, "children"> & {
        /** Pick one of the available button presets to customize the look and feel of the button */
        preset: PresetButton;
        /** A Text `uuid` if provided, the corrresponding text will be rendered */
        text?: UuidText;
        /** An IconAsset `uuid`, if provided, the corresponding icon will be rendered*/
        icon?: UuidIconAsset;
        /** A function to run when the user clicks on the button. */
        onClick?: ClickEventButton;
        /**
         * If sent, neither `text` nor `icon` will be rendered,
         * rather the nodes you sent here will
         */
        children?: NonNullable<PropElement["children"]>;

        permittedDisabled: boolean;
    };

export const DEF_BUTTON: PropDefaults<Omit<PropButton, "text" | "icon">> = {
    ...DEF_BUTTONPLATFORM,
};

export type DefButton = typeof DEF_BUTTON;

export type PropTypesButton = PropTypes<PropButton, DefButton>;
