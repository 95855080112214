import React from "@platform/react";
import { StorageDriver, StorageGetDriver } from "@platform/react/store";

import { ERROR_TEXTPLATFORM } from "./types";
import type { PropDefaults, PropTypes } from "../../Component";
import type {
    ArgProviderTextPlatform,
    GetterTextPlatform,
    PropProviderTextPlatform,
    StateTextPlatform,
} from "./types";

export function CreateProviderTextPlatform<G extends GetterTextPlatform>(
    arg: ArgProviderTextPlatform<G>,
) {
    const { Context, getter, languages, storageName, matcher } = arg;

    const DEF_PROVIDER_TEXTPLATFORM: PropDefaults<Omit<PropProviderTextPlatform<G>, "onError">> = {
        loading: null,
    };

    const Storage = StorageGetDriver(StorageDriver.SESSION);

    type State = NonNullable<StateTextPlatform<G>>;
    type Data = State["textData"];

    function ProviderTextPlatform(
        props: PropTypes<PropProviderTextPlatform<G>, typeof DEF_PROVIDER_TEXTPLATFORM>,
    ) {
        const { children, loading, onError, ...rest } = props;
        const [data, setData] = React.useState<undefined | Data>(undefined);
        const [language, setLang] = React.useState(rest.language);

        React.useEffect(() => {
            (async () => {
                // there's something loaded, no need to do anything else.
                if (data) return;
                // is there a stored dictionary? use it.
                const stored = await Storage.get(storageName);
                if (stored) {
                    setData(JSON.parse(stored));
                    return;
                }
                // nothing stored, fetch using provided method, store it, and set it.
                try {
                    const _data = await getter();
                    if (!_data) {
                        if (onError) onError(new Error(ERROR_TEXTPLATFORM.INVALID_RESPONSE));
                        return;
                    }
                    await Storage.set(storageName, JSON.stringify(_data));
                    setData(_data as Data);
                } catch (error) {
                    if (onError) onError(error as Error);
                }
            })();
        }, [data, onError]);

        if (!data) return loading;

        const state: State = {
            textLanguages: languages,
            textLanguage: language,
            textData: data,

            textGet(_arg) {
                if (!data) {
                    if (onError) onError(new Error(ERROR_TEXTPLATFORM.INVALID_DATA));
                    return "";
                }
                const lang = (_arg.language || language) as keyof Data;
                const dict = data[lang];
                if (!dict) {
                    if (onError) onError(new Error(ERROR_TEXTPLATFORM.INVALID_LANG));
                    return "";
                }
                const replacements = Array.isArray(_arg.replace) ? _arg.replace : [_arg.replace];
                const text = replacements.reduce((acc, replacement) => {
                    if (!acc || !replacement) return acc;
                    return acc.replace(matcher, replacement);
                }, dict[_arg.uuid]);
                return text || _arg.fallback || `*${String(_arg.uuid)}*`;
            },

            async textClear() {
                await Storage.del(storageName);
                setData(undefined);
            },

            textSetLanguage(lang) {
                setLang(lang);
            },
        };
        return <Context.Provider value={state}>{children}</Context.Provider>;
    }
    ProviderTextPlatform.defaultProps = DEF_PROVIDER_TEXTPLATFORM;

    return ProviderTextPlatform;
}
