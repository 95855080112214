import React from "@platform/react";
import { ProviderStore } from "@platform/react/store";
import { ProviderMenu } from "@platform/react-storybook/Component/Menu/Provider";
import type { PropDefaults, PropElement, PropTypes } from "@platform/react-storybook/Component";

import { Store } from "@front/store";

import { ProviderTheme } from "@front/components/Theme";
import { ProviderText } from "@front/components/Element/Text";
import { ProviderAsset } from "@front/components/Element/Asset/Provider";
// import { LoadingAnimation } from "@front/components/Element/LoadingAnimation";
import { ErrorCatcher } from "@front/components/Element/ErrorCatcher";

// const Loading = <LoadingAnimation />;
const Loading = undefined;

export type PropWrapper = {
    children: NonNullable<PropElement["children"]>;
};

export const DEF_WRAPPER: PropDefaults<PropWrapper> = {};

export type PropTypesWrapper = PropTypes<PropWrapper, typeof DEF_WRAPPER>;

export function WrapperApp(props: PropWrapper) {
    const { children } = props as PropTypesWrapper;
    return (
        <ProviderStore store={Store}>
            <ProviderMenu>
                <ProviderTheme>
                    <ProviderAsset loading={Loading}>
                        <ProviderText loading={Loading}>
                            <ErrorCatcher>{children}</ErrorCatcher>
                        </ProviderText>
                    </ProviderAsset>
                </ProviderTheme>
            </ProviderMenu>
        </ProviderStore>
    );
}
ErrorCatcher.defaultProps = DEF_WRAPPER;
