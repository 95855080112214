import { PathCreate } from "@platform/react-storybook/Component/Router/utils";

import { paramVideo } from "../params/video";
import { PathGetHome } from "./_segments/home";
import { PathGetSecret } from "./_segments/secret";
import { PathGetProfile } from "./_segments/profile";

export function PathGetUser<R extends string>(ROOT: R) {
    return {
        ROOT,

        get USER() {
            const { HOME } = PathGetHome(ROOT, "user");
            const { SECRET } = PathGetSecret(ROOT);
            const { PROFILE } = PathGetProfile(ROOT);
            return {
                HOME,
                SOON: PathCreate(ROOT, "soon", paramVideo),
                SECRET,
                PROFILE,
                SESSION: PathCreate(ROOT, "session"),
                CONTACT: PathCreate(ROOT, "contact"),
            };
        },
    };
}
