import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_PAYMENT_TRIAL } from "./_namespace";
import type { NSSlicePaymentTrial } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSlicePaymentTrial(store: StoreState): NSSlicePaymentTrial.State {
    return store[NAME_SLICE_PAYMENT_TRIAL];
}

SelectorSlicePaymentTrial.isOpen = CreateSelector(
    SelectorSlicePaymentTrial,
    ({ isOpen }) => isOpen,
);
