import { CreateActions } from "@platform/react/src/store";
import { NAME_SLICE_PROGRAMS } from "./_namespace";
import type { NSSlicePrograms } from "./_namespace";

export const ActionSlicePrograms = CreateActions<{
    /** Sets a give property errors */
    set: NSSlicePrograms.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSlicePrograms.ArgClear | null;
    /** Sets the routine as complete */
    setRoutineComplete: void;
    /** Sets the task as complete */
    setTaskComplete: NSSlicePrograms.State["activeTask"];
    /** Sets the task cycles as complete */
    setTaskCyclesComplete: { uuidWorkout: NSSlicePrograms.State["uuid-workout"] };
    /** Set the next video */
    setNextVideo: { isFullScreen: boolean } | void;
    /** Set the actual video */
    setActualVideo: Pick<NSSlicePrograms.State, "activeVideo" | "activeTask">;
    /** Set first workout */
    setFirstWorkout: { uuidWorkout: NSSlicePrograms.State["uuid-workout"] };
    /** Set the next Workout */
    setNextWorkout: { "uuid-workout": string; hasFinishedAMRAP: boolean };
    /** Set routine start time */
    setRoutineStartTime: void;
    /** Set routine start time */
    setRoutinePartialTime: void;
    /** Set routine start time */
    setRoutineEndTime: { uuidWorkout: NSSlicePrograms.State["uuid-workout"] };
    /** Set timer */
    setTimer: NSSlicePrograms.timer;
    /** Set next training */
    nextTraining: void;
    /** Save survey */
    saveSurvey: { physical: string; emotional: string };
    /** Cleans hasFinished flag of tasks routine */
    cleanTasks: void;
    /** Save task progress */
    setTaskTimeProgress: {
        progress: number;
        uuid: string;
        cycleForWorkout: number;
        uuidWorkout: string;
    };
}>(NAME_SLICE_PROGRAMS, [
    "set",
    "clear",
    "setRoutineComplete",
    "setTaskComplete",
    "setNextVideo",
    "setNextWorkout",
    "cleanTasks",
    "setTaskCyclesComplete",
    "setActualVideo",
    "setFirstWorkout",
    "setTaskTimeProgress",
    "setRoutineStartTime",
    "setRoutinePartialTime",
    "setRoutineEndTime",
    "nextTraining",
    "setTimer",
    "saveSurvey",
]);
