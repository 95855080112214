import { CreateReducer } from "@platform/react/store";
import { ActionSliceNetwork } from "./_actions";

import { INIT_SLICE_NETWORK } from "./_namespace";

export const ReducerSliceNetwork = CreateReducer(INIT_SLICE_NETWORK, ({ addCase }) => {
    addCase(ActionSliceNetwork.set, (state, { payload }) => {
        state.status = payload;
    });
});
