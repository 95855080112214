import type { RouteDefs } from "@platform/react-storybook/Component/Router/types";
import { getPathHook, RoutesParse } from "@platform/react-storybook/Component/Router";

import type { StatusPayment } from "@common/content/payment/openpay";
import { isActivePayment } from "@common/content/payment/openpay";
import { ROUTE_COMMON, RoutesLoading } from "./common";
import { PATH } from "../paths";
import { PAGE } from "../pages";

const isActive = isActivePayment(process.env.REACT_APP_PAYMENTS_STATUS as StatusPayment);

export const ROUTE_WWW_PAYMENT = {
    PAYMENT: {
        path: "/payment",
        page: "Payment",
    },
    PAYMENT_SETUP_ALL: {
        path: "/payment/setup",
        page: "Payment/Setup",
    },
    PAYMENT_SETUP_ONE: {
        path: "/payment/setup/:code",
        page: "Payment/Setup",
    },
    PAYMENT_METHOD: {
        path: "/payment/method",
        page: "Payment/Method",
    },
    PAYMENT_METHOD_DELETE: {
        path: "/payment/method/delete",
        page: "Payment/Method/Delete",
    },
    PAYMENT_PLAN_ALL: {
        path: "/payment/plans",
        page: "Payment/Plans",
    },
    PAYMENT_PLAN_ONE: {
        path: "/payment/plans/:code",
        page: "Payment/Plans",
    },
    PAYMENT_STATUS_ALL: {
        path: "/payment/status",
        page: "Payment/Status",
    },
    PAYMENT_STATUS_ONE: {
        path: "/payment/status/:status",
        page: "Payment/Status",
    },
    PAYMENT_STATUS_ONE_CODE: {
        path: "/payment/status/:status/:code",
        page: "Payment/Status",
    },
    PAYMENT_CREDIT_CARD_ALL: {
        path: "/payment/credit-card",
        page: "Payment/CreditCard",
    },
    PAYMENT_CREDIT_CARD_ONE: {
        path: "/payment/credit-card/:type",
        page: "Payment/CreditCard",
    },
    PAYMENT_CREDIT_CARD_ONE_CONFIRM: {
        path: "/payment/method/:type/confirm",
        page: "Payment/CreditCard/Confirm",
    },
    PAYMENT_CHOOSE_PLAN_ALL: {
        path: "/payment/choose_plan",
        page: "Payment/ChoosePlan",
    },
    PAYMENT_CHOOSE_PLAN_ONE: {
        path: "/payment/choose_plan/:back",
        page: "Payment/ChoosePlan",
    },
    PAYMENT_HISTORY: {
        path: "/payment/history",
        page: "Payment/History",
    },
    PAYMENT_CANCEL_SUBSCRIPTION: {
        path: "/payment/subscription",
        page: "Payment/CancelSubscription",
    },
    PAYMENT_CASH_STATUS_ALL: {
        path: "/payment/status/charge",
        page: "Payment/CreditCard/Confirm/Status",
    },
    PAYMENT_CASH_STATUS_ONE: {
        path: "/payment/status/charge/:slug",
        page: "Payment/CreditCard/Confirm/Status",
    },
    PAYMENT_DETAILS: {
        path: "/payment/details/:id",
        page: "Payment/Details",
    },
} as const;

const ROUTER_PAYMENT = isActive ? ROUTE_WWW_PAYMENT : {};

export const ROUTE_WWW: RouteDefs = {
    WWW_HOME: {
        path: PATH.WWW.HOME.ROOT,
        page: PAGE.WWW_HOME,
    },
    WWW_HOME_CRACK_ONE: {
        path: PATH.WWW.HOME.CRACK.PARAM,
        page: PAGE.WWW_HOME_CRACK,
    },
    WWW_HOME_CATEGORY_ONE: {
        path: PATH.WWW.HOME.CATEGORY.PARAM,
        page: PAGE.WWW_HOME_CATEGORY,
    },
    WWW_SUPPORT: {
        path: PATH.WWW.SUPPORT.ROOT,
        page: PAGE.WWW_SUPPORT,
    },
    WWW_ABOUT: {
        path: PATH.WWW.ABOUT,
        page: PAGE.WWW_ABOUT,
    },
    WWW_BLOG: {
        path: PATH.WWW.BLOGS.BLOG.ROOT,
        page: PAGE.WWW_BLOG,
    },
    WWW_BLOG_FULL: {
        path: PATH.WWW.BLOGS.BLOG.CRACK,
        page: PAGE.WWW_BLOG_CRACK,
    },
    WWW_CUSTOM_LANDING: {
        path: PATH.WWW.HOME.CUSTOM_LANDING.PARAM,
        page: PAGE.WWW_CUSTOM_LANDING,
    },
    ...ROUTER_PAYMENT,
    // Must always be last
    ...ROUTE_COMMON,
} as const;

export const usePathWww = getPathHook(ROUTE_WWW);

export const RoutesWww = RoutesParse(ROUTE_WWW, { loading: RoutesLoading });
