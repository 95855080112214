import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_PROGRAMS } from "./_namespace";
import type { NSSlicePrograms } from "./_namespace";
import type { StoreState } from "../..";
import { getTimeFromSecondsFormatted } from "./_utils";

export function SelectorSlicePrograms(store: StoreState): NSSlicePrograms.State {
    return store[NAME_SLICE_PROGRAMS];
}

SelectorSlicePrograms.id = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.currentCrackProgram?.id,
);

SelectorSlicePrograms.activeVideo = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.activeVideo,
);

SelectorSlicePrograms.currentCrackProgram = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.currentCrackProgram,
);
SelectorSlicePrograms.task = CreateSelector(SelectorSlicePrograms, (state) => state.activeTask);

SelectorSlicePrograms.listTasks = CreateSelector(SelectorSlicePrograms, (state) => state.tasks);
SelectorSlicePrograms.activeTask = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.activeTask,
);

SelectorSlicePrograms.isShowMeHow = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.isShowMeHow,
);

SelectorSlicePrograms.isLeaveProgram = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.isLeaveProgram,
);

SelectorSlicePrograms.openBottomSheet = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.openBottomSheet,
);
SelectorSlicePrograms.isFirstTime = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.isFirstTime,
);

SelectorSlicePrograms.week = CreateSelector(SelectorSlicePrograms, (state) => state.week);

SelectorSlicePrograms.day = CreateSelector(SelectorSlicePrograms, (state) => state.day);
SelectorSlicePrograms.uuidWorkout = CreateSelector(
    SelectorSlicePrograms,
    (state) => state["uuid-workout"],
);

SelectorSlicePrograms.isLastTask = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.isLastTask,
);

SelectorSlicePrograms.workoutType = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.workoutType,
);

SelectorSlicePrograms.nameNextTask = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.nameNextTask,
);

SelectorSlicePrograms.lastCycle = CreateSelector(SelectorSlicePrograms, (state) => state.lastCycle);
SelectorSlicePrograms.cycleForWorkout = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.cycleForWorkout,
);
SelectorSlicePrograms.calendar = CreateSelector(SelectorSlicePrograms, (state) => state.calendar);
SelectorSlicePrograms.training = CreateSelector(SelectorSlicePrograms, (state) => {
    if (!state.training) return undefined;
    return state.training.find(
        (calendar) =>
            calendar.day === state.day.toString() && calendar.week === state.week.toString(),
    );
});

SelectorSlicePrograms.actualWorkout = CreateSelector(SelectorSlicePrograms, (state) => {
    if (!state.training) return undefined;
    const training = state.training.find(
        (calendar) =>
            calendar.day === state.day.toString() && calendar.week === state.week.toString(),
    );
    if (!training) return undefined;
    return training.workout.find((routine) => routine["uuid-workout"] === state["uuid-workout"]);
});

SelectorSlicePrograms.hasFinished = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.hasFinished,
);
SelectorSlicePrograms.progress = CreateSelector(SelectorSlicePrograms, (state) => {
    const ZERO = 0;
    const HUNDRED = 100;
    let PROGRESS = ZERO;
    if (!state.training) return PROGRESS;
    const trainingsQuantity = state.training.length;
    const finishedTrainings = state.training.filter((_training) =>
        Boolean(_training.hasFinished),
    ).length;
    PROGRESS = finishedTrainings / trainingsQuantity;
    return PROGRESS * HUNDRED;
});

SelectorSlicePrograms.hasFinishedTheProgram = CreateSelector(SelectorSlicePrograms, (state) => {
    if (!state.training) return false;
    const ONE = 1;
    return state.training[state.training.length - ONE].hasFinished;
});

SelectorSlicePrograms.isTaskTime = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.activeTask?.taskType === "time",
);

SelectorSlicePrograms.getDurationTime = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.activeTask?.milliseconds,
);

SelectorSlicePrograms.progressTask = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.progressTask,
);

SelectorSlicePrograms.trainingTimes = CreateSelector(SelectorSlicePrograms, (state) => {
    if (!state.training) return undefined;
    const training = state.training.find(
        (calendar) =>
            calendar.day === state.day.toString() && calendar.week === state.week.toString(),
    );
    if (!training) return undefined;
    return {
        title: state.currentCrackProgram?.cover.title,
        training: training.time,
        workouts: training.workout.map((_workout) => {
            if (!_workout.time)
                return {
                    title: _workout.title,
                    time: `00:00`,
                };
            const timer = getTimeFromSecondsFormatted(_workout.time);
            return {
                title: _workout.title,
                time: `${timer.minutes}:${timer.seconds}`,
            };
        }),
    };
});

SelectorSlicePrograms.currentWorkout = CreateSelector(SelectorSlicePrograms, (state) => {
    if (!state.training) return undefined;
    const training = state.training.find(
        (calendar) =>
            calendar.day === state.day.toString() && calendar.week === state.week.toString(),
    );
    if (!training) return undefined;

    return training.workout;
});

SelectorSlicePrograms.exerciseDetails = CreateSelector(
    SelectorSlicePrograms,
    (state) => state.exerciseDetails,
);

SelectorSlicePrograms.currentDayWithoutWeek = CreateSelector(SelectorSlicePrograms, (state) => {
    const WEEK = 7;
    const ONE = 1;
    const WEEK_OFFSET = (Number(state.week) - ONE) * WEEK;
    return String(Number(state.day) + WEEK_OFFSET);
});
