import { PREFIX_FONT_THEME } from ".";

export const PREFIX_SIZE_FONT_THEME = `${PREFIX_FONT_THEME}_size` as const;

export const SIZE_FONT_THEME = {
    tomato: "10px",
    carrot: "14px",
    celery: "17px",
    radish: "18px",
    corn: "22px",
    pepper: "28px",
    spinach: "32px",
    onion: "36px",
    pea: "42px",
} as const;

export type SizeFontTheme = typeof SIZE_FONT_THEME;

export type UuidSizeFontTheme = keyof SizeFontTheme;

export type ValueSizeFontTheme = SizeFontTheme[UuidSizeFontTheme];

export const UUID_SIZE_FONT_THEME = Object.keys(SIZE_FONT_THEME) as readonly UuidSizeFontTheme[];
