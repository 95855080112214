import { PREFIX_BOX_THEME } from ".";

export const PREFIX_SPACE_BOX_THEME = `${PREFIX_BOX_THEME}_space` as const;

export const SPACE_BOX_THEME = {
    none: "0",
    banana: "1px",
    melon: "2px",
    kiwi: "4px",
    pear: "8px",
    lychee: "16px",
    grape: "24px",
    cherry: "32px",
    mango: "40px",
    peach: "48px",
    lemon: "56px",
    papaya: "64px",
    guava: "80px",
    fig: "104px",
    chikoo: "144px",
    avocado: "160px",
    apple: "174px",
    coconut: "240px",
    durian: "272px",
    quince: "296px",
    watermelon: "344px",
} as const;

export type SpaceBoxTheme = typeof SPACE_BOX_THEME;

export type UuidSpaceBoxTheme = keyof SpaceBoxTheme;

export type ValueSpaceBoxTheme = SpaceBoxTheme[UuidSpaceBoxTheme];

export const UUID_SPACE_BOX_THEME = Object.keys(SPACE_BOX_THEME) as readonly UuidSpaceBoxTheme[];
