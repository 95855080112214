import { PREFIX_FONT_THEME } from ".";

export const PREFIX_FACE_FONT_THEME = `${PREFIX_FONT_THEME}_face` as const;

export const FACE_FONT_THEME = {
    lotus: "akzidenzgrotesk-bold",
    tulip: "akzidenzgrotesk-medium",
    lily: "akzidenzgrotesk-light",
    pansyl: "inter",
} as const;

export type FaceFontTheme = typeof FACE_FONT_THEME;

export type UuidFaceFontTheme = keyof FaceFontTheme;

export type ValueFaceFontTheme = FaceFontTheme[UuidFaceFontTheme];

export const UUID_FACE_FONT_THEME = Object.keys(FACE_FONT_THEME) as readonly UuidFaceFontTheme[];
