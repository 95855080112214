import { PathCreate } from "@platform/react-storybook/Component/Router/utils";

import { paramCrack } from "../../params/crack";
import { paramVideo } from "../../params/video";

import { PathGetCategory } from "./category";
import type { SegmentOrigin } from "./_common";
import { SEGMENT_ORIGIN } from "./_common";

export function PathGetCrack<R extends string, O extends SegmentOrigin>(...args: [R, O]) {
    const [ROOT, ORIGIN] = args;
    return {
        ROOT,

        get CRACK() {
            const PATH_CRACK = PathCreate(ROOT, "crack");
            const PATH_CRACK_ONE = PathCreate(PATH_CRACK, paramCrack);
            const { CATEGORY } = PathGetCategory(PATH_CRACK_ONE);
            const RETURN_BASE = {
                ROOT: PATH_CRACK,
                PARAM: PATH_CRACK_ONE,
            };
            const RETURN_USER = {
                ...RETURN_BASE,
                CATEGORY: {
                    ...CATEGORY,
                    PARAM_VIDEO: PathCreate(CATEGORY.PARAM, paramVideo),
                },
            };
            type PathCrack = O extends typeof SEGMENT_ORIGIN.USER
                ? typeof RETURN_USER
                : typeof RETURN_BASE;
            return (ORIGIN === SEGMENT_ORIGIN.USER ? RETURN_USER : RETURN_BASE) as PathCrack;
        },
    };
}
