import { CreateStore } from "@platform/react/store";

import { Reducer } from "./_reducer";
import { Storage } from "./_storage";

export type StoreState = ReturnType<typeof Reducer>;

export const { useDispatch, useSelector, Store } = CreateStore<StoreState>({
    Storage,
    Reducer,
});
