import { PREFIX_FONT_THEME } from ".";

export const PREFIX_SPACE_WRAP_FONT_THEME = `${PREFIX_FONT_THEME}_wrap_space` as const;

export const SPACE_WRAP_FONT_THEME = {
    default: "normal",
    none: "nowrap",
    north: "pre",
    south: "pre-wrap",
    east: "pre-line",
    west: "break-spaces",
} as const;

export type SpaceWrapFontTheme = typeof SPACE_WRAP_FONT_THEME;

export type UuidSpaceWrapFontTheme = keyof SpaceWrapFontTheme;

export type ValueSpaceWrapFontTheme = SpaceWrapFontTheme[UuidSpaceWrapFontTheme];

export const UUID_SPACE_WRAP_FONT_THEME = Object.keys(
    SPACE_WRAP_FONT_THEME,
) as readonly UuidSpaceWrapFontTheme[];
