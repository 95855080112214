import React from "@platform/react";

import type { PropDefaults, PropTypes } from "../../Component";
import type {
    ArgConsumerThemePlatform,
    GetterThemePlatform,
    PropConsumerThemePlatform,
} from "./types";
import { ERROR_THEMEPLATFORM, NAME_CONSUMER_THEMEPLATFORM } from "./types";

export function CreateConsumerThemePlatform<G extends GetterThemePlatform>(
    arg: ArgConsumerThemePlatform<G>,
) {
    const { Context } = arg;

    const DEF_CONSUMER_THEMEPLATFORM: PropDefaults<PropConsumerThemePlatform<G>> = {};

    function ThemePlatform(
        props: PropTypes<PropConsumerThemePlatform<G>, typeof DEF_CONSUMER_THEMEPLATFORM>,
    ) {
        const context = React.useContext(Context);

        if (!context) {
            throw new Error(ERROR_THEMEPLATFORM.NO_PROVIDER);
        }
        const { themeData, themeCurrent } = context;
        const ref = React.useRef<HTMLElement>();
        const children = React.cloneElement(React.Children.only(props.children), {
            ref(el: HTMLElement) {
                ref.current = el;
            },
        });

        type PartialTheme = { [varname: string]: string };
        let vars: undefined | PartialTheme = undefined;
        if (props.vars === "*") {
            /** All of the variables will be sent */
            vars = themeData[themeCurrent];
        } else if (typeof props.vars === "string") {
            /** A single variable will be sent */
            vars = { [props.vars]: themeData[themeCurrent][props.vars] };
        } else if (Array.isArray(props.vars)) {
            /** Multiple variables will be sent */
            vars = props.vars.reduce(
                (acc, v) => ({ ...acc, [v]: themeData[themeCurrent][v] }),
                {} as PartialTheme,
            );
        } else {
            vars = props.vars as PartialTheme;
        }

        React.useEffect(() => {
            const { current } = ref;
            if (!current) return;
            Object.entries(vars as NonNullable<PartialTheme>).forEach(([key, val]) => {
                current.style.setProperty(`--${key}`, val);
            });
        }, [vars]);

        return children;
    }
    ThemePlatform.displayName = NAME_CONSUMER_THEMEPLATFORM;
    ThemePlatform.defaultProps = DEF_CONSUMER_THEMEPLATFORM;

    return ThemePlatform;
}
