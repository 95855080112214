import React from "@platform/react";

import { CreateTextPlatform } from "@platform/react-storybook/Bundle/Text";
import { DEFAULT_LANGUAGE } from "@common/content/language";
import { ERROR_TEXTPLATFORM, NAME_TEXTPLATFORM } from "@platform/react-storybook/Bundle/Text/types";

import { DEF_TEXT, TAG_PRESET_TEXT, NAME_TEXT, TYPE_TEXT, ERROR_TEXT } from "./types";
import { getter, languages } from "./data";
import type { PropTypesText, PropProviderText } from "./types";

import "./_rules.module.scss";

const { ProviderTextPlatform, useTextPlatform } = CreateTextPlatform({
    getter,
    languages,
});

export function useText() {
    try {
        return useTextPlatform();
    } catch (error) {
        throw new Error(ERROR_TEXTPLATFORM.NO_PROVIDER.replace(NAME_TEXTPLATFORM, NAME_TEXT));
    }
}

export function ProviderText(props: PropProviderText) {
    const { children, ...rest } = props;
    return (
        <ProviderTextPlatform language={DEFAULT_LANGUAGE} {...rest}>
            {children}
        </ProviderTextPlatform>
    );
}

/**
 * It extends the base functionality of `@platform/text` bundle to handle the written content of
 * the app, allowing it to be adapted to other languages automatically.
 */
// eslint-disable-next-line complexity
export function Text(props: PropTypesText) {
    const {
        children,
        uuid,
        preset,
        fallback,
        replace,
        raw,
        strip: shouldStrip,
        tag: propTag,
        ...rest
    } = props;

    const { textGet } = useText();

    const propsText = {
        "data-preset": "",
        "data-name": NAME_TEXT,
        "data-type": TYPE_TEXT,
        "data-uuid": uuid,
        ...rest,
    };
    if (preset) propsText["data-preset"] = preset;
    else Object.defineProperty(propsText, "data-preset", { value: undefined });
    let tag = propTag;
    if (!tag) tag = preset ? TAG_PRESET_TEXT[preset] : "span";

    // are both uuid and children falsy? do nothing;
    if (!uuid && !children) {
        return null;
    }

    // handle all cases when children is sent either as an Array, JSX.Element or plain text.
    if (Array.isArray(children))
        return (
            <React.Fragment>
                {React.Children.map(children, (node) => clone({ tag, node, props: propsText }))}
            </React.Fragment>
        );
    if (React.isValidElement(children))
        return <React.Fragment>{clone({ tag, node: children, props: propsText })}</React.Fragment>;
    if (children && raw) {
        return React.createElement(tag, {
            ...propsText,
            dangerouslySetInnerHTML: { __html: children },
        });
    }
    if (children) return React.createElement(tag, propsText, String(children));

    // at this point, an uuid should be sent, return null but let know the developer.
    if (!uuid) {
        // eslint-disable-next-line no-console
        console.error(ERROR_TEXT.NO_UUID);
        return null;
    }
    // resolve corresponding text.
    const content = textGet({ uuid, replace, fallback });
    const finalProps = raw
        ? { ...propsText, dangerouslySetInnerHTML: { __html: content } }
        : propsText;
    let finalChildren = raw ? undefined : content;
    if (finalChildren && shouldStrip) finalChildren = finalChildren.replace(/<[^>]*>/g, "");
    return React.createElement(tag, finalProps, finalChildren);
}
Text.defaultProps = DEF_TEXT;

function clone(arg: {
    tag: string;
    node: React.ReactNode;
    props: Record<string, unknown>;
}): React.ReactNode {
    const { node, tag, props: propsClone } = arg;
    // TODO: [MC-821] Do further tests for this case.
    if (Array.isArray(node)) {
        const ch = React.Children.map(node, (n) => clone({ tag, node: n, props: propsClone }));
        if (!ch) return null;
        return ch;
    }
    if (React.isValidElement(node)) {
        const { props: propsNode } = node;
        return React.cloneElement(node, {
            ...propsNode,
            ...propsClone,
        });
    }
    if (!node) return null;
    return React.createElement(tag, {}, String(node));
}
