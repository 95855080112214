import { PREFIX_BORDER_BOX_THEME } from "@common/content/theme/box/border";

export const PREFIX_STYLE_BORDER_BOX_THEME = `${PREFIX_BORDER_BOX_THEME}_style` as const;

export const STYLE_BORDER_BOX_THEME = {
    default: "solid",
    sequoia: "dotted",
    oak: "dashed",
    palm: "double",
    maple: "groove",
    willow: "ridge",
    none: "none",
} as const;

export type StyleBorderBoxTheme = typeof STYLE_BORDER_BOX_THEME;

export type UuidStyleBorderBoxTheme = keyof StyleBorderBoxTheme;

export type ValueStyleBorderBoxTheme = StyleBorderBoxTheme[UuidStyleBorderBoxTheme];

export const UUID_STYLE_BORDER_THEME = Object.keys(
    STYLE_BORDER_BOX_THEME,
) as readonly UuidStyleBorderBoxTheme[];
