import type {
    PropProviderThemePlatform,
    PropConsumerThemePlatform,
} from "@platform/react-storybook/Bundle/Theme/types";
import type { PropDefaults } from "@platform/react-storybook/Component";

import Namespace from "./_index.scss";
import type { getter } from "./data";

export type GetterTheme = typeof getter;

export const { NAME_THEME, TYPE_THEME } = Namespace;
export const DEF_THEME: PropDefaults<PropConsumerThemePlatform<GetterTheme>> = {};

export type PropProviderTheme = Omit<
    PropProviderThemePlatform<GetterTheme>,
    "theme" | "shouldInjectOnLoad"
>;
