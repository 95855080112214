import { CombineReducers } from "@platform/react/store";

import { SliceException } from "./slices/exception";
import { SliceFetching } from "./slices/fetching";
import { SliceApp } from "./slices/app";
import { SliceNetwork } from "./slices/network";
import { SliceSession } from "./slices/session";
import { SliceEnrollment } from "./slices/enrollment";
import { SlicePrograms } from "./slices/programs";
import { SliceHistoryVideo } from "./slices/historyVideo";
import { SliceDeleteAccount } from "./slices/deleteAccount";
import { SlicePaymentTrial } from "./slices/payment-trial";
import { SlicePayment } from "./slices/payment";
import { SliceSecrets } from "./slices/secret";
import { SlicePurchases } from "./slices/purchases";

export const Reducer = CombineReducers({
    ...SliceException,
    ...SliceFetching,
    ...SliceApp,
    ...SliceNetwork,
    ...SliceSession,
    ...SliceEnrollment,
    ...SlicePrograms,
    ...SliceHistoryVideo,
    ...SliceDeleteAccount,
    ...SlicePaymentTrial,
    ...SlicePayment,
    ...SliceSecrets,
    ...SlicePurchases,
});
