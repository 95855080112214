export const TermsES = `
<h1 class="terms-title">Términos y Condiciones de uso de Master Cracks</h1>
<h5 class="terms-date">Fecha: <span class="tems-data-number">{{DATE}}</span></h5>
<p class="terms-paragraph">
    Master Cracks, Inc. (en adelante, "Master Cracks") es un proveedor de servicios y productos de
    tecnología que ayudan a sus usuarios a desarrollarse física, atlética y tácticamente a través de
    una plataforma digital. Al utilizar la aplicación web y/o sitio web de mastercracks.com, o
    cualquier servicio, productos, características, contenido o aplicaciones proporcionadas en
    relación con el mismo (colectivamente, el "Servicio"), implica que el usuario (en adelante, el
    "Usuario") ha leído, entendido y acepta estar obligado por estos términos y condiciones de uso
    (en adelante, los "Términos de Servicio"), siendo que pueden ser modificados por nosotros en
    cualquier momento, a nuestra entera discreción.
</p>
<p class="terms-paragraph">
    Asimismo, al acceder o utilizar nuestro Servicio, el Usuario también acepta la recopilación y el
    uso de su información como se indica en nuestra Política de Privacidad disponible en www.Master
    Cracks.com/privacy-policy (nuestra "Política de Privacidad"), ya sea que el Usuario esté
    registrado o no en el Servicio de Master Cracks. Master Cracks se reserva el derecho de
    modificar estos Términos de Servicio y proporcionará un aviso de estos cambios como se describe
    a continuación, incluyendo, pero no limitado a, a través de una aceptación mediante un clic o
    una notificación push. Estos Términos de Servicio se aplican a todos los visitantes, usuarios y
    otras personas que accedan a nuestro Servicio o utilicen nuestros productos (colectivamente, los
    "Usuarios").
</p>
<p class="terms-paragraph">
    Invitamos a todos los Usuarios a leer detenidamente los Términos de Servicio detenidamente para
    asegurarse de que comprenden cada una de las disposiciones de los mimos. Estos Términos de
    Servicio contienen una disposición obligatoria de arbitraje individual y de renuncia a acciones
    colectivas/juicios con jurado que implica el uso del arbitraje unipersonal para resolver
    disputas, en lugar de juicios con jurado o acciones colectivas.
</p>

<h4 class="terms-subtitle">A. Servicio de Master Cracks</h4>
<ul>
    <li>
        <h5 class="terms-subtitle">Admisibilidad:</h5>
        <p class="terms-paragraph">
            El presente es un acuerdo vinculante entre el Usuario y Master Cracks. El Usuario debe
            leer y aceptar estos Términos de Servicio antes de utilizar el Servicio de Master
            Cracks. Si el Usuario no está de acuerdo con estos Términos de Servicio, el Usuario no
            podrá hacer uso del Servicio de Master Cracks. El Usuario podrá hacer uso del Servicio
            de Master Cracks sólo si está dispuesto a crear un contrato vinculante con nosotros, y
            sólo en cumplimiento de estos Términos de Servicio y todas las leyes, normas y
            reglamentos locales, estatales, nacionales e internacionales aplicables. Cualquier uso o
            acceso a nuestro Servicio por parte de cualquier persona menor de 18 (dieciocho) años
            está estrictamente prohibido y constituye una violación de estos Términos de Servicio.
            El Servicio de Master Cracks no estará disponible para ningún Usuario que hayamos
            eliminado previamente de nuestro Servicio.
        </p>
    </li>

    <li>
        <h5 class="terms-subtitle">Acceso y Uso:</h5>
        <p class="terms-paragraph">
            Sujeto al cumplimiento por parte del Usuario de estos Términos de Servicio, el Usuario
            podrá acceder y utilizar el Servicio Master Cracks únicamente para su uso personal y no
            comercial. Master Cracks se reserva todos los derechos no concedidos expresamente por
            virtud de los presentes Términos de Servicio en y para el Servicio Master Cracks y la
            Propiedad Intelectual de Master Cracks (definida más adelante). Master Cracks podrá
            suspender o dar por terminado el acceso del Usuario al Servicio Master Cracks en
            cualquier momento, por cualquier razón o en ausencia de la misma.
        </p>
    </li>
    <li>
        <h5 class="terms-subtitle">Restricciones:</h5>
        <p class="terms-paragraph">
            El usuario no llevará a cabo por sí mismo, ni ayudará o permitirá que otros lleven a
            cabo nada de lo siguiente:
        </p>
        <ul>
            <li>
                <p class="terms-paragraph">
                    Utilizar el Servicio de Master Cracks para cualquier otro propósito que no sea
                    el expresamente indicado en los Términos de Servicio;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Desmontar, realizar ingeniería inversa, decodificar o descompilar cualquier
                    parte del Servicio Master Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Utilizar cualquier robot, raspador, herramienta de minería de datos, herramienta
                    de recopilación o extracción de datos, o cualquier otro medio automatizado, para
                    acceder, recopilar, copiar o grabar el Servicio;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Copiar, alquilar, arrendar, vender, transferir, ceder, sublicenciar, modificar,
                    alterar o crear trabajos derivados de cualquier parte del Servicio de Master
                    Cracks o de cualquier Propiedad Intelectual de Master Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Eliminar cualquier aviso de derechos de autor o leyendas de propiedad,
                    incluyendo Propiedad Industrial y/o Intelectual del Servicio de Master Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Utilizar el Servicio Master Cracks de manera que afecte: (i) la estabilidad de
                    los servidores de Master Cracks; (ii) el funcionamiento o el rendimiento del
                    Servicio de Master Cracks o el uso del Servicio de Master Cracks por parte de
                    cualquier otro Usuario; o (iii) el comportamiento de otras aplicaciones que
                    utilicen el Servicio de Master Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Utilizar el Servicio de Master Cracks de cualquier manera o para cualquier
                    propósito que viole cualquier ley aplicable, regulación, requisito u obligación
                    legal, obligación contractual, o cualquier derecho de cualquier persona,
                    incluyendo, pero no limitado a, los derechos de propiedad intelectual, derechos
                    de privacidad o derechos de publicidad, o que de otra manera pueda ser
                    perjudicial (a discreción de Master Cracks) para Master Cracks, los proveedores
                    de Master Cracks, o los Usuarios de Mater Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Utilizar el Servicio de Master Cracks en competencia con Master Cracks, para
                    desarrollar productos o servicios de la competencia, o de cualquier otro modo en
                    detrimento o desventaja comercial de Master Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Utilizar el Servicio de Master Cracks para realizar análisis comparativos o
                    competitivos del Servicio de Master Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Intentar interferir, comprometer la integridad o seguridad del sistema, o
                    descifrar cualquier transmisión hacia o desde los servidores que ejecutan el
                    Servicio de Master Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Transmitir virus u otros agentes de software a través del Servicio Master
                    Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Hacerse pasar por otra persona o tergiversar su relación con una persona o
                    entidad, ocultar o intentar ocultar la identidad de los Usuarios, o utilizar de
                    otro modo el Servicio Master Cracks para cualquier propósito invasivo o
                    fraudulento;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Compartir contraseñas o credenciales de autenticación para el Servicio Master
                    Cracks;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Violar las medidas que Master Cracks pueda utilizar para prevenir o restringir
                    el acceso al Servicio Master Cracks o hacer cumplir las limitaciones de uso del
                    Servicio Master Cracks o del contenido del mismo, incluyendo, sin limitación,
                    las características que impiden o restringen el uso o la copia de cualquier
                    contenido;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Identificar a Master Cracks o mostrar cualquier parte de su Servicio en
                    cualquier sitio sin previa autorización, o en un servicio que desprestigie a
                    Master Cracks o sus productos o servicios, o que infrinja cualquiera de los
                    derechos de propiedad intelectual u otros derechos de Master Cracks; y
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Identificar o referirse a Master Cracks o a su Servicio de una manera que pueda
                    implicar razonablemente un respaldo, relación o afiliación o patrocinio entre el
                    Usuario o un tercero y Master Cracks, aparte del uso permitido por el Usuario de
                    nuestro Servicio bajo estos Términos de Servicio, sin el consentimiento expreso
                    por escrito de Master Cracks.
                </p>
            </li>
        </ul>
    </li>
    <li>
        <h5 class="terms-subtitle">Cuentas del Usuario:</h5>
        <p class="terms-paragraph">
            La cuenta de Usuario en el Servicio de Master Cracks (en adelante la "Cuenta de
            Usuario") le da al Usuario acceso a los productos y funcionalidades que Master Cracks
            establezca y mantenga en sus plataformas, a su entera discreción. Master Cracks puede
            mantener diferentes tipos de Cuentas de Usuario para diferentes tipos de Usuarios. Si un
            Usuario abre una Cuenta de Usuario en nombre de una empresa, organización u otra
            entidad, entonces: (i) "La empresa" incluye al Usuario y a dicha entidad, y (ii) la
            empresa representa y garantiza que el Usuario es un representante autorizado de la
            entidad con la autoridad para obligar a la entidad a estos Términos de Servicio y que el
            Usuario acepta estos Términos de Servicio en nombre de la entidad. Al conectarse al
            Servicio de Master Cracks con un servicio de terceros, el Usuario otorga permiso a
            Master Cracks para acceder y utilizar la información del Usuario de ese servicio según
            lo permitido por ese servicio, y para almacenar las credenciales de inicio de sesión del
            Usuario para ese servicio.
        </p>
        <p class="terms-paragraph">
            El Usuario también acepta que toda la información suministrada a Master Cracks es
            verdadera, precisa, actual y completa. El Usuario está obligado a mantener la
            información de su cuenta actualizada en todo momento. Algunos de los datos personales
            necesarios pueden ser, entre otros, los siguientes:
        </p>
        <ul>
            <li><p class="terms-paragraph">Dirección de correo electrónico;</p></li>
            <li><p class="terms-paragraph">El nombre y los apellidos del Usuario;</p></li>
            <li><p class="terms-paragraph">La fecha de nacimiento del Usuario;</p></li>
            <li>
                <p class="terms-paragraph">
                    El número de teléfono, incluyendo el número de teléfono móvil o el número de
                    teléfono fijo;
                </p>
            </li>
            <li><p class="terms-paragraph">Domicilio;</p></li>
            <li>
                <p class="terms-paragraph">
                    Información financiera, incluyendo números de tarjetas de crédito;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Información sobre pagos o impuestos, incluidos, entre otros, los datos de la
                    cuenta bancaria;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Información de inicio de sesión y contraseña del usuario;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Nombre de usuario y contraseña de Facebook, Gmail u otras redes sociales;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    El nombre del dominio desde el que el Usuario accede a Internet;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Las preferencias de búsqueda, incluyendo la latitud y la longitud de la
                    ubicación del Usuario;
                </p>
            </li>
            <li><p class="terms-paragraph">Dirección de la ubicación del Usuario; y</p></li>
            <li>
                <p class="terms-paragraph">
                    La fecha y la hora en que el Usuario accede a la Aplicación y/o al Servicio, así
                    como la duración de la sesión.
                </p>
            </li>
        </ul>
    </li>
    <li>
        <p class="terms-paragraph">
            Uso de la Información del Usuario El Usuario nunca podrá utilizar la Cuenta de Usuario
            de otro Usuario sin permiso. Al crear la Cuenta de Usuario, el Usuario debe proporcionar
            información de perfil precisa y completa, y debe mantener esta información actualizada.
            El Usuario es el único responsable de la actividad que se produzca en su Cuenta de
            Usuario y debe mantener segura la contraseña de su Cuenta de Usuario. Master Cracks
            anima al Usuario a utilizar contraseñas "fuertes" (contraseñas que utilizan una
            combinación de letras mayúsculas y minúsculas, números y símbolos) para su Cuenta de
            Usuario. El Usuario debe notificar inmediatamente a Master Cracks cualquier violación de
            la seguridad o el uso no autorizado de su Cuenta de Usuario. Master Cracks no será
            responsable de las pérdidas causadas por cualquier uso no autorizado de la Cuenta de
            Usuario. El Usuario puede controlar ciertos aspectos de su perfil de Usuario y cómo
            puede interactuar con el Servicio de Master Cracks cambiando la configuración en su
            página de ajustes. Al proporcionar a Master Cracks la dirección de correo electrónico
            del Usuario, el Usuario da su consentimiento para que Master Cracks utilice la dirección
            de correo electrónico para enviar al Usuario los avisos relacionados con el Servicio,
            incluidos los avisos exigidos por la ley, en lugar de la comunicación por correo postal.
            Master Cracks también puede utilizar la dirección de correo electrónico del Usuario para
            enviarle otros mensajes promocionales, como cambios en las características del Servicio
            de Master Cracks y ofertas especiales. Si el Usuario no desea recibir dichos mensajes de
            correo electrónico, puede optar por no recibirlos o cambiar sus preferencias poniéndose
            en contacto con el servicio de asistencia de Master Cracks en hello@.... o haciendo clic
            en el enlace para darse de baja dentro de cada mensaje promocional. La exclusión
            voluntaria puede impedir que el Usuario reciba mensajes de correo electrónico sobre
            actualizaciones, mejoras u ofertas; sin embargo, la exclusión voluntaria no impedirá que
            el Usuario reciba avisos relacionados con el Servicio. El Usuario reconoce que no es
            propietario de la Cuenta de Usuario que utiliza para acceder al Servicio de Master
            Cracks. Sin perjuicio de cualquier disposición en estos Términos de Servicio que indique
            lo contrario, el Usuario acepta que Master Cracks tiene el derecho absoluto de
            gestionar, regular, controlar, modificar y/o eliminar cualquier dato almacenado por
            Master Cracks o en su nombre en sus servidores (incluidos los de nuestros proveedores de
            almacenamiento de terceros) como Master Cracks considere oportuno a su entera
            discreción, en cualquier caso general o específico, y que Master Cracks no tendrá
            ninguna responsabilidad ante el Usuario basada en el ejercicio de dicho derecho por
            parte de Master Cracks. Todos los datos que se encuentren en los servidores de Master
            Cracks son susceptibles de ser eliminados, modificados, o transferidos. A pesar de
            cualquier valor atribuido a dichos datos por el Usuario o cualquier tercero, el Usuario
            entiende y acepta que cualquier dato, historial de la cuenta de usuario y contenido de
            la cuenta de usuario que resida en los servidores de Master Cracks, puede ser eliminado,
            alterado, trasladado o transferido en cualquier momento por cualquier razón a discreción
            de Master Cracks, con o sin previo aviso y sin responsabilidad de ningún tipo. Master
            Cracks no proporciona ni garantiza, y rechaza expresamente, cualquier valor, en efectivo
            o de otro tipo, atribuido a cualquier dato que resida en sus servidores.
        </p>
    </li>
    <li>
        <p class="terms-paragraph">
            Datos del Usuario Como parte del Servicio de Master Cracks, éste recabará datos,
            contenido e información, incluyendo video, audio, información personal, incluyendo pero
            no limitado a, la imagen del Usuario, la voz y comentarios que el Usuario proporcione a
            Master Cracks o que sea recopilada por Master Cracks o a través del Servicio, incluyendo
            sin limitación los Datos de Entrenamiento como se describe en nuestra Política de
            Privacidad, así como todos los videos, imágenes y comentarios, ya sean capturados por
            Master Cracks o proporcionados por el Usuario donde aparezca la imagen y/o la voz del
            Usuario, incluyendo aquellos que cualquiera de las partes suba a cualquier red social
            relacionada con Master Cracks, al igual que cualquier Contenido que el Usuario
            proporcione a Master Cracks a través de la plataforma o página web (según dicho término
            se define más adelante). (en adelante, los "Datos del Usuario"). El Usuario otorga a
            Master Cracks, y declara y garantiza que tiene todos los derechos necesarios para
            otorgar a Master Cracks, un derecho y licencia perpetua, irrevocable, no exclusiva,
            sublicenciable, transferible y libre de regalías para recolectar, usar, reproducir,
            distribuir electrónicamente, transmitir, hacer transmitir, ejecutar, mostrar, almacenar,
            archivar, y para modificar y hacer trabajos derivados de todos y cada uno de los Datos
            del Usuario con el fin de proporcionar y mantener el Servicio de Master Cracks para el
            Usuario y, únicamente en forma anónima o agregada, para mejorar los productos y
            servicios de Master Cracks y para otros fines comerciales de Master Cracks (y todos y
            cada uno de esos datos derivados se consideran parte de la Propiedad Intelectual de
            Master Cracks). En relación con los Datos del Usuario, el Usuario afirma, declara y
            garantiza que: (a) cuenta con el consentimiento por escrito de todas y cada una de las
            personas físicas identificables que figuran en los Datos del Usuario en relación con el
            nombre o la imagen de dicha persona en la forma contemplada en el Servicio y en los
            presentes Términos de Servicio, y que cada una de dichas personas ha eximido al Usuario
            de cualquier responsabilidad que pueda surgir en relación con dicho uso; (b) los Datos
            del Usuario y el uso que haga Master Cracks de los mismos, tal y como se contempla en
            los presentes Términos de Servicio y en el Servicio, no violarán ninguna ley ni
            infringirán ningún derecho de terceros, incluidos, entre otros, los derechos de
            propiedad intelectual y los derechos de privacidad. Mater Cracks no se responsabiliza ni
            asume ninguna responsabilidad por los Datos del Usuario. El Usuario será el único
            responsable e indemnizará a Mater Cracks por sus Datos de Usuario.
        </p>
    </li>
    <li>
        <p class="terms-paragraph">
            Modificaciones al Servicio de Master Cracks Master Cracks puede, sin previo aviso,
            cambiar su Servicio, dejar de proporcionar su Servicio o características de su Servicio,
            a los Usuarios; o imponer límites de uso para su Servicio. Master Cracks puede terminar
            o suspender permanente o temporalmente el acceso del Usuario a su Servicio sin previo
            aviso y sin responsabilidad para Master Cracks, por cualquier motivo, incluyendo si a
            consideración de Master Cracks el Usuario viola cualquier disposición de estos Términos
            de Servicio. Tras la terminación por cualquier motivo, o a falta del mismo, el Usuario
            sigue estando obligado por estos Términos de Servicio.
        </p>
    </li>

    <li>
        <p class="terms-paragraph">
            Interacciones con otros Usuarios El Usuario es el único responsable de sus interacciones
            con otros Usuarios. Master Cracks se reserva el derecho, pero no tiene la obligación, de
            supervisar las disputas entre el Usuario y otros Usuarios. Master Cracks no tendrá
            ninguna responsabilidad por las interacciones del Usuario con otros Usuarios, o por la
            acción o inacción de cualquier Usuario.
        </p>
    </li>
    <li>
        <p class="terms-paragraph">
            Ubicación del Servicio El Servicio Master Cracks es controlado y operado desde
            instalaciones en los Estados Unidos. Aquellos que accedan o utilicen el Servicio Master
            Cracks desde otras jurisdicciones son totalmente responsables del cumplimiento de todas
            las leyes y regulaciones locales y de los Estados Unidos que sean aplicables,
            incluyendo, pero no limitándose a las regulaciones de exportación e importación. El
            Usuario no puede utilizar el Servicio Master Cracks si es residente de un país en
            cualquier forma limitado por los Estados Unidos o es una persona o entidad extranjera
            bloqueada o denegada por el gobierno de los Estados Unidos.
        </p>
    </li>
    <li>
        <p class="terms-paragraph">
            Propiedad Intelectual de Master Cracks El Usuario reconoce y acepta que el Servicio de
            Master Cracks y todos los materiales y contenidos mostrados o puestos a disposición en
            su Servicio, así como todo el software, los algoritmos, el código, la tecnología y la
            propiedad intelectual subyacente e incluida en o con el Servicio de Master Cracks, y
            todos los derechos de propiedad intelectual de los mismos y en todo el mundo (colectiva
            e individualmente, nuestra "Propiedad Intelectual"), son de su propiedad única y
            exclusiva (o de los licenciantes de Master Cracks, según corresponda). Salvo lo
            dispuesto explícitamente en el presente documento, o en los sitios de Master Cracks,
            nada de lo dispuesto en estas Condiciones de Servicio se considerará que crea una
            licencia de o bajo cualquier derecho de propiedad intelectual, y el Usuario se
            compromete a no acceder, vender, licenciar, alquilar, modificar, distribuir, copiar,
            reproducir, transmitir, comunicar públicamente, ejecutar públicamente, publicar,
            adaptar, editar o crear trabajos derivados de cualquiera de la Propiedad Intelectual de
            Master Cracks. El uso de la Propiedad Intelectual de Master Cracks para cualquier
            propósito que no esté expresamente permitido por estos Términos de Servicio está
            estrictamente prohibido, incluyendo, pero sin limitarse a, cualquier uso comercial. El
            Usuario puede elegir, o Master Cracks puede invitar al Usuario a enviar, comentarios o
            ideas sobre su Servicio, incluyendo, sin limitación, sobre cómo mejorar su Servicio o
            sus productos (en adelante, las "Ideas"). Al enviar cualquier Idea, el Usuario acepta
            que su divulgación es gratuita, no solicitada y sin restricciones y que no pondrá a
            Master Cracks bajo ninguna obligación fiduciaria o de otro tipo y que Master Cracks es
            libre de utilizar la Idea sin ninguna compensación adicional para el Usuario, y/o de
            divulgar la Idea de forma no confidencial o de otra manera a cualquier persona. El
            Usuario reconoce además que, por la aceptación de su presentación, Master Cracks no
            renuncia a ningún derecho de uso de ideas similares o relacionadas previamente conocidas
            por Master Cracks, o desarrolladas por empleados de Master Cracks, u obtenidas de
            fuentes distintas al Usuario. Master Cracks respeta los derechos de propiedad
            intelectual de otros y pide que los Usuarios de nuestros Servicios hagan lo mismo. Si el
            Usuario cree que uno de nuestros usuarios, a través del uso de nuestros Servicios, está
            infringiendo ilegalmente los derechos de autor de una obra, y desea que se retire el
            material supuestamente infractor, deberá proporcionar la siguiente información en forma
            de notificación escrita (según la Ley de Derechos de Autor de la Era Digital) al Agente
            de Derechos de Autor designado por Master Cracks:
        </p>
        <ul>
            <li>
                <p class="terms-paragraph">
                    1. Una firma electrónica o física del propietario o de la persona autorizada
                    para actuar en nombre del propietario de los derechos de autor;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    2. Identificación de la obra con derechos de autor que se reclama como
                    infringida;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    3. Una descripción del material de los Servicios que el Usuario alega que se ha
                    infringido, e información razonablemente suficiente para permitir a Master
                    Cracks localizar el material (por ejemplo, la URL de la página web);
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    4. El nombre, la dirección, el número de teléfono y la dirección de correo
                    electrónico del Usuario;
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    5. Una declaración de que el Usuario cree de buena fe que el uso controvertido
                    no está autorizado por el propietario de los derechos de autor, su agente o la
                    ley; y
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    6. Una declaración del Usuario, hecha so pena de perjurio, de que la información
                    anterior en su notificación es exacta y que el Usuario es el propietario de los
                    derechos de autor o está autorizado para actuar en nombre del propietario de los
                    derechos de autor.
                </p>
            </li>
        </ul>
        <h5 class="terms-subtitle">
            La información de contacto del agente de derechos de autor de Master Cracks es la
            siguiente: [CONTACTO]
        </h5>
    </li>
    <li>
        <p class="terms-paragraph">
            Su Contenido Algunas áreas del Servicio permiten al Usuario publicar su contenido, como
            información de perfil, comentarios, preguntas y otros contenidos o información (en
            adelante, el "Contenido"). Master Cracks no reclama ningún derecho de propiedad sobre el
            Contenido del Usuario. El Contenido del Usuario sigue siendo suyo; sin embargo, al
            compartir su Contenido a través del Servicio, el Usuario acepta permitir que otros vean,
            editen y/o compartan su Contenido siguiendo su configuración y estas Condiciones de
            Servicio. Master Cracks tiene el derecho (pero no la obligación), a su entera
            discreción, de eliminar cualquier Contenido suyo que se comparta a través del Servicio.
            El Usuario acepta que su Contenido no incluirá ningún contenido o material que: (i)
            pueda crear un riesgo de daño, pérdida, lesión física o mental, angustia emocional,
            muerte, discapacidad, desfiguración o enfermedad física o mental para el Usuario o para
            cualquier otra persona; (ii) pueda crear un riesgo de cualquier otra pérdida o daño para
            cualquier persona o propiedad; (iii) busque dañar o explotar a los niños exponiéndolos a
            contenidos inapropiados, pidiéndoles detalles de identificación personal o de otra
            manera; (iv) pueda constituir o contribuir a un delito o agravio; (v) contenga cualquier
            información o contenido que Master Cracks considere ilegal, dañino, abusivo, ofensivo
            desde el punto de vista racial o étnico, difamatorio, infractor, invasor de la
            privacidad personal o de los derechos de publicidad, acosador, humillante para otras
            personas (públicamente o de otro modo), calumnioso, amenazante, profano o de cualquier
            otro modo objetable; (vi) contenga cualquier información o contenido que sea ilegal
            (incluyendo, sin limitación, la divulgación de información privilegiada bajo la ley de
            valores o de secretos comerciales de otra parte); (vii) contenga cualquier información o
            contenido que el Usuario no tenga derecho a poner a disposición bajo cualquier ley o
            bajo relaciones contractuales o fiduciarias; o (viii) contenga cualquier información o
            contenido que el Usuario sepa que no es correcto y actual. El Usuario acepta que su
            Contenido no viola ni violará derechos de terceros de ningún tipo, incluidos, sin
            limitación, los derechos de propiedad intelectual o los derechos de privacidad. Master
            Cracks se reserva el derecho, pero no está obligado, a rechazar y/o eliminar cualquier
            Contenido suyo que Master Cracks considere, a su entera discreción, que viola estas
            disposiciones. El Usuario entiende que la publicación de su Contenido en el Servicio no
            sustituye el registro del mismo en la Oficina de Derechos de Autor de los Estados
            Unidos, el Gremio de Escritores de América o cualquier otra organización de derechos. En
            relación con el Contenido del Usuario, éste afirma, declara y garantiza que: (a) el
            Usuario tiene el consentimiento por escrito de cada persona física identificable en su
            Contenido para utilizar el nombre o la imagen de dicha persona de la manera contemplada
            por el Servicio y estas Condiciones de Servicio, y cada una de dichas personas ha
            liberado al Usuario de cualquier responsabilidad que pueda surgir sobre dicho uso; (b)
            el Contenido del Usuario y el uso que haga Master Cracks del mismo según lo contemplado
            en estas Condiciones de Servicio y el Servicio no violarán ninguna ley ni infringirán
            ningún derecho de terceros, incluidos, entre otros, los derechos de propiedad
            intelectual y los derechos de privacidad; (c) Master Cracks podrá ejercer los derechos
            sobre el Contenido del Usuario concedidos en virtud de estas Condiciones del Servicio
            sin responsabilidad por el pago de cualquier cuota exigible en virtud de cualquier
            convenio colectivo o de otro modo; y (d) según el leal saber y entender de los Usuarios,
            todo su Contenido y demás información que el Usuario proporcione a Master Cracks es
            veraz y exacta. Master Cracks no se responsabiliza ni asume ninguna responsabilidad por
            ninguno de los Contenidos del Usuario. El Usuario será el único responsable de su
            Contenido y de las consecuencias de su envío o publicación. El Usuario entiende y acepta
            que puede estar expuesto a que su Contenido sea inexacto, censurable, inapropiado para
            los niños, o de otra manera inadecuado para el propósito del Usuario, y el Usuario
            acepta que Master Cracks no será responsable de ningún daño que el Usuario alegue
            incurrir a causa del Contenido del Usuario.
        </p>
    </li>

    <li>
        <p class="terms-paragraph">
            Instrucciones; Restricciones; Advertencias; Liberación de Responsabilidad. Por favor,
            lea cuidadosamente todas las instrucciones que vienen con los productos de Master
            Cracks, incluyendo las instrucciones que pueden estar disponibles en línea en el sitio
            web de Master Cracks, o poniéndose en contacto con el soporte de Master Cracks en
            hello@.... El Usuario entiende y acepta que asume todo el riesgo y la responsabilidad, y
            que Master Cracks no tiene ninguna obligación o responsabilidad ante el Usuario o
            cualquier otra persona por cualquiera o todas las siguientes actividades:
        </p>
        <ul>
            <li><h5>TENGA EN CUENTA LAS SIGUIENTES ADVERTENCIAS:</h5></li>
            <li>
                <p class="terms-paragraph">
                    La realización de cualquier ejercicio o instrucción de forma incorrecta puede
                    dar lugar a lesiones graves o a un deterioro de la funcionalidad. Supervise a
                    los niños alrededor de los productos Master Cracks o durante el entrenamiento.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    No realice ningún movimiento que no haya sido explícitamente instruido por un
                    Crack de Master Cracks.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Cualquier participación en las clases debe estar debidamente supervisado con la
                    presencia de un adulto.
                </p>
            </li>
            <li><p class="terms-paragraph">Siga todas las advertencias e instrucciones.</p></li>
            <li>
                <p class="terms-paragraph">
                    Asegúrese de que se encuentra en un espacio de entrenamiento adecuado y de que
                    entrena sobre una superficie sólida, nivelada y limpia. Se recomienda que no
                    intente entrenar en espacios cerrados, o con objetos frágiles u otras
                    personas/animales cerca.
                </p>
            </li>
        </ul>
    </li>

    <li>
        <p class="terms-paragraph">
            LIBERACIÓN DE RESPONSABILIDAD: Master Cracks no garantiza ningún resultado por parte de
            los instructores de Master Cracks ni asegura que su contenido sea preciso, fiable,
            siempre disponible o completo. El Usuario puede encontrar contenido a través del
            Servicio que podría encontrar ofensivo, indecente u objetable. Master Cracks no tiene
            ninguna responsabilidad por el contenido enviado por el Usuario. Master Cracks se ha
            esforzado por ser lo más preciso posible al describir y mostrar las características de
            los productos de Master Cracks; sin embargo, no se garantiza que la calidad, las
            descripciones de los productos u otros contenidos del Servicio sean precisos, completos,
            fiables, actuales o libres de errores. El uso por parte del Usuario de cualquier
            servicio o producto de terceros en relación con el uso de los servicios de Master Cracks
            está sujeto a estos Términos de Servicio y a cualquier término de terceros aplicable a
            dichos servicios o productos de terceros. Al utilizar cualquier servicio o producto de
            terceros, el Usuario es responsable de cualquier información que proporcione al tercero.
            El uso por parte del Usuario de servicios o productos de terceros puede incurrir en
            cargos por uso y/o datos, siendo el Usuario responsable de dichos cargos. Master Cracks
            no tiene ninguna responsabilidad por los servicios o productos de terceros. El Usuario
            reconoce que es el único responsable de obtener cualquier acceso a Internet a través de
            un proveedor de servicios de datos celulares o un proveedor de servicios inalámbricos u
            otro proveedor de servicios de conectividad a Internet para acceder a los servicios de
            Master Cracks. El Usuario debe asegurarse siempre de que está lo suficientemente en
            forma para emprender cualquier rutina de ejercicios. Al rehabilitar una lesión, el
            Usuario siempre debe consultar con su médico, fisioterapeuta u otro profesional
            sanitario cualificado antes de iniciar un ejercicio que pueda ser perjudicial para su
            recuperación. El Usuario no debe asumir NUNCA que puede reproducir con exactitud los
            movimientos que ve en el vídeo sin la instrucción adecuada de un profesional
            cualificado. Es responsabilidad del Usuario asegurarse de que está físicamente en
            condiciones de realizar dichos movimientos. Las rutinas del vídeo pretenden ser una guía
            general y, aunque el Master Cracks intenta describir las rutinas con precisión para que
            sean seguras y adecuadas, y si el Usuario tiene alguna lesión, enfermedad u otra
            condición que pueda hacer que ciertos movimientos estén contraindicados, es su
            responsabilidad evitarlos. El Usuario debe consultar siempre a un profesional para
            obtener los máximos resultados del equipo y evitar lesiones.
        </p>
    </li>

    <li>
        <h5 class="terms-subtitle">Cargos y Pagos</h5>
        <ul>
            <li>
                <p class="terms-paragraph">
                    Políticas de Facturación. Al utilizar el Servicio, el Usuario acepta los precios
                    y las condiciones de pago de Master Cracks tal y como Master Cracks puede
                    actualizarlos de vez en vez. A menos que se especifique lo contrario en el
                    momento de la compra, el Usuario debe pagar por sus servicios o productos de
                    Master Cracks cuando el Usuario realiza su pedido. El Usuario reconoce que el
                    monto facturado puede variar debido a cambios en las tarifas aplicables, ofertas
                    promocionales y cambios en los impuestos aplicables, y el Usuario autoriza a
                    Master Cracks a cambiar su forma de pago por los montos correspondientes.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Planes de Suscripción. El Usuario se compromete a realizar los pagos, y Master
                    Cracks cobrará automáticamente mediante el método de pago asociado a la Cuenta
                    de Usuario (en adelante, el "Método de Pago"), como se describe a continuación
                    durante el tiempo que su Cuenta de Usuario permanezca activa. Master Cracks
                    puede ofrecer planes en los que el Usuario puede inscribirse y que le permiten
                    utilizar ciertos aspectos del Servicio (en adelante, un "Plan de Suscripción").
                    Los Planes de Suscripción pueden establecer asignaciones para el uso de
                    determinados aspectos del Servicio. El uso del Servicio por encima de la
                    asignación designada en un Plan de Suscripción puede dar lugar a tarifas
                    adicionales, según se especifique en el plan. Los Planes de Suscripción pueden
                    ofrecerse a cambio de una cuota u otro cargo por un período determinado de uso
                    del Servicio (por ejemplo, mensual o anual) (en adelante, un "Periodo de
                    Suscripción"). Si el Usuario opta por utilizar un Plan de Suscripción de pago,
                    el Usuario acepta los precios y las condiciones de pago especificadas en el
                    momento de la compra y en el presente documento, ya que Master Cracks puede
                    actualizarlos de vez en cuando. Master Cracks podrá modificar los Planes de
                    Suscripción ofreciendo nuevos servicios por tarifas adicionales y añadiendo o
                    modificando las tarifas de los Planes de Suscripción/servicios existentes, en
                    cualquier momento y a su entera discreción. Cualquier cambio en el precio o las
                    condiciones de pago de un Plan de Suscripción entrará en vigor en el ciclo de
                    facturación siguiente a la notificación de dicho cambio al Usuario, tal y como
                    se establece en los presentes Términos de Servicio. Todos los Planes de
                    Suscripción se renovarán automáticamente hasta que sean cancelados por el
                    Usuario. Si el Usuario no desea que un Plan de Suscripción se renueve, deberá
                    cancelarlo al menos tres (3) días antes de que finalice el Periodo de
                    Suscripción. Si Master Cracks da por terminado el Plan de Suscripción del
                    Usuario, Master Cracks concederá al Cliente un reembolso prorrateado por la
                    parte restante no utilizada de su Periodo de Suscripción. El Usuario no tiene
                    derecho a un reembolso por cualquier Plan de Suscripción que el Usuario cancele.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Información sobre el pago: Impuestos. El Usuario debe proporcionar a Master
                    Cracks un Método de Pago actual, válido y aceptado. El Usuario autoriza a Master
                    Cracks (a través de nuestros proveedores de pago externos) a facturar las
                    tarifas al Método de Pago del Usuario, junto con cualquier impuesto aplicable o
                    tarifa adicional que se deba pagar durante el período de facturación. Toda la
                    información que el Usuario proporcione en relación con una compra o transacción
                    u otra interacción de transacción monetaria con el Servicio de Master Cracks
                    debe ser precisa, completa y actual. El Usuario pagará cualquier impuesto
                    aplicable, si lo hubiera, relacionado con tales compras, transacciones u otras
                    interacciones de transacciones monetarias.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Proveedor de pagos de terceros. Las transacciones de Master Cracks que se
                    realizan dentro del sitio web, serán llevadas a cabo por la pasarela de pagos de
                    Openpay. Al utilizar el Servicio de Master Cracks, el Usuario acepta el
                    contenido de los términos y condiciones de uso, y las demás políticas de
                    Openpay, disponibles en Openpay.
                </p>
            </li>
        </ul>
    </li>

    <li>
        <h5 class="terms-subtitle">Envíos y Devoluciones.</h5>
        <ul>
            <li>
                <p class="terms-paragraph">
                    Envíos. Todos los productos serán entregados en la dirección de envío que el
                    Usuario haya proporcionado. Si Master Cracks proporciona una fecha de envío
                    prevista, dicha fecha es una estimación y está sujeta a cambios. Master Cracks
                    no será responsable de ningún cambio en dicha fecha de envío prevista. El
                    Usuario será notificado por correo electrónico si hay algún problema que retrase
                    su entrega más allá de la fecha de envío prevista. Las direcciones de
                    facturación y de envío del Usuario pueden ser diferentes. Master Cracks se
                    encargará de embalar y enviar los productos de Master Cracks al Usuario; en el
                    entendido que se podrá cobrar una cantidad adicional al Usuario si cambia su
                    dirección de envío después de haber pedido dichos productos. Las fechas de envío
                    y entrega programadas son sólo estimaciones y no pueden ser garantizadas. Master
                    Cracks hará esfuerzos comercialmente razonables para enviar dichos productos al
                    Usuario en las fechas de entrega programadas, pero Master Cracks no es
                    responsable, bajo ninguna circunstancia, de ninguna pérdida, daño o penalización
                    debido a cualquier retraso en los envíos o entregas.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Devoluciones. El Usuario podrá solicitar la devolución de los productos a Master
                    Cracks sin cargo alguno para obtener el reembolso total del importe que pagó por
                    dichos productos, menos los gastos de envío o entrega, en cualquier momento
                    dentro de los treinta (30) días siguientes a la recepción (o cualquier otro
                    período más largo que exija la legislación aplicable o que ofrezca Master Cracks
                    en el momento de la venta, (el "Período de devolución"). Esta garantía de
                    devolución de dinero no cubre ningún servicio y no cubre la insatisfacción o el
                    mal funcionamiento causado por el desgaste ordinario o los daños causados por el
                    uso inadecuado o los accidentes, sujeto a los términos de nuestra Garantía
                    Limitada. Los daños en los productos de Master Cracks a la recepción de los
                    mismos deben ser anotados y documentados mediante fotografías y/o vídeos en el
                    momento de la entrega. El usuario debe inspeccionar minuciosamente los productos
                    e informar de cualquier daño en un plazo de 48 horas a la empresa de entrega y
                    al servicio de asistencia de Master Cracks.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Garantía Limitada. De acuerdo con los términos de la Garantía Limitada, si surge
                    un defecto en un producto de Master Cracks o un componente garantizado dentro
                    del período de Garantía Limitada aplicable, el único y exclusivo remedio del
                    Usuario es que Master Cracks, a discreción de Master Cracks en la medida
                    permitida por la ley, reemplace o repare el producto o componente de Master
                    Cracks defectuoso o que funcione mal con el mismo o un modelo comparable o, si
                    así lo acuerdan las partes, Master Cracks puede proporcionar un crédito por
                    dicha cantidad para la compra de un nuevo producto o servicio de Master Cracks.
                    Cualquier reemplazo o componente reparado estará garantizado por el resto del
                    período original de la Garantía Limitada o 30 días, lo que sea más largo, o por
                    cualquier período adicional que sea requerido por la ley aplicable.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Devolución de productos. Master Cracks ofrecerá sin cargo alguno el embalaje y
                    el envío para la devolución por parte del Usuario en el caso de que éste
                    devuelva los productos debido a un defecto; para todas las demás devoluciones,
                    el Usuario es responsable de pagar los gastos de embalaje y envío asociados. Se
                    espera que el producto de Master Cracks sea devuelto en las mismas condiciones
                    en que fue recibido. Si faltan accesorios o hay daños en el equipo que no se
                    hayan observado al recibirlo, se asociará una tarifa por el reacondicionamiento
                    y la reparación que se deducirá del precio de compra del Usuario antes del
                    reembolso. Para solicitar una devolución o informar de un defecto, el Usuario
                    debe enviar un correo electrónico al departamento de atención al cliente de
                    Master Cracks a hello@.... y proporcionar su nombre, información de contacto y
                    el número de serie del producto de Master Cracks. Nada en esta Política de
                    Devolución reducirá o afectará de alguna manera los derechos legales del Usuario
                    con respecto al producto de Master Cracks.
                </p>
            </li>
        </ul>
    </li>

    <li>
        <p class="terms-paragraph">
            Ausencia de Asesoramiento Profesional Si el Servicio proporciona cualquier información
            que pueda ser interpretada como consejo profesional (por ejemplo, consejo médico), dicha
            información es sólo para fines informativos y no debe ser interpretada como consejo
            profesional. No debe tomarse ninguna medida basada en la información contenida en el
            Servicio. El usuario debe buscar asesoramiento profesional independiente de una persona
            licenciada y/o cualificada en el área correspondiente.
        </p>
    </li>
    <li>
        <p class="terms-paragraph">
            Privacidad Master Cracks se preocupa por la privacidad de nuestros Usuarios. El Usuario
            entiende que, al utilizar nuestro Servicio, otorga su consentimiento para la
            recopilación, uso y divulgación de su información personal y de los datos agregados y/o
            anonimizados, tal como se indica en nuestra Política de Privacidad, disponible en
            www.mastercracks.com/privacy-policy, y a que su información personal sea recopilada,
            utilizada, transferida y procesada en los Estados Unidos.
        </p>
    </li>

    <li>
        <p class="terms-paragraph">
            Seguridad El Usuario debe leer cuidadosamente todas las instrucciones que vienen con el
            producto de Master Cracks, incluyendo las instrucciones que pueden estar disponibles en
            línea en el sitio web de Master Cracks o poniéndose en contacto con el soporte en
            hello@... El Usuario entiende y acepta que asumirá todo el riesgo y la responsabilidad,
            y que Master Cracks no tiene ninguna obligación o responsabilidad ante el Usuario o
            cualquier otra persona por cualquiera o todas las siguientes actividades: Master Cracks
            se preocupa por la integridad y seguridad de la información personal de los Usuarios.
            Master Cracks utiliza salvaguardas físicas, administrativas y técnicas a su entera
            discreción para preservar la integridad y seguridad de la información personal del
            Usuario. Sin embargo, Master Cracks no puede garantizar que terceras partes no
            autorizadas nunca podrán vencer sus medidas de seguridad o utilizar su información
            personal para fines indebidos. El Usuario reconoce que proporciona su información
            personal bajo su propio riesgo.
        </p>
    </li>

    <li>
        <p class="terms-paragraph">
            Renuncia a Sitios y Enlaces de Terceros El Servicio puede contener materiales de
            terceros o enlaces a materiales de terceros que no son propiedad ni están controlados
            por Master Cracks. Master Cracks no respalda ni asume ninguna responsabilidad por dichos
            sitios, información, materiales, productos o servicios de terceros. Si el Usuario accede
            a un sitio web o servicio de terceros desde el Servicio o comparte su Contenido de
            Usuario en o a través de cualquier sitio web o servicio de terceros, el Usuario lo hace
            bajo su propio riesgo, y el Usuario entiende que estos Términos de Servicio y la
            política de privacidad de Master Cracks no se aplican a su uso de dichos sitios. El
            Usuario exime expresamente a Master Cracks de toda responsabilidad derivada de su uso de
            cualquier sitio web, servicio o contenido de terceros, incluidos, sin limitación, los
            materiales de terceros y los contenidos de los usuarios enviados por otros Usuarios.
            Además, los tratos del Usuario con las promociones de los anunciantes que se encuentran
            en el Servicio o su participación en ellas, incluidos el pago y la entrega de bienes, y
            cualquier otro término (como las garantías) son exclusivamente entre el Usuario y dichos
            anunciantes. El Usuario acepta que Master Cracks no será responsable de ninguna pérdida
            o daño de cualquier tipo relacionado con sus tratos con dichos anunciantes.
        </p>
    </li>

    <li>
        <p class="terms-paragraph">
            Indemnización El Usuario se compromete a defender, indemnizar y eximir de
            responsabilidad a Master Cracks y a sus filiales, agentes, licenciatarios,
            administradores y otras empresas afiliadas, así como a sus empleados, contratistas,
            agentes, funcionarios y directores, de y contra cualquier reclamación, daño, obligación,
            pérdida, responsabilidad, coste o deuda y gastos (incluidos, entre otros, los honorarios
            de los abogados) derivados de: (i) el uso por parte del Usuario del Servicio Master
            Cracks y el acceso al mismo, incluyendo cualquier dato o contenido transmitido o
            recibido por el Usuario; (ii) la violación por parte del Usuario de cualquier término de
            estos Términos de Servicio, incluyendo sin limitación el incumplimiento por parte del
            Usuario de cualquiera de las declaraciones y garantías anteriores; (iii) la violación
            por parte del Usuario de cualquier derecho de terceros, incluyendo sin limitación
            cualquier derecho de privacidad o de propiedad intelectual; (iv) la violación por parte
            del Usuario de cualquier ley, norma o reglamento aplicable; (v) los Datos del Usuario,
            incluyendo sin limitación la información engañosa, falsa o inexacta; (vi) la mala
            conducta intencionada del Usuario; o (vii) el acceso y uso por parte de cualquier otra
            parte del Servicio de Master Cracks con el nombre de usuario único del Usuario, la
            contraseña u otro código de seguridad apropiado; y (viii) el uso indebido por parte del
            Usuario de cualquier producto o servicio de Master Cracks.
        </p>
    </li>

    <li>
        <p class="terms-paragraph">
            Ausencia de Garantía El Servicio Master Cracks se proporciona "tal cual" y "según
            disponibilidad". El uso del servicio de Master Cracks es por cuenta y riesgo del
            Usuario. En la medida máxima permitida por la ley aplicable, el servicio de Master
            Cracks se proporciona sin garantías de ningún tipo, ya sean expresas o implícitas,
            incluyendo, pero sin limitarse a, las garantías implícitas de comerciabilidad, idoneidad
            para un propósito particular o no infracción. Ningún consejo o información, ya sea oral
            o escrito, obtenido por el Usuario de Master Cracks o a través del servicio de Master
            Cracks creará ninguna garantía que no esté expresamente indicada en este documento. Sin
            limitar lo anterior, Master Cracks, nuestras subsidiarias, nuestros afiliados y los
            otorgantes de licencias de Master Cracks no garantizan que el contenido sea preciso,
            confiable o correcto; que el servicio de Master Cracks cumplirá con los requisitos del
            Usuario; que el servicio de Master Cracks estará disponible en cualquier momento o lugar
            en particular, ininterrumpido o seguro; que cualquier defecto o error será corregido; o
            que nuestro servicio está libre de virus u otros componentes dañinos. Cualquier
            contenido descargado u obtenido de otra manera utilizando el servicio de Master Cracks
            se descarga bajo el propio riesgo del Usuario y el Usuario será el único responsable de
            cualquier daño a su sistema informático o dispositivo móvil o de la pérdida de datos que
            resulte de dicha descarga o del uso del servicio de Master Cracks por parte del Usuario.
            La ley federal, algunos estados, provincias y otras jurisdicciones no permiten la
            exclusión y las limitaciones de ciertas garantías implícitas, por lo que las exclusiones
            anteriores pueden no aplicarse al Usuario. Estos términos dan al Usuario derechos
            legales específicos, y el Usuario también puede tener otros derechos que varían de un
            estado a otro. Las renuncias y exclusiones de estos términos no se aplicarán en la
            medida en que lo prohíba la legislación aplicable.
        </p>
    </li>

    <li>
        <p class="terms-paragraph">
            Limitación de Responsabilidad En la medida máxima permitida por la ley aplicable, en
            ningún caso Master Cracks, o sus afiliados, agentes, directores, empleados, proveedores
            o licenciantes, serán responsables de ningún daño indirecto, punitivo, incidental,
            especial, consecuente o ejemplar, incluyendo sin limitación los daños por pérdida de
            beneficios, fondo de comercio, uso, datos u otras pérdidas intangibles, que surjan de o
            estén relacionados con el uso de, o la incapacidad de usar, su Servicio. En ninguna
            circunstancia seremos responsables de ningún daño, pérdida o perjuicio resultante de la
            piratería informática, la manipulación u otro acceso o uso no autorizado de su Servicio
            o de la cuenta del Usuario o de la información contenida en ella. En la medida máxima
            permitida por la ley aplicable, Master Cracks no asume ninguna responsabilidad por: (i)
            errores, equivocaciones o inexactitudes del contenido; (ii) lesiones personales o daños
            a la propiedad, de cualquier naturaleza, que resulten del acceso del Usuario o del uso
            del Servicio de Master Cracks; (iii) cualquier acceso o uso no autorizado de los
            servidores seguros de Master Cracks y/o toda la información personal almacenada en
            ellos; (iv) cualquier interrupción o cese de la transmisión hacia o desde el Servicio de
            Master Cracks; (v) cualquier error, virus o similares que puedan ser transmitidos a o a
            través del Servicio de Master Cracks por cualquier tercero; (vi) cualquier error u
            omisión en cualquier contenido o por cualquier pérdida o daño incurrido como resultado
            del uso de cualquier contenido publicado, enviado por correo electrónico, transmitido o
            puesto a disposición a través del Servicio de Master Cracks; y/o (vii) el contenido de
            los Usuarios o la conducta difamatoria, ofensiva o ilegal de cualquier tercero. En
            ningún caso Master Cracks, o sus afiliados, agentes, directores, empleados, proveedores
            o licenciantes, serán responsables ante el Usuario de cualquier reclamación,
            procedimiento, responsabilidad, obligación, daño, pérdida o coste que exceda la cantidad
            que el Usuario haya pagado a Master Cracks en virtud del presente documento o 1,000.00
            dólares, lo que sea mayor. Esta sección de limitación de responsabilidad se aplica tanto
            si la supuesta responsabilidad se basa en un contrato, agravio, negligencia,
            responsabilidad estricta o cualquier otra base, incluso si Master Cracks ha sido
            advertido de la posibilidad de tales daños. Algunos Estados no permiten la exclusión o
            limitación de los daños incidentales o consecuentes, por lo que las limitaciones o
            exclusiones anteriores pueden no ser aplicables al Usuario. Estos Términos de Servicio
            otorgan al Usuario derechos legales específicos, y el Usuario también puede tener otros
            derechos que varían de un Estado a otro. Las renuncias, exclusiones y limitaciones de
            responsabilidad en virtud de estos Términos de Servicio no se aplicarán en la medida en
            que lo prohíba la legislación aplicable.
        </p>
    </li>

    <li>
        <h5 class="terms-subtitle">
            Ley Aplicable, Arbitraje, y Renuncia a Acciones Colectivas o Juicios ante Jurado
        </h5>
        <ul>
            <li>
                <p class="terms-paragraph">
                    Ley aplicable. El Usuario acepta que: (i) se considera que el Servicio de Master
                    Cracks tiene su sede únicamente en California ; y (ii) se considera que el
                    Servicio de Master Cracks es un servicio pasivo que no da lugar a una
                    jurisdicción personal sobre Master Cracks, ni específica ni general, en
                    jurisdicciones distintas de California. Estas Condiciones de Servicio se regirán
                    por el derecho sustantivo interno del Estado de California, independientemente
                    de los principios de conflicto de leyes. Las partes reconocen que estos Términos
                    de Servicio evidencian una o más transacciones que involucran el comercio
                    interestatal. Sin perjuicio de las frases anteriores relativas al derecho
                    sustantivo, cualquier arbitraje realizado en virtud de estos Términos de
                    Servicio se regirán por la Ley Federal de Arbitraje (9 U.S.C. §§ 1-16). Se
                    excluye expresamente la aplicación de la Convención de las Naciones Unidas sobre
                    los Contratos de Compraventa Internacional de Mercancías. El Usuario acepta
                    someterse a la jurisdicción de los tribunales federales y estatales ubicados en
                    Sacramento, California, para cualquier acción en la que Master Cracks se reserve
                    el derecho de solicitar una medida cautelar u otra reparación equitativa en un
                    tribunal de jurisdicción competente para evitar la infracción, la apropiación
                    indebida o la violación real o amenazada de los derechos de autor, las marcas
                    comerciales, los secretos comerciales, las patentes u otros derechos de
                    propiedad intelectual o de propiedad de Master Cracks, como se indica en la
                    disposición de arbitraje que figura a continuación, incluida cualquier
                    reparación provisional necesaria para evitar daños irreparables. El Usuario
                    acepta que el condado de Sacramento, California, es el foro adecuado para
                    cualquier apelación de un laudo arbitral o para los procedimientos de los
                    tribunales de primera instancia en caso de que la disposición de arbitraje a
                    continuación se considere inaplicable.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Arbitraje. El Usuario debe leer esta sección cuidadosamente ya que requiere que
                    las partes arbitren sus disputas y limita la forma en que el Usuario puede
                    buscar compensación por parte de Master Cracks Para cualquier disputa con Master
                    Cracks, el Usuario se compromete a contactar primero a Master Cracks en
                    hello@... e intentar resolver la disputa con Master Cracks informalmente. En el
                    improbable caso de que Master Cracks no haya podido resolver una disputa que
                    Master Cracks tenga con el Usuario después de sesenta (60) días, las Partes
                    acuerdan resolver cualquier reclamo, disputa o controversia (excluyendo
                    cualquier reclamo de desagravio por mandato judicial u otro desagravio
                    equitativo como se establece a continuación) que surja de o en conexión con o en
                    relación con estos Términos de Servicio, o el incumplimiento o presunto
                    incumplimiento de las mismas (colectivamente, "Reclamaciones"), mediante el
                    arbitraje vinculante de JAMS , en virtud de los Procedimientos Opcionales para
                    el Arbitraje Acelerado vigentes en ese momento para JAMS, salvo lo dispuesto en
                    el presente documento. Se puede contactar con JAMS en www.jamsadr.com. El
                    arbitraje se llevará a cabo en el condado de Sacramento, California, salvo que
                    el Usuario y Master Cracks acuerden algo distinto. Si el Usuario está utilizando
                    el Servicio de Master Cracks con fines comerciales, cada parte será responsable
                    de pagar los honorarios de presentación, administrativos y del árbitro de JAMS
                    de acuerdo con las reglas de JAMS, y el laudo dictado por el árbitro incluirá
                    los costos del arbitraje, los honorarios razonables de los abogados y los costos
                    razonables de los testigos expertos y otros. Si el Usuario es un particular que
                    utiliza el Servicio Master Cracks con fines no comerciales (i) JAMS podrá exigir
                    al Usuario el pago de una tasa por la iniciación de su caso, a menos que el
                    Usuario solicite y obtenga con éxito una exención de tasas de JAMS; (ii) el
                    laudo dictado por el árbitro podrá incluir los costes de arbitraje del Usuario,
                    los honorarios razonables de los abogados y los costes razonables de los
                    expertos y otros testigos del Usuario; y (iii) el Usuario podrá demandar en un
                    tribunal de reclamaciones de menor cuantía de la jurisdicción competente sin
                    participar primero en el arbitraje, pero esto no exime al Usuario de su
                    compromiso de participar en el proceso informal de resolución de conflictos.
                    Cualquier juicio sobre el laudo dictado por el árbitro podrá ser presentado en
                    cualquier tribunal de jurisdicción competente. Nada de lo dispuesto en esta
                    sección se considerará como un impedimento para que Master Cracks solicite a los
                    tribunales una medida cautelar u otra reparación equitativa, según sea
                    necesario, para evitar la infracción, la apropiación indebida o la violación
                    real o amenaza de la seguridad de los datos, los derechos de propiedad
                    intelectual u otros derechos de propiedad de Master Cracks.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Renuncia a la Acción Colectiva/Juicio por Jurado. Con respecto a todas las
                    personas y entidades, independientemente de si han obtenido o utilizado el
                    Servicio de Master Cracks para fines personales, comerciales o de otro tipo,
                    todas las reclamaciones deben presentarse de manera individual, y no como
                    demandante o miembro de un grupo, en cualquier pretendida acción colectiva u
                    otros procedimientos representativos. Esta renuncia se aplica al arbitraje
                    colectivo y, a menos que Master Cracks acuerde lo contrario, el árbitro no podrá
                    consolidar las reclamaciones de más de una persona. El Usuario acepta que, al
                    suscribir estos Términos de Servicio, el Usuario y Master Cracks renuncian cada
                    uno a su derecho a un juicio con jurado o a participar en una acción colectiva,
                    u otros procedimientos representativos de cualquier tipo.
                </p>
            </li>
        </ul>
    </li>

    <li>
        <h5 class="terms-subtitle">Generales</h5>
        <ul>
            <li>
                <p class="terms-paragraph">
                    Cesión. Estos Términos de Servicio, así como los derechos y licencias concedidos
                    en virtud de las mismas, no podrán ser transferidos, ni cedidos por el Usuario
                    sin el previo consentimiento expreso y por escrito de Master Cracks, pero podrán
                    ser cedidos por Master Cracks sin restricción alguna. Cualquier intento de
                    transferencia o cesión que viole el presente documento será nulo y sin efecto.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Procedimientos de Notificación y Cambios a los Términos de Servicio. Master
                    Cracks puede proporcionar notificaciones, ya sea que dichas notificaciones sean
                    requeridas por la ley o sean para propósitos de mercadotecnia u otros propósitos
                    relacionados con el negocio, al Usuario a través de una notificación por correo
                    electrónico, una notificación por escrito o en papel, o a través de la
                    publicación de dicha notificación en el sitio web de Master Cracks, según Master
                    Cracks determine a su entera discreción. Master Cracks se reserva el derecho de
                    determinar la forma y los medios de proporcionar notificaciones a nuestros
                    Usuarios, siempre y cuando los Usuarios puedan optar por no recibir ciertas
                    notificaciones según lo requieran las leyes aplicables o según lo descrito en
                    estos Términos de Servicio o en la Política de Privacidad de Master Cracks.
                    Master Cracks no es responsable de ningún filtro automático que el Usuario o su
                    proveedor de red pueda aplicar a las notificaciones por correo electrónico que
                    Master Cracks envíe a la dirección de correo electrónico que el Usuario
                    proporcione a Master Cracks. Master Cracks puede, a su entera discreción,
                    modificar o actualizar estos Términos de Servicio de vez en cuando, por lo que
                    el Cliente debe revisar esta página periódicamente. El uso continuado de nuestro
                    Servicio por parte del Cliente después de cualquier cambio constituye la
                    aceptación por parte del Usuario de los nuevos Términos de Servicio. Si el
                    Usuario no está de acuerdo con alguna parte de estos Términos de Servicio o con
                    cualquier término futuro de uso del Servicio, no utilice o acceda (o continúe
                    accediendo) al Servicio de Master Cracks.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Acuerdo completo/Divisibilidad. Estos Términos de Servicio, junto con cualquier
                    enmienda y cualquier acuerdo adicional que el Usuario pueda celebrar con Master
                    Cracks en relación con el Servicio de Master Cracks, constituirán el acuerdo
                    completo entre el Usuario y Master Cracks en relación con nuestro Servicio.
                    Ninguno de los empleados o representantes de Master Cracks está autorizado a
                    realizar ninguna modificación o adición a estos Términos de Servicio. Cualquier
                    declaración o comentario realizado entre el Usuario y cualquiera de los
                    empleados o representantes de Master Cracks está expresamente excluido de estos
                    Términos de Servicio y no se aplicará al Usuario o a Master Cracks o al uso del
                    Servicio de Master Cracks por parte del Usuario. Si alguna disposición de estos
                    Términos de Servicio es considerada inválida por un tribunal de jurisdicción
                    competente, la invalidez de dicha disposición no afectará a la validez de las
                    restantes disposiciones de estos Términos de Servicio, que permanecerán en pleno
                    vigor y efecto.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Ausencia de Renuncia. Ninguna renuncia a cualquier término de estos Términos de
                    Servicio se considerará una renuncia adicional o continua de dicho término o de
                    cualquier otro término, y nuestro incapacidad o falta para hacer valer cualquier
                    derecho o disposición bajo estos Términos de Servicio no constituirá una
                    renuncia a dicho derecho o disposición.
                </p>
            </li>
            <li>
                <p class="terms-paragraph">
                    Contacto. Por favor, póngase en contacto con nosotros en {{EMAIL}} Respecto a
                    cualquier pregunta relacionada con estos Términos de Servicio.
                </p>
            </li>
        </ul>
    </li>
</ul>
`;
