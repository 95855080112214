import { PREFIX_FONT_THEME } from ".";

export const PREFIX_WORD_WRAP_FONT_THEME = `${PREFIX_FONT_THEME}_wrap_word` as const;

export const WORD_WRAP_FONT_THEME = {
    default: "normal",
    couch: "break-all",
    chair: "keep-all",
} as const;

export type WordWrapFontTheme = typeof WORD_WRAP_FONT_THEME;

export type UuidWordWrapFontTheme = keyof WordWrapFontTheme;

export type ValueWordWrapFontTheme = WordWrapFontTheme[UuidWordWrapFontTheme];

export const UUID_WORD_WRAP_FONT_THEME = Object.keys(
    WORD_WRAP_FONT_THEME,
) as readonly UuidWordWrapFontTheme[];
