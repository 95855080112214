import Namespace from "./_index.scss";

export const { NAME_ROUTER, TYPE_ROUTER } = Namespace;

export const PARAM_PREFIX = ":";

// -------------------------------------------------------------------------------------------------

export const PATH_ROOT = "/";

export type PathRoot = typeof PATH_ROOT;

// -------------------------------------------------------------------------------------------------

export const TARGET_PLATFORMLINK = {
    self: "_self",
    blank: "_blank",
    system: "_system",
} as const;

export type ValueTargetPlatformLink = typeof TARGET_PLATFORMLINK[keyof typeof TARGET_PLATFORMLINK];

// -------------------------------------------------------------------------------------------------

export type RecordURL = { pathname: string; search: string; hash: string };

export function isRecordURL(entry: unknown): entry is RecordURL {
    if (!entry || typeof entry !== "object") return false;
    const hasHref = Object.hasOwn(entry, "href");
    const hasPathname = Object.hasOwn(entry, "pathname");
    return hasHref && hasPathname;
}

// -------------------------------------------------------------------------------------------------

export type RouteDef = {
    path: string;
    page: string | Promise<{ default: React.ComponentType<unknown> }>;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    children?: RouteDefs;
};

export type RouteDefs = Record<string, RouteDef>;

export type Route = {
    path: string;
    id: string;
    element: React.ReactNode;
    children?: Route[];
};

export type RoutesParseProps = {
    loading?: JSX.Element;
};
