import type { PropElement, PropTypes, PropDefaults } from "@platform/react-storybook/Component";
import Namespace from "./_index.scss";

export const { NAME_LOADING_ANIMATION, TYPE_LOADING_ANIMATION } = Namespace;

export type PropLoadingAnimation = PropElement & {
    showOnFullScreen?: boolean;
    hasBackground?: boolean;
};

export const DEF_LOADING_ANIMATION: PropDefaults<PropLoadingAnimation> = {
    showOnFullScreen: true,
    hasBackground: true,
};

export type PropTypesLoadingAnimation = PropTypes<
    PropLoadingAnimation,
    typeof DEF_LOADING_ANIMATION
>;
