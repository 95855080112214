import { PREFIX_FONT_THEME } from ".";

export const PREFIX_DECO_FONT_THEME = `${PREFIX_FONT_THEME}_deco` as const;

export const DECO_FONT_THEME = {
    default: "none",
    winter: "underline",
    autumn: "overline",
    spring: "line-through",
} as const;

export type DecoFontTheme = typeof DECO_FONT_THEME;

export type UuidDecoFontTheme = keyof DecoFontTheme;

export type ValueDecoFontTheme = DecoFontTheme[UuidDecoFontTheme];

export const UUID_DECO_FONT_THEME = Object.keys(DECO_FONT_THEME) as readonly UuidDecoFontTheme[];
