import { CreateReducer } from "@platform/react/store";
import { ActionSliceSession } from "./_actions";
import { INIT_SLICE_SESSION } from "./_namespace";

export const ReducerSliceSession = CreateReducer(INIT_SLICE_SESSION, ({ addCase }) => {
    addCase(ActionSliceSession.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSliceSession.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_SESSION;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });
});
