import { PathCreate } from "@platform/react-storybook/Component/Router/utils";
import { paramCrack } from "../../params/landing";

export function PathGetSignUp<R extends string>(ROOT: R) {
    return {
        ROOT,

        get SIGNUP() {
            const PATH_CRACK = PathCreate(this.ROOT, "signup");
            const PATH_CRACK_ONE = PathCreate(PATH_CRACK, paramCrack);
            const RETURN_BASE = {
                ROOT: PATH_CRACK,
                PARAM: PATH_CRACK_ONE,
            };
            const RETURN_USER = {
                ...RETURN_BASE,
                FORM: {
                    ...RETURN_BASE,
                    PARAM_FORM: PathCreate(PATH_CRACK_ONE, "form"),
                },
                CONFIRM: {
                    ...RETURN_BASE,
                    PARAM_CONFIRM: PathCreate(PATH_CRACK_ONE, "confirm"),
                },
            };
            return RETURN_USER;
        },
    };
}
