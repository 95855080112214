import { PREFIX_FONT_THEME } from ".";

export const PREFIX_WEIGHT_FONT_THEME = `${PREFIX_FONT_THEME}_weight` as const;

export const WEIGHT_FONT_THEME = {
    labrador: "100",
    akita: "200",
    beagle: "300",
    boxer: "400",
    bulldog: "500",
    collie: "600",
    schnauzer: "700",
    mastiff: "800",
    poodle: "900",
} as const;

export type WeightFontTheme = typeof WEIGHT_FONT_THEME;

export type UuidWeightFontTheme = keyof WeightFontTheme;

export type ValueWeightFontTheme = WeightFontTheme[UuidWeightFontTheme];

export const UUID_WEIGHT_FONT_THEME = Object.keys(
    WEIGHT_FONT_THEME,
) as readonly UuidWeightFontTheme[];
