import React from "@platform/react";

import { Component } from "../../Component";
import type { PropDefaults, PropTypes } from "../../Component";
import type {
    ArgConsumerTextPlatform,
    GetterTextPlatform,
    PropConsumerTextPlatform,
} from "./types";
import {
    ERROR_TEXTPLATFORM,
    TYPE_CONSUMER_TEXTPLATFORM,
    NAME_CONSUMER_TEXTPLATFORM,
} from "./types";

export function CreateConsumerTextPlatform<G extends GetterTextPlatform>(
    arg: ArgConsumerTextPlatform<G>,
) {
    const { Context } = arg;

    const DEF_CONSUMER_TEXTPLATFORM: PropDefaults<
        Omit<PropConsumerTextPlatform<G>, "replace" | "fallback">
    > = {
        tag: "span",
    };

    function TextPlatform(
        props: PropTypes<PropConsumerTextPlatform<G>, typeof DEF_CONSUMER_TEXTPLATFORM>,
    ) {
        const { uuid, replace, fallback, tag, ...rest } = props;
        const context = React.useContext(Context);

        if (!context) {
            throw new Error(ERROR_TEXTPLATFORM.NO_PROVIDER);
        }
        const { textGet: getText } = context;

        const __html = getText({ uuid, replace, fallback });

        return (
            <Component
                {...rest}
                dangerouslySetInnerHTML={{ __html }}
                name={NAME_CONSUMER_TEXTPLATFORM}
                tag={tag}
                type={TYPE_CONSUMER_TEXTPLATFORM}
            />
        );
    }
    TextPlatform.displayName = NAME_CONSUMER_TEXTPLATFORM;
    TextPlatform.defaultProps = DEF_CONSUMER_TEXTPLATFORM;

    return TextPlatform;
}
