export const NAME_SLICE_EXCEPTION = "exception";

export declare namespace NSSliceException {
    export type Exception = {
        id: string;
        stack?: Error["stack"];
        name?: Error["name"];
        message: Error["message"];
    };

    export type State = NSSliceException.Exception[];
}

export const INIT_SLICE_EXCEPTION: NSSliceException.State = [];
