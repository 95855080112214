import { PathCreate } from "@platform/react-storybook/Component/Router/utils";

import { paramAny } from "../../params/any";

export function PathGetSecret<R extends string>(ROOT: R) {
    return {
        ROOT,

        get SECRET() {
            const ROOT_SECRET = PathCreate(ROOT, "secret");
            const ROOT_SECRET_LINKED = PathCreate(ROOT_SECRET, "linked", paramAny);
            const ROOT_PROGRAM = PathCreate(ROOT_SECRET, "program", paramAny);
            return {
                ROOT: ROOT_SECRET,
                LINKED: ROOT_SECRET_LINKED,
                PROGRAM: {
                    ALL: PathCreate(ROOT_SECRET, "program"),
                    ROOT: ROOT_PROGRAM,
                    TIPS: PathCreate(ROOT_PROGRAM, "tips"),
                    TRAIN: PathCreate(ROOT_PROGRAM, "train", paramAny),
                    FINISH: PathCreate(ROOT_PROGRAM, "finish"),
                },
            };
        },
    };
}
