import { CreateActions } from "@platform/react/src/store";
import { NAME_SLICE_APP } from "./_namespace";
import type { NSSliceApp } from "./_namespace";

export const ActionSliceApp = CreateActions<{
    /** Sets a give property errors */
    set: NSSliceApp.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSliceApp.ArgClear | null;
}>(NAME_SLICE_APP, ["set", "clear"]);
