export const ISO_639_1 = {
    aa: "Afar",
    ab: "Abkhazian",
    af: "Afrikaans",
    am: "Amharic",
    ar: "Arabic",
    "ar-ae": "Arabic (U.A.E.)",
    "ar-bh": "Arabic (Bahrain)",
    "ar-dz": "Arabic (Algeria)",
    "ar-eg": "Arabic (Egypt)",
    "ar-iq": "Arabic (Iraq)",
    "ar-jo": "Arabic (Jordan)",
    "ar-kw": "Arabic (Kuwait)",
    "ar-lb": "Arabic (Lebanon)",
    "ar-ly": "Arabic (libya)",
    "ar-ma": "Arabic (Morocco)",
    "ar-om": "Arabic (Oman)",
    "ar-qa": "Arabic (Qatar)",
    "ar-sa": "Arabic (Saudi Arabia)",
    "ar-sy": "Arabic (Syria)",
    "ar-tn": "Arabic (Tunisia)",
    "ar-ye": "Arabic (Yemen)",
    as: "Assamese",
    ay: "Aymara",
    az: "Azeri",
    ba: "Bashkir",
    be: "Belarusian",
    bg: "Bulgarian",
    bh: "Bihari",
    bi: "Bislama",
    bn: "Bengali",
    bo: "Tibetan",
    br: "Breton",
    ca: "Catalan",
    co: "Corsican",
    cs: "Czech",
    cy: "Welsh",
    da: "Danish",
    de: "German",
    "de-at": "German (Austria)",
    "de-ch": "German (Switzerland)",
    "de-li": "German (Liechtenstein)",
    "de-lu": "German (Luxembourg)",
    div: "Divehi",
    dz: "Bhutani",
    el: "Greek",
    en: "English",
    "en-au": "English (Australia)",
    "en-bz": "English (Belize)",
    "en-ca": "English (Canada)",
    "en-gb": "English (United Kingdom)",
    "en-ie": "English (Ireland)",
    "en-jm": "English (Jamaica)",
    "en-nz": "English (New Zealand)",
    "en-ph": "English (Philippines)",
    "en-tt": "English (Trinidad)",
    "en-us": "English",
    "en-za": "English (South Africa)",
    "en-zw": "English (Zimbabwe)",
    eo: "Esperanto",
    es: "Spanish",
    "es-ar": "Español (Argentina)",
    "es-bo": "Español (Bolivia)",
    "es-cl": "Español (Chile)",
    "es-co": "Español (Colombia)",
    "es-cr": "Español (Costa Rica)",
    "es-do": "Español (Dominican Republic)",
    "es-ec": "Español (Ecuador)",
    "es-es": "Español (España)",
    "es-gt": "Español (Guatemala)",
    "es-hn": "Español (Honduras)",
    "es-mx": "Español",
    "es-ni": "Español (Nicaragua)",
    "es-pa": "Español (Panama)",
    "es-pe": "Español (Peru)",
    "es-pr": "Español (Puerto Rico)",
    "es-py": "Español (Paraguay)",
    "es-sv": "Español (El Salvador)",
    "es-us": "Español (United States)",
    "es-uy": "Español (Uruguay)",
    "es-ve": "Español (Venezuela)",
    et: "Estonian",
    eu: "Basque",
    fa: "Farsi",
    fi: "Finnish",
    fj: "Fiji",
    fo: "Faeroese",
    fr: "French",
    "fr-be": "French (Belgium)",
    "fr-ca": "French (Canada)",
    "fr-ch": "French (Switzerland)",
    "fr-lu": "French (Luxembourg)",
    "fr-mc": "French (Monaco)",
    fy: "Frisian",
    ga: "Irish",
    gd: "Gaelic",
    gl: "Galician",
    gn: "Guarani",
    gu: "Gujarati",
    ha: "Hausa",
    he: "Hebrew",
    hi: "Hindi",
    hr: "Croatian",
    hu: "Hungarian",
    hy: "Armenian",
    ia: "Interlingua",
    id: "Indonesian",
    ie: "Interlingue",
    ik: "Inupiak",
    in: "Indonesian",
    is: "Icelandic",
    it: "Italian",
    "it-ch": "Italian (Switzerland)",
    iw: "Hebrew",
    ja: "Japanese",
    ji: "Yiddish",
    jw: "Javanese",
    ka: "Georgian",
    kk: "Kazakh",
    kl: "Greenlandic",
    km: "Cambodian",
    kn: "Kannada",
    ko: "Korean",
    kok: "Konkani",
    ks: "Kashmiri",
    ku: "Kurdish",
    ky: "Kirghiz",
    kz: "Kyrgyz",
    la: "Latin",
    ln: "Lingala",
    lo: "Laothian",
    ls: "Slovenian",
    lt: "Lithuanian",
    lv: "Latvian",
    mg: "Malagasy",
    mi: "Maori",
    mk: "FYRO Macedonian",
    ml: "Malayalam",
    mn: "Mongolian",
    mo: "Moldavian",
    mr: "Marathi",
    ms: "Malay",
    mt: "Maltese",
    my: "Burmese",
    na: "Nauru",
    "nb-no": "Norwegian (Bokmal)",
    ne: "Nepali (India)",
    nl: "Dutch",
    "nl-be": "Dutch (Belgium)",
    "nn-no": "Norwegian",
    no: "Norwegian (Bokmal)",
    oc: "Occitan",
    om: "(Afan)/Oromoor/Oriya",
    or: "Oriya",
    pa: "Punjabi",
    pl: "Polish",
    ps: "Pashto/Pushto",
    pt: "Portuguese",
    "pt-br": "Portuguese (Brazil)",
    qu: "Quechua",
    rm: "Rhaeto-Romanic",
    rn: "Kirundi",
    ro: "Romanian",
    "ro-md": "Romanian (Moldova)",
    ru: "Russian",
    "ru-md": "Russian (Moldova)",
    rw: "Kinyarwanda",
    sa: "Sanskrit",
    sb: "Sorbian",
    sd: "Sindhi",
    sg: "Sangro",
    sh: "Serbo-Croatian",
    si: "Singhalese",
    sk: "Slovak",
    sl: "Slovenian",
    sm: "Samoan",
    sn: "Shona",
    so: "Somali",
    sq: "Albanian",
    sr: "Serbian",
    ss: "Siswati",
    st: "Sesotho",
    su: "Sundanese",
    sv: "Swedish",
    "sv-fi": "Swedish (Finland)",
    sw: "Swahili",
    sx: "Sutu",
    syr: "Syriac",
    ta: "Tamil",
    te: "Telugu",
    tg: "Tajik",
    th: "Thai",
    ti: "Tigrinya",
    tk: "Turkmen",
    tl: "Tagalog",
    tn: "Tswana",
    to: "Tonga",
    tr: "Turkish",
    ts: "Tsonga",
    tt: "Tatar",
    tw: "Twi",
    uk: "Ukrainian",
    ur: "Urdu",
    us: "English",
    uz: "Uzbek",
    vi: "Vietnamese",
    vo: "Volapuk",
    wo: "Wolof",
    xh: "Xhosa",
    yi: "Yiddish",
    yo: "Yoruba",
    zh: "Chinese",
    "zh-cn": "Chinese (China)",
    "zh-hk": "Chinese (Hong Kong SAR)",
    "zh-mo": "Chinese (Macau SAR)",
    "zh-sg": "Chinese (Singapore)",
    "zh-tw": "Chinese (Taiwan)",
    zu: "Zulu",
} as const;

export type Iso6391 = typeof ISO_639_1;

export type UuidIso6391 = keyof Iso6391;

export type ValueIso6391 = Iso6391[UuidIso6391];
