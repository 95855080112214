import { PathCreate } from "@platform/react-storybook/Component/Router/utils";
import { paramProfile, PARAM_VALUE_PROFILE } from "../../params/profile";

export function PathGetProfile<R extends string>(ROOT: R) {
    return {
        ROOT,

        get PROFILE() {
            const ROOT_PROFILE = PathCreate(ROOT, "profile");
            const PARAM = PathCreate(ROOT_PROFILE, paramProfile);
            return {
                ROOT: ROOT_PROFILE,
                PARAM,
                SETTINGS_DELETE: PathCreate(ROOT_PROFILE, PARAM_VALUE_PROFILE.SETTINGS, "delete"),
            };
        },
    };
}
