/* eslint-disable max-classes-per-file */
/// <reference types="@havesource/cordova-plugin-push" />

import React from "react";

import type { MobileInfoResponse } from ".";
import { MobileInfo } from ".";

export enum CODE_MOBILE_PUSH {
    NOT_MOBILE,
    NOT_FOUND,
    UNKNOWN,
    DENIED,
    OK,
}

export const RESPONSE_MOBILE_PUSH = {
    [CODE_MOBILE_PUSH.NOT_MOBILE]: class ErrorNotMobileMobilePush extends Error {},
    [CODE_MOBILE_PUSH.NOT_FOUND]: class ErrorNotFoundMobilePush extends Error {},
    [CODE_MOBILE_PUSH.UNKNOWN]: class ErrorUnknownMobilePush extends Error {},
    [CODE_MOBILE_PUSH.DENIED]: class ErrorDeniedMobilePush extends Error {},
    [CODE_MOBILE_PUSH.OK]: true,
} as const;

export type ResponseMobilePush = typeof RESPONSE_MOBILE_PUSH[CODE_MOBILE_PUSH.OK] | Error;

export type DataMobilePush = MobileInfoResponse & { tokenPush: string };

export type EventRegisterMobilePush = (
    error: Omit<ResponseMobilePush, CODE_MOBILE_PUSH.OK> | null,
    data: DataMobilePush | null
) => void;

export type EventTapMobilePush<Payload> = (payload: Payload) => void;

type ArgMobilePush<Payload> = {
    onRegister: EventRegisterMobilePush;
    onNotification?: EventTapMobilePush<Payload>;
};
export async function MobilePush<Payload>(
    arg: ArgMobilePush<Payload>
): Promise<ResponseMobilePush> {
    const info = await MobileInfo();
    if (!info) return new RESPONSE_MOBILE_PUSH[CODE_MOBILE_PUSH.NOT_MOBILE]();
    if (!window.PushNotification) return new RESPONSE_MOBILE_PUSH[CODE_MOBILE_PUSH.NOT_FOUND]();

    const { onRegister, onNotification } = arg;

    const plugin = window.PushNotification.init({
        android: {},
        ios: { alert: "true", badge: "true", sound: "true" },
    });

    plugin.on("error", (e) => {
        const error = new RESPONSE_MOBILE_PUSH[CODE_MOBILE_PUSH.UNKNOWN]();
        error.message = e.message;
        onRegister(error, null);
    });

    plugin.on("registration", ({ registrationId: tokenPush }) => {
        onRegister(null, { ...info, tokenPush });
    });

    if (onNotification) {
        plugin.on("notification", (payload) => {
            // TODO: Do test to better infer payload on both Android and iOS
            onNotification(payload as unknown as Payload);
        });
    }

    return RESPONSE_MOBILE_PUSH[CODE_MOBILE_PUSH.OK];
}

export function useMobilePush<Payload>(arg: ArgMobilePush<Payload>) {
    const { onRegister, onNotification: onTap } = arg;

    const refRegisteredPush = React.useRef(false);

    React.useEffect(() => {
        if (refRegisteredPush.current) return;
        refRegisteredPush.current = true;
        (async () => {
            const resp = await MobilePush({ onRegister, onNotification: onTap });
            if (resp instanceof Error) onRegister(resp, null);
        })();
    }, [onRegister, onTap]);
}
