export type OpenpayElementRequest = {
    type: string;
    id_element?: string;
    id_user?: string;
    status?: string;
};

export type OpenpayElement = {
    // id
    id: string;
    // type of element plan, customer,
    type: string;
    // id element
    id_element: string;
    id_user: string;
    description?: string;
    status: string;
};

export type OpenpayAddress = {
    line1: string;
    line2: string;
    line3: string;
    postal_code: string;
    state: string;
    city: string;
    country_code: string;
};

//https://documents.openpay.mx/docs/api/#crear-un-nuevo-cliente
export type OpenpayRecordBodyCustomer = {
    external_id?: string;
    name: string;
    last_name?: string;
    email: string;
    //Enviar con valor false si requiere que el cliente se cree sin cuenta para manejo del saldo.
    requires_account?: boolean;
    phone_number?: string;
    address?: OpenpayAddress;
};

//https://documents.openpay.mx/docs/api/#crear-un-nuevo-cliente
//REQUEST del WRAPPER
export type OpenpayRecordBodyCard = {
    id_customer: string;
    holder_name: string;
    card_number: number;
    cvv2: number;
    expiration_month: number;
    //Enviar con valor false si requiere que el cliente se cree sin cuenta para manejo del saldo.
    expiration_year: string;
};

export type OpenpayRecordBodySubscription = {
    id_customer: string;
    id_plan: string;
    id_card: string;
};

export type OpenpayResponse = {
    id: string;
    http_code: number;
    error_code: number;
    description: string;
};

export type ResponseOpenPay<T> = {
    status: string;
    data: T;
    time: string;
};

export enum StatusPayment {
    Active = "active",
    Disabled = "disabled",
}

export const isActivePayment = (status: StatusPayment) => status === StatusPayment.Active;
