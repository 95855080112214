import { CreateActions } from "@platform/react/src/store";
import { NAME_SLICE_DELETE_ACCOUNT } from "./_namespace";
import type { NSSliceDeleteAccount } from "./_namespace";

export const ActionSliceDeleteAccount = CreateActions<{
    /** Sets a give property errors */
    set: NSSliceDeleteAccount.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSliceDeleteAccount.ArgClear | null;
}>(NAME_SLICE_DELETE_ACCOUNT, ["set", "clear"]);
