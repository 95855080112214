import React from "@platform/react";

import type { PropTypes } from ".";

export const NAME_ERRORCATCH = "ErrorCatch";

export type CatchEvent = {
    error: Error;
    info: React.ErrorInfo;
};

export type StateErrorCatch = {
    error?: Error;
};

export type PropErrorCatch = {
    children: React.ReactNode;
    onCatch?: (ev: CatchEvent) => void;
    render?: JSX.Element;
};

export const DEF_ERRORCATCH = {};

export class ErrorCatch extends React.Component<
    PropTypes<PropErrorCatch, typeof DEF_ERRORCATCH>,
    StateErrorCatch
> {
    public static displayName = NAME_ERRORCATCH;
    public static defaultProps = DEF_ERRORCATCH;

    public static getDerivedStateFromError(error: Error) {
        return { error };
    }

    public state = { error: undefined };

    public componentDidCatch(error: Error, info: React.ErrorInfo) {
        const { onCatch } = this.props;
        if (onCatch) onCatch({ error, info });
    }

    public render() {
        const { render, children } = this.props;
        const { error } = this.state;
        // if no custom render is sent, always render children, regardless of error.
        if (!render) return children;

        // render custom content only when an error found.
        return error ? render : children;
    }
}
