import React from "@platform/react";
import { SVGInjector } from "@tanem/svg-injector";

import type {
    GetterImagePlatform,
    PropConsumerImagePlatform,
    ArgCommonImagePlatform,
} from "./types";
import {
    EXTS_IMAGEPLATFORM,
    ERROR_PROVIDER_IMAGE,
    NAME_CONSUMER_IMAGEPLATFORM,
    TYPE_CONSUMER_IMAGEPLATFORM,
} from "./types";

import type { PropDefaults, PropTypes } from "../../Component";
import { Component, useCombinedRefs } from "../../Component";

const INDEX_LAST = -1;

export function CreateConsumerImagePlatform<G extends GetterImagePlatform>(
    arg: ArgCommonImagePlatform<G>,
) {
    const { Context } = arg;

    const DEF_CONSUMER_IMAGEPLATFORM: PropDefaults<
        Omit<
            PropConsumerImagePlatform<G>,
            "onInject" | "evalScripts" | "caption" | "renumerateIRIElements" | "tag"
        >
    > = {};

    /** This is the documentation for the component and should be available on Storybook */
    const ConsumerImage = React.forwardRef(RefHandler);
    ConsumerImage.displayName = NAME_CONSUMER_IMAGEPLATFORM;
    ConsumerImage.defaultProps = DEF_CONSUMER_IMAGEPLATFORM;
    return ConsumerImage;

    function RefHandler(
        props: PropTypes<PropConsumerImagePlatform<G>, typeof DEF_CONSUMER_IMAGEPLATFORM>,
        refForwarded: React.ForwardedRef<HTMLDivElement>,
    ) {
        const {
            tag = "figure",
            uuid,
            caption,
            onInject,
            evalScripts,
            renumerateIRIElements,
            ...rest
        } = props;

        // NOTE: since this component makes async calls, there's a chance that those are resolved
        //       when the component is no longer mounted, this reference will keep track of the
        //       mounted state, and let the component update its state only when available.
        const refMounted = React.useRef(true);
        const refElement = React.useRef<HTMLDivElement>(null);
        const refCombined = useCombinedRefs({ refForwarded, refElement });
        const context = React.useContext(Context);
        const [loading, setLoading] = React.useState<boolean | null>(true);

        React.useEffect(() => {
            if (!refElement.current || !context) return undefined;
            const { current: figure } = refElement;
            const src = context.imageData[uuid];
            // if an invalid source is sent, show the broken image svg
            if (!src) {
                setLoading(null);
                return unmount;
            }
            // determine the extension
            const ext = src.split(".").slice(INDEX_LAST).shift();
            if (!ext) throw new Error(ERROR_PROVIDER_IMAGE.EXTENSION_INVALID);
            // non svgs, just get a regular image element dynamically loaded.
            if (ext !== EXTS_IMAGEPLATFORM.SVG) {
                const element = new window.Image();
                // save the current display mode.
                const { display } = element.style;
                element.src = src;
                element.onload = () => {
                    // restore original display once loaded.
                    element.style.display = display;
                    if (refMounted.current) setLoading(false);
                };
                // hide the image while loading.
                element.style.display = "none";
                figure.prepend(element);
                return unmount;
            }
            // dealing with an svg
            const element = document.createElement("svg");
            figure.prepend(element);
            element.dataset.src = src;
            SVGInjector(element, {
                evalScripts,
                renumerateIRIElements,
                afterEach(err, el) {
                    // if an error found, show the 404 link and give it a message
                    if (err) {
                        figure.dataset.error = err.message;
                        if (refMounted.current) setLoading(null);
                        return;
                    }
                    if (onInject) onInject(el);
                    figure.dataset.injected = "true";
                    if (refMounted.current) setLoading(false);
                },
            });
            return unmount;

            // run cleanup once unmounted
            function unmount() {
                refMounted.current = false;
                // create an array of all possible generated elements
                // (normally a nodelist is returned instead of an array)
                // and remove them from the component.
                [
                    ...[].slice.call(figure.getElementsByTagName("svg")),
                    ...[].slice.call(figure.getElementsByTagName("img")),
                ].forEach((el) => figure.removeChild(el));
            }
        }, [context, evalScripts, onInject, renumerateIRIElements, uuid]);

        if (!context) return null;

        return (
            <Component
                {...rest}
                ref={refCombined}
                name={NAME_CONSUMER_IMAGEPLATFORM}
                tag={tag}
                type={TYPE_CONSUMER_IMAGEPLATFORM}>
                {(() => {
                    if (loading && context.imageLoader) return context.imageLoader;
                    if (loading === null) return <Image404 />;
                    if (caption)
                        return React.isValidElement(caption) ? (
                            caption
                        ) : (
                            <figcaption>{caption}</figcaption>
                        );
                    return null;
                })()}
            </Component>
        );
    }
}

export function Image404() {
    return (
        <svg height="20" viewBox="0 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg">
            <polygon
                fill="var(--ci-primary-color, currentColor)"
                points="40 472 472 472 472 312 440 312 440 440 72 440 72 312 40 312 40 472"
            />
            <path
                d="M40,265.755l49.373,25.437,53.82-46.829,56.159,50.528L256,247.052l56.648,47.839,56.159-50.528,53.82,46.829L472,265.755V40H40ZM72,72H440V246.244l-12.738,6.564-58.809-51.171-56.471,50.806L256,205.167l-55.982,47.276-56.471-50.806L84.738,252.808,72,246.244Z"
                fill="var(--ci-primary-color, currentColor)"
            />
        </svg>
    );
}
