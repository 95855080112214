import { PREFIX_COLOR_THEME } from "@common/content/theme/color";

export const PREFIX_OPACITY_COLOR_THEME = `${PREFIX_COLOR_THEME}_opacity` as const;

export const OPACITY_COLOR_THEME = {
    default: "1.0",
    earth: "0.8",
    mars: "0.6",
    jupiter: "0.4",
    venus: "0.2",
    none: "0",
} as const;

export type OpacityColorTheme = typeof OPACITY_COLOR_THEME;

export type UuidOpacityColorTheme = keyof OpacityColorTheme;

export type ValueOpacityColorTheme = OpacityColorTheme[UuidOpacityColorTheme];

export const UUID_OPACITY_COLOR_THEME = Object.keys(
    OPACITY_COLOR_THEME,
) as readonly UuidOpacityColorTheme[];
