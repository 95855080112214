import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_NETWORK } from "./_namespace";
import type { NSSliceNetwork } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSliceNetwork(store: StoreState): NSSliceNetwork.State {
    return store[NAME_SLICE_NETWORK];
}

SelectorSliceNetwork.status = CreateSelector(SelectorSliceNetwork, (state) => state.status);
