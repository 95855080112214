import React from "@platform/react";
import { CreateImagePlatform } from "@platform/react-storybook/Bundle/Image";
import { GROUP_BITMAP_ASSET, GetterBitmapAsset } from "@common/content/asset/bitmap";
import type {
    PropConsumerImagePlatform,
    PropProviderImagePlatform,
} from "@platform/react-storybook/Bundle/Image/types";
import type { PropDefaults, PropTypes } from "@platform/react-storybook/Component";

import type { UuidBitmapAsset } from "@common/content/asset/bitmap";
import { GetterEnv } from "@common/content/env";

import Namespace from "./_index.scss";
import { GetterAsset } from "..";

export async function getterBitmapAsset() {
    // TODO: MC-475: Avoid calling MS every time a props is changed, and as type the response Promise
    const assets = await GetterAsset();
    const bitmaps = assets.reduce((acc, { type, url, uuid }) => {
        if (type !== GROUP_BITMAP_ASSET) return acc;
        return { ...acc, [uuid]: url };
    }, {} as { [K in UuidBitmapAsset]: string });
    return Promise.resolve(bitmaps);
}

export type GetterBitmapAsset = typeof getterBitmapAsset;

export const { NAME_BITMAP_ASSET, TYPE_BITMAP_ASSET } = Namespace;

export const { ProviderImagePlatform, ImagePlatform, useImagePlatform } = CreateImagePlatform({
    getter: getterBitmapAsset,
});

export type PropProviderBitmap = PropProviderImagePlatform;
export const ProviderBitmapAsset = ProviderImagePlatform;

export function useBitmapAsset() {
    return useImagePlatform();
}

export type PropBitmapAsset = Omit<
    PropConsumerImagePlatform<GetterBitmapAsset>,
    "onInject" | "evalScripts" | "renumerateIRIElements" | "tag"
>;

export const DEF_BITMAP_ASSET: PropDefaults<Omit<PropBitmapAsset, "caption">> = {};

const { REACT_APP_URL_ASSETS } = GetterEnv(process.env);

export const BITMAP_ASSET = GetterBitmapAsset(REACT_APP_URL_ASSETS as string);

export function BitmapAsset(props: PropTypes<PropBitmapAsset, typeof DEF_BITMAP_ASSET>) {
    const { ...rest } = props;
    return <ImagePlatform {...rest} data-name={NAME_BITMAP_ASSET} data-type={TYPE_BITMAP_ASSET} />;
}
BitmapAsset.defaultProps = DEF_BITMAP_ASSET;
