import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_DELETE_ACCOUNT } from "./_namespace";
import type { NSSliceDeleteAccount } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSliceDeleteAccount(store: StoreState): NSSliceDeleteAccount.State {
    return store[NAME_SLICE_DELETE_ACCOUNT];
}

SelectorSliceDeleteAccount.explanation = CreateSelector(
    SelectorSliceDeleteAccount,
    (state) => state.explanation,
);
SelectorSliceDeleteAccount.reason = CreateSelector(
    SelectorSliceDeleteAccount,
    (state) => state.reason,
);
