import React from "@platform/react";
import { Component, getClassName } from "@platform/react-storybook/Component";

import { useVectorAsset } from "@front/components/Element/Asset/Vector";

import { NAME_LOADING_ANIMATION, TYPE_LOADING_ANIMATION, DEF_LOADING_ANIMATION } from "./types";
import type { PropTypesLoadingAnimation } from "./types";

import Rules from "./_rules.module.scss";

export function LoadingAnimation(props: PropTypesLoadingAnimation) {
    const { children, showOnFullScreen, hasBackground, className: _className, ...rest } = props;

    const {
        imageData: { "logo-mc-icon": iconLogo },
    } = useVectorAsset();

    const className = getClassName(
        showOnFullScreen && Rules.isFullScreen,
        hasBackground && Rules.hasBackground,
        _className,
    );

    return (
        <Component
            {...rest}
            className={className}
            name={NAME_LOADING_ANIMATION}
            tag="div"
            type={TYPE_LOADING_ANIMATION}>
            <figure className={Rules.videoLoading}>
                <div
                    className={Rules.videoIconLoading}
                    style={{ "--bg": `url(${iconLogo})` } as React.CSSProperties}
                />
                {children}
            </figure>
        </Component>
    );
}

LoadingAnimation.defaultProps = DEF_LOADING_ANIMATION;
