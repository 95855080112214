import type { UnPromise } from "@platform/types";
import type React from "@platform/react";

import Namespace from "./_index.scss";

export type ValThemePlatform = { [varname: string]: string };
//                                 ↑VT  ↑KV           ↑VV
export type DataThemePlatform = { [theme: string]: ValThemePlatform };
//                             ↑D  ↑KT          ↑VT

export type GetterThemePlatform<D = DataThemePlatform> = () => Promise<D>;

/**
 * NOTE: these are shortcuts referencing the shape of the retrieved data.
 *       @see DataThemePlatform for reference.
 *       - D  = DataTheme
 *       - KT = KeyTheme
 *       - VT = ValTheme
 *       - KV = KeyPropTheme
 *       - VV = ValPropTheme
 */
export type DataTheme<G extends GetterThemePlatform> = UnPromise<ReturnType<G>>;
export type KeyTheme<G extends GetterThemePlatform> = keyof DataTheme<G>;
export type ValTheme<G extends GetterThemePlatform> = DataTheme<G>[KeyTheme<G>];
export type KeyPropTheme<G extends GetterThemePlatform> = keyof ValTheme<G>;
export type ValPropTheme<G extends GetterThemePlatform> = ValTheme<G>[KeyPropTheme<G>];

export type ArgThemePlatform<G extends GetterThemePlatform> = {
    /** A method that resolves data uses as dictionary */
    getter: G;
};

export type StateThemePlatform<G extends GetterThemePlatform> =
    | undefined
    | {
          /** A dictionary of data to work with */
          themeData: DataTheme<G>;
          /** the currently enabled theme */
          themeCurrent: KeyTheme<G>;
          /** Themes available */
          themeAvailable: KeyTheme<G>[];
          /** Resets provider back to initial state */
          themeReset: () => void;
          /** Returns a value from the data dictionary */
          themeGetValue: (varname: KeyPropTheme<G>) => undefined | ValPropTheme<G>;
          /** Sets the current theme */
          themeSet: (theme: KeyTheme<G>) => void;
      };

export type ArgCommonThemePlatform<G extends GetterThemePlatform> = ArgThemePlatform<G> & {
    Context: React.Context<StateThemePlatform<G>>;
};

export type ArgProviderThemePlatform<G extends GetterThemePlatform> = ArgCommonThemePlatform<G>;

export type PropProviderThemePlatform<G extends GetterThemePlatform> = {
    /** Components where the Consumer will reside */
    children: Exclude<React.ReactChild, React.ReactText>;
    /** The theme that will be enabled by default */
    theme: KeyTheme<G>;
    /** Determines if the vars should be injected to document when theme is available */
    shouldInjectOnLoad?: boolean;
    /** What to show while the getter is being loaded */
    loading?: null | JSX.Element;
    /** By default errors will be silent, unless you send a method here where you could catch them */
    onError?: (err: Error) => void;
};

export type ArgConsumerThemePlatform<G extends GetterThemePlatform> = ArgCommonThemePlatform<G>;

export type PropConsumerThemePlatform<G extends GetterThemePlatform> = {
    /** A single element that will be injected with the theme variables*/
    children: PropProviderThemePlatform<G>["children"];
    /** Determines the variables that will be injected to child element */
    vars: "*" | KeyPropTheme<G> | KeyPropTheme<G>[] | ValThemePlatform;
};

export const {
    NAME_THEMEPLATFORM,
    TYPE_THEMEPLATFORM,
    NAME_CONSUMER_THEMEPLATFORM,
    TYPE_CONSUMER_THEMEPLATFORM,
    NAME_PROVIDER_THEMEPLATFORM,
    TYPE_PROVIDER_THEMEPLATFORM,
} = Namespace;

export const ERROR_THEMEPLATFORM = {
    NO_PROVIDER: `${NAME_THEMEPLATFORM}: Expecting a <${NAME_PROVIDER_THEMEPLATFORM} /> to be available.`,
    INVALID_RESPONSE: `${NAME_THEMEPLATFORM}: Invalid response from getter.`,
} as const;
