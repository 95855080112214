import React from "@platform/react";

import { CreateProviderTrackerPlatform } from "./_Provider";
import { CreateConsumerTrackerPlatform } from "./_Consumer";
import { ERROR_TRACKERPLATFORM } from "./types";
import type { StateTrackerPlatform } from "./types";

export function CreateTrackerPlatform() {
    const Context = React.createContext<StateTrackerPlatform>(undefined);
    const ProviderTrackerPlatform = CreateProviderTrackerPlatform({ Context });
    const TrackerPlatform = CreateConsumerTrackerPlatform();

    return {
        ContextTrackerPlatform: Context,
        ProviderTrackerPlatform,
        TrackerPlatform,
        useTrackerPlatform,
    };

    function useTrackerPlatform() {
        const context = React.useContext(Context);
        if (!context) throw new Error(ERROR_TRACKERPLATFORM.NO_PROVIDER);
        return context;
    }
}
