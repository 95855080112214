import React from "@platform/react";
import { CreateImagePlatform } from "@platform/react-storybook/Bundle/Image";
import { GROUP_VECTOR_ASSET } from "@common/content/asset/vector";
import { getVar, Theme } from "@front/components/Theme";
import type { UuidVectorAsset } from "@common/content/asset/vector";
import type { UuidColorTheme } from "@common/content/theme/color";
import type {
    PropConsumerImagePlatform,
    PropProviderImagePlatform,
} from "@platform/react-storybook/Bundle/Image/types";

import type { PropDefaults, PropTypes } from "@platform/react-storybook/Component";
import { GetterAsset } from "..";
import Namespace from "./_index.scss";
import "./_rules.scss";

export async function getterVectorAsset() {
    const assets = await GetterAsset();
    const bitmaps = assets.reduce((acc, { type, url, uuid }) => {
        if (type !== GROUP_VECTOR_ASSET) return acc;
        return { ...acc, [uuid]: url };
    }, {} as { [K in UuidVectorAsset]: string });
    return Promise.resolve(bitmaps);
}

export type GetterVectorAsset = typeof getterVectorAsset;

export const { NAME_VECTOR_ASSET, TYPE_VECTOR_ASSET } = Namespace;

export const { ProviderImagePlatform, ImagePlatform, useImagePlatform } = CreateImagePlatform({
    getter: getterVectorAsset,
});

export type PropProviderVector = PropProviderImagePlatform;
export const ProviderVectorAsset = ProviderImagePlatform;

export function useVectorAsset() {
    return useImagePlatform();
}

type PropPlatform = Omit<
    PropConsumerImagePlatform<GetterVectorAsset>,
    "onInject" | "evalScripts" | "renumerateIRIElements" | "tag"
>;

export type PropVectorAsset = PropPlatform & {
    fill?: UuidColorTheme;
    stroke?: UuidColorTheme;
};

export type UuidVector = PropVectorAsset["uuid"];
export const DEF_VECTOR_ASSET: PropDefaults<Omit<PropVectorAsset, "caption" | "fill" | "stroke">> =
    {};

export function VectorAsset(props: PropTypes<PropVectorAsset, typeof DEF_VECTOR_ASSET>) {
    const { fill, stroke, ...rest } = props;
    const theme: Record<string, string> = {};
    if (fill) theme.fill = getVar(`color-${fill}`);
    if (stroke) theme.stroke = getVar(`color-${stroke}`);
    return (
        <Theme vars={theme}>
            <ImagePlatform {...rest} data-name={NAME_VECTOR_ASSET} data-type={TYPE_VECTOR_ASSET} />
        </Theme>
    );
}
VectorAsset.defaultProps = DEF_VECTOR_ASSET;
