import { isRecord } from "@platform/types/record";
import { isStatus } from "@common/content/data";
import { isUuidLanguage } from "@common/content/language";
import { UUID_TEXT } from "@common/content/text/_data";
import type { UuidDataText } from "@common/content/text/_data";
import type { StatusKey } from "@common/content/data";
import type { RecordLanguage } from "@common/content/language";

export { DATA_TEXT } from "@common/content/text/_data";

export type { DataText, UuidDataText } from "@common/content/text/_data";

export const NAME_TEXT = "text" as const;

export type NameText = typeof NAME_TEXT;

export const ROUTE_TEXT = {
    TEXT_ALL: `/${NAME_TEXT}`,
    TEXT_ONE: `/${NAME_TEXT}`,
} as const;

export type RouteText = typeof ROUTE_TEXT;

export type RecordText = {
    language: RecordLanguage["uuid"];
    uuid: string;
    text: string;
    status: StatusKey;
    // dateCreate: string;
    // dateUpdate: string;
};

export function isText(entry: unknown): entry is UuidDataText {
    if (typeof entry !== "string") return false;
    return UUID_TEXT.includes(entry as UuidDataText);
}

export function isRecordText(entry: unknown): entry is Partial<RecordText> {
    if (!isRecord(entry)) return false;
    const MIN_PROPS = 1;
    const keysValid = ["language", "uuid", "status", "text"];
    const keysEntry = Object.keys(entry as Record<string, unknown>);
    if (keysEntry.length < MIN_PROPS) return false;
    if (keysEntry.filter((key) => !keysValid.includes(key)).length) return false;
    const text = entry as RecordText;
    if (text.language && !isUuidLanguage(text.language)) return false;
    if (text.status && !isStatus(text.status)) return false;
    return true;
}
