import type { UuidVideoAsset } from "@common/content/asset/video";
import type {
    DayType,
    Program,
    WeekType,
    PropRoutineTask,
    WeekCalendarItem,
    PropRoutine,
    Timer,
} from "@common/content/crack/programs";
import type { UuidDataText } from "@common/content/text/_data";

export const NAME_SLICE_PROGRAMS = "programs";
export declare namespace NSSlicePrograms {
    export type timer = {
        seconds: number;
        minutes: number;
        hours: number;
        days: number;
    };

    export type exerciseDetails = {
        title: UuidDataText;
        subtitle: UuidDataText;
        description: UuidDataText;
    };
    export type NextNameVideo = {
        amount?: string;
        title: UuidDataText;
    };

    export type State = {
        id: string;
        isLeaveProgram?: boolean;
        openBottomSheet?: boolean;
        activeVideo?: UuidVideoAsset;
        currentCrackProgram?: Program;
        training?: Program["calendar-training"];
        tasks: PropRoutineTask[];
        activeTask?: PropRoutineTask;
        isLastTask?: boolean;
        isLastWorkout?: boolean;
        workoutType?: PropRoutine["workoutType"];
        nameNextTask?: NextNameVideo;
        isShowMeHow?: boolean;
        hasFinished: boolean;
        isFirstTime: boolean;
        lastCycle: boolean;
        day: DayType;
        week: WeekType;
        timer: timer;
        calendar: WeekCalendarItem[];
        "uuid-workout": string;
        cycleForWorkout: { [key: string]: number };
        progressTask: {
            [key: string]: Partial<{ cycleForWorkout: number; [key: string]: number }>;
        };
        startTime: timer;
        partialTime: timer[];
        endTime: timer;
        timerForWorkout: { [key: string]: Timer };
        exerciseDetails: exerciseDetails;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_PROGRAMS: NSSlicePrograms.State = {
    id: "",
    hasFinished: false,
    activeVideo: undefined,
    currentCrackProgram: undefined,
    isFirstTime: true,
    day: "1",
    week: "1",
    "uuid-workout": "10",
    isLastTask: false,
    isLastWorkout: false,
    nameNextTask: undefined,
    lastCycle: false,
    tasks: [],
    cycleForWorkout: {},
    timerForWorkout: {},
    calendar: [],
    training: undefined,
    progressTask: {},
    timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    },
    endTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    },
    partialTime: [],
    startTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    },
    exerciseDetails: {
        title: "",
        subtitle: "",
        description: "",
    },
};
