export const PrivacyES = `
<h1 class="terms-title">AVISO DE PRIVACIDAD</h1>
<p class="terms-paragraph">
    AVISO DE PRIVACIDAD INTEGRAL PARA LA PLATAFORMA ONLINE: “MASTER CRACKS” (en lo sucesivo, el
    “Aviso de Privacidad”).
</p>
<p class="terms-paragraph">
    PLATAFORMA SOCCER CRACKS, S. DE R.L. DE C.V. (en lo sucesivo, la “Empresa”), señalando para
    efectos del presente Aviso de Privacidad, el domicilio ubicado en Av. Juan Salvador Agraz No.
    97, Piso 10, Interior 1004, Colonia Santa Fe, Alcaldía Cuajimalpa de Morelos, Ciudad de México,
    C.P. 05300, es el responsable del tratamiento de los datos personales que nos proporcione a
    través de las Plataformas (según dicho término se define más adelante), los cuales serán
    protegidos conforme a lo dispuesto por la Ley Federal de Protección de Datos Personales en
    Posesión de los Particulares, su Reglamento y demás normatividad que resulte aplicable.
</p>
<p class="terms-paragraph">
    El presente Aviso de Privacidad tiene por objeto delimitar las bases en las que se procesarán
    los datos personales que recolectemos o nos proporcione. La Empresa no se hace responsable por
    los avisos de privacidad de otras páginas o aplicaciones móviles, y le incentivamos a leer todas
    las políticas y avisos de privacidad aplicables al utilizar otras páginas de internet y
    aplicaciones móviles.
</p>
<ol>
    <li class="terms-paragraph">Datos personales que utilizaremos.</li>
</ol>
<p class="terms-paragraph">
    Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, podremos
    recabar en nuestra página de internet
    <a href="/">www.mastercracks.club</a>
     y en nuestra aplicación móvil denominada Master Cracks (en lo sucesivo y conjuntamente, las
    “Plataformas”) de usted los siguientes datos e información:
</p>
<ol>
    <li class="terms-paragraph">Nombre(s) y Apellido(s);</li>
    <li class="terms-paragraph">Correo electrónico;</li>
    <li class="terms-paragraph">Número de celular;</li>
    <li class="terms-paragraph">Género;</li>
    <li class="terms-paragraph">Fecha de Nacimiento;</li>
    <li class="terms-paragraph">Nacionalidad;</li>
    <li class="terms-paragraph">
        Dirección (Código Postal, Asentamiento/Alcaldía/Colonia, Delegación/Municipio y Estado);
    </li>
    <li class="terms-paragraph">Información estadística;</li>
    <li class="terms-paragraph">Información respecto del uso de nuestras Plataformas:</li>
</ol>
<ol>
    <li class="terms-paragraph">Información que proporcione al llenar cuestionarios;</li>
    <li class="terms-paragraph">
        Información que proporcione al participar en concursos, promociones y/o encuestas; e;
    </li>
    <li class="terms-paragraph">
        Información que proporcione al sostener comunicaciones con la Empresa y/o su personal; e
    </li>
</ol>
<ol>
    <li class="terms-paragraph">
        Información que recolectemos de usted. Nosotros podremos captar automáticamente de usted la
        siguiente información:
    </li>
</ol>
<ol>
    <li class="terms-paragraph">
        Información técnica, incluyendo su dirección IP (internet protocol), su historial de
        búsquedas, su información de usuario, su información móvil y de su red, sistema operativo,
        plataforma de uso e información respecto de su ubicación geográfica.
    </li>
    <li class="terms-paragraph">
        Información sobre sus visitas y accesos a nuestras Plataformas, incluyendo su URL (Unifrom
        Resource Locators), los productos digitales a los que tuvo acceso, tiempo de respuesta de
        las páginas, descargas, errores operativos, duración de visita a cada una de las páginas,
        información de interacción en páginas y métodos para salir de las Plataformas en cuestión.
    </li>
</ol>
<ol>
    <li class="terms-paragraph">Datos personales sensibles.</li>
</ol>
<p class="terms-paragraph">
    Se informa que, para las finalidades previstas en el presente Aviso de Privacidad, no se
    recabarán de usted datos personales sensibles.
</p>
<ol>
    <li class="terms-paragraph">Fines para los que utilizaremos sus datos personales.</li>
</ol>
<p class="terms-paragraph">
    Los datos personales que recabemos de usted, los utilizaremos, enunciativa más no
    limitativamente, para las siguientes finalidades:
</p>
<ol>
    <li class="terms-paragraph">Finalidades primarias:</li>
</ol>
<ol>
    <li class="terms-paragraph">Para su inscripción y, por ende, uso de nuestras Plataformas.</li>
    <li class="terms-paragraph">
        Para operar efectivamente y proveerle de la mejor experiencia posible.
    </li>
    <li class="terms-paragraph">
        Para verificar y comprobar su identidad al momento de acceder a su cuenta registrada.
    </li>
    <li class="terms-paragraph">
        Para integrar su expediente electrónico como usuario de nuestras Plataformas.
    </li>
    <li class="terms-paragraph">
        Dar respuesta y seguimiento a dudas, sugerencias y quejas que sean realizadas por usted
        referentes a los servicios que se ofrecen a través de las Plataformas.
    </li>
    <li class="terms-paragraph">
        Para brindar los servicios que se ofrecen a través de las Plataformas.
    </li>
</ol>
<ol>
    <li class="terms-paragraph">Finalidades secundarias:</li>
</ol>
<ol>
    <li class="terms-paragraph">
        Para realizar estadísticas respecto del uso de las Plataformas por parte de los usuarios.
    </li>
    <li class="terms-paragraph">Para ofrecer servicios específicos al usuario.</li>
    <li class="terms-paragraph">
        Para hacerle llegar avisos y/o notificaciones de cualquier tipo respecto de ofertas, cambios
        en los términos y condiciones de uso y/o al presente Aviso de Privacidad.
    </li>
    <li class="terms-paragraph">Para mejorar nuestros servicios.</li>
    <li class="terms-paragraph">Para comunicarnos con usted.</li>
    <li class="terms-paragraph">Para entender sus preferencias.</li>
    <li class="terms-paragraph">Para monitorear el uso y actividad en nuestras Plataformas.</li>
    <li class="terms-paragraph">
        Para cumplir con las disposiciones legales y reglamentarias aplicables.
    </li>
</ol>
<ol>
    <li class="terms-paragraph">Comunicaciones.</li>
</ol>
<p class="terms-paragraph">
    Favor de dirigir toda comunicación que desee hacer en relación con el presente Aviso de
    Privacidad al correo electrónico siguiente:
    <a href="mailto:{{EMAIL}}"> {{EMAIL}} </a>
     Sus solicitudes serán respondidas por nuestro personal en días y horas hábiles.
</p>
<ol>
    <li class="terms-paragraph">Aceptación.</li>
</ol>
<p class="terms-paragraph">
    Al utilizar cualquiera de nuestras Plataformas usted acepta el presente Aviso de Privacidad en
    todos sus términos y, por ende, a que nosotros hagamos uso de sus datos personales. En caso de
    no estar de acuerdo con el presente Aviso de Privacidad, en todo o en parte, debe de hacernos
    saber dicha situación al correo electrónico establecido en el apartado inmediatamente anterior,
    de lo contrario, se entenderá su expresa y completa conformidad con el presente Aviso de
    Privacidad.
</p>
<ol>
    <li class="terms-paragraph">Captación de la información.</li>
</ol>
<p class="terms-paragraph">
    Usted permite que conozcamos su información, ya sea que usted la proporcione o nosotros la
    recolectemos mediante su uso en nuestras Plataformas.
</p>
<ol>
    <li class="terms-paragraph">Terceros con acceso a la información y/o datos personales.</li>
</ol>
<p class="terms-paragraph">
    Usted acepta que nosotros podremos compartir y/o dar acceso, de cualquier forma, a su
    información a:
</p>
<ol>
    <li class="terms-paragraph">Nuestro personal;</li>
    <li class="terms-paragraph">Nuestras sociedades afiliadas;</li>
    <li class="terms-paragraph">
        Entidades de seguridad pública, tales como cuerpos de policía estatales, federales o
        internacionales;
    </li>
    <li class="terms-paragraph">Proveedores de servicio;</li>
    <li class="terms-paragraph">Socios;</li>
    <li class="terms-paragraph">Personas, físicas o morales, que operen nuestras Plataformas; y</li>
    <li class="terms-paragraph">Terceras personas que usted autorice para tales efectos.</li>
</ol>
<p class="terms-paragraph">
    Las personas antes mencionadas deberán cumplir con la legislación y reglamentación aplicable. La
    Empresa no se hace responsable en caso de que uno o cualquiera de estos incumpla con lo
    establecido en las leyes aplicables respecto de información del usuario.
</p>
<ol>
    <li class="terms-paragraph">Menores de Edad.</li>
</ol>
<p class="terms-paragraph">
    Los menores de edad, de conformidad con la legislación aplicable, no tienen permitido utilizar
    las Plataformas sin el consentimiento y estricta supervisión de sus padres y/o tutores. En caso
    de que el menor de edad haga uso indebido de las Plataformas, sus padres y/o tutores serán los
    únicos responsables y responderán ante la Empresa por cualquier daño y perjuicio que el menor de
    edad a su cargo cause a esta.
</p>
<p class="terms-paragraph">
    Al utilizar las Plataformas, el menor de edad y sus padres y/o tutores liberan de toda
    responsabilidad a la Empresa y serán responsables de sacar en paz y a salvo a la misma por el
    mal uso que la Empresa haga de los datos personales y/o la información del menor de edad, toda
    vez que la Empresa actuará bajo el entendido que todos sus usuarios son mayores de edad y/o
    cuentan con la autorización y supervisión estricta de sus padres y/o tutores.
</p>
<ol>
    <li class="terms-paragraph">
        Solicitud de Acceso, Rectificación, Cancelación u Oposición de Datos Personales y Revocación
        del Consentimiento (Solicitud Arco).
    </li>
</ol>
<p class="terms-paragraph">
    Todos sus datos personales son tratados de acuerdo a la legislación aplicable y vigente en el
    país, por ello le informamos que usted o su representante legal, tiene en todo momento el
    derecho de acceder, rectificar, cancelar u oponerse al tratamiento que le damos a sus datos
    personales, así como revocar el consentimiento otorgado para el tratamiento de los mismos;
    derecho que podrá hacer valer a través, por medio del siguiente correo electrónico:
    <a href="mailto:{{EMAIL}}"> {{EMAIL}} </a>
    , a través del cual se le atenderá en tiempo y forma. Para que la Empresa pueda darle
    seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su
    identidad a través de los documentos de acreditación que corresponda para cada caso en
    específico, de lo contrario, la Empresa no se verá obligada a formalizar su solicitud, pues es
    intención de la Empresa preservar los datos personales en todo momento.
</p>
<ol>
    <li class="terms-paragraph">Cambios a nuestro Aviso de Privacidad.</li>
</ol>
<p class="terms-paragraph">
    Cualquier cambio que efectuemos a nuestro Aviso de Privacidad lo comunicaremos al usuario al
    correo electrónico con el que se registro en las Plataformas y se verá reflejado en el presente
    documento, mismo que podrá ser consultado a través de cualquiera de las Plataformas.
</p>
<p class="terms-paragraph">
    Derivado de lo anterior, se entiende que el usuario tendrá, en todo momento, acceso a los
    cambios y últimas versiones del Aviso de Privacidad.
</p>
<ol>
    <li class="terms-paragraph">Cookies.</li>
</ol>
<p class="terms-paragraph">
    El usuario reconoce que la Empresa, a través de las Plataformas, emplea cookies y/o tecnologías
    similares. Las cookies son archivos de información almacenados en su computadora o dispositivo
    móvil que contienen información anónima identificando características propias del usuario en las
    Plataformas. Esta tecnología permite que las Plataformas “recuerden” cosas del usuario y
    modifique su experiencia de uso.
</p>
<ol>
    <li class="terms-paragraph">Mayor información.</li>
</ol>
<p class="terms-paragraph">
    Para mayor información, favor de dirigirse al siguiente correo electrónico:
    <a href="mailto:{{EMAIL}}"> {{EMAIL}} </a>
</p>
<p class="terms-paragraph">Última versión vigente al {{DATE}}</p>
`;
