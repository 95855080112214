import { CreateStorage, StorageDriver } from "@platform/react/store";
import { NAME_SLICE_PURCHASES } from "./slices/purchases";
import { NAME_SLICE_PROGRAMS } from "./slices/programs";
import { NAME_SLICE_APP } from "./slices/app";
import { NAME_SLICE_SESSION } from "./slices/session";
import { NAME_SLICE_HISTORY_VIDEO } from "./slices/historyVideo";
import { NAME_SLICE_PAYMENT } from "./slices/payment";
import { NAME_SLICE_PAYMENT_TRIAL } from "./slices/payment-trial";

export const Storage = CreateStorage([
    { key: NAME_SLICE_APP, type: StorageDriver.LOCAL },
    { key: NAME_SLICE_SESSION, type: StorageDriver.LOCAL },
    { key: NAME_SLICE_PROGRAMS, type: StorageDriver.LOCAL },
    { key: NAME_SLICE_HISTORY_VIDEO, type: StorageDriver.LOCAL },
    { key: NAME_SLICE_PAYMENT, type: StorageDriver.LOCAL },
    { key: NAME_SLICE_PAYMENT_TRIAL, type: StorageDriver.LOCAL },
    { key: NAME_SLICE_PURCHASES, type: StorageDriver.SESSION },
]);
