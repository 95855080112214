import { CreateActions } from "@platform/react/src/store";

import type { SecretAnswer } from "@common/content/secret/answer";

import { NAME_SLICE_SECRET } from "./_namespace";
import type { NSSliceSecret } from "./_namespace";

export const ActionSliceSecret = CreateActions<{
    /** Sets a give property errors */
    set: NSSliceSecret.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSliceSecret.ArgClear | null;
    /** Set mental program */
    setProgram: NSSliceSecret.State["program"];
    /** Clear daily training */
    clearDailyTraining: void;
    /** Set daily training */
    setDailyTraining: SecretAnswer;
    /** Get new day */
    setNewDay: void;
    /** The user starts the program */
    startsProgram: void;
    /** Finished daily training */
    setDailyFinished: void;
    /** User watched tips */
    setHasViewedTips: void;
    /** Clear user response */
    clearHasViewedTips: void;
}>(NAME_SLICE_SECRET, [
    "set",
    "clear",
    "setProgram",
    "clearDailyTraining",
    "setDailyTraining",
    "setNewDay",
    "startsProgram",
    "setDailyFinished",
    "setHasViewedTips",
    "clearHasViewedTips",
]);
