/// <reference types="cordova-plugin-inappbrowser" />

// TODO: Review documentation to map common options like  we did for hasLocationBar

/**
 * Reference: https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-inappbrowser/
 */
type OpenBrowserInAppOptions = {
    /** Whether to show the location bar or not */
    hasLocationBar?: boolean;
    /** Creates the browser and loads the page, but does not show it.
     * The loadstop event fires when loading is complete. */
    isHidden?: boolean;
};

const OPEN_BROWSER_IN_APP_OPTIONS_DEFS: OpenBrowserInAppOptions = {
    hasLocationBar: true,
    isHidden: false,
};

/**
 * REQUIRED PLUGINS: 👇
 *  - cordova-plugin-file: https://github.com/apache/cordova-plugin-inappbrowser
 */
export function OpenBrowserInApp(url: string, opt?: OpenBrowserInAppOptions): void {
    const InAppBrowser = getPlugin();
    const { hasLocationBar, isHidden } = { ...OPEN_BROWSER_IN_APP_OPTIONS_DEFS, ...(opt || {}) };
    const options = [
        `location=${hasLocationBar ? "yes" : "no"}`,
        `hidden=${isHidden ? "yes" : "no"}`,
    ].join(",");
    const ref = InAppBrowser.open(url, "_blank", options);
    ref.show();
}

/**
 * REQUIRED PLUGINS: 👇
 *  - cordova-plugin-file: https://github.com/apache/cordova-plugin-inappbrowser
 */
export function OpenBrowserSystem(url: string) {
    const InAppBrowser = getPlugin();
    const ref = InAppBrowser.open(url, "_system");
    ref.show();
}

function getPlugin() {
    const { InAppBrowser } = window.cordova;
    if (!InAppBrowser) throw new Error("InAppBrowser plugin not found.");
    return InAppBrowser;
}
