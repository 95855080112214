import React from "@platform/react";

import type { PropDefaults, PropElement, PropTypes } from "..";
import { Component } from "..";

import Namespace from "./_index.scss";
import "./_rules.scss";

export const { TYPE_BUTTONPLATFORM, NAME_BUTTONPLATFORM } = Namespace;

export const KIND_BUTTONPLATFORM = {
    SUBMIT: "submit",
    DEFAULT: "button",
    RESET: "reset",
} as const;

export type KindButtonPlatform = typeof KIND_BUTTONPLATFORM;

export type PropButtonPlatform = Omit<PropElement, "children"> & {
    /** The content inside the button, either a Text's uuid or an arbitrary element */
    children: NonNullable<PropElement["children"]>;
    /** Determine the native "type" property for a button. */
    kind?: KindButtonPlatform[keyof KindButtonPlatform];
    /** What to do when the button is clicked */
    onClick?: null | React.HTMLProps<HTMLButtonElement>["onClick"];
    /** Whether the button is enabled for click or not */
    disabled?: React.HTMLProps<HTMLButtonElement>["disabled"];
};

export const DEF_BUTTONPLATFORM: Omit<PropDefaults<PropButtonPlatform>, "onClick"> = {
    disabled: false,
    kind: KIND_BUTTONPLATFORM.DEFAULT,
} as const;

export type PropTypesButtonPlatform = PropTypes<PropButtonPlatform, typeof DEF_BUTTONPLATFORM>;

/**
 * The base implementation of a button, it should be enhanced by your own implementation.
 */
export const ButtonPlatform = React.forwardRef((props: PropTypesButtonPlatform, ref) => {
    const { children, kind, onClick, disabled, ...rest } = props;
    return (
        <Component
            {...rest}
            ref={ref}
            disabled={disabled}
            html-type={kind}
            name={NAME_BUTTONPLATFORM}
            tag="button"
            type={TYPE_BUTTONPLATFORM}
            onClick={handleClick}>
            {children}
        </Component>
    );

    function handleClick(ev: React.MouseEvent<HTMLButtonElement>) {
        if (onClick) onClick(ev);
    }
});
ButtonPlatform.defaultProps = DEF_BUTTONPLATFORM;
ButtonPlatform.displayName = NAME_BUTTONPLATFORM;
