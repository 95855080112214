import { ParamCreate } from "@platform/react-storybook/Component/Router/utils";
import { UUID_CRACK } from "@common/content/crack/uuid";
import type { UuidCrack } from "@common/content/crack/uuid";

export type ParamValueProfile = UuidCrack;

export const { paramCrack, useParamCrack } = ParamCreate({
    name: "crack",
    values: UUID_CRACK,
});
