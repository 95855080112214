import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_PAYMENT } from "./_namespace";
import type { NSSlicePayment } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSlicePayment(store: StoreState): NSSlicePayment.State {
    return store[NAME_SLICE_PAYMENT];
}

SelectorSlicePayment.getCreditCard = CreateSelector(
    SelectorSlicePayment,
    (state) => state.creditCard,
);

SelectorSlicePayment.getUuidPlan = CreateSelector(SelectorSlicePayment, (state) => state.uuidPlan);
SelectorSlicePayment.getUuidCreditCard = CreateSelector(
    SelectorSlicePayment,
    (state) => state.uuidCreditCard,
);

SelectorSlicePayment.getPlan = CreateSelector(SelectorSlicePayment, (state) => state.plan);

SelectorSlicePayment.historyPayment = CreateSelector(
    SelectorSlicePayment,
    (state) => state.historyPayment,
);
SelectorSlicePayment.getSubscriptions = CreateSelector(
    SelectorSlicePayment,
    (state) => state.subscription,
);

SelectorSlicePayment.getCardRemove = CreateSelector(
    SelectorSlicePayment,
    (state) => state.idCardRemove,
);
SelectorSlicePayment.getMovements = CreateSelector(
    SelectorSlicePayment,
    (state) => state.movements,
);

SelectorSlicePayment.getCharge = CreateSelector(SelectorSlicePayment, (state) => state.charge);
SelectorSlicePayment.discount = CreateSelector(SelectorSlicePayment, (state) => state.discount);
