export const NAME_SLICE_DELETE_ACCOUNT = "deleteAccount";

export const DELETE_REASON = {
    NO_REASON: 0,
    PRICE: 1,
    DIFFICULT: 2,
    TIME: 3,
    LIKE: 4,
    OTHER: 5,
} as const;

export declare namespace NSSliceDeleteAccount {
    /** Determines the explanation */
    export type explanation = string;
    /** Determines the reason */
    export type reason = typeof DELETE_REASON[keyof typeof DELETE_REASON];

    export type State = {
        explanation: explanation;
        reason: reason;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_DELETE_ACCOUNT: NSSliceDeleteAccount.State = {
    explanation: "",
    reason: DELETE_REASON.NO_REASON,
};
