import { CreateActions } from "@platform/react/src/store";

import { NAME_SLICE_PAYMENT_TRIAL } from "./_namespace";
import type { NSSlicePaymentTrial } from "./_namespace";

export const ActionSlicePaymentTrial = CreateActions<{
    /** Sets a give property errors */
    set: NSSlicePaymentTrial.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSlicePaymentTrial.ArgClear;
    /** */
}>(NAME_SLICE_PAYMENT_TRIAL, ["set", "clear"]);
