import { CreateReducer } from "@platform/react/src/store";
import { ActionSliceException } from "./_actions";
import { INIT_SLICE_EXCEPTION } from "./_namespace";

export const ReducerSliceException = CreateReducer(INIT_SLICE_EXCEPTION, ({ addCase }) => {
    /** Cleanup errors */
    addCase(ActionSliceException.clean, () => INIT_SLICE_EXCEPTION);
    /** Append an error to the stack */
    addCase(ActionSliceException.add, (state, { payload }) => [...state, payload]);
});
