import { CreateReducer } from "@platform/react/store";
import { ActionSliceDeleteAccount } from "./_actions";
import { INIT_SLICE_DELETE_ACCOUNT } from "./_namespace";

export const ReducerSliceDeleteAccount = CreateReducer(INIT_SLICE_DELETE_ACCOUNT, ({ addCase }) => {
    addCase(ActionSliceDeleteAccount.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSliceDeleteAccount.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_DELETE_ACCOUNT;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });
});
