import { ParamCreate } from "@platform/react-storybook/Component/Router/utils";
import { UUID_CATEGORY } from "@common/content/category";
import type { UuidCategory } from "@common/content/category";

export type ParamValueProfile = UuidCategory;

export const { paramCategory, useParamCategory } = ParamCreate({
    name: "category",
    values: UUID_CATEGORY,
});
