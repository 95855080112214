import React from "@platform/react";
import { MobileInfo } from "@platform/utils-react/mobile";

import { ERROR_THEMEPLATFORM } from "./types";
import type { PropDefaults, PropTypes } from "../../Component";
import type {
    ArgProviderThemePlatform,
    GetterThemePlatform,
    PropProviderThemePlatform,
    StateThemePlatform,
    DataThemePlatform,
    ValThemePlatform,
} from "./types";

export function CreateProviderThemePlatform<G extends GetterThemePlatform>(
    arg: ArgProviderThemePlatform<G>,
) {
    const { Context, getter } = arg;

    const DEF_PROVIDER_THEMEPLATFORM: PropDefaults<Omit<PropProviderThemePlatform<G>, "onError">> =
        {
            loading: null,
            shouldInjectOnLoad: false,
        };

    type State = NonNullable<StateThemePlatform<G>>;
    type Data = State["themeData"];
    type Themes = State["themeAvailable"];

    function ProviderThemePlatform(
        props: PropTypes<PropProviderThemePlatform<G>, typeof DEF_PROVIDER_THEMEPLATFORM>,
    ) {
        const { children, loading, shouldInjectOnLoad, onError, ...rest } = props;
        const [data, setData] = React.useState<undefined | Data>(undefined);
        const [theme, setTheme] = React.useState(rest.theme);

        /** Add a class so the platform is known by css */
        React.useEffect(() => {
            (async () => {
                const info = await MobileInfo();
                if (!info) return;
                const { osName } = info;
                document.documentElement.className = [
                    document.documentElement.className,
                    `platform-${osName.toLowerCase()}`,
                ].join(" ");
            })();
        }, []);

        React.useEffect(() => {
            (async () => {
                // data already obtained from the getter, do nothing.
                if (data) {
                    if (theme) handleInject(data[theme]);
                    return;
                }
                try {
                    const _data = await getter();
                    if (!_data) {
                        if (onError) onError(new Error(ERROR_THEMEPLATFORM.INVALID_RESPONSE));
                        return;
                    }
                    setData(_data as Data);
                    if (shouldInjectOnLoad) {
                        const t = theme as keyof DataThemePlatform;
                        if (!_data[t]) {
                            if (onError) onError(new Error());
                            return;
                        }
                        handleInject(_data[t]);
                    }
                } catch (error) {
                    if (onError) onError(error as Error);
                }

                function handleInject(vars: ValThemePlatform) {
                    if (!shouldInjectOnLoad) return;
                    Object.entries(vars).forEach(([key, val]) => {
                        document.documentElement.style.setProperty(`--${key}`, val);
                    });
                }
            })();
        }, [data, onError, shouldInjectOnLoad, theme]);

        if (!data) return loading;

        return (
            <Context.Provider
                value={{
                    themeCurrent: theme,
                    themeAvailable: Object.keys(data) as Themes,
                    themeData: data,

                    themeReset() {
                        setData(undefined);
                    },

                    themeSet(_theme) {
                        setTheme(_theme);
                    },

                    themeGetValue(uuid) {
                        const current = data[theme];
                        if (!current[uuid]) return undefined;
                        return current[uuid];
                    },
                }}>
                {children}
            </Context.Provider>
        );
    }
    ProviderThemePlatform.defaultProps = DEF_PROVIDER_THEMEPLATFORM;

    return ProviderThemePlatform;
}
