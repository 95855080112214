export const PAGE_USER = {
    USER_ROOT: "Root",
    USER_HOME: "Home",
    USER_HOME_CRACK: "Home/Crack",
    USER_HOME_CATEGORY: "Home/Category",
    USER_HOME_VIDEO: "Home/Video",
    USER_SOON: "Soon",
    USER_SESSION: "Session",
    USER_PROFILE: "Profile",
    USER_PROFILE_DELETE: "Profile/Delete",
    USER_PROFILE_CONTACT: "Profile/Contact",
    USER_SECRET: "Secret",
    USER_SECRET_PROGRAM: "Secret/Program",
    USER_SECRET_PROGRAM_TIPS: "Secret/Program/Tips",
    USER_SECRET_PROGRAM_TRAIN: "Secret/Program/Train",
    USER_SECRET_PROGRAM_FINISH: "Secret/Program/Finish",
} as const;
