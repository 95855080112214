import * as Color2K from "color2k";
import { DATA_THEME } from "@common/content/theme";

export async function getter() {
    const data = await Promise.resolve(DATA_THEME);
    type Data = typeof data;
    type KData = keyof Data;
    type Theme = Data[KData];
    type KTheme = keyof Theme;
    type KOther = Exclude<KTheme, `color${string}`>;
    type KColor = Exclude<KTheme, KOther>;
    type KPreset = Exclude<KColor, `color_${string}`>;
    type PropColor = {
        tone: { [K in Extract<KColor, `color_tone${string}`>]: Theme[K] };
        bright: { [K in Extract<KColor, `color_bright${string}`>]: Theme[K] };
        opacity: { [K in Extract<KColor, `color_opacity${string}`>]: Theme[K] };
        preset: { [K in KPreset]: Theme[K] };
    };
    return Object.entries(data).reduce((accData, [themeKey, theme]) => {
        const themeEntries = Object.entries(theme);
        // separate color from non-color props
        const propOther = themeEntries
            .filter(([k]) => !k.startsWith("color"))
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {} as Omit<Theme, KColor>);
        // color props will be broke down into workable pieces.
        const { tone, bright, opacity, preset } = themeEntries
            .filter(([k]) => k.startsWith("color"))
            .reduce(
                (acc, [k, v]) => ({
                    ...acc,
                    tone: !k.startsWith("color_tone") ? acc.tone : { ...acc.tone, [k]: v },
                    bright: !k.startsWith("color_bright") ? acc.bright : { ...acc.bright, [k]: v },
                    opacity: !k.startsWith("color_opacity")
                        ? acc.opacity
                        : { ...acc.opacity, [k]: v },
                    preset: !k.startsWith("color-") ? acc.preset : { ...acc.preset, [k]: v },
                }),
                { tone: {}, bright: {}, opacity: {}, preset: {} } as PropColor,
            );

        // process each preset, so the computed color gets corrected.
        const propColor = Object.entries(preset).reduce((acc, [k, prop]) => {
            const entryTone = Object.entries(tone).find(([_k]) => _k.endsWith(prop.tone));
            if (!entryTone) throw new Error(`Invalid tone ${prop.tone} for ${k}`);
            const entryBright = Object.entries(bright).find(([_k]) => _k.endsWith(prop.bright));
            if (!entryBright) throw new Error(`Invalid bright ${prop.bright} for ${k}`);
            const entryOpacity = Object.entries(opacity).find(([_k]) => _k.endsWith(prop.opacity));
            if (!entryOpacity) throw new Error(`Invalid opacity ${prop.opacity} for ${k}`);
            const vt = entryTone[1];
            const vb = parseFloat(entryBright[1]);
            const vo = parseFloat(entryOpacity[1]);
            const ZERO = 0;
            const UNIT = 1;
            let color: string = vt;
            if (vb !== ZERO) {
                color = vb < ZERO ? Color2K.darken(color, vb) : Color2K.lighten(color, vb);
            }
            color = Color2K.transparentize(color, UNIT - vo);
            return { ...acc, [k]: color };
        }, {} as { [K in keyof PropColor["preset"]]: string });
        return {
            ...accData,
            [themeKey]: {
                ...propColor,
                ...propOther,
            },
        };
    }, {} as { [KD in KData]: Pick<Data[KData], KOther> } & { [KD in KData]: { [KP in KPreset]: string } });
}
