import { PREFIX_COLOR_THEME } from "@common/content/theme/color";

export const PREFIX_TONE_COLOR_THEME = `${PREFIX_COLOR_THEME}_tone` as const;

export const TONE_COLOR_THEME = {
    lion: "#FFFFFF",
    lynx: "#000000",
    leopard: "#E6FF58",
    tiger: "#30BE78",
    puma: "#236EFA",
    jaguar: "#0A0087",
    cheetah: "#F523C8",
    ocelot: "#1B1C1E",
    serval: "#FF5454",
    panther: "#858585",
    cougar: "#E4E4E4",
} as const;

export type ToneColorTheme = typeof TONE_COLOR_THEME;

export type UuidToneColorTheme = keyof ToneColorTheme;

export type ValueToneColorTheme = ToneColorTheme[UuidToneColorTheme];

export const UUID_TONE_COLOR_THEME = Object.keys(TONE_COLOR_THEME) as readonly UuidToneColorTheme[];
