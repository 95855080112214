export const TermsEN = `
<h1 class="terms-title">Master Cracks Terms of Service</h1>
<h5 class="terms-date">Effective Date: <span class="tems-data-number">{{DATE}}</span></h5>
<p class="terms-paragraph">
    Master Cracks, Inc. ('Master Cracks') is a provider of technology services and products that help their users
    develop physically, athletically, and tactically through a digital platform.
    By using the native application, web application, and/or website of mastercracks.com, or any services, products,
    features, content, or applications provided in connection therewith (collectively, the 'Service'), implies that the
    user (the 'User') have read, understood, and agree to be bound by these terms of service (the 'Terms'), as they may
    be amended by us from time to time at our sole discretion.
    Likewise, by accessing or using our Service, the User also agrees to the collection and use of their information as
    outlined in our Privacy Policy available at www.Master Cracks.com/privacy-policy (our 'Privacy Policy'), whether or
    not the User is registered for Master Cracks Service. Master Cracks reserves the right to modify these Terms of
    Service and will provide notice of these changes as described below, including but not limited to, through a
    click-through agreement or push notification. These Terms of Service apply to all visitors, users, and others who
    access our Service or use our products (collectively, 'Users').
</p>
<p class="terms-paragraph">
    We invite all Users to read these carefully to ensure that they understand each provision of the Terms of Service.
    These Terms of Service contain a mandatory individual arbitration and class action/jury trial waiver provision that
    requires the use of arbitration on an individual basis to resolve disputes, rather than jury trials or class
    actions.
<ul>
    <li>
        <h3 class="terms-subtitle">Master Cracks Service</h3>
        <ul>
            <li>
                <h4 class="terms-subtitle">Eligibility:</h4>
                <p class="terms-paragraph">
                    This is a binding agreement between the User and Master Cracks. The User must read and agree to these
                    Terms of Service before using Master Cracks Service. If the User does not agree to these Terms of
                    Service, the User may not use Master Cracks Service. The User may use Master Cracks Service only if
                    it can form a binding contract with us, and only in compliance with these Terms of Service and all
                    applicable local, state, national, and international laws, rules, and regulations. Any use or access
                    to our Service by anyone under 18 (eighteen) years old is strictly prohibited and in violation of
                    these Terms of Service. Master Cracks Service is not available to any User we previously removed
                    from our Service.</p>
            </li>
            <li>
                <h4 class="terms-subtitle">Access and Use:</h4>
                <p class="terms-paragraph">
                            Subject to the User compliance with these Terms of Service, the User may access and use Master Cracks
                    Service solely for their personal, non-commercial use. We reserve all rights not expressly granted
                    by these Terms of Service in and to Master Cracks Service and Master Cracks Intellectual Property
                    (defined below). Master Cracks may suspend or terminate the User access to Master Cracks Service at
                    any time for any reason or no reason.</p>
            </li>
            <li>
                <h4 class="terms-subtitle">Restrictions:</h4>
                <p class="terms-paragraph">
                    The User will not, and will not assist, permit, or enable others to do any of the following:</>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            Use Master Cracks Service for any purpose other than as expressly outlined in the Terms of
                            Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Disassemble, reverse engineer, decode, or decompile any part of Master Cracks Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Use any robot, spider, scraper, data mining tool, data gathering or extraction tool, or any
                            other automated means, to access, collect, copy, or record the Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Copy, rent, lease, sell, transfer, assign, sublicense, modify, alter, or create derivative
                            works of any part of Master Cracks Service or any Master Cracks Intellectual Property;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Remove any copyright notices or proprietary legends from Master Cracks Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Use Master Cracks Service in a manner that impacts: (i) the stability of Master Cracks
                            servers; (ii) the operation or performance of Master Cracks Service or any other User's use
                            of Master Cracks Service; or (iii) the behavior of other applications using Master Cracks
                            Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Use Master Cracks Service in any manner or for any purpose that violates any applicable law,
                            regulation, legal requirement or obligation, contractual obligation, or any right of any
                            person including, but not limited to, intellectual property rights, rights of privacy, or
                            rights of publicity, or which otherwise may be harmful (in Master Cracks sole discretion) to
                            Master Cracks, Master Cracks providers, Master Cracks suppliers or Mater Cracks Users;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Use Master Cracks Service in competition with Master Cracks, to develop competing products or
                            services, or otherwise to Master Cracks detriment or commercial disadvantage;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Use Master Cracks Service for benchmarking or competitive analysis of Master Cracks Service;
                        </p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Attempt to interfere with, compromise the system integrity or security of, or decipher any
                            transmissions to or from, the servers running Master Cracks Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Transmit viruses, worms, or other software agents through Master Cracks Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Impersonate another person or misrepresent your affiliation with a person or entity, hide or
                            attempt to hide the Users identity, or otherwise use Master Cracks Service for any invasive
                            or fraudulent purpose;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Share passwords or authentication credentials for Master Cracks Service;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Bypass the measures Master Cracks may use to prevent or restrict access to Master Cracks
                            Service or enforce limitations on use of Master Cracks Service or the content therein,
                            including without limitation features that prevent or restrict use or copying of any
                            content;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Identify Master Cracks or display any portion of its Service on any site or service that
                            disparages Master Cracks or its products or services, or infringes any of Master Cracks
                            intellectual property or other rights; and</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Identify or refer to Master Cracks or its Service in a manner that could reasonably imply an
                            endorsement, relationship, or affiliation with or sponsorship between the User or a third
                            party and Master Cracks, other than the User permitted use of our Service under these Terms,
                            without Master Cracks express written consent.</p>
                    </li>
                </ul>
            </li>
            <li>
                <h4 class="terms-subtitle"> User Accounts</h4>
                <p class="terms-paragraph">
                             The User account on Master Cracks Service (the 'User Account') gives the User access to the products
                    and functionality that Master Cracks may establish and maintain from time to time and at its sole
                    discretion. Master Cracks may maintain different types of User Accounts for different types of
                    Users. If a User opens a User Account on behalf of a company, organization, or other entity, then
                    (i) 'The company' includes the User and that entity, and (ii) the company represent and warrant that
                    the User is an authorized representative of the entity with the authority to bind the entity to
                    these Terms of Service and that the User agrees to these Terms of Service on the entity's behalf. By
                    connecting to Master Cracks Service with a third-party service, the User gives Master Cracks
                    permission to access and use the User information from that service as permitted by that service,
                    and to store the User log-in credentials for that service.</p>
                <p class="terms-paragraph">
                            The User further agrees that all information supplied to Master Cracks is true, accurate, current,
                    and complete. The User is required to keep his account information updated at all times. Some of the
                    needed personal information may include, but is not limited to, the following:</p>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            Email address;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            The User first and last name;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Date of Birth of the User; </p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Phone number, including a mobile phone number or landline number;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Mailing address;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Financial information, e.g., credit card numbers;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Payment or tax information, including, but not limited to, bank account information;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            User login and password information;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Facebook, Gmail, or other social media login username and password;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            The name of the domain from which the User access the Internet;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Search preferences including latitude and longitude of the User location;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Street address of the User location; and</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            The date and time the User access the App and/or the Service and the duration time.</p>
                    </li>
                </ul>
            </li>
            <li>
                <h4 class="terms-subtitle"> Usage of the User Information</h4>
                <p class="terms-paragraph">
                            The User may never use another User's User Account without permission. When creating the User
                    Account, the User must provide accurate and complete profile information, and must keep this
                    information up to date. The User is solely responsible for the activity that occurs on its User
                    Account and must keep its User Account password secure. Master Cracks encourage the User to use
                    'strong' passwords (passwords that use a combination of upper and lowercase letters, numbers, and
                    symbols) with its User Account. The User must notify Master Cracks immediately of any breach of
                    security or unauthorized use of their User Account. Master Cracks will not be liable for any losses
                    caused by any unauthorized use of the User Account.</p>
                <p class="terms-paragraph">
                            The User may control certain aspects of its User profile and how it can interact with Master Cracks
                    Service by changing the settings in their settings page. By providing Master Cracks with the User
                    email address the User consent to Master Cracks using the email address to send the User the
                    Service-related notices, including any notices required by law, instead of communication by postal
                    mail. Master Cracks may also use the Users email address to send him other promotional messages,
                    such as changes to features of Master Cracks Service and special offers. If the User does not want
                    to receive such email messages, it may opt-out or change its preferences by contacting Master Cracks
                    support at hello@.... or by clicking the unsubscribe link within each promotional message. Opting
                    out may prevent the User from receiving email messages regarding updates, improvements, or offers;
                    however, opting out will not prevent the User from receiving Service-related notices.</p>
                <p class="terms-paragraph">
                            The User acknowledges that it does not own the User Account it uses to access Master Cracks Service.
                    Notwithstanding anything in these Terms of Service to the contrary, the User agrees that Master
                    Cracks have the absolute right to manage, regulate, control, modify and/or eliminate any data stored
                    by Master Cracks or on its behalf on its (including by our third-party hosting providers') servers
                    as Master Cracks see fit in its sole discretion, in any general or specific case, and that Master
                    Cracks will have no liability to the User-based on Master Cracks exercise of such right. All data on
                    Master Cracks servers are subject to deletion, alteration, or transfer. </p>
                <p class="terms-paragraph">
                            Notwithstanding any value attributed to such data by the User or any third party, the User
                    understands and agrees that any data, user account history, and user account content residing on
                    Master Cracks servers, may be deleted, altered, moved, or transferred at any time for any reason in
                    Master Cracks discretion, with or without notice and with no liability of any kind. Master Cracks do
                    not provide or guarantee, and expressly disclaims, any value, cash or otherwise, attributed to any
                    data residing on its servers.</p>

            </li>
            <li>
                <h4 class="terms-subtitle"> User Data</h4>
                <p class="terms-paragraph">
                            As part of Master Cracks Service, it will collect data, content, and information, including video,
                    audio, and personal information, including but not limited to, User's image, voice and messages that
                    the User provides to Master Cracks or that is collected by Master Cracks or via the Service,
                    including without limitation Workout Data as described in our Privacy Policy, as well as all videos,
                    images, messages whether captured by Master Cracks or provided by the User where the image and/or
                    voice of the User appears, including those that either party uploads to any social network related
                    to Master Cracks, as well as any Content that the User provides to Master Cracks through the
                    platform or web page (as such term is defined below). ('User Data'). The User hereby grant to Master
                    Cracks, and represent and warrant that it have all rights necessary to grant to Master Cracks, a
                    perpetual, irrevocable, non-exclusive, sublicensable, transferable and royalty-free right and
                    license to collect, use, reproduce, electronically distribute, transmit, have transmitted, perform,
                    display, store, archive, and to modify and make derivative works of any and all User Data in order
                    to provide and maintain Master Cracks Service for the User and, solely in anonymous or aggregate
                    form, to improve Master Cracks products and services and for Master Cracks other business purposes
                    (and any and all such derived data is deemed part of Master Cracks Intellectual Property). In
                    connection with the User Data, the User affirm, represent and warrant that: (a) it have the written
                    consent of each and every identifiable natural person in the User Data regarding such person's name
                    or likeness in the manner contemplated by the Service and these Terms of Service, and each such
                    person has released the User from any liability that may arise in relation to such use; (b) the User
                    Data and Master Cracks use thereof as contemplated by these Terms of Service and the Service will
                    not violate any law or infringe any rights of any third party, including but not limited to any
                    intellectual property rights and privacy rights. Mater Cracks take no responsibility and assume no
                    liability for any of the User Data. The User shall be solely responsible and indemnify Master Cracks
                    for its User Data.</p>

            </li>
            <li>
                <h4 class="terms-subtitle">Changes to Master Cracks Service</h4>
                <p class="terms-paragraph">
                            Master Cracks may, without prior notice, change its Service; stop providing its Service or features
                    of its Service, to Users; or create usage limits for its Service. Master Cracks may permanently or
                    temporarily terminate or suspend the User access to its Service without notice and liability for any
                    reason, including if in Master Cracks sole determination, the User violates any provision of these
                    Terms of Service, or for no reason. Upon termination for any reason or no reason, the User continues
                    to be bound by these Terms of Service.</p>

            </li>



            <li>
                <h4 class="terms-subtitle"> Interactions with Other Users</h4>
                <p class="terms-paragraph">
                            The User is solely responsible for their interactions with other Users. Master Cracks reserve the
                    right, but have no obligation, to monitor disputes between the User and other Users. Master Cracks
                    shall have no liability for the User interactions with other Users, or any User's action or
                    inaction.</p>

            </li>
            <li>
                <h4 class="terms-subtitle">Service Location</h4>
                <p class="terms-paragraph">
                            Master Cracks Service is controlled and operated from facilities in the United States . Those who
                    access or use Master Cracks Service from other jurisdictions is entirely responsible for compliance
                    with all applicable United States and local laws and regulations, including but not limited to
                    export and import regulations. The User may not use Master Cracks Service if it is a resident of a
                    country embargoed by the United States or are a foreign person or entity blocked or denied by the
                    United States government.</p>

            </li>
            <li>
                <h4 class="terms-subtitle">Master Cracks Intellectual Property</h4>
                <p class="terms-paragraph">
                            The User acknowledges and agrees that Master Cracks Service and all materials and content displayed
                    or made available on its Service, and all software, algorithms, code, technology, and intellectual
                    property underlying and included in or with Master Cracks Service, and all intellectual property
                    rights therein and thereto throughout the world (collectively and individually, our 'Intellectual
                    Property'), are their (or Master Cracks licensors' as applicable) sole and exclusive property.
                    Except as explicitly provided herein, or on Master Cracks sites, nothing in these Terms of Service
                    will be deemed to create a license in or under any intellectual property rights, and the User agrees
                    not to access, sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display,
                    publicly perform, publish, adapt, edit or create derivative works from any of Master Cracks
                    Intellectual Property. Use of Master Cracks Intellectual Property for any purpose not expressly
                    permitted by these Terms of Service are strictly prohibited, including, but not limited to, any
                    commercial uses.</p>
                <p class="terms-paragraph">
                            The User may choose to, or Master Cracks may invite the User to submit, comments or ideas about its
                    Service, including without limitation about how to improve its Service or its products ('Ideas'). By
                    submitting any Idea, the User agrees that its disclosure is gratuitous, unsolicited and without
                    restriction and will not place Master Cracks under any fiduciary or other obligation and that Master
                    Cracks are free to use the Idea without any additional compensation to the User, and/or to disclose
                    the Idea on a non-confidential basis or otherwise to anyone. The User further acknowledges that, by
                    acceptance of its submission, Master Cracks do not waive any rights to use similar or related ideas
                    previously known to Master Cracks, or developed by Master Cracks employees, or obtained from sources
                    other than the User.</p>
                <p class="terms-paragraph">
                            Master Cracks respects the intellectual property rights of others and asks that users of our Services
                    do the same. If the User believes that one of our users is, through the use of our Services,
                    unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material
                    removed, the following information in the form of a written notification (under the Digital
                    Millennium Copyright Act) must be provided to Master Cracks designated Copyright Agent:</p>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            An electronic or physical signature of the owner or of the person authorized to act on behalf
                            of the owner of the copyright interest;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Identification of the copyrighted work claimed to have been infringed;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            A description of the material on the Services that the User claim is infringing, and
                            information reasonably sufficient to permit Master Cracks to locate the material (for
                            example, the URL of the webpage);</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            The User name, address, telephone number, and email address;</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            A statement that the User has a good faith belief that the disputed use is not authorized by
                            the copyright owner, its agent, or the law; and</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            A statement by the User, made under penalty of perjury, that the above information in its
                            notice is accurate and that the User is the copyright owner or authorized to act on the
                            copyright owner's behalf.</p>
                    </li>
                </ul>
                <p class="terms-paragraph">
                            The contact information for the Copyright Agent for Master Cracks is as follows:</p>
                <p class="terms-paragraph">
                            [CONTACT] </p>
            </li>
            <li>
                <h4 class="terms-subtitle">Your Content</h4>
                </h4> Some areas of the Service allow the User to post their content such as profile information,
                comments, questions, and other content or information ('Content'). Master Cracks claim no ownership
                rights over the User Content. The User Content remains theirs; however, by sharing its Content through
                the Service, the User agrees to allow others to view, edit, and/or share its Content following its
                settings and these Terms of Service. Master Cracks has the right (but not the obligation) in its sole
                discretion to remove any of its Content that is shared via the Service.</p>
                <p class="terms-paragraph">
                            The User agrees that its Content will not include any content or materials that: (i) may create a
                    risk of harm, loss, physical or mental injury, emotional distress, death, disability, disfigurement,
                    or physical or mental illness to the User, or to any other person; (ii) may create a risk of any
                    other loss or damage to any person or property; (iii) seeks to harm or exploit children by exposing
                    them to inappropriate content, asking for personally identifiable details or otherwise; (iv) may
                    constitute or contribute to a crime or tort; (v) contains any information or content that Master
                    Cracks deem to be unlawful, harmful, abusive, racially or ethnically offensive, defamatory,
                    infringing, invasive of personal privacy or publicity rights, harassing, humiliating to other people
                    (publicly or otherwise), libelous, threatening, profane, or otherwise objectionable; (vi) contains
                    any information or content that is illegal (including, without limitation, the disclosure of insider
                    information under securities law or of another party's trade secrets); (vii) contains any
                    information or content that the User do not have a right to make available under any law or under
                    contractual or fiduciary relationships; or (viii) contains any information or content that the User
                    knows is not correct and current. The User agrees that its Content does not and will not violate
                    third-party rights of any kind, including without limitation any intellectual property rights or
                    rights of privacy. Master Cracks reserve the right but is not obligated, to reject and/or remove any
                    of your Content that Master Cracks believe, in its sole discretion, violates these provisions. The
                    User understands that publishing its Content on the Service is not a substitute for registering it
                    with the U.S. Copyright Office, the Writer's Guild of America, or any other rights organization.</p>
                <p class="terms-paragraph">
                            In connection with the User Content, the User affirms, represents, and warrants that: (a) the User
                    has the written consent of each identifiable natural person in its Content to use such person's name
                    or likeness in the manner contemplated by the Service and these Terms of Service, and each such
                    person has released the User from any liability that may arise about such use; (b) the User Content
                    and Master Cracks use thereof as contemplated by these Terms of Service and the Service will not
                    violate any law or infringe any rights of any third party, including but not limited to any
                    intellectual property rights and privacy rights; (c) Master Cracks may exercise the rights to the
                    User Content granted under these Terms of Service without liability for payment of any fees payable
                    under any collective bargaining agreement or otherwise; and (d) to the best of the Users knowledge,
                    all of its Content and other information that the User provides to Master Cracks is truthful and
                    accurate.</p>
                <p class="terms-paragraph">
                            Master Cracks take no responsibility and assume no liability for any of the Users Content. The User
                    shall be solely responsible for its Content and the consequences of posting or publishing it. The
                    User understands and agrees that it may be exposed to its Content that is inaccurate, objectionable,
                    inappropriate for children, or otherwise unsuited to the User purpose, and the User agrees that
                    Master Cracks shall not be liable for any damages the User allege to incur because of the User
                    Content. </p>
            </li>

            <li>
                <h4 class="terms-subtitle">Instructions; Restrictions; Warnings; Disclaimers.</h4>
                <p class="terms-paragraph">
                            Please carefully read all instructions that come with the Master Cracks products, including
                    instructions that may be available online on Master Cracks website, or by contacting Master Cracks
                    support at hello@.... The User understands and agrees that it assume all risk and liability, and
                    Master Cracks have no obligation or liability whatsoever to the User or anyone else for any or all
                    the following activities:</p>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            PLEASE HEED THE FOLLOWING WARNINGS:</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Performing any exercises or instructions incorrectly may result in serious injury or impaired
                            functionality. Supervise children around the Master Cracks products or during training.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Do not perform any movements that have not been explicitly instructed by a Master Cracks
                            Cracks. </p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Any participation in classes should be properly supervised with an adult present.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Follow all warnings and instructions.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Please ensure you are in a proper training space, and you train on a solid, level, clean
                            surface. It is recommended you not attempt to train in close quarters, or with fragile
                            objects or other people/animals nearby.</p>
                    </li>
                </ul>
            </li>


            <li>
                <h4 class="terms-subtitle"> DISCLAIMERS:</h4>
                <p class="terms-paragraph">
                            Master Cracks does not guarantee any results from Master Cracks instructors or ensure their content
                    is accurate, reliable, always available, or complete. The User may encounter content through the
                    Service that could find offensive, indecent, or objectionable. Master Cracks has no responsibility
                    or liability for User submitted content. Master Cracks has endeavored to be as accurate as possible
                    in describing and displaying features of the Master Cracks products; however, there is no warranty
                    or guarantee that the quality, product descriptions, or other content of the Service are accurate,
                    complete, reliable, current, or error-free.</p>
                <p class="terms-paragraph">
                            The Users use of any third-party services or products in connection with the Users use of Master
                    Cracks services is subject to these Terms of Service and any third-party terms applicable to such
                    third-party services or products. When using any third-party services or products the User is
                    responsible for any information the User provides to the third party. The User use of third-party
                    services or products may incur usage and/or data charges, the User is responsible for any such
                    charges. Master Cracks has no responsibility or liability for any third-party services or products.
                </p>
                <p class="terms-paragraph">
                            The User acknowledges that it is solely responsible for obtaining any access to the Internet through
                    a cellular data service provider or a wireless service provider or other Internet connectivity
                    service provider to access Master Cracks services.</p>
                <p class="terms-paragraph">
                            The User always should be sure it is fit enough to undertake any exercise routine. When
                    rehabilitating an injury, the User always should consult with their Doctor, Physical Therapist, or
                    other qualified health care practitioner before embarking on an exercise that may be detrimental to
                    its recovery. The User should NEVER assume that he can accurately duplicate the movements you see on
                    video without proper instruction from a qualified professional. It is the Users responsibility to
                    make sure he is physically fit enough to perform such movements. </p>
                <p class="terms-paragraph">
                            Video routines are meant to be a general guide and while Master Cracks try to describe the routines
                    accurately to be safe and appropriate, and if the User has some injury, disease, or other condition
                    that might make certain movements contra-indicated, it is your responsibility to avoid them. The
                    User should always consult a professional to gain maximum results from equipment and avoid injury.
                </p>
            </li>

            <li>
                <h4 class="terms-subtitle">Charges and Payment</h4>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            Billing Policies By using the Service, the User agrees to Master Cracks pricing and payment
                            terms as Master Cracks may update them from time to time. Unless otherwise specified at the
                            time of purchase, the User must pay for its Master Cracks services or products when the User
                            places its order. The User acknowledges that the amount billed may vary due to changes in
                            applicable fees, promotional offers, and changes in applicable taxes, and the User
                            authorizes Master Cracks to change its payment method for the corresponding amounts.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Subscription Plans The User agrees to make payments, and Master Cracks will automatically
                            charge the payment method associated with the Users User Account ('Payment Method'), as
                            described below for so long as its User Account remains active. Master Cracks may offer
                            plans that the User can sign up for that allow the User to use certain aspects of the
                            Service (a 'Subscription Plan'). Subscription Plans may set allotments for use of designated
                            Service aspects. Use of Service aspects over a Subscription Plan's designated allotment may
                            result in additional fees, as specified in the plan. Subscription Plans may be offered for a
                            fee or other charge for a set period of time-of-Service use (e.g., monthly, or annually) (a
                            'Subscription Period'). If the User elects to use a paid Subscription Plan, the User agrees
                            to the pricing and payment terms specified at checkout and herein, as Master Cracks may
                            update them from time to time. Master Cracks may change Subscription Plans by offering new
                            services for additional fees and adding or amending fees for existing Subscription
                            Plans/services, at any time at its sole discretion. Any change to a Subscription Plan's
                            pricing or payment terms shall become effective in the billing cycle following notice of
                            such change to the User as provided in these Terms of Service. All Subscription Plans will
                            automatically renew until canceled by the User. If the User does not want a Subscription
                            Plan to renew, the User should cancel it at least three (3) days before the end of the
                            Subscription Period. If Master Cracks terminate the User Subscription Plan, Master Cracks
                            will grant the Client a prorated refund for the remaining unused portion of their
                            Subscription Period. The User is has not entitled to a refund for any Subscription Plan that
                            the User cancels.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Payment Information: Taxes The User must provide Master Cracks with a current, valid,
                            accepted Payment Method. The User hereby authorizes Master Cracks (through our third-party
                            payment providers) to bill the fees to the User Payment Method, along with any applicable
                            taxes or additional fees due during the billing period. All information that the User
                            provides in connection with a purchase or transaction or other monetary transaction
                            interaction with Master Cracks Service must be accurate, complete, and current. The User
                            will pay any applicable taxes, if any, relating to any such purchases, transactions, or
                            other monetary transaction interactions. </p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Third-Party Payment Provider Master Cracks use Stripe as our third-party service provider for
                            payment services. By using Master Cracks Service the User agrees to be bound by Stripe's
                            Services Agreement available at Stripe. </p>
                    </li>
                </ul>
            </li>

            <li>
                <h4 class="terms-subtitle">Shipping and Returns</h4>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            Shipping All products ordered will be delivered to the shipping address the User provided. If
                            Master Cracks provides an expected shipping date, such date is an estimate and subject to
                            change. Master Cracks shall not be liable for any change in such expected shipping date. The
                            User will be notified via email if there is an issue that will delay its delivery beyond the
                            expected delivery date. The User billing and shipping addresses may be different. Master
                            Cracks will arrange for packing and shipment of the Master Cracks products to the User;
                            provided that we may charge the User if he changes your shipping address after ordering such
                            products. Scheduled shipping and delivery dates are estimates only and cannot be guaranteed.
                            Master Cracks will make commercially reasonable efforts to ship such products to the User by
                            the scheduled delivery dates, but Master Cracks are not liable, under any circumstances, for
                            any losses, damages, or penalties due to any delays in shipments or deliveries.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Returns The User may request to return products to Master Cracks at no charge for a full
                            refund of the amount you paid for such products, minus any shipping or delivery fees, at any
                            time within thirty (30) days of receipt (or any such longer period as required by applicable
                            law or offered by Master Cracks at the time of sale, the 'Return Period'). This money-back
                            guarantee does not cover any services and does not cover dissatisfaction or malfunction
                            caused by ordinary wear and tear or damage caused by improper use or accidents, subject to
                            the terms of our Limited Warranty. Damage to Master Cracks products upon receipt of delivery
                            must be notated and documented using photographs and/or videos upon delivery. The User
                            should thoroughly inspect products and report any damage within 48 hours to the delivery
                            company and Master Cracks support.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Limited Warranty Per the terms of the Limited Warranty, if a defect arises in a Master Cracks
                            product or a warranted component within the applicable Limited Warranty period, The User
                            sole and exclusive remedy is for Master Cracks to, at Master Crack's discretion to the
                            extent permitted by law, either replace or repair the defective or malfunctioning Master
                            Cracks product or component with the same or a comparable model or, if agreed by the
                            parties, Master Cracks may provide credit in such amount toward your purchase of a new
                            Master Cracks product or service. Any replacement or repaired component shall be warranted
                            for the remainder of the original Limited Warranty period or 30 days, whichever is longer,
                            or for any additional period that is required by applicable law.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Returning Your Products Master Cracks will offer at no charge packaging and shipping for the
                            User return in the event the User return products due to a defect; for all other returns,
                            the User is responsible for paying fees for all associated packing and shipping costs. </p>
                        <p class="terms-paragraph">
                            The Master Cracks product is expected to be returned in the same condition that it was
                            received. If there are missing accessories or damage to the equipment that was not noted
                            upon receipt, a fee will be associated for refurbishment and repair that will be deducted
                            from the User purchase price before refund.</p>
                        <p class="terms-paragraph">
                            To request a return or report a defect, the User should email Master Cracks customer service
                            department at hello@.... and provide its name, contact information and the serial number on
                            the Master Cracks product. Nothing in this Return Policy will reduce or otherwise affect the
                            Users statutory rights concerning the Master Cracks product. </p>
                    </li>
                </ul>
            </li>


            <li>
                <h4 class="terms-subtitle">No Professional Advice</h4>
                <p class="terms-paragraph">
                            If the Service provides any information that could be construed as professional advice (e.g., medical
                    advice), such information is for informational purposes only and should not be construed as
                    professional advice. No action should be taken based upon any information contained in the Service.
                    The User should seek independent professional advice from a person who is licensed and/or qualified
                    in the applicable area.</p>
            </li>


            <li>
                <h4 class="terms-subtitle">Privacy</h4>
                <p class="terms-paragraph">
                            Master Cracks cares about the privacy of our Users. The User understands that by using our Service
                    you consent to the collection, use, and disclosure of your personal information and aggregate and/or
                    anonymized data as outlined in our Privacy Policy available at www.mastercracks.com/privacy-policy,
                    and to have your personal information collected, used, transferred to, and processed in the United
                    States.</p>
            </li>

            <li>
                <h4 class="terms-subtitle">Security</h4>
                <p class="terms-paragraph">
                            The User should carefully read all instructions that come with the Master Cracks product, including
                    instructions that may be available online on Master Cracks website or by contacting support at
                    hello@... The User understands and agrees that he will assume all risk and liability, and Master
                    Cracks has no obligation or liability whatsoever to the User or anyone else for any or all of the
                    following activities:</p>
                <p class="terms-paragraph">
                            Master Cracks cares about the integrity and security of the Users personal information. Master
                    Caracks use physical, managerial, and technical safeguards in its sole discretion to preserve the
                    integrity and security of the Users personal information. However, Master Cracks cannot guarantee
                    that unauthorized third parties will never be able to defeat its security measures or use your
                    personal information for improper purposes. The User acknowledges that it provides their personal
                    information at his own risk.</p>
            </li>

            <li>
                <h4 class="terms-subtitle">Disclaimer of Third-Party Links and Products</h4>
                <p class="terms-paragraph">
                            The Service may contain third-party materials or links to third-party materials that are not owned or
                    controlled by Master Cracks. Master Cracks do not endorse or assume any responsibility for any such
                    third-party sites, information, materials, products, or services. If the User access a third-party
                    website or service from the Service or share your User Content on or through any third-party website
                    or service, the User do so at its own risk, and the User understands that these Terms of Service and
                    Master Crack's privacy policy do not apply to your use of such sites. The User expressly relieves
                    Master Cracks from all liability arising from its use of any third-party website, service, or
                    content, including without limitation third-party materials and user content submitted by other
                    users. Additionally, the User dealings with or participation in promotions of advertisers found on
                    the Service, including payment and delivery of goods, and any other terms (such as warranties) are
                    solely between the User and such advertisers. The User agrees that Master Cracks shall not be
                    responsible for any loss or damage of any sort relating to your dealings with such advertisers.</p>

            </li>

            <li>
                <h4 class="terms-subtitle">Indemnity</h4>
                <p class="terms-paragraph">
                            The User agrees to defend, indemnify and hold harmless Master Cracks and its subsidiaries, agents,
                    licensors, managers, and other affiliated companies, and their employees, contractors, agents,
                    officers and directors, from and against any and all claims, damages, obligations, losses,
                    liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising
                    from: (i) the User use of and access to Master Cracks Service, including any data or content
                    transmitted or received by the User; (ii) the User violation of any term of these Terms of Service,
                    including without limitation the User breach of any of the representations and warranties above;
                    (iii) the User violation of any third-party right, including without limitation any right of privacy
                    or intellectual property rights; (iv) the User violation of any applicable law, rule or regulation;
                    (v) User Data including without limitation misleading, false, or inaccurate information; (vi) the
                    User willful misconduct; or (vii) any other party's access and use of Master Cracks Service with the
                    User unique username, password or other appropriate security code; and (viii) the User misuse of any
                    Master Cracks products or services.</p>
            </li>

            <li>
                <h4 class="terms-subtitle">No Warranty</h4>
                <p class="terms-paragraph">
                            Master Cracks Service is provided on an 'as is' and 'as available' basis. The use of Master Cracks
                    service is at the Users own risk. To the maximum extent permitted by applicable law, Master Cracks
                    service is provided without warranties of any kind, whether express or implied, including, but not
                    limited to, implied warranties of merchantability, fitness for a particular purpose, or
                    non-infringement. No advice or information, whether oral or written, obtained by the User from
                    Master Cracks or through Master Cracks service will create any warranty not expressly stated herein.
                    Without limiting the foregoing, Master Cracks, our subsidiaries, our affiliates, and Master Cracks
                    licensors do not warrant that the content is accurate, reliable, or correct; that Master Cracks
                    service will meet the User requirements; that Master Cracks service will be available at any
                    particular time or location, uninterrupted or secure; that any defects or errors will be corrected;
                    or that our service is free of viruses or other harmful components. Any content downloaded or
                    otherwise obtained using Master Cracks service is downloaded at the Users own risk and the User will
                    be solely responsible for any damage to your computer system or mobile device or loss of data that
                    results from such download or the User use of Master Cracks service.</p>
                <p class="terms-paragraph">
                            Federal law, some states, provinces, and other jurisdictions do not allow the exclusion and
                    limitations of certain implied warranties, so the above exclusions may not apply to the User. These
                    terms give the User specific legal rights, and the User may also have other rights which vary from
                    state to state. The disclaimers and exclusions under these terms will not apply to the extent
                    prohibited by applicable law.</p>
            </li>

            <li>
                <h4 class="terms-subtitle">Limitation of Liability</h4>
                <p class="terms-paragraph">
                            To the maximum extent permitted by applicable law, in no event shall Master Cracks, or its
                    affiliates, agents, directors, employees, suppliers or licensors, be liable for any indirect,
                    punitive, incidental, special, consequential, or exemplary damages, including without limitation
                    damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or
                    relating to the use of, or inability to use, its Service. Under no circumstances will we be
                    responsible for any damage, loss. or injury resulting from hacking, tampering, or other unauthorized
                    access or use of its Service or the User account or the information contained therein.</p>
                <p class="terms-paragraph">
                            To the maximum extent permitted by applicable law, Master Cracks assumes no liability or
                    responsibility for any: (i) errors, mistakes, or inaccuracies of content; (ii) personal injury or
                    property damage, of any nature whatsoever, resulting from the User access to or use of Master Cracks
                    Service; (iii) any unauthorized access to or use of Master Cracks secure servers and/or all personal
                    information stored therein; (iv) any interruption or cessation of transmission to or from Master
                    Cracks Service; (v) any bugs, viruses, trojan horses, or the like that may be transmitted to or
                    through Master Cracks Service by any third party; (vi) any errors or omissions in any content or for
                    any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or
                    otherwise made available through Master Cracks Service; and/or (vii) the Users content or the
                    defamatory, offensive, or illegal conduct of any third party. In no event shall Master Cracks, or
                    its affiliates, agents, directors, employees, suppliers, or licensors, be liable to the User for any
                    claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding the
                    amount the User paid to Master Cracks hereunder or $1,000.00, whichever is greater.</p>
                <p class="terms-paragraph">
                            This limitation of liability section applies whether the alleged liability is based on contract,
                    tort, negligence, strict liability, or any other basis, even if Master Cracks has been advised of
                    the possibility of such damage.</p>
                <p class="terms-paragraph">
                            Some States do not allow the exclusion or limitation of incidental or consequential damages, so the
                    above limitations or exclusions may not apply to the User. These Terms of Service give the User
                    specific legal rights, and the User may also have other rights which vary from State to State. The
                    disclaimers, exclusions, and limitations of liability under these Terms of Service will not apply to
                    the extent prohibited by applicable law.</p>
            </li>

            <li>
                <h4 class="terms-subtitle">Governing Law, Arbitration, and Class Action/Jury Trial Waiver</h4>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            Governing Law. The User agrees that: (i) Master Cracks Service shall be deemed solely based
                            in California; and (ii) Master Cracks Service shall be deemed a passive one that does not
                            give rise to personal jurisdiction over Master Cracks, either specific or general, in
                            jurisdictions other than California. These Terms of Service shall be governed by the
                            internal substantive laws of the State of California, without respect to its conflict of
                            laws principles. The parties acknowledge that these Terms of Service evidence one or more
                            transactions involving interstate commerce. Notwithstanding the preceding sentences
                            concerning the substantive law, any arbitration conducted under these Terms of Service shall
                            be governed by the Federal Arbitration Act (9 U.S.C. §§ 1-16). The application of the United
                            Nations Convention on Contracts for the International Sale of Goods is expressly excluded.
                            The Users agrees to submit to the personal jurisdiction of the federal and state courts
                            located in Sacramento, California for any actions for which Master Cracks retain the right
                            to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent
                            the actual or threatened infringement, misappropriation or violation of Master Cracks
                            copyrights, trademarks, trade secrets, patents, or other intellectual property or
                            proprietary rights, as outlined in the Arbitration provision below, including any
                            provisional relief required to prevent irreparable harm. The User agrees that Sacramento
                            County, California is the proper forum for any appeals of an arbitration award or for trial
                            court proceedings if the arbitration provision below is found to be unenforceable. </p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Arbitration The User should read this section carefully because it requires the parties to
                            arbitrate their disputes and limits the way the User can seek relief from Master Cracks For
                            any dispute with Master Cracks, the User agrees to first contact Master Cracks at hello@...
                            and attempt to resolve the dispute with Master Cracks informally. In the unlikely event that
                            Master Cracks have not been able to resolve a dispute Master Cracks have with the User after
                            sixty (60) days, the Parties agree to resolve any claim, dispute, or controversy (excluding
                            any claims for injunctive or other equitable relief as provided below) arising out of or in
                            connection with or relating to these Terms of Service, or the breach or alleged breach
                            thereof (collectively, 'Claims'), by binding arbitration by JAMS , under the Optional
                            Expedited Arbitration Procedures then in effect for JAMS, except as provided herein. JAMS
                            may be contacted at www.jamsadr.com. The arbitration will be conducted in Sacramento County,
                            California unless the User and Master Cracks agree otherwise. If the User is using Master
                            Cracks Service for commercial purposes, each party will be responsible for paying any JAMS
                            filing, administrative and arbitrator fees under with JAMS rules, and the award rendered by
                            the arbitrator shall include costs of arbitration, reasonable attorneys' fees, and
                            reasonable costs for expert and other witnesses. If the User is an individual using Master
                            Cracks Service for non-commercial purposes: (i) JAMS may require the User to pay a fee for
                            the initiation of its case, unless the User applies for and successfully obtain a fee waiver
                            from JAMS; (ii) the award rendered by the arbitrator may include the User costs of
                            arbitration, the User reasonable attorney's fees, and the User reasonable costs for expert
                            and other witnesses; and (iii) the User may sue in a small claims court of competent
                            jurisdiction without first engaging in arbitration, but this does not absolve the User of
                            its commitment to engage in the informal dispute resolution process. Any judgment on the
                            award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                            Nothing in this section shall be deemed as preventing Master Cracks from seeking injunctive
                            or other equitable relief from the courts as necessary to prevent the actual or threatened
                            infringement, misappropriation, or violation of Master Cracks data security, intellectual
                            property rights, or other proprietary rights.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Class Action/Jury Trial Waiver Concerning all persons and entities, regardless of whether
                            they have obtained or used Master Cracks Service for personal, commercial, or other
                            purposes, all claims must be brought in the parties' capacity, and not as a plaintiff or
                            class member in any purported class action, collective action, private attorney general
                            action or other representative proceedings. This waiver applies to class arbitration, and,
                            unless Master Cracks agree otherwise, the arbitrator may not consolidate more than one
                            person's claims. The User agrees that, by entering into these Terms of Service, the User and
                            Master Cracks are each waiving the right to a trial by jury or to participate in a class
                            action, collective action, private attorney general action, or other representative
                            proceedings of any kind.</p>
                    </li>
                </ul>
            </li>

            <li>
                <h4 class="terms-subtitle">General</h4>
                <ul>
                    <li>
                        <p class="terms-paragraph">
                            Assignment These Terms of Use, and any rights and licenses granted hereunder, may not be
                            transferred, or assigned by the User without Master Cracks prior express written consent,
                            but may be assigned by Master Cracks without restriction. Any attempted transfer or
                            assignment in violation hereof shall be null and void.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Notification Procedures and Changes to the Terms of Service Master Cracks may provide
                            notifications, whether such notifications are required by law or are for marketing or other
                            business-related purposes, to the User via email notice, written or hard copy notice, or
                            through posting of such notice on Master Cracks website, as Master Cracks determine in its
                            sole discretion. Master Cracks reserves the right to determine the form and means of
                            providing notifications to our Users, provided that the Users may opt-out of certain
                            notifications as required under applicable laws or as described in these Terms of Service or
                            Master Cracks Privacy Policy. Master Cracks is not responsible for any automatic filtering
                            the User or its network provider may apply to email notifications Master Cracks send to the
                            email address the User provides Master Cracks. Master Cracks may, in our sole discretion,
                            modify or update these Terms of Service from time to time, and so the Client should review
                            this page periodically. The Client continued use of our Service after any such change
                            constitutes the User acceptance of the new Terms of Service. If the User does not agree to
                            any part of these Terms of Service or any future Terms Use of Service, it should not use or
                            access (or continue to access) Master Cracks Service.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Entire Agreement/Severability These Terms of Service, together with any amendments and any
                            additional agreements the User may enter into with Master Cracks in connection with Master
                            Cracks Service, shall constitute the entire agreement between the User and Master Cracks
                            concerning our Service. None of Master Cracks employees or representatives are authorized to
                            make any modification or addition to these Terms of Service. Any statements or comments made
                            between the User and any of Master Cracks employees or representatives are expressly
                            excluded from these Terms of Service and will not apply to the User or Master Cracks or the
                            User use of Master Cracks Service. If any provision of these Terms of Service are deemed
                            invalid by a court of competent jurisdiction, the invalidity of such provision shall not
                            affect the validity of the remaining provisions of these Terms of Service, which shall
                            remain in full force and effect.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            No Waiver No waiver of any term of these Terms of Service shall be deemed a further or
                            continuing waiver of such term or any other term, and our failure to assert any right or
                            provision under these Terms of Service shall not constitute a waiver of such right or
                            provision.</p>
                    </li>
                    <li>
                        <p class="terms-paragraph">
                            Contact Please contact us at {{EMAIL}} with any questions regarding these Terms of Service.
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ul>
</p>
`;
