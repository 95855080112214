import { PathCreate } from "@platform/react-storybook/Component/Router/utils";

import { PathGetCategory } from "./category";
import { PathGetCrack } from "./crack";
import { PathGetCustomLanding } from "./landing";
import { SEGMENT_ORIGIN } from "./_common";
import type { SegmentOrigin } from "./_common";

export function PathGetHome<R extends string, O extends SegmentOrigin>(...args: [R, O]) {
    const [ROOT, ORIGIN] = args;
    return {
        ROOT,

        get HOME() {
            const HOME_ROOT = ORIGIN === SEGMENT_ORIGIN.WWW ? ROOT : PathCreate(this.ROOT, "home");
            const { CATEGORY } = PathGetCategory(HOME_ROOT);
            const { CRACK } = PathGetCrack(HOME_ROOT, ORIGIN);
            const { CUSTOM_LANDING } = PathGetCustomLanding(HOME_ROOT);
            const NEW_LANDING = PathCreate(this.ROOT, "new");

            return {
                ROOT: HOME_ROOT,
                CATEGORY,
                CRACK,
                CUSTOM_LANDING,
                NEW_LANDING,
            };
        },
    };
}
