import React from "@platform/react";
import { Component } from "@platform/react-storybook/Component";
import { IconAsset } from "@front/components/Element/Asset/Icon";

import { Button } from "@front/components/Element/Button";
import { Text } from "@front/components/Element/Text";

import "./_rules.module.scss";
import {
    DEF_SECTION_ERROR_INTERNAL,
    NAME_SECTION_ERROR_INTERNAL,
    TYPE_SECTION_ERROR_INTERNAL,
} from "./types";
import type { PropSectionErrorInternal } from "./types";

const TIME_UNIT = 1000;
const TIME_INI = 5;
const TIME_END = 0;

export function SectionErrorInternal(props: PropSectionErrorInternal) {
    const refInterval = React.useRef<NodeJS.Timer>();
    const [time, setTime] = React.useState<number>(TIME_INI);

    React.useEffect(() => {
        refInterval.current = setInterval(() => {
            // eslint-disable-next-line no-param-reassign
            setTime((t) => --t);
        }, TIME_UNIT);
        return handleUnmount;

        function handleUnmount() {
            if (!refInterval.current) return;
            clearInterval(refInterval.current);
        }
    }, []);

    React.useEffect(() => {
        if (time !== TIME_END) return;
        if (refInterval.current) clearInterval(refInterval.current);
        window.location.replace("/");
    }, [time]);
    return (
        <Component {...props} name={NAME_SECTION_ERROR_INTERNAL} type={TYPE_SECTION_ERROR_INTERNAL}>
            <IconAsset fill="paraguay" uuid="error-alert" />
            <header>
                <Text preset="campeche" uuid="error-500-title" />
            </header>
            <section>
                <Text preset="lanus" uuid="error-500-desc" />
            </section>

            {time}
            <div>
                <Button preset="morocco" text="button-continue" onClick={handleContinue} />
            </div>
        </Component>
    );

    function handleContinue() {
        window.location.replace("/");
    }
}
SectionErrorInternal.defaultProps = DEF_SECTION_ERROR_INTERNAL;
