import { PREFIX_BOX_THEME } from "@common/content/theme/box";

export const PREFIX_BORDER_BOX_THEME = `${PREFIX_BOX_THEME}_border` as const;

export const UUID_BORDER_BOX_THEME = [
    "none",
    "affogatto",
    "latte",
    "ristretto",
    "capuccino",
    "mocha",
    "espresso",
] as const;

export type UuidBorderBoxTheme = typeof UUID_BORDER_BOX_THEME[number];
