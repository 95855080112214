import { PathCreate } from "@platform/react-storybook/Component/Router/utils";
import { PathGetSupport } from "./_segments/support";
import { PathGetSignUp } from "./_segments/signup";

export function PathGetCommon<R extends string>(ROOT: R) {
    const { SUPPORT } = PathGetSupport(ROOT);
    const { SIGNUP } = PathGetSignUp(ROOT);
    return {
        ROOT,

        get COMMON() {
            return {
                SUPPORT,
                SIGNUP,
                FAQS: PathCreate(ROOT, "faqs"),
            };
        },
    };
}
