import { DATA_TEXT } from "@common/content/text";
import { ISO_639_1 } from "@common/content/language/ISO_639_1";
import { DATA_LANGUAGE } from "@common/content/language";
import type { Iso6391 } from "@common/content/language/ISO_639_1";
import type { UuidLanguage } from "@common/content/language";

export const languages = DATA_LANGUAGE.reduce(
    (acc, key) => ({ ...acc, [key]: ISO_639_1[key] }),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    {} as { readonly [K in UuidLanguage]: Iso6391[K] },
);

export async function getter() {
    return Promise.resolve(DATA_TEXT);
}
