import type { UuidIconAsset } from "@common/content/asset/icon";
import type { UuidCustomLanding } from "@common/content/landing/uuid";

export const NAME_SLICE_SESSION = "session";

export const USER_STATUS = {
    INACTIVE: 0,
    PENDING: 2,
    ACTIVE: 3,
    DELETE: 4,
} as const;

export const PROVIDERS = {
    OTHER: "other",
    OPENPAY: "openpay",
    INAPP: "inApp",
    UNDEFINED: undefined,
} as const;

export declare namespace NSSliceSession {
    /** Determines the user id */
    export type idUser = string;
    /** Determines if the uses is logged in, onlu for user-app */
    export type isLoggedIn = boolean;
    /** Stores the last location that a user trying to login consumed */
    export type lastLocation = string;
    /** Determines the user email */
    export type email = string;
    /** Determines the user name */
    export type firstName = string;
    /** Determines the user last name */
    export type lastName = string;
    /** Determines the user status */
    export type status = typeof USER_STATUS[keyof typeof USER_STATUS];
    /** Determines if the user was invited */
    export type invitation = string;
    /** Determines if the user has created a password */
    export type hasCreatedPassword = boolean;
    /** Whether the user has answered their preferences on enrollment */
    export type hasSecretPreferences = boolean;
    /** Determines if the user has send his birthday date */
    export type hasBirthday = null | boolean;
    /** Determines if the user has added their phone number */
    export type hasAddedPhoneNumber = boolean;
    /** Determines the provider used to pay the subscription */
    export type provider = typeof PROVIDERS[keyof typeof PROVIDERS];
    /** Expiration date of the subscription */
    export type expiryDate = Date;
    /** Determines if the user needs to complete an additional survey */
    export type isComingFromBusiness = boolean;
    /** Determines if the user needs to complete an additional survey */
    export type isBusinessSurveyCompleted = boolean;
    /** Specifies which lading the user comes from */
    export type origin = UuidCustomLanding;
    /** Determines if the user needs to complete an additional survey */
    export type isBusinessSurveyStarted = boolean;
    /** Determines if the user needs to complete an additional survey */
    export type businessSurveyCurrentIndex = number;
    /** Determines if the user needs to complete an additional survey */
    export type businessSurveyAnswers = { [id: string]: number };

    export type HasSubscription = boolean;

    /** Determines the user avatar */
    export type avatar = Extract<UuidIconAsset, `avatar-${string}`>;

    export type State = {
        id: idUser;
        isFirstTime: boolean;
        isLoggedIn: isLoggedIn;
        lastLocation?: lastLocation;
        email: email;
        firstName: firstName;
        lastName: lastName;
        status: status;
        invitation: invitation;
        avatar?: avatar;
        hasCreatedPassword: hasCreatedPassword;
        hasBirthday: hasBirthday;
        hasSecretPreferences: hasSecretPreferences;
        day: string;
        month: string;
        year: string;
        hasAddedPhoneNumber: hasAddedPhoneNumber;
        hasSubscription: HasSubscription;
        membership: "NONE" | "TRIAL" | "PREMIUM" | "TRIAL_EXPIRED";
        dateTrial?: string;
        isFinalStep: boolean;
        provider: provider;
        expiryDate?: expiryDate;
        isFirstTimeOpenApp: boolean;
        isComingFromBusiness: isComingFromBusiness;
        isBusinessSurveyCompleted: isBusinessSurveyCompleted;
        origin?: origin;
        isBusinessSurveyStarted: isBusinessSurveyStarted;
        businessSurveyCurrentIndex: businessSurveyCurrentIndex;
        businessSurveyAnswers: businessSurveyAnswers;
        transactionID?: string;
        shouldReloadSubscription: boolean;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_SESSION: NSSliceSession.State = {
    id: "",
    isLoggedIn: false,
    lastLocation: "",
    email: "",
    firstName: "",
    lastName: "",
    status: USER_STATUS.INACTIVE,
    invitation: "",
    avatar: undefined,
    hasCreatedPassword: false,
    hasBirthday: false,
    day: "",
    month: "",
    year: "",
    hasAddedPhoneNumber: false,
    hasSubscription: false,
    hasSecretPreferences: false,
    isFirstTime: false,
    membership: "NONE",
    isFinalStep: false,
    provider: PROVIDERS.INAPP,
    expiryDate: undefined,
    isFirstTimeOpenApp: true,
    isComingFromBusiness: false,
    isBusinessSurveyCompleted: false,
    isBusinessSurveyStarted: false,
    businessSurveyCurrentIndex: 0,
    businessSurveyAnswers: {},
    shouldReloadSubscription: true,
};
