export const UUID_CUSTOM_LANDING_CRACKS = [
    "generic",
    "rafael-marquez",
    "pavel-pardo",
    "jared-borgetti",
    "jo-canales",
    "oswaldo-sanchez",
    "norma-palafox",
    "charlyn-corral",
    "carlos-salcido",
    "buffon-academy",
    "h-gonzales",
] as const;

export const UUID_CUSTOM_LANDING_BUSINESS = [
    "steren",
    "academias-por-la-paz",
    "iztacalco",
    "mastercracks",
] as const;

export const UUID_PREMIUM_B2B = [
    "steren",
    "academias-por-la-paz",
    "anforama",
    "friedman",
    "cancun-calcio",
    "mastercracks",
    "iztacalco",
] as const;

export const UUID_CUSTOM_LANDING = [
    ...UUID_CUSTOM_LANDING_CRACKS,
    ...UUID_CUSTOM_LANDING_BUSINESS,
] as const;

export type UuidDefaultLanding = typeof UUID_CUSTOM_LANDING_CRACKS[number];
export type UuidCustomLanding = typeof UUID_CUSTOM_LANDING[number];
export type UuidBusinessCustomLanding = typeof UUID_CUSTOM_LANDING_BUSINESS[number];
export type UuidPremiumB2B = typeof UUID_PREMIUM_B2B[number];

export function getExpCustomLanding() {
    return UUID_CUSTOM_LANDING.join(" | " as const);
}

export function isDefaultLanding(x?: string): x is UuidDefaultLanding {
    if (!x) return true;
    if (typeof x !== "string") return true;
    return UUID_CUSTOM_LANDING_CRACKS.includes(x.toLocaleLowerCase() as UuidDefaultLanding);
}

export function isBusinessLanding(x?: string): x is UuidBusinessCustomLanding {
    if (!x) return false;
    if (typeof x !== "string") return false;
    return UUID_CUSTOM_LANDING_BUSINESS.includes(
        x.toLocaleLowerCase() as UuidBusinessCustomLanding,
    );
}

export function isPremiumB2B(x?: string): x is UuidPremiumB2B {
    if (!x) return false;
    if (typeof x !== "string") return false;
    return UUID_PREMIUM_B2B.includes(x.toLocaleLowerCase() as UuidPremiumB2B);
}
