export const GROUP_BITMAP_ASSET = "bitmaps" as const;

export type GroupBitmapAsset = typeof GROUP_BITMAP_ASSET;

export const EXT_BITMAP_ASSET = ".png" as const;

export type ExtBitmapAsset = typeof EXT_BITMAP_ASSET;

export const PREFIX_BITMAP_ASSET = "bmp" as const;

export type PrefixBitmapAsset = typeof PREFIX_BITMAP_ASSET;

export const UUID_BITMAP_ASSET = [
    "blog-5-pasos-para-quitarle-poder-a-los-pensamientos-intrusivos",
    "blog-4-candidates-1-cup-and-a-lot-to-learn",
    "blog-has-you-gone-from-facing-highly-challenging-situations",
    "blog-we-are-all-the-selection",
    "blog-cristiano-ronaldo-the-master-crack-par-excellence",
    "blog-men-and-the-taboo-of-mental-health-at-work",
    "blog-year-end-resolutions-between-wish-and-reality",
    "blog-why-so-many-mental-illnesses-come-together",
    "blog-the-7-abilities-of-the-world-champions",
    "blog-well-being-in-your-hands-your-life-will-be-easier-if-you-practice-this-daily",
    "blog-how-to-be-a-good-leader-learn-from-the-great-stars",
    "blog-4-tips-to-control-anxiety",
    "blog-sindrome-del-burnout-cuando-el-estres-laboral-se-vuelve-fatiga",
    "blog-tips-to-get-started-in-the-world-of-mindfulness",
    "blog-5-tips-to-develop-a-winning-mindset",
    "blog-4-actions-to-combat-procrastination",
    "blog-gigi-buffon-joins-the-mastercracks-family",
    "blog-cut-with-toxic-routines-and-stop-living-automatically",
    "blog-how-to-start-meditating-tips-to-incorporate-meditation-into-your-life",
    "blog-the-most-common-mental-disorders-among-footballers",
    "blog-6-benefits-of-exercise-that-will-change-your-life",
    "blog-how-the-mind-of-cracks-works",
    "blog-the-value-of-yes-discover-the-power-of-positive-thoughts",
    "blog-what-is-coaching",
    "blog-como-lograr-resultados-entrenando-en-casa",
    "blog-que-es-el-insomnio-y-cómo-puedes-enfrentarlo",
    "blog-charlyn-corral-campeona-con-la-selección-femenil",
    "blog-los-secretos-de-jimmy-lozano",
    "blog-que-es-y-para-qué-sirve-masterCracks",
    "blog-el-verdadero-sentido-del-crecimiento-personal",
    "blog-10-habitos-del-liderazgo-en-una-empresa",
    "blog-rasgos-de-un-mal-líder-y-como-mejorarlos",
    "blog-las-cualidades-de-un-lider-y-como-desarrollarlas",
    "blog-la-reaccion-quimica-del-cerebro-ante-los-insultos-y-como-contrarrestar",
    "blog-rafael-marquez-uno-de-los-mejores-futbolistas-mexicanos-de-todos-los-tiempos",
    "blog-los-beneficios-de-tender-la-cama-por-las-mananas",
    "blog-cuales-son-los-beneficios-de-un-programa-contra-el-estres",
    "blog-como-dormir-bien-para-mejorar-tu-rendimiento",
    "blog-como-aprender-a-ser-lider-si-eres-emprendedor-y-ya-tienes-empleados",
    "blog-como-el-dormir-bien-afecta-de-manera-positiv-el-desempeno-de-un-atlet-de-alto-rendimiento",
    "blog-el-mal-descanso-y-sus-consecuencias-para-la-salud",
    "blog-truco-para-hacer-tus-propositos-de-ano-nuevo",
    "blog-que-es-un-proposito-smart",
    "blog-visualizacion-y-propositos-de-ano-nuevo",
    "blog-como-saber-si-mi-proposito-de-ano-nuevo-es-realista",
    "blog-cuales-son-los-propositos-de-ano-nuevo-mas-comunes-y-mas-sencillos",
    "blog-propositos-de-ano-nuevo-para-un-nino",
    "blog-5-propositos-de-ano-nuevo-para-un-estudiante",
    "blog-como-generar-cambios-que-quiero-hacer-en-mi-vida-este-2024",
    "blog-como-un-evento-deportivo-puede-inspirarte-a-mejorar-física-y-mentalmente",
    "blog-cuantas-personas-cumplen-sus-propositos-de-ano-nuevo",
    "blog-cuantas-personas-siguen-con-sus-propositos-de-ano-nuevo-iniciando-febrero",
    "blog-cuanto-tiempo-es-lo-minimo-que-tengo-que-dormir-para-tener-una-vida-sana",
    "blog-5-buenas-comidas-para-despues-del-entrenamiento-fisico",
    "blog-5-consejos-para-continuar-con-tus-propositos-de-ano-nuevo-en-febrero",
    "blog-los-enemigos-del-buen-dormir-los-problemas-mas-comunes-del-sueno",
    "blog-placeholder",
    "blog-placeholder-1",
    "blog-placeholder-2",
    "blog-placeholder-3",
    "blog-placeholder-4",
    "blog-placeholder-5",
    "blog-placeholder-6",
    "blog-placeholder-7",
    "blog-placeholder-8",
    "blog-placeholder-9",
    "blog-placeholder-10",
    "blog-placeholder-11",
    "blog-placeholder-12",
    "blog-placeholder-13",
    "blog-placeholder-14",
    "blog-placeholder-15",
    "blog-placeholder-16",
    "blog-placeholder-17",
    "blog-placeholder-18",
    "blog-placeholder-19",
    "blog-placeholder-20",
    "blog-placeholder-21",
    "blog-placeholder-22",
    "blog-placeholder-23",
    "blog-placeholder-24",
    "blog-placeholder-25",
    "blog-placeholder-26",
    "blog-placeholder-27",
    "blog-placeholder-28",
    "blog-placeholder-29",
    "blog-placeholder-30",
    "blog-test",
    "coming-soon-bg",
    "coming-soon-fg",
    "crack-a",
    "crack-b",
    "category-stories",
    "category-skills",
    "category-mindset",
    "category-fitness",
    "crack-carlos-salcido",
    "crack-charlyn-corral",
    "crack-gianluigi-buffon",
    "crack-jared-borgetti",
    "crack-jo-canales",
    "crack-norma-palafox",
    "crack-oswaldo-sanchez",
    "crack-pavel-pardo",
    "crack-rafa-marquez",
    "cube",
    "cube-carlos-salcido",
    "cube-jo-canales",
    "cube-pavel-pardo",
    "cube-rafa-marquez",
    "download-apple",
    "download-google",
    "download-apple-en-us",
    "download-apple-es-mx",
    "download-google-en-us",
    "download-google-es-mx",
    "download-android-01",
    "download-android-02",
    "download-android-03",
    "download-android-04",
    "download-android-05",
    "download-android-06",
    "download-android-07",
    "download-android-08",
    "download-android-09",
    "download-android-10",
    "download-android-11",
    "home-header",
    "portrait-a",
    "portrait-b",
    "payment-method-card",
    "payment-method-cash",
    "payment-method-visa",
    "payment-method-mastercard",
    "payment-stores",
    "landscape-a",
    "landscape-b",
    "landscape-c",
    "landscape-d",
    "landscape-e",
    "landscape-f",
    "landscape-g",
    "medal-a",
    "medal-b",
    "medal-c",
    "medal-d",
    "medal-e",
    "medal-f",
    "player-skills",
    "player-fitness",
    "player-stories",
    "player-mindset",
    "player-programs",
    "player-challenges",
    "pt-welcome-1",
    "pt-welcome-2",
    "pt-welcome-3",
    "pt-welcome-4",
    "pt-welcome-5",
    "secret-dream-thumb",
    "secret-team-thumb",
    "secret-habits-thumb",
    "secret-resignification-failure-thumb",
    "secret-leadership-thumb",
    "secret-financial-health-thumb",
    "secret-movement-thumb",
    "secret-purpose-thumb",
    "secret-visualization-thumb",
    "secret-physical-mental-training-thumb",
    "secret-nutrition-thumb",
    "tip-answers-beginning-final",
    "tip-best-coach-final",
    "tip-caffeine-use-final",
    "tip-athletes-dream-final",
    "tip-dont-stop-learning-final",
    "tip-effect-electronic-screens-final",
    "tip-effects-jetlag-final",
    "tip-finish-what-you-do-final",
    "tip-get-out-bed-final",
    "tip-know-your-chronotype-final",
    "tip-sleep-environment-final",
    "tip-strengths-weaknesses-final",
    "tip-success-end-final",
    "tip-team-but-up-to-you-final",
    "tip-well-done-nap-final",
    "tip-whom-they-come-final",
    "tip-yes-value-final",
    "secret-yes-value-check",
    "secret-yes-value-cta",
    "secret-yes-value-hacks",
    "secret-yes-value-quote",
    "secret-yes-value-slide",
    "secret-yes-value-slide-finish",
    "secret-yes-value-text",
    "secret-yes-value-thumb",
    "tip-answer-beginning-thumb",
    "tip-best-coach-thumb",
    "tip-chronotype-thumb",
    "tip-tip-effects-jetlag-thumb",
    "tip-finish-do-thumb",
    "tip-get-out-bed-thumb",
    "tip-optimize-caffeine-thumb",
    "tip-relationship-sleep-thumb",
    "tip-success-isnt-end-thumb",
    "tip-value-nap-thumb",
    "tip-yes-value-thumb",
    "tip-caffeine-use-hacks",
    "tip-caffeine-use-quote-1",
    "tip-caffeine-use-quote-2",
    "tip-caffeine-use-quote-3",
    "tip-caffeine-use-slide",
    "tip-caffeine-use-step-1",
    "tip-caffeine-use-step-2",
    "tip-caffeine-use-thumb",
    "tip-know-your-chronotype-check-1",
    "tip-know-your-chronotype-check-2",
    "tip-know-your-chronotype-hacks",
    "tip-know-your-chronotype-intro",
    "tip-know-your-chronotype-quote-1",
    "tip-know-your-chronotype-quote-2",
    "tip-know-your-chronotype-quote-3",
    "tip-know-your-chronotype-quote-4",
    "tip-know-your-chronotype-quote-5",
    "tip-know-your-chronotype-quote-6",
    "tip-know-your-chronotype-quote-7",
    "tip-know-your-chronotype-slide-1",
    "tip-know-your-chronotype-slide-2",
    "tip-know-your-chronotype-thumb",
    "tip-effect-electronic-screens-dyk",
    "tip-effect-electronic-screens-hacks",
    "tip-effect-electronic-screens-quote-1",
    "tip-effect-electronic-screens-quote-2",
    "tip-effect-electronic-screens-quote-3",
    "tip-effect-electronic-screens-quote-4",
    "tip-effect-electronic-screens-step-1",
    "tip-effect-electronic-screens-step-2",
    "tip-effect-electronic-screens-step-3",
    "tip-effect-electronic-screens-text",
    "tip-success-end-check",
    "tip-success-end-text",
    "tip-success-end-thumb",
    "tip-best-coach-hacks",
    "tip-best-coach-text",
    "tip-best-coach-thumb",
    "tip-best-coach-quote",
    "tip-athletes-dream-check",
    "tip-athletes-dream-dyk",
    "tip-athletes-dream-hacks",
    "tip-athletes-dream-quote-1",
    "tip-athletes-dream-quote-2",
    "tip-athletes-dream-step-1",
    "tip-athletes-dream-step-2",
    "tip-athletes-dream-thumb",
    "tip-well-done-nap-check-1",
    "tip-well-done-nap-check-2",
    "tip-well-done-nap-quote-1",
    "tip-well-done-nap-quote-2",
    "tip-well-done-nap-thumb",
    "tip-team-but-up-to-you-quote-1",
    "tip-team-but-up-to-you-quote-2",
    "tip-team-but-up-to-you-quote-3",
    "tip-team-but-up-to-you-text-1",
    "tip-team-but-up-to-you-text-2",
    "tip-team-but-up-to-you-text-3",
    "tip-team-but-up-to-you-thumb",
    "tip-sleep-environment-hacks",
    "tip-sleep-environment-quote-1",
    "tip-sleep-environment-quote-5",
    "tip-sleep-environment-thumb",
    "tip-strengths-weaknesses-dyk",
    "tip-strengths-weaknesses-hacks",
    "tip-strengths-weaknesses-quote",
    "tip-strengths-weaknesses-text",
    "tip-strengths-weaknesses-thumb",
    "tip-effects-jetlag-check",
    "tip-effects-jetlag-intro",
    "tip-effects-jetlag-slide",
    "tip-effects-jetlag-thumb",
    "tip-answers-beginning-text",
    "tip-answers-beginning-thumb",
    "tip-whom-they-come-quote-1",
    "tip-whom-they-come-text-1",
    "tip-whom-they-come-text-2",
    "tip-whom-they-come-thumb",
    "tip-get-out-bed-check",
    "tip-get-out-bed-quote",
    "tip-get-out-bed-text",
    "tip-get-out-bed-thumb",
    "tip-effect-electronic-screens-thumb",
    "tip-dont-stop-learning-hacks",
    "tip-dont-stop-learning-thumb",
    "tip-finish-what-you-do-check-1",
    "tip-finish-what-you-do-check-2",
    "tip-finish-what-you-do-check-3",
    "tip-finish-what-you-do-check-4",
    "tip-finish-what-you-do-check-5",
    "tip-finish-what-you-do-check-6",
    "tip-finish-what-you-do-quote-1",
    "tip-finish-what-you-do-quote-3",
    "tip-finish-what-you-do-quote-4",
    "tip-finish-what-you-do-quote-5",
    "tip-finish-what-you-do-quote-6",
    "tip-finish-what-you-do-quote-7",
    "tip-dont-stop-learning-quote-1",
    "tip-finish-what-you-do-quote-8",
    "tip-finish-what-you-do-quote-9",
    "tip-finish-what-you-do-quote-10",
    "tip-finish-what-you-do-quote-11",
    "tip-finish-what-you-do-thumb",
    "tip-finish-what-you-do-quote-2",
    "tip-idols-referents-02",
    "tip-idols-referents-05",
    "tip-idols-referents-06",
    "tip-idols-referents-07",
    "tip-idols-referents-08",
    "tip-idols-referents-thumb",
    "tip-relationship-sleep-03",
    "tip-relationship-sleep-04",
    "tip-relationship-sleep-05",
    "tip-relationship-sleep-06",
    "tip-relationship-sleep-07",
    "tip-relationship-sleep-08",
    "tip-sleep-diary-02",
    "tip-sleep-diary-03",
    "tip-sleep-diary-04",
    "tip-sleep-diary-thumb",
    "tip-win-in-your-mind-03",
    "tip-win-in-your-mind-05",
    "tip-win-in-your-mind-06",
    "tip-win-in-your-mind-07",
    "tip-win-in-your-mind-thumb",
    "tip-make-your-team-your-banner-thumb",
    "tip-make-your-team-your-banner-06",
    "tip-make-your-team-your-banner-07",
    "tip-make-your-team-your-banner-08",
    "tip-make-your-team-your-banner-09",
    "tip-make-your-team-your-banner-10",
    "tip-savings-thumb",
    "tip-diversification-of-income-sources-thumb",
    "tip-financial-education-thumb",
    "tip-investments-thumb",
    "tip-long-term-planning-thumb",
    "tip-budget-thumb",
    "tip-processed-foods-thumb",
    "tip-carbohydrates-thumb",
    "tip-healthy-fats-thumb",
    "tip-hydration-thumb",
    "tip-omega-3-thumb",
    "tip-proteins-thumb",
    "visualization-change-your-habits-and-transform-your-life-thumb",
    "visualization-embrace-your-strengths-thumb",
    "visualization-energizing-visualization-thumb",
    "visualization-how-to-give-the-best-to-your-team-thumb",
    "visualization-learn-to-handle-criticism-thumb",
    "visualization-make-your-mind-your-best-ally-thumb",
    "visualization-reprogram-yourself-to-grow-thumb",
    "visualization-the-pleasure-of-finishing-what-you-do-thumb",
    "visualization-visualize-it-first-in-your-mind-thumb",
    "visualization-your-idol-sleeps-in-your-bed-thumb",
    "program-share",
    "program-coming-soon-1",
    "program-coming-soon-2",
    "program-coming-soon-3",
    "program-coming-soon-4",
    "jo-canales-fitness-pre-game-activation",
    "jo-canales-fitness-cool-down",
    "jo-canales-fitness-warm-up",
    "jo-canales-fitness-program-full-body-cover",
    "jo-canales-fitness-program-full-body-share",
    "jo-canales-fitness-program-full-body-thumb",
    "jo-canales-fitness-program-full-body-achieve",
    "jo-canales-fitness-program-full-body-home-cover",
    "jo-canales-fitness-program-full-body-home-share",
    "jo-canales-fitness-program-full-body-home-thumb",
    "jo-canales-fitness-program-full-body-home-achieve",
    "jo-canales-fitness-program-master-core-cover",
    "jo-canales-fitness-program-master-core-share",
    "jo-canales-fitness-program-master-core-thumb",
    "jo-canales-fitness-program-master-core-achieve",
    "jo-canales-fitness-program-new-years-resolution-cover",
    "jo-canales-fitness-program-new-years-resolution-share",
    "jo-canales-fitness-program-new-years-resolution-thumb",
    "jo-canales-fitness-program-new-years-resolution-achieve",
    "jo-canales-fitness-program-power-legs-cover",
    "jo-canales-fitness-program-power-legs-share",
    "jo-canales-fitness-program-power-legs-thumb",
    "jo-canales-fitness-program-power-legs-achieve",
    "jo-canales-fitness-program-pre-season-cover",
    "jo-canales-fitness-program-pre-season-share",
    "jo-canales-fitness-program-pre-season-thumb",
    "jo-canales-fitness-program-pre-season-achieve",
    "jo-canales-fitness-program-road-qatar-cover",
    "jo-canales-fitness-program-road-qatar-share",
    "jo-canales-fitness-program-road-qatar-thumb",
    "jo-canales-fitness-program-road-qatar-achieve",
    "jo-canales-fitness-program-soccer-skills-cover",
    "jo-canales-fitness-program-soccer-skills-share",
    "jo-canales-fitness-program-soccer-skills-thumb",
    "jo-canales-fitness-program-soccer-skills-achieve",
    "jo-canales-fitness-program-workout-express-cover",
    "jo-canales-fitness-program-workout-express-share",
    "jo-canales-fitness-program-workout-express-thumb",
    "jo-canales-fitness-program-workout-express-achieve",
    "jo-canales-fitness-3x3-shuttle-run",
    "jo-canales-fitness-ball-taps",
    "jo-canales-fitness-ball-twist",
    "jo-canales-fitness-banded-pulls",
    "jo-canales-fitness-bicep-curls-db",
    "jo-canales-fitness-bicep-curls-object",
    "jo-canales-fitness-bicycle-abs",
    "jo-canales-fitness-box-dips",
    "jo-canales-fitness-broad-jump",
    "jo-canales-fitness-burpees",
    "jo-canales-fitness-c-ups",
    "jo-canales-fitness-deadlift",
    "jo-canales-fitness-floor-bench-db",
    "jo-canales-fitness-floor-press-object",
    "jo-canales-fitness-flutter-kicks",
    "jo-canales-fitness-glute-bridge",
    "jo-canales-fitness-good-mornings",
    "jo-canales-fitness-half-burpees",
    "jo-canales-fitness-high-knees-displacement",
    "jo-canales-fitness-high-knees",
    "jo-canales-fitness-high-pulls-object",
    "jo-canales-fitness-inchworm-push-up",
    "jo-canales-fitness-inchworm",
    "jo-canales-fitness-in-out",
    "jo-canales-fitness-jumping-jacks",
    "jo-canales-fitness-jumping-lunges",
    "jo-canales-fitness-jump-squat",
    "jo-canales-fitness-lateral-jumps",
    "jo-canales-fitness-lunges-db",
    "jo-canales-fitness-lunges-object",
    "jo-canales-fitness-lunges",
    "jo-canales-fitness-mountain-climbers",
    "jo-canales-fitness-pendlay-row-db",
    "jo-canales-fitness-pendlay-row-object",
    "jo-canales-fitness-plank-dynamic",
    "jo-canales-fitness-plank-jacks",
    "jo-canales-fitness-plank-knee-elbow",
    "jo-canales-fitness-plank",
    "jo-canales-fitness-plank-toe-touches",
    "jo-canales-fitness-plank-tricep-extension",
    "jo-canales-fitness-pull-ups",
    "jo-canales-fitness-push-ups",
    "jo-canales-fitness-run",
    "jo-canales-fitness-russian-twist",
    "jo-canales-fitness-scissors",
    "jo-canales-fitness-shoulder-db",
    "jo-canales-fitness-shoulder-object",
    "jo-canales-fitness-shoulder-taps",
    "jo-canales-fitness-single-leg-bridge",
    "jo-canales-fitness-single-leg-deadlift",
    "jo-canales-fitness-single-unders",
    "jo-canales-fitness-sit-crunches",
    "jo-canales-fitness-split-squat",
    "jo-canales-fitness-squats-db",
    "jo-canales-fitness-squats-object",
    "jo-canales-fitness-squats",
    "jo-canales-fitness-step-ups",
    "jo-canales-fitness-superman",
    "jo-canales-fitness-trx-rows",
    "jo-canales-fitness-uneven-push-ups",
    "jo-canales-fitness-walking-lunges",
    "jo-canales-fitness-wall-sit",
    "jo-canales-skills-displacement",
    "jo-canales-skills-pull-ups-instep",
    "jo-canales-skills-pull-ups-thigh",
    "jo-canales-skills-control-insteps-thighs",
    "jo-canales-skills-driving-lateral",
    "jo-canales-skills-driving-free",
    "jo-canales-skills-control-targeted",
    "jo-canales-skills-roulette-zidane",
    "jo-canales-skills-pass",
    "jo-canales-skills-pass-alternate",
    "jo-canales-skills-reception-chest",
    "jo-canales-skills-shot-outside-box",
    "jo-canales-skills-volley-hitting",
    "jo-canales-skills-pull-ups-inner",
    "jo-canales-skills-pull-ups-inner-instep",
    "jo-canales-skills-zig-zag",
    "pavel-pardo-mindset-1-visualization",
    "pavel-pardo-mindset-2-inner-speech",
    "pavel-pardo-mindset-3-self-motivation",
    "pavel-pardo-mindset-4-mental-growth",
    "pavel-pardo-skills-bike",
    "pavel-pardo-skills-outside-shot",
    "pavel-pardo-skills-pass-control-int",
    "pavel-pardo-skills-pass-control-tri",
    "pavel-pardo-skills-poison-dart",
    "pavel-pardo-skills-zig-zag",
    "pavel-pardo-stories-1",
    "pavel-pardo-stories-2",
    "pavel-pardo-stories-3",
    "pavel-pardo-stories-4",
    "pavel-pardo-challenge-20-kick-ups",
    "rafa-marquez-mindset-1-determination",
    "rafa-marquez-mindset-2-anger-management",
    "rafa-marquez-mindset-3-leadership",
    "rafa-marquez-mindset-4-discipline",
    "rafa-marquez-skills-headshot",
    "rafa-marquez-skills-long-shot",
    "rafa-marquez-skills-free-kick",
    "rafa-marquez-skills-art-defending-1",
    "rafa-marquez-skills-art-defending-2",
    "rafa-marquez-stories-1",
    "rafa-marquez-stories-2",
    "rafa-marquez-stories-3",
    "rafa-marquez-stories-4",
    "rafa-marquez-challenge-hit-crossbar",
    "carlos-salcido-mindset-1-inspiration",
    "carlos-salcido-mindset-2-self-esteem",
    "carlos-salcido-mindset-3-resilience",
    "carlos-salcido-mindset-4-perseverance",
    "carlos-salcido-skills-outline",
    "carlos-salcido-skills-approach-speed",
    "carlos-salcido-skills-blocking",
    "carlos-salcido-stories-1",
    "carlos-salcido-stories-2",
    "carlos-salcido-stories-3",
    "carlos-salcido-stories-4",
    "carlos-salcido-challege-sit-down-kick-ups",
    "carlos-salcido-challege-motorcycle",
    "charlyn-corral-mindset-1-courage",
    "charlyn-corral-mindset-2-modesty",
    "charlyn-corral-mindset-3-sense-agency",
    "charlyn-corral-mindset-4-flow",
    "charlyn-corral-skills-cutting-hitting-ball",
    "charlyn-corral-skills-little-hat",
    "charlyn-corral-stories-1",
    "charlyn-corral-stories-2",
    "charlyn-corral-stories-3",
    "charlyn-corral-stories-4",
    "charlyn-corral-challege-neck-stall-push-ups",
    "charlyn-corral-challege-hump-arround-wolrd",
    "norma-palafox-mindset-1-tenacity",
    "norma-palafox-mindset-2-confidence",
    "norma-palafox-mindset-3-hope",
    "norma-palafox-mindset-4-decision-taking",
    "norma-palafox-skills-speed",
    "norma-palafox-skills-dribbling",
    "norma-palafox-charlyn-corral-skills-walls",
    "norma-palafox-stories-1",
    "norma-palafox-stories-2",
    "jared-borgetti-mindset-1-empathy",
    "jared-borgetti-mindset-2-gratitude",
    "jared-borgetti-mindset-3-overcoming",
    "jared-borgetti-mindset-4-persistence",
    "jared-borgetti-skills-header",
    "jared-borgetti-skills-scorer",
    "jared-borgetti-skills-art-forward",
    "jared-borgetti-stories-1",
    "jared-borgetti-stories-2",
    "jared-borgetti-stories-3",
    "jared-borgetti-stories-4",
    "jared-borgetti-challenge-fake-headshot",
    "oswaldo-sanchez-mindset-1-will",
    "oswaldo-sanchez-mindset-2-flexibility",
    "oswaldo-sanchez-mindset-3-loss-management",
    "oswaldo-sanchez-mindset-4-concentration",
    "oswaldo-sanchez-stories-1",
    "oswaldo-sanchez-stories-2",
    "oswaldo-sanchez-stories-3",
    "oswaldo-sanchez-stories-4",
    "gigi-buffon-challenge-juggles",
    "gigi-buffon-stories-1",
    "gigi-buffon-stories-2",
    "gigi-buffon-stories-3",
    "gigi-buffon-stories-4",
    "gigi-buffon-mindset-teamwork",
    "gigi-buffon-mindset-mental-growth",
    "gigi-buffon-mindset-perseverance-value",
    "gigi-buffon-mindset-leadership",
    "gigi-buffon-skills-stop-grab",
    "gigi-buffon-skills-side-falls",
    "gigi-buffon-skills-goal-orientation",
    "gigi-buffon-skills-reaction-capacity",
    "gigi-buffon-skills-high-output-1",
    "gigi-buffon-skills-high-output-2",
    "gigi-buffon-skills-low-output",
    "gigi-buffon-skills-skill",
    "gigi-buffon-fitness-squats",
    "gigi-buffon-fitness-jump-squats",
    "gigi-buffon-fitness-squats-unstable",
    "gigi-buffon-fitness-weighted-squats",
    "gigi-buffon-fitness-dynamic-movement-ball",
    "gigi-buffon-fitness-single-leg-lunge",
    "gigi-buffon-fitness-opposite-single-leg-lunge",
    "gigi-buffon-fitness-strength",
    "gigi-buffon-fitness-toning-abs",
    "gigi-buffon-fitness-toning-abs-side-plank",
    "gigi-buffon-fitness-toning-abs-bridge",
    "gigi-buffon-fitness-toning-abs-plank-fitball",
    "gigi-buffon-fitness-toning-waist-back",
    "gigi-buffon-fitness-stability-abs",
    "gigi-buffon-fitness-stimulation-abs",
    "gigi-buffon-fitness-toning-body",
    "gigi-buffon-fitness-toning-shoulder-pectoral",
    "gigi-buffon-fitness-bench-push-ups",
    "gigi-buffon-fitness-push-ups-unstable",
    "gigi-buffon-fitness-wirst-strength",
    "gigi-buffon-fitness-push-ups-handles",
    "gigi-buffon-fitness-dorsal-handles",
    "gigi-buffon-fitness-back-muscles-handles",
    "gigi-buffon-fitness-shoulder-back-stability",
    "screenshot-challenge-1",
    "screenshot-challenge-2",
    "screenshot-challenge-3",
    "screenshot-programs",
    "screenshot",
    "tablet",
    "achievement-fitness-x3",
    "achievement-fitness-x5",
    "achievement-fitness-x25",
    "achievement-mindset-x5",
    "achievement-program-x1",
    "achievement-player-game",
    "achievement-player-month",
    "achievement-player-team",
    "achievement-player-back",
    "achievement-player-morning",
    "achievement-player-night",
    "achievement-challenge-x1",
    "achievement-challenge-x5",
    "achievement-watch-week",
    "achievement-watch-month",
    "achievement-watch-year",
    "achievement-day-birthday",
    "achievement-day-new-years",
    "achievement-day-international-sports",
    "achievement-app-download",
    "achievement-app-purchase",
    "achievement-app-sharing",
    "achievement-app-account",
    "achievement-app-profile",
    "achievement-captain-badge",
    "achievement-glove-gold",
    "achievement-glove-silver",
    "achievement-glove-bronce",
    "achievement-femenil-gold",
    "achievement-femenil-silver",
    "achievement-femenil-bronce",
    "achievement-shoe-gold",
    "achievement-ball-gold",
    "achievement-stars-x3",
    "achievement-stars-x10",
    "achievement-position-complete",
    "achievement-position-program",
    "achievement-position-goalkeeper",
    "achievement-position-striker",
    "achievement-position-defender",
    "achievement-position-midfielder",
    "achievement-world-cup",
    "achievement-jo-canales-program",
    "achievement-jo-canales-program-14-days",
    "achievement-jo-canales-program-7-days",
    "achievement-jo-canales-program-6-days",
    "achievement-jo-canales-program-5-days",
    "landing-cracks",
    "landing-cracks-rectangle",
    "landing-texture-main",
    "landing-texture-about",
    "landing-phone-frame",
    "payment-method-apple-pay",
    "payment-method-apple",
    "payment-method-google-pay",
    "payment-method-google",
    "pup-up-banner",
    "about-us-1",
    "about-us-2",
    "about-us-3",
    "landing-all-cracks",
    "landing-crack-rafael-marquez",
    "landing-crack-pavel-pardo",
    "landing-crack-jared-borgetti",
    "landing-crack-jo-canales",
    "landing-crack-oswaldo-sanchez",
    "landing-crack-norma-palafox",
    "landing-crack-charlyn-corral",
    "landing-crack-carlos-salcido",
    "landing-crack-buffon-academy",
    "landing-business-steren",
    "landing-sign-rafael-marquez",
    "landing-sign-pavel-pardo",
    "landing-sign-jared-borgetti",
    "landing-sign-jo-canales",
    "landing-sign-oswaldo-sanchez",
    "landing-sign-norma-palafox",
    "landing-sign-charlyn-corral",
    "landing-sign-carlos-salcido",
    "landing-sign-buffon-academy",
    "landing-personalized-training",
    "landing-training-programs",
    "landing-academias-por-la-paz",
    "landing-iztacalco",
    "program-secret-stress-management-thumb",
    "program-secret-stress-management-main",
    "program-secret-stress-management-day01",
    "program-secret-stress-management-day01-12",
    "program-secret-stress-management-day01-11",
    "program-secret-stress-management-day01-10",
    "program-secret-stress-management-day01-09",
    "program-secret-stress-management-day01-08",
    "program-secret-stress-management-day01-final",
    "program-secret-stress-management-day02",
    "program-secret-stress-management-day02-final",
    "program-secret-stress-management-day03",
    "program-secret-stress-management-day03-final",
    "program-secret-stress-management-day04",
    "program-secret-stress-management-day04-final",
    "program-secret-stress-management-day05",
    "program-secret-stress-management-day05-final",
    "program-secret-stress-management-day06",
    "program-secret-stress-management-day06-03",
    "program-secret-stress-management-day06-final",
    "program-secret-stress-management-day07",
    "program-secret-stress-management-day07-final",
    "program-secret-stress-management-day08",
    "program-secret-stress-management-day08-05",
    "program-secret-stress-management-day08-final",
    "program-secret-stress-management-day09",
    "program-secret-stress-management-day09-04",
    "program-secret-stress-management-day09-07",
    "program-secret-stress-management-day09-final",
    "program-secret-stress-management-day10",
    "program-secret-stress-management-day10-03",
    "program-secret-stress-management-day10-05",
    "program-secret-stress-management-day10-06",
    "program-secret-stress-management-day10-07",
    "program-secret-stress-management-day10-08",
    "program-secret-stress-management-day10-final",
    "program-secret-sleep-thumb",
    "program-secret-sleep-main",
    "program-secret-sleep-day01",
    "program-secret-sleep-day01-01",
    "program-secret-sleep-day01-final",
    "program-secret-sleep-day02",
    "program-secret-sleep-day02-02",
    "program-secret-sleep-day02-03",
    "program-secret-sleep-day02-04",
    "program-secret-sleep-day02-05",
    "program-secret-sleep-day02-06",
    "program-secret-sleep-day02-07",
    "program-secret-sleep-day02-08",
    "program-secret-sleep-day02-09",
    "program-secret-sleep-day02-10",
    "program-secret-sleep-day02-11",
    "program-secret-sleep-day02-final",
    "program-secret-sleep-day03",
    "program-secret-sleep-day03-final",
    "program-secret-sleep-day04",
    "program-secret-sleep-day04-final",
    "program-secret-sleep-day05",
    "program-secret-sleep-day05-02",
    "program-secret-sleep-day05-03",
    "program-secret-sleep-day05-04",
    "program-secret-sleep-day05-05",
    "program-secret-sleep-day05-06",
    "program-secret-sleep-day05-07",
    "program-secret-sleep-day05-08",
    "program-secret-sleep-day05-09",
    "program-secret-sleep-day05-final",
    "program-secret-sleep-day06",
    "program-secret-sleep-day06-final",
    "program-secret-sleep-day07",
    "program-secret-sleep-day07-02",
    "program-secret-sleep-day07-final",
    "program-secret-sleep-day08",
    "program-secret-sleep-day08-final",
    "program-secret-sleep-day09",
    "program-secret-sleep-day09-final",
    "program-secret-sleep-day10",
    "program-secret-sleep-day10-01",
    "program-secret-sleep-day10-final",
    "program-secret-sleep-day11",
    "program-secret-sleep-day11-02",
    "program-secret-sleep-day11-final",
    "program-secret-sleep-day12",
    "program-secret-sleep-day12-final",
    "payment-openpay",
    "payment-credit-mastercard",
    "payment-credit-visa",
    "payment-credit-amex",
    "payment-credit-carnet",
    "payment-debit-bbva",
    "payment-debit-santander",
    "payment-debit-hsbc",
    "payment-debit-scotiabank",
    "payment-debit-inbursa",
    "payment-debit-ixe",
    "mobile-frame",
    "crack-bn-carlos-salcido",
    "crack-bn-charlyn-corral",
    "crack-bn-gianluigi-buffon",
    "crack-bn-jared-borgetti",
    "crack-bn-jo-canales",
    "crack-bn-norma-palafox",
    "crack-bn-oswaldo-sanchez",
    "crack-bn-pavel-pardo",
    "crack-bn-rafa-marquez",
    "crack-sm-carlos-salcido",
    "crack-sm-charlyn-corral",
    "crack-sm-gianluigi-buffon",
    "crack-sm-jared-borgetti",
    "crack-sm-jo-canales",
    "crack-sm-norma-palafox",
    "crack-sm-oswaldo-sanchez",
    "crack-sm-pavel-pardo",
    "crack-sm-rafa-marquez",
    "banner-secrets",
    "banner-podcast",
    "placeholder-tip-1",
    "placeholder-tip-2",
    "placeholder-tip-3",
    "placeholder-tip-4",
    "placeholder-tip-5",
    "placeholder-tip-6",
    "placeholder-tip-7",
    "placeholder-tip-8",
    "placeholder-tip-9",
    "placeholder-tip-10",
    "placeholder-tip-11",
    "placeholder-tip-12",
    "placeholder-tip-13",
    "placeholder-tip-14",
    "placeholder-tip-15",
    "placeholder-tip-16",
    "placeholder-tip-17",
    "placeholder-tip-18",
    "placeholder-tip-19",
    "placeholder-tip-20",
    "placeholder-tip-21",
    "placeholder-tip-22",
    "placeholder-tip-23",
    "placeholder-tip-24",
    "placeholder-tip-25",
    "placeholder-tip-26",
    "placeholder-tip-27",
    "placeholder-tip-28",
    "placeholder-tip-29",
    "placeholder-tip-30",
    "placeholder-tip-31",
    "placeholder-tip-32",
    "placeholder-tip-33",
    "placeholder-tip-34",
    "placeholder-tip-35",
    "placeholder-tip-36",
    "placeholder-tip-37",
    "placeholder-tip-38",
    "placeholder-tip-39",
    "placeholder-tip-40",
    "placeholder-tip-41",
    "placeholder-tip-42",
    "placeholder-tip-43",
    "placeholder-tip-44",
    "placeholder-tip-45",
    "placeholder-tip-46",
    "placeholder-tip-47",
    "placeholder-tip-48",
    "placeholder-tip-49",
    "placeholder-tip-50",
    "placeholder-program-1",
    "placeholder-program-2",
    "placeholder-program-3",
    "placeholder-program-4",
    "placeholder-program-5",
    "placeholder-program-6",
    "placeholder-program-7",
    "placeholder-program-8",
    "placeholder-program-9",
    "placeholder-program-10",
    "placeholder-program-11",
    "placeholder-program-12",
    "placeholder-program-13",
    "placeholder-program-14",
    "placeholder-program-15",
    "placeholder-program-16",
    "placeholder-program-17",
    "placeholder-program-18",
    "placeholder-program-19",
    "placeholder-program-20",
    "placeholder-program-21",
    "placeholder-program-22",
    "placeholder-program-23",
    "placeholder-program-24",
    "placeholder-program-25",
    "placeholder-program-26",
    "placeholder-program-27",
    "placeholder-program-28",
    "placeholder-program-29",
    "placeholder-program-30",
    "placeholder-program-31",
    "placeholder-program-32",
    "placeholder-program-33",
    "placeholder-program-34",
    "placeholder-program-35",
    "placeholder-program-36",
    "placeholder-program-37",
    "placeholder-program-38",
    "placeholder-program-39",
    "placeholder-program-40",
    "placeholder-program-41",
    "placeholder-program-42",
    "placeholder-program-43",
    "placeholder-program-44",
    "placeholder-program-45",
    "placeholder-program-46",
    "placeholder-program-47",
    "placeholder-program-48",
    "placeholder-program-49",
    "placeholder-program-50",
] as const;

export type UuidBitmapAsset = typeof UUID_BITMAP_ASSET[number];

export type FilenameBitmapAsset<Uuid extends string> =
    `/${GroupBitmapAsset}/${PrefixBitmapAsset}_${Uuid}${ExtBitmapAsset}`;

export const BITMAP_ASSET = UUID_BITMAP_ASSET.reduce(
    (acc, uuid) => ({
        ...acc,
        [uuid]: ["", GROUP_BITMAP_ASSET, `${PREFIX_BITMAP_ASSET}_${uuid}${EXT_BITMAP_ASSET}`].join(
            "/",
        ),
    }),
    {} as { readonly [K in UuidBitmapAsset]: FilenameBitmapAsset<K> },
);

export type BitmapAsset = typeof BITMAP_ASSET;
export type ValueBitmapAsset = BitmapAsset[UuidBitmapAsset];

export function GetterBitmapAsset(url: string) {
    return UUID_BITMAP_ASSET.reduce(
        (acc, uuid) => ({ ...acc, [uuid]: `${url}${BITMAP_ASSET[uuid]}` as const }),
        {} as { [K in UuidBitmapAsset]: `${typeof url}${FilenameBitmapAsset<K>}` },
    );
}
