import { PathCreate } from "@platform/react-storybook/Component/Router/utils";

export function PathGetSupport<R extends string>(ROOT: R) {
    return {
        ROOT,

        get SUPPORT() {
            const ROOT_SUPPORT = PathCreate(ROOT, "support");
            return {
                ROOT: ROOT_SUPPORT,
                TERMS: PathCreate(ROOT_SUPPORT, "terms"),
                PRIVACY: PathCreate(ROOT_SUPPORT, "privacy-policy"),
            };
        },
    };
}
