export const PREFIX_BOX_THEME = `box` as const;

export const UUID_BOX_THEME = [
    "none",
    "ekans",
    "pikachu",
    "zubat",
    "tangela",
    "pinsir",
    "krabby",
    "cubone",
    "paras",
    "bulbasaur",
    "geodude",
    "ponyta",
    "vulpix",
    "mankey",
    "ditto",
    "diglett",
    "doduo",
    "grimer",
    "gengar",
    "onix",
    "snorlax",
    "evee",
    "hypno",
    "weedle",
    "oddish",
    "machop",
    "gastly",
    "muk",
    "jynx",
] as const;

export type UuidBoxTheme = typeof UUID_BOX_THEME[number];
