import type { SecretProgram } from "@common/content/secret/program";
import type { SecretAnswer } from "@common/content/secret/answer";

export const NAME_SLICE_SECRET = "secret";

export declare namespace NSSliceSecret {
    export type State = {
        id?: string;
        program?: SecretProgram;
        isFirstTime: boolean;
        progress: number;
        currentDay: string;
        currentDayTraining: SecretAnswer[];
        hasSeenTips: boolean;
        shouldUpdateProgram: boolean;
        lastLocation: string;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_SECRET: NSSliceSecret.State = {
    id: "",
    isFirstTime: true,
    progress: 0,
    currentDay: "1",
    currentDayTraining: [],
    hasSeenTips: false,
    shouldUpdateProgram: false,
    lastLocation: "",
};
