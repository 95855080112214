import type { FilterUnion } from "@platform/types/union";
import type { UuidIconAsset } from "@common/content/asset/icon";
import type { UuidPositionCrack } from "@common/content/crack/position";
import type { UuidBioCrack } from "@common/content/crack/bio";
import type { UuidCountry } from "@common/content/country";
import type { UuidCrack } from "@common/content/crack/uuid";
import type { UuidTitleStatsCrack } from "@common/content/crack/stats-title";
import type { UuidBodyStatsCrack } from "@common/content/crack/stats-body";

export { DATA_CRACK } from "./_data";

export const UUID_PRESET_ITEM_LIST_SECTION = ["round", "simple", "info"] as const;

export type UuidPresetItemListSection = typeof UUID_PRESET_ITEM_LIST_SECTION[number];

export type RecordStatsCrack<Uuid extends UuidCrack> = {
    // TODO: Limit the type of icon that can be used here
    preset: UuidPresetItemListSection;
    icon: UuidIconAsset;
    title: FilterUnion<UuidTitleStatsCrack, `${string}-${Uuid}-${string}`>;
    desc: FilterUnion<UuidBodyStatsCrack, `${string}-${Uuid}-${string}`>;
};

export type RecordCrack<Uuid extends UuidCrack> = {
    uuid: Uuid;
    nameFirst: string;
    nameLast: string;
    nameAlias: string;
    position: UuidPositionCrack;
    country: UuidCountry;
    bio: FilterUnion<UuidBioCrack, `${string}-${Uuid}`>;
    stats: RecordStatsCrack<Uuid>[];
};
