import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_APP } from "./_namespace";
import type { NSSliceApp } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSliceApp(store: StoreState): NSSliceApp.State {
    return store[NAME_SLICE_APP];
}

SelectorSliceApp.version = CreateSelector(SelectorSliceApp, (state) => state.version);
SelectorSliceApp.runs = CreateSelector(SelectorSliceApp, (state) => state.runs);
SelectorSliceApp.isLoggedIn = CreateSelector(SelectorSliceApp, (state) => state.isLoggedIn);
SelectorSliceApp.history = CreateSelector(SelectorSliceApp, (state) => state.history);
SelectorSliceApp.hasBeenRun = CreateSelector(SelectorSliceApp, (state) => state.hasBeenRun);
