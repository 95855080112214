import { CreateReducer } from "@platform/react/store";
import { ActionSliceEnrollment } from "./_actions";
import { INIT_SLICE_ENROLLMENT } from "./_namespace";

export const ReducerSliceEnrollment = CreateReducer(INIT_SLICE_ENROLLMENT, ({ addCase }) => {
    addCase(ActionSliceEnrollment.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSliceEnrollment.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_ENROLLMENT;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });
});
