import {
    GetterBitmapAsset,
    UUID_BITMAP_ASSET,
    GROUP_BITMAP_ASSET,
} from "@common/content/asset/bitmap";
import {
    GetterVectorAsset,
    UUID_VECTOR_ASSET,
    GROUP_VECTOR_ASSET,
} from "@common/content/asset/vector";
import { GetterIconAsset, UUID_ICON_ASSET, GROUP_ICON_ASSET } from "@common/content/asset/icon";
import { GetterEnv } from "@common/content/env";
import Namespace from "./_index.scss";

export const { NAME_ASSET, TYPE_ASSET } = Namespace;

export async function GetterAsset() {
    const { REACT_APP_URL_ASSETS } = GetterEnv(process.env);
    if (typeof REACT_APP_URL_ASSETS !== "string") throw new Error("Expecting an APP_URL_ASSETS");
    const BITMAP_ASSET = GetterBitmapAsset(REACT_APP_URL_ASSETS);
    const VECTOR_ASSET = GetterVectorAsset(REACT_APP_URL_ASSETS);
    const ICON_ASSET = GetterIconAsset(REACT_APP_URL_ASSETS);
    // TODO: MC-676: TypeScript is not resolving @commons because of the aliasing.
    //       I'm recreating the RecordAsset type so the typings are not lost.
    return Promise.resolve([
        ...UUID_BITMAP_ASSET.map((uuid) => ({
            uuid,
            type: GROUP_BITMAP_ASSET,
            url: BITMAP_ASSET[uuid],
        })),
        ...UUID_VECTOR_ASSET.map((uuid) => ({
            uuid,
            type: GROUP_VECTOR_ASSET,
            url: VECTOR_ASSET[uuid],
        })),
        ...UUID_ICON_ASSET.map((uuid) => ({
            uuid,
            type: GROUP_ICON_ASSET,
            url: ICON_ASSET[uuid],
        })),
    ]);
}

export type GetterAsset = typeof GetterAsset;
