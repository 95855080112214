import React from "@platform/react";
import { ErrorCatch } from "@platform/react-storybook/Component/ErrorCatch";

import { Store } from "@front/store";
import {
    ActionSliceNetwork,
    STATUS_SLICE_NETWORK,
    NAME_SLICE_NETWORK,
} from "@front/store/slices/network";

import { SectionErrorNetwork } from "./_ErrorNetwork";
import { SectionErrorInternal } from "./_ErrorInternal";
import type { PropErrorCatcher, PropTypesErrorCatcher } from "./types";
import { DEF_ERROR_CATCHER } from "./types";

export function ErrorCatcher(props: PropErrorCatcher) {
    const { children } = props as PropTypesErrorCatcher;

    const [status, statusSet] = React.useState(getStatus());

    const callbackStoreChange = React.useCallback(handleStoreChange, [status]);

    React.useEffect(handleMountStatus, []);
    React.useEffect(handleMountSubscription, [callbackStoreChange]);

    return (
        <ErrorCatch render={<SectionErrorInternal />}>
            {status === STATUS_SLICE_NETWORK.OFFLINE ? <SectionErrorNetwork /> : children}
        </ErrorCatch>
    );

    function getStatus() {
        const state = Store.getState();
        return state[NAME_SLICE_NETWORK].status;
    }

    function handleMountSubscription() {
        const unsubscribe = Store.subscribe(callbackStoreChange);
        return () => {
            unsubscribe();
        };
    }

    function handleMountStatus() {
        window.addEventListener(STATUS_SLICE_NETWORK.OFFLINE, handleNetworkOffline);
        window.addEventListener(STATUS_SLICE_NETWORK.ONLINE, handleNetworkOnline);

        return () => {
            window.removeEventListener(STATUS_SLICE_NETWORK.OFFLINE, handleNetworkOffline);
            window.removeEventListener(STATUS_SLICE_NETWORK.ONLINE, handleNetworkOnline);
        };

        function handleNetworkOffline() {
            Store.dispatch(ActionSliceNetwork.set(STATUS_SLICE_NETWORK.OFFLINE));
        }

        function handleNetworkOnline() {
            Store.dispatch(ActionSliceNetwork.set(STATUS_SLICE_NETWORK.ONLINE));
        }
    }

    function handleStoreChange() {
        const current = getStatus();
        if (status !== current) statusSet(current);
    }
}
ErrorCatcher.defaultProps = DEF_ERROR_CATCHER;
