import { CreateActions } from "@platform/react/src/store";
import type {
    OpenPaySubscriptionResponse,
    Subscription,
} from "@common/content/payment/subscription";
import type { Card } from "@common/content/payment/paymentMethod";
import { NAME_SLICE_PAYMENT } from "./_namespace";
import type { NSSlicePayment, PlanMC, Charge } from "./_namespace";

export type SubscriptionByUser = Subscription & { card: Card };

export const ActionSlicePayment = CreateActions<{
    /** Sets a give property errors */
    set: NSSlicePayment.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSlicePayment.ArgClear | null;
    /** */
    setCreditCard: NSSlicePayment.CreditCard;
    /** */
    setPlan: { uuidPlan: string | undefined };
    setPlanMc: PlanMC;
    /** set payments history */
    setHistoryPayment: NSSlicePayment.History[];
    setUuidCreditCard: { uuid: string | undefined };
    setSubscription: { subscription: SubscriptionByUser };
    setCharge: { charge: Charge };
    setSubscriptions: { subscriptions: OpenPaySubscriptionResponse[] };
    setDiscount: NSSlicePayment.Discount;
}>(NAME_SLICE_PAYMENT, [
    "set",
    "clear",
    "setCreditCard",
    "setPlan",
    "setPlanMc",
    "setHistoryPayment",
    "setUuidCreditCard",
    "setSubscription",
    "setCharge",
    "setDiscount",
]);
