import { isRecord } from "@platform/types/record";
import { ISO_639_1 } from "@common/content/language/ISO_639_1";
import { isStatus } from "@common/content/data";
import type { StatusKey } from "@common/content/data";
import type { UuidIso6391, ValueIso6391 } from "@common/content/language/ISO_639_1";

export const NAME_LANGUAGE = "language" as const;

export type NameLanguage = typeof NAME_LANGUAGE;

export const ROUTE_LANGUAGUE = {
    LANGUAGE_ALL: `/${NAME_LANGUAGE}`,
    LANGUAGE_ONE: `/${NAME_LANGUAGE}/:uuid`,
} as const;

export type RouteLanguage = typeof ROUTE_LANGUAGUE;

export type RecordLanguage = {
    uuid: UuidIso6391;
    name: ValueIso6391;
    status: StatusKey;
};

export const DEFAULT_LANGUAGE = "es-mx" as const;

export const DATA_LANGUAGE = ["en-us", "es-mx"] as const;

export type UuidLanguage = typeof DATA_LANGUAGE[number];

export function isLanguage(entry: unknown): entry is Partial<RecordLanguage> {
    if (!isRecord(entry)) return false;
    const MIN_PROPS = 1;
    const keysValid = ["uuid", "name", "status"];
    const keysEntry = Object.keys(entry as Record<string, unknown>);
    // is a property set?  an empty object is not a language;
    if (keysEntry.length < MIN_PROPS) return false;
    // do not allow properties that are not valid.
    if (keysEntry.filter((key) => !keysValid.includes(key)).length) {
        return false;
    }
    const language = entry as RecordLanguage;
    if (language.uuid && !isUuidLanguage(language.uuid)) return false;
    if (language.status && !isStatus(language.status)) return false;
    return true;
}

export function isUuidLanguage(entry: unknown): entry is RecordLanguage["uuid"] {
    const uuid = String(entry);
    return Object.keys(ISO_639_1).includes(uuid);
}
