import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_ENROLLMENT } from "./_namespace";
import type { NSSliceEnrollment } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSliceEnrollment(store: StoreState): NSSliceEnrollment.State {
    return store[NAME_SLICE_ENROLLMENT];
}

SelectorSliceEnrollment.isStep1 = CreateSelector(
    SelectorSliceEnrollment,
    (state) => !state.hasEnrolled,
);
SelectorSliceEnrollment.isStep2 = CreateSelector(
    SelectorSliceEnrollment,
    (state) => state.hasEnrolled,
);
