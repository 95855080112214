import { CreateSelector } from "@platform/react/store";

import { INIT_SLICE_SESSION, NAME_SLICE_SESSION, USER_STATUS } from "./_namespace";
import type { NSSliceSession } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSliceSession(store: StoreState): NSSliceSession.State {
    return store[NAME_SLICE_SESSION];
}

SelectorSliceSession.id = CreateSelector(SelectorSliceSession, (state) => state.id);

SelectorSliceSession.isLoggedIn = CreateSelector(SelectorSliceSession, (state) => state.isLoggedIn);

SelectorSliceSession.isFirstTime = CreateSelector(
    SelectorSliceSession,
    (state) => state.isFirstTime,
);

SelectorSliceSession.email = CreateSelector(SelectorSliceSession, (state) => state.email);

SelectorSliceSession.firstName = CreateSelector(SelectorSliceSession, (state) => state.firstName);

SelectorSliceSession.lastName = CreateSelector(SelectorSliceSession, (state) => state.lastName);

SelectorSliceSession.avatar = CreateSelector(SelectorSliceSession, (state) => state.avatar);

SelectorSliceSession.status = CreateSelector(SelectorSliceSession, (state) => state.status);

SelectorSliceSession.isFirstTimeOpenApp = CreateSelector(
    SelectorSliceSession,
    (state) => state.isFirstTimeOpenApp,
);

SelectorSliceSession.lastLocation = CreateSelector(
    SelectorSliceSession,
    (state) => state.lastLocation,
);

SelectorSliceSession.hasCreatedPassword = CreateSelector(
    SelectorSliceSession,
    (state) => state.hasCreatedPassword,
);

SelectorSliceSession.hasBirthday = CreateSelector(
    SelectorSliceSession,
    (state) => state.hasBirthday,
);

SelectorSliceSession.hasSecretPreferences = CreateSelector(
    SelectorSliceSession,
    (state) => state.hasSecretPreferences,
);

SelectorSliceSession.hasSubscription = CreateSelector(
    SelectorSliceSession,
    (state) => state.hasSubscription,
);

SelectorSliceSession.hasMembership = CreateSelector(
    SelectorSliceSession,
    (state) =>
        Boolean(state.membership) &&
        state.membership !== "NONE" &&
        state.membership !== "TRIAL_EXPIRED",
);

SelectorSliceSession.membership = CreateSelector(SelectorSliceSession, (state) => ({
    membership: state.membership,
    dateTrial: state.dateTrial,
}));

SelectorSliceSession.user = CreateSelector(SelectorSliceSession, (state) => {
    const { isLoggedIn, email } = state;
    if (!isLoggedIn) return null;
    return email;
});

SelectorSliceSession.isBusinessSurveyCompleted = CreateSelector(
    SelectorSliceSession,
    (state) => state.isBusinessSurveyCompleted,
);

SelectorSliceSession.origin = CreateSelector(SelectorSliceSession, (state) => state.origin);

SelectorSliceSession.shouldShowBusinessSurvey = CreateSelector(
    SelectorSliceSession,
    ({ isBusinessSurveyCompleted, isComingFromBusiness }) => {
        if (!isComingFromBusiness || isBusinessSurveyCompleted) {
            return false;
        }

        return Boolean(!isBusinessSurveyCompleted);
    },
);

export const SelectorSessionHasEmail = CreateSelector(SelectorSliceSession.email, (email) =>
    Boolean(email),
);

export const SelectorSessionIsStatusActive = CreateSelector(
    SelectorSliceSession,
    ({ status }) => status === USER_STATUS.ACTIVE,
);

export const SelectorSessionIsStatusPending = CreateSelector(
    SelectorSliceSession,
    ({ status }) => status === USER_STATUS.PENDING,
);

// -- Pre & Post Enrollment

export const SelectorSessionIsInactive = CreateSelector(
    SelectorSessionHasEmail,
    (hasEmail) => !hasEmail,
);

export const SelectorSessionIsLogin = CreateSelector(
    [
        SelectorSessionIsInactive,
        SelectorSessionIsStatusActive,
        SelectorSliceSession.shouldShowBusinessSurvey,
    ],
    (isInactive, isStatusActive, shouldShowBusinessSurvey) =>
        !isInactive && isStatusActive && !shouldShowBusinessSurvey,
);

// -- Enrollment

export const SelectorSessionIsEnroll = CreateSelector(
    [SelectorSessionHasEmail, SelectorSessionIsStatusPending],
    (hasEmail, isStatusPending) => hasEmail && isStatusPending,
);

export const SelectorSessionIsEnrollPasswordPending = CreateSelector(
    [SelectorSessionIsEnroll, SelectorSliceSession.hasCreatedPassword],
    (isEnroll, hasCreatedPassword) => isEnroll && !hasCreatedPassword,
);

export const SelectorSessionIsEnrollPasswordReady = CreateSelector(
    [SelectorSessionIsEnroll, SelectorSliceSession.hasCreatedPassword],
    (isEnroll, hasCreatedPassword) => isEnroll && hasCreatedPassword,
);

export const SelectorSessionIsEnrollBirthdayPending = CreateSelector(
    [SelectorSessionIsEnroll, SelectorSliceSession.hasBirthday],
    (isEnroll, hasBirthday) => isEnroll && hasBirthday === INIT_SLICE_SESSION.hasBirthday,
);

export const SelectorSessionIsEnrollBirthdaySkipped = CreateSelector(
    SelectorSliceSession.hasBirthday,
    (hasBirthday) => hasBirthday === null,
);

export const SelectorSessionIsEnrollBirthdayReady = CreateSelector(
    [
        SelectorSessionIsEnroll,
        SelectorSliceSession.hasBirthday,
        SelectorSessionIsEnrollBirthdaySkipped,
    ],
    (isEnroll, hasBirthday, isEnrollBirthdaySkipped) =>
        isEnroll && (hasBirthday || isEnrollBirthdaySkipped),
);

export const SelectorSessionIsEnrollSurveyPending = CreateSelector(
    [SelectorSessionIsEnroll, SelectorSliceSession.hasSecretPreferences],
    (isEnroll, hasSecretPreferences) =>
        isEnroll && hasSecretPreferences === INIT_SLICE_SESSION.hasSecretPreferences,
);

export const SelectorSessionIsEnrollSurveyReady = CreateSelector(
    [SelectorSessionIsEnroll, SelectorSliceSession.hasSecretPreferences],
    (isEnroll, hasSecretPreferences) => isEnroll && hasSecretPreferences,
);

SelectorSliceSession.membershipStatus = CreateSelector(SelectorSliceSession, (state) => {
    if (state.membership === "TRIAL" && state.dateTrial) {
        const EXTRA_MONTH = 1;
        const today = new Date();
        const trialInitialDate = new Date(state.dateTrial);
        const trialExpirationDate = new Date(
            trialInitialDate.setMonth(trialInitialDate.getMonth() + EXTRA_MONTH),
        );

        if (today > trialExpirationDate) {
            return "TRIAL_EXPIRED";
        }

        return "TRIAL";
    }

    return state.membership ?? "NONE";
});

SelectorSliceSession.provider = CreateSelector(SelectorSliceSession, (state) => state.provider);
SelectorSliceSession.expiryDate = CreateSelector(SelectorSliceSession, (state) => state.expiryDate);
SelectorSliceSession.isComingFromBusiness = CreateSelector(
    SelectorSliceSession,
    (state) => state.isComingFromBusiness,
);
SelectorSliceSession.isBusinessSurveyStarted = CreateSelector(
    SelectorSliceSession,
    (state) => state.isBusinessSurveyStarted,
);
SelectorSliceSession.businessSurveyCurrentIndex = CreateSelector(
    SelectorSliceSession,
    (state) => state.businessSurveyCurrentIndex,
);
SelectorSliceSession.businessSurveyAnswers = CreateSelector(
    SelectorSliceSession,
    (state) => state.businessSurveyAnswers,
);
SelectorSliceSession.transactionID = CreateSelector(
    SelectorSliceSession,
    (state) => state.transactionID,
);
SelectorSliceSession.shouldReloadSubscription = CreateSelector(
    SelectorSliceSession,
    (state) => state.shouldReloadSubscription,
);
