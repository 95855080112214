export const GROUP_VIDEO_ASSET = "videos" as const;

export type GroupVideoAsset = typeof GROUP_VIDEO_ASSET;

export const EXT_VIDEO_ASSET = ".mp4" as const;

export type ExtVideoAsset = typeof EXT_VIDEO_ASSET;

export const PREFIX_VIDEO_ASSET = "vid" as const;

export type PrefixVideoAsset = typeof PREFIX_VIDEO_ASSET;
export const UUID_VIDEO_ASSET = [
    "coming-soon-challenges",
    "coming-soon-favorites",
    "coming-soon-secrets",
    "coming-soon-community",
    "coming-soon-programs",
    "programs-general-finish",
    "programs-general-start",
    "programs-general-rest",
    "secret-yes-value-dyk",
    "secret-yes-value-intro",
    "secret-yes-value-step-1",
    "secret-yes-value-step-2",
    "secret-yes-value-step-3",
    "secret-yes-value-visualization",
    "tip-caffeine-use-dyk",
    "tip-caffeine-use-step-3",
    "tip-know-your-chronotype-complement",
    "tip-know-your-chronotype-dyk",
    "tip-effect-electronic-screens-complement-1",
    "tip-effect-electronic-screens-complement-2",
    "tip-effect-electronic-screens-reprogram",
    "tip-success-end-dyk",
    "tip-success-end-hacks",
    "tip-success-end-intro",
    "tip-success-end-step-1",
    "tip-success-end-step-2",
    "tip-success-end-step-3",
    "tip-success-end-step-4",
    "tip-success-end-visualization",
    "tip-best-coach-complement",
    "tip-best-coach-dyk",
    "tip-best-coach-intro",
    "tip-best-coach-step-1",
    "tip-best-coach-step-2",
    "tip-best-coach-step-3",
    "tip-athletes-dream-complement-1",
    "tip-athletes-dream-complement-2",
    "tip-well-done-nap-dyk",
    "tip-well-done-nap-hacks",
    "tip-well-done-nap-reprogram",
    "tip-team-but-up-to-you-intro",
    "tip-team-but-up-to-you-complement-1",
    "tip-team-but-up-to-you-complement-2",
    "tip-team-but-up-to-you-step-1",
    "tip-team-but-up-to-you-step-2",
    "tip-team-but-up-to-you-step-3",
    "tip-team-but-up-to-you-visualization",
    "tip-sleep-environment-dyk",
    "tip-sleep-environment-quote-2",
    "tip-sleep-environment-quote-3",
    "tip-sleep-environment-quote-4",
    "tip-sleep-environment-step-1",
    "tip-sleep-environment-step-2",
    "tip-strengths-weaknesses-intro",
    "tip-strengths-weaknesses-context",
    "tip-strengths-weaknesses-step-1",
    "tip-strengths-weaknesses-step-2",
    "tip-strengths-weaknesses-step-3",
    "tip-strengths-weaknesses-visualization",
    "tip-effects-jetlag-complement",
    "tip-effects-jetlag-dyk",
    "tip-effects-jetlag-hacks",
    "tip-effects-jetlag-steps-1",
    "tip-effects-jetlag-steps-2",
    "tip-effects-jetlag-steps-3",
    "tip-effects-jetlag-steps-4",
    "tip-answers-beginning-dyk",
    "tip-answers-beginning-intro",
    "tip-answers-beginning-hacks",
    "tip-answers-beginning-step-1",
    "tip-answers-beginning-step-2",
    "tip-answers-beginning-step-3",
    "tip-answers-beginning-visualization",
    "tip-whom-they-come-context",
    "tip-whom-they-come-intro",
    "tip-whom-they-come-quote-2",
    "tip-whom-they-come-step-1",
    "tip-whom-they-come-step-2",
    "tip-whom-they-come-step-3",
    "tip-whom-they-come-step-4",
    "tip-whom-they-come-visualization",
    "tip-get-out-bed-dyk",
    "tip-get-out-bed-intro",
    "tip-get-out-bed-hacks",
    "tip-get-out-bed-step-1",
    "tip-get-out-bed-step-2",
    "tip-get-out-bed-step-3",
    "tip-get-out-bed-step-4",
    "tip-get-out-bed-step-5",
    "tip-get-out-bed-visualization",
    "tip-dont-stop-learning-dyk",
    "tip-dont-stop-learning-context",
    "tip-dont-stop-learning-step-1",
    "tip-dont-stop-learning-step-2",
    "tip-dont-stop-learning-step-3",
    "tip-dont-stop-learning-step-4",
    "tip-dont-stop-learning-intro",
    "tip-finish-what-you-do-context-1",
    "tip-finish-what-you-do-context-2",
    "tip-finish-what-you-do-intro",
    "tip-finish-what-you-do-motivational",
    "tip-finish-what-you-do-visualization",
    "tip-idols-referents-01",
    "tip-idols-referents-03",
    "tip-idols-referents-04",
    "tip-idols-referents-09",
    "tip-idols-referents-10",
    "tip-idols-referents-11",
    "tip-idols-referents-12",
    "tip-idols-referents-13",
    "tip-relationship-sleep-01",
    "tip-relationship-sleep-02",
    "tip-relationship-sleep-09",
    "tip-sleep-diary-01",
    "tip-sleep-diary-05",
    "tip-sleep-diary-06",
    "tip-sleep-diary-07",
    "tip-sleep-diary-08",
    "tip-sleep-diary-09",
    "tip-win-in-your-mind-01",
    "tip-win-in-your-mind-02",
    "tip-win-in-your-mind-04",
    "tip-win-in-your-mind-08",
    "tip-win-in-your-mind-09",
    "tip-make-your-team-your-banner-01",
    "tip-make-your-team-your-banner-02",
    "tip-make-your-team-your-banner-03",
    "tip-make-your-team-your-banner-04",
    "tip-make-your-team-your-banner-05",
    "tip-savings-1",
    "tip-diversification-of-income-sources-1",
    "tip-financial-education-1",
    "tip-investments-1",
    "tip-long-term-planning-1",
    "tip-budget-1",
    "tip-processed-foods-1",
    "tip-carbohydrates-1",
    "tip-healthy-fats-1",
    "tip-hydration-1",
    "tip-omega-3-1",
    "tip-proteins-1",
    "visualization-change-your-habits-and-transform-your-life",
    "visualization-embrace-your-strengths",
    "visualization-energizing-visualization",
    "visualization-how-to-give-the-best-to-your-team",
    "visualization-learn-to-handle-criticism",
    "visualization-make-your-mind-your-best-ally",
    "visualization-reprogram-yourself-to-grow",
    "visualization-the-pleasure-of-finishing-what-you-do",
    "visualization-visualize-it-first-in-your-mind",
    "visualization-your-idol-sleeps-in-your-bed",
    "jo-canales-ls-fitness-pre-game-activation",
    "jo-canales-ls-fitness-cool-down",
    "jo-canales-ls-fitness-warm-up",
    "jo-canales-ls-fitness-3x3-shuttle-run-loop",
    "jo-canales-ls-fitness-3x3-shuttle-run-smh",
    "jo-canales-ls-fitness-ball-taps-loop",
    "jo-canales-ls-fitness-ball-taps-smh",
    "jo-canales-ls-fitness-ball-twist-loop",
    "jo-canales-ls-fitness-ball-twist-smh",
    "jo-canales-ls-fitness-banded-pulls-loop",
    "jo-canales-ls-fitness-banded-pulls-smh",
    "jo-canales-ls-fitness-bicep-curls-db-loop",
    "jo-canales-ls-fitness-bicep-curls-db-smh",
    "jo-canales-ls-fitness-bicep-curls-object-loop",
    "jo-canales-ls-fitness-bicep-curls-object-smh",
    "jo-canales-ls-fitness-bicycle-abs-loop",
    "jo-canales-ls-fitness-bicycle-abs-smh",
    "jo-canales-ls-fitness-box-dips-loop",
    "jo-canales-ls-fitness-box-dips-smh",
    "jo-canales-ls-fitness-broad-jump-loop",
    "jo-canales-ls-fitness-broad-jump-smh",
    "jo-canales-ls-fitness-burpees-loop",
    "jo-canales-ls-fitness-burpees-smh",
    "jo-canales-ls-fitness-c-ups-loop",
    "jo-canales-ls-fitness-c-ups-smh",
    "jo-canales-ls-fitness-deadlift-loop",
    "jo-canales-ls-fitness-deadlift-smh",
    "jo-canales-ls-fitness-floor-bench-db-loop",
    "jo-canales-ls-fitness-floor-bench-db-smh",
    "jo-canales-ls-fitness-floor-press-object-loop",
    "jo-canales-ls-fitness-floor-press-object-smh",
    "jo-canales-ls-fitness-flutter-kicks-loop",
    "jo-canales-ls-fitness-flutter-kicks-smh",
    "jo-canales-ls-fitness-glute-bridge-loop",
    "jo-canales-ls-fitness-glute-bridge-smh",
    "jo-canales-ls-fitness-good-mornings-loop",
    "jo-canales-ls-fitness-good-mornings-smh",
    "jo-canales-ls-fitness-half-burpees-loop",
    "jo-canales-ls-fitness-half-burpees-smh",
    "jo-canales-ls-fitness-high-knees-displacement-loop",
    "jo-canales-ls-fitness-high-knees-displacement-smh",
    "jo-canales-ls-fitness-high-knees-loop",
    "jo-canales-ls-fitness-high-knees-smh",
    "jo-canales-ls-fitness-high-pulls-object-loop",
    "jo-canales-ls-fitness-high-pulls-object-smh",
    "jo-canales-ls-fitness-inchworm-loop",
    "jo-canales-ls-fitness-inchworm-smh",
    "jo-canales-ls-fitness-inchworm-push-up-loop",
    "jo-canales-ls-fitness-inchworm-push-up-smh",
    "jo-canales-ls-fitness-in-out-loop",
    "jo-canales-ls-fitness-in-out-smh",
    "jo-canales-ls-fitness-jumping-jacks-loop",
    "jo-canales-ls-fitness-jumping-jacks-smh",
    "jo-canales-ls-fitness-jumping-lunges-loop",
    "jo-canales-ls-fitness-jumping-lunges-smh",
    "jo-canales-ls-fitness-jump-squat-loop",
    "jo-canales-ls-fitness-jump-squat-smh",
    "jo-canales-ls-fitness-lateral-jumps-loop",
    "jo-canales-ls-fitness-lateral-jumps-smh",
    "jo-canales-ls-fitness-lunges-db-loop",
    "jo-canales-ls-fitness-lunges-db-smh",
    "jo-canales-ls-fitness-lunges-loop",
    "jo-canales-ls-fitness-lunges-smh",
    "jo-canales-ls-fitness-lunges-object-loop",
    "jo-canales-ls-fitness-lunges-object-smh",
    "jo-canales-ls-fitness-mountain-climbers-loop",
    "jo-canales-ls-fitness-mountain-climbers-smh",
    "jo-canales-ls-fitness-pendlay-row-db-loop",
    "jo-canales-ls-fitness-pendlay-row-db-smh",
    "jo-canales-ls-fitness-pendlay-row-object-loop",
    "jo-canales-ls-fitness-pendlay-row-object-smh",
    "jo-canales-ls-fitness-plank-dynamic-loop",
    "jo-canales-ls-fitness-plank-dynamic-smh",
    "jo-canales-ls-fitness-plank-jacks-loop",
    "jo-canales-ls-fitness-plank-jacks-smh",
    "jo-canales-ls-fitness-plank-knee-elbow-loop",
    "jo-canales-ls-fitness-plank-knee-elbow-smh",
    "jo-canales-ls-fitness-plank-loop",
    "jo-canales-ls-fitness-plank-smh",
    "jo-canales-ls-fitness-plank-toe-touches-loop",
    "jo-canales-ls-fitness-plank-toe-touches-smh",
    "jo-canales-ls-fitness-plank-tricep-extension-loop",
    "jo-canales-ls-fitness-plank-tricep-extension-smh",
    "jo-canales-ls-fitness-pull-ups-loop",
    "jo-canales-ls-fitness-pull-ups-smh",
    "jo-canales-ls-fitness-push-ups-loop",
    "jo-canales-ls-fitness-push-ups-smh",
    "jo-canales-ls-fitness-run-loop",
    "jo-canales-ls-fitness-run-smh",
    "jo-canales-ls-fitness-russian-twist-loop",
    "jo-canales-ls-fitness-russian-twist-smh",
    "jo-canales-ls-fitness-scissors-loop",
    "jo-canales-ls-fitness-scissors-smh",
    "jo-canales-ls-fitness-shoulder-db-loop",
    "jo-canales-ls-fitness-shoulder-db-smh",
    "jo-canales-ls-fitness-shoulder-object-loop",
    "jo-canales-ls-fitness-shoulder-object-smh",
    "jo-canales-ls-fitness-shoulder-taps-loop",
    "jo-canales-ls-fitness-shoulder-taps-smh",
    "jo-canales-ls-fitness-single-leg-bridge-loop",
    "jo-canales-ls-fitness-single-leg-bridge-smh",
    "jo-canales-ls-fitness-single-leg-deadlift-loop",
    "jo-canales-ls-fitness-single-leg-deadlift-smh",
    "jo-canales-ls-fitness-single-unders-loop",
    "jo-canales-ls-fitness-single-unders-smh",
    "jo-canales-ls-fitness-sit-crunches-loop",
    "jo-canales-ls-fitness-sit-crunches-smh",
    "jo-canales-ls-fitness-split-squat-loop",
    "jo-canales-ls-fitness-split-squat-smh",
    "jo-canales-ls-fitness-squats-db-loop",
    "jo-canales-ls-fitness-squats-db-smh",
    "jo-canales-ls-fitness-squats-loop",
    "jo-canales-ls-fitness-squats-smh",
    "jo-canales-ls-fitness-squats-object-loop",
    "jo-canales-ls-fitness-squats-object-smh",
    "jo-canales-ls-fitness-step-ups-loop",
    "jo-canales-ls-fitness-step-ups-smh",
    "jo-canales-ls-fitness-superman-loop",
    "jo-canales-ls-fitness-superman-smh",
    "jo-canales-ls-fitness-trx-rows-loop",
    "jo-canales-ls-fitness-trx-rows-smh",
    "jo-canales-ls-fitness-uneven-push-ups-loop",
    "jo-canales-ls-fitness-uneven-push-ups-smh",
    "jo-canales-ls-fitness-walking-lunges-loop",
    "jo-canales-ls-fitness-walking-lunges-smh",
    "jo-canales-ls-fitness-wall-sit-loop",
    "jo-canales-ls-fitness-wall-sit-smh",
    "jo-canales-ls-skills-displacement",
    "jo-canales-ls-skills-pull-ups-instep",
    "jo-canales-ls-skills-pull-ups-thigh",
    "jo-canales-ls-skills-control-insteps-thighs",
    "jo-canales-ls-skills-driving-lateral",
    "jo-canales-ls-skills-driving-free",
    "jo-canales-ls-skills-control-targeted",
    "jo-canales-ls-skills-roulette-zidane",
    "jo-canales-ls-skills-pass",
    "jo-canales-ls-skills-pass-alternate",
    "jo-canales-ls-skills-reception-chest",
    "jo-canales-ls-skills-shot-outside-box",
    "jo-canales-ls-skills-volley-hitting",
    "jo-canales-ls-skills-pull-ups-inner",
    "jo-canales-ls-skills-pull-ups-inner-instep",
    "jo-canales-ls-skills-zig-zag",
    "jo-canales-pt-program-full-body-home-welcome",
    "jo-canales-pt-program-master-core-welcome",
    "jo-canales-pt-program-power-legs-welcome",
    "jo-canales-pt-program-workout-express-welcome",
    "jo-canales-pt-program-qatar-road-welcome",
    "jo-canales-pt-program-pre-season-welcome",
    "jo-canales-pt-program-full-body-welcome",
    "jo-canales-pt-program-soccer-skills-welcome",
    "jo-canales-pt-program-new-years-resolution-welcome",
    "pavel-pardo-ls-mindset-1-visualization",
    "pavel-pardo-ls-mindset-2-inner-speech",
    "pavel-pardo-ls-mindset-3-self-motivation",
    "pavel-pardo-ls-mindset-4-mental-growth",
    "pavel-pardo-ls-motivation-1",
    "pavel-pardo-ls-motivation-2",
    "pavel-pardo-ls-motivation-3",
    "pavel-pardo-ls-motivation-4",
    "pavel-pardo-ls-program-welcome-1",
    "pavel-pardo-ls-program-welcome-2",
    "pavel-pardo-ls-skills-bike",
    "pavel-pardo-ls-skills-outside-shot",
    "pavel-pardo-ls-skills-pass-control-int",
    "pavel-pardo-ls-skills-pass-control-tri",
    "pavel-pardo-ls-skills-poison-dart",
    "pavel-pardo-ls-skills-zig-zag",
    "pavel-pardo-ls-stories-1",
    "pavel-pardo-ls-stories-2",
    "pavel-pardo-ls-stories-3",
    "pavel-pardo-ls-stories-4",
    "pavel-pardo-pt-challenge-kick-ups",
    "pavel-pardo-pt-marketing-1",
    "pavel-pardo-pt-marketing-2",
    "rafa-marquez-ls-mindset-1-determination",
    "rafa-marquez-ls-mindset-2-anger-management",
    "rafa-marquez-ls-mindset-3-leadership",
    "rafa-marquez-ls-mindset-4-discipline",
    "rafa-marquez-ls-motivation-1",
    "rafa-marquez-ls-motivation-2",
    "rafa-marquez-ls-motivation-3",
    "rafa-marquez-ls-motivation-4",
    "rafa-marquez-ls-program-close",
    "rafa-marquez-ls-program-welcome",
    "rafa-marquez-ls-skills-headshot",
    "rafa-marquez-ls-skills-long-shot",
    "rafa-marquez-ls-skills-free-kick",
    "rafa-marquez-ls-skills-art-defending-1",
    "rafa-marquez-ls-skills-art-defending-2",
    "rafa-marquez-ls-stories-1",
    "rafa-marquez-ls-stories-2",
    "rafa-marquez-ls-stories-3",
    "rafa-marquez-ls-stories-4",
    "rafa-marquez-pt-marketing-1",
    "rafa-marquez-pt-marketing-2",
    "rafa-marquez-pt-marketing-3",
    "rafa-marquez-pt-marketing-4",
    "carlos-salcido-ls-mindset-1-inspiration",
    "carlos-salcido-ls-mindset-2-self-esteem",
    "carlos-salcido-ls-mindset-3-resilience",
    "carlos-salcido-ls-mindset-4-perseverance",
    "carlos-salcido-ls-skills-outline",
    "carlos-salcido-ls-skills-approach-speed",
    "carlos-salcido-ls-skills-blocking",
    "carlos-salcido-ls-stories-1",
    "carlos-salcido-ls-stories-2",
    "carlos-salcido-ls-stories-3",
    "carlos-salcido-ls-stories-4",
    "carlos-salcido-pt-marketing-1",
    "charlyn-corral-ls-mindset-1-courage",
    "charlyn-corral-ls-mindset-2-modesty",
    "charlyn-corral-ls-mindset-3-sense-agency",
    "charlyn-corral-ls-mindset-4-flow",
    "charlyn-corral-ls-skills-cutting-hitting-ball",
    "charlyn-corral-ls-skills-little-hat",
    "charlyn-corral-ls-stories-1",
    "charlyn-corral-ls-stories-2",
    "charlyn-corral-ls-stories-3",
    "charlyn-corral-ls-stories-4",
    "charlyn-corral-pt-marketing-1",
    "charlyn-norma-pt-marketing-1",
    "jared-borgetti-ls-mindset-1-empathy",
    "jared-borgetti-ls-mindset-2-gratitude",
    "jared-borgetti-ls-mindset-3-overcoming",
    "jared-borgetti-ls-mindset-4-persistence",
    "jared-borgetti-ls-skills-header",
    "jared-borgetti-ls-skills-scorer",
    "jared-borgetti-ls-skills-art-forward",
    "jared-borgetti-ls-stories-1",
    "jared-borgetti-ls-stories-2",
    "jared-borgetti-ls-stories-3",
    "jared-borgetti-ls-stories-4",
    "jared-borgetti-pt-marketing-1",
    "norma-palafox-ls-mindset-1-tenacity",
    "norma-palafox-ls-mindset-2-confidence",
    "norma-palafox-ls-mindset-3-hope",
    "norma-palafox-ls-mindset-4-decision-taking",
    "norma-palafox-ls-skills-speed",
    "norma-palafox-ls-skills-dribbling",
    "norma-palafox-charlyn-corral-ls-skills-walls",
    "norma-palafox-ls-stories-1",
    "norma-palafox-ls-stories-2",
    "norma-palafox-pt-marketing-1",
    "oswaldo-sanchez-ls-mindset-1-will",
    "oswaldo-sanchez-ls-mindset-2-flexibility",
    "oswaldo-sanchez-ls-mindset-3-loss-management",
    "oswaldo-sanchez-ls-mindset-4-concentration",
    "oswaldo-sanchez-ls-stories-1",
    "oswaldo-sanchez-ls-stories-2",
    "oswaldo-sanchez-ls-stories-3",
    "oswaldo-sanchez-ls-stories-4",
    "oswaldo-sanchez-pt-marketing-1",
    "gigi-buffon-ls-stories-1",
    "gigi-buffon-ls-stories-2",
    "gigi-buffon-ls-stories-3",
    "gigi-buffon-ls-stories-4",
    "gigi-buffon-ls-mindset-teamwork",
    "gigi-buffon-ls-mindset-mental-growth",
    "gigi-buffon-ls-mindset-perseverance-value",
    "gigi-buffon-ls-mindset-leadership",
    "gigi-buffon-ls-skills-stop-grab",
    "gigi-buffon-ls-skills-side-falls",
    "gigi-buffon-ls-skills-goal-orientation",
    "gigi-buffon-ls-skills-reaction-capacity",
    "gigi-buffon-ls-skills-high-output-1",
    "gigi-buffon-ls-skills-high-output-2",
    "gigi-buffon-ls-skills-low-output",
    "gigi-buffon-ls-skills-skill",
    "gigi-buffon-ls-fitness-squats-smh",
    "gigi-buffon-ls-fitness-squats-loop",
    "gigi-buffon-ls-fitness-jump-squats-smh",
    "gigi-buffon-ls-fitness-jump-squats-loop",
    "gigi-buffon-ls-fitness-squats-unstable-smh",
    "gigi-buffon-ls-fitness-squats-unstable-loop",
    "gigi-buffon-ls-fitness-weighted-squats-smh",
    "gigi-buffon-ls-fitness-weighted-squats-loop",
    "gigi-buffon-ls-fitness-dynamic-movement-ball-smh",
    "gigi-buffon-ls-fitness-dynamic-movement-ball-loop",
    "gigi-buffon-ls-fitness-single-leg-lunge-smh",
    "gigi-buffon-ls-fitness-single-leg-lunge-loop",
    "gigi-buffon-ls-fitness-opposite-single-leg-lunge-smh",
    "gigi-buffon-ls-fitness-opposite-single-leg-lunge-loop",
    "gigi-buffon-ls-fitness-strength-smh",
    "gigi-buffon-ls-fitness-strength-loop",
    "gigi-buffon-ls-fitness-toning-abs-smh",
    "gigi-buffon-ls-fitness-toning-abs-side-plank-smh",
    "gigi-buffon-ls-fitness-toning-abs-bridge-smh",
    "gigi-buffon-ls-fitness-toning-abs-plank-fitball-smh",
    "gigi-buffon-ls-fitness-toning-waist-back-smh",
    "gigi-buffon-ls-fitness-stability-abs-smh",
    "gigi-buffon-ls-fitness-stability-abs-loop",
    "gigi-buffon-ls-fitness-stimulation-abs-smh",
    "gigi-buffon-ls-fitness-toning-body-smh",
    "gigi-buffon-ls-fitness-toning-shoulder-pectoral-smh",
    "gigi-buffon-ls-fitness-toning-shoulder-pectoral-loop",
    "gigi-buffon-ls-fitness-bench-push-ups-smh",
    "gigi-buffon-ls-fitness-bench-push-ups-loop",
    "gigi-buffon-ls-fitness-push-ups-unstable-smh",
    "gigi-buffon-ls-fitness-push-ups-unstable-loop",
    "gigi-buffon-ls-fitness-wirst-strength-smh",
    "gigi-buffon-ls-fitness-wirst-strength-loop",
    "gigi-buffon-ls-fitness-push-ups-handles-smh",
    "gigi-buffon-ls-fitness-push-ups-handles-loop",
    "gigi-buffon-ls-fitness-dorsal-handles-smh",
    "gigi-buffon-ls-fitness-dorsal-handles-loop",
    "gigi-buffon-ls-fitness-back-muscles-handles-smh",
    "gigi-buffon-ls-fitness-back-muscles-handles-loop",
    "gigi-buffon-ls-fitness-shoulder-back-stability-smh",
    "gigi-buffon-ls-fitness-shoulder-back-stability-loop",
    "test-ls-challenge-1",
    "test-ls-challenge-2",
    "test-pt-challenge-1",
    "test-pt-challenge-2",
    "test-pt-crack-1",
    "test-pt-crack-2",
    "program-secret-stress-management-day01-07",
    "program-secret-stress-management-day01-06",
    "program-secret-stress-management-day01-05",
    "program-secret-stress-management-day01-04",
    "program-secret-stress-management-day01-03",
    "program-secret-stress-management-day01-02",
    "program-secret-stress-management-day01-01",
    "program-secret-stress-management-day02-01",
    "program-secret-stress-management-day02-02",
    "program-secret-stress-management-day02-03",
    "program-secret-stress-management-day02-04",
    "program-secret-stress-management-day02-05",
    "program-secret-stress-management-day02-06",
    "program-secret-stress-management-day02-07",
    "program-secret-stress-management-day02-08",
    "program-secret-stress-management-day02-09",
    "program-secret-stress-management-day02-10",
    "program-secret-stress-management-day03-01",
    "program-secret-stress-management-day03-02",
    "program-secret-stress-management-day03-03",
    "program-secret-stress-management-day03-04",
    "program-secret-stress-management-day03-05",
    "program-secret-stress-management-day03-06",
    "program-secret-stress-management-day03-07",
    "program-secret-stress-management-day04-01",
    "program-secret-stress-management-day05-01",
    "program-secret-stress-management-day05-02",
    "program-secret-stress-management-day05-03",
    "program-secret-stress-management-day05-04",
    "program-secret-stress-management-day05-05",
    "program-secret-stress-management-day06-01",
    "program-secret-stress-management-day06-02",
    "program-secret-stress-management-day06-04",
    "program-secret-stress-management-day06-05",
    "program-secret-stress-management-day07-01",
    "program-secret-stress-management-day08-01",
    "program-secret-stress-management-day08-02",
    "program-secret-stress-management-day08-03",
    "program-secret-stress-management-day08-04",
    "program-secret-stress-management-day09-01",
    "program-secret-stress-management-day09-02",
    "program-secret-stress-management-day09-03",
    "program-secret-stress-management-day09-05",
    "program-secret-stress-management-day09-06",
    "program-secret-stress-management-day09-08",
    "program-secret-stress-management-day09-09",
    "program-secret-stress-management-day10-01",
    "program-secret-stress-management-day10-02",
    "program-secret-stress-management-day10-04",
    "placeholder-tip-1",
    "placeholder-tip-2",
    "placeholder-tip-3",
    "placeholder-tip-4",
    "placeholder-tip-5",
    "placeholder-tip-6",
    "placeholder-tip-7",
    "placeholder-tip-8",
    "placeholder-tip-9",
    "placeholder-tip-10",
    "placeholder-tip-11",
    "placeholder-tip-12",
    "placeholder-tip-13",
    "placeholder-tip-14",
    "placeholder-tip-15",
    "placeholder-tip-16",
    "placeholder-tip-17",
    "placeholder-tip-18",
    "placeholder-tip-19",
    "placeholder-tip-20",
    "placeholder-tip-21",
    "placeholder-tip-22",
    "placeholder-tip-23",
    "placeholder-tip-24",
    "placeholder-tip-25",
    "placeholder-tip-26",
    "placeholder-tip-27",
    "placeholder-tip-28",
    "placeholder-tip-29",
    "placeholder-tip-30",
    "placeholder-tip-31",
    "placeholder-tip-32",
    "placeholder-tip-33",
    "placeholder-tip-34",
    "placeholder-tip-35",
    "placeholder-tip-36",
    "placeholder-tip-37",
    "placeholder-tip-38",
    "placeholder-tip-39",
    "placeholder-tip-40",
    "placeholder-tip-41",
    "placeholder-tip-42",
    "placeholder-tip-43",
    "placeholder-tip-44",
    "placeholder-tip-45",
    "placeholder-tip-46",
    "placeholder-tip-47",
    "placeholder-tip-48",
    "placeholder-tip-49",
    "placeholder-tip-50",
    "placeholder-program-1",
    "placeholder-program-2",
    "placeholder-program-3",
    "placeholder-program-4",
    "placeholder-program-5",
    "placeholder-program-6",
    "placeholder-program-7",
    "placeholder-program-8",
    "placeholder-program-9",
    "placeholder-program-10",
    "placeholder-program-11",
    "placeholder-program-12",
    "placeholder-program-13",
    "placeholder-program-14",
    "placeholder-program-15",
    "placeholder-program-16",
    "placeholder-program-17",
    "placeholder-program-18",
    "placeholder-program-19",
    "placeholder-program-20",
    "placeholder-program-21",
    "placeholder-program-22",
    "placeholder-program-23",
    "placeholder-program-24",
    "placeholder-program-25",
    "placeholder-program-26",
    "placeholder-program-27",
    "placeholder-program-28",
    "placeholder-program-29",
    "placeholder-program-30",
    "placeholder-program-31",
    "placeholder-program-32",
    "placeholder-program-33",
    "placeholder-program-34",
    "placeholder-program-35",
    "placeholder-program-36",
    "placeholder-program-37",
    "placeholder-program-38",
    "placeholder-program-39",
    "placeholder-program-40",
    "placeholder-program-41",
    "placeholder-program-42",
    "placeholder-program-43",
    "placeholder-program-44",
    "placeholder-program-45",
    "placeholder-program-46",
    "placeholder-program-47",
    "placeholder-program-48",
    "placeholder-program-49",
    "placeholder-program-50",
    "shorts-rafa-marquez-this-is-the-key-to-success",
    "shorts-carlos-salcido-resilience",
    "shorts-charlyn-corral-flow-is-the-nature-of-life",
    "shorts-jared-borgetti-be-thankful-and-enjoy-every-moment",
    "shorts-norma-palafox-the-barrier-of-fear",
    "shorts-pavel-pardo-do-you-feel-like-you-lack-motivation",
    "program-secret-sleep-day01-01",
    "program-secret-sleep-day01-02",
    "program-secret-sleep-day01-03",
    "program-secret-sleep-day01-04",
    "program-secret-sleep-day02-01",
    "program-secret-sleep-day02-12",
    "program-secret-sleep-day03-01",
    "program-secret-sleep-day03-02",
    "program-secret-sleep-day03-03",
    "program-secret-sleep-day03-04",
    "program-secret-sleep-day03-05",
    "program-secret-sleep-day04-01",
    "program-secret-sleep-day05-01",
    "program-secret-sleep-day05-08",
    "program-secret-sleep-day05-09",
    "program-secret-sleep-day05-10",
    "program-secret-sleep-day05-11",
    "program-secret-sleep-day06-01",
    "program-secret-sleep-day06-02",
    "program-secret-sleep-day06-03",
    "program-secret-sleep-day07-01",
    "program-secret-sleep-day07-02",
    "program-secret-sleep-day07-03",
    "program-secret-sleep-day08-01",
    "program-secret-sleep-day08-02",
    "program-secret-sleep-day08-03",
    "program-secret-sleep-day08-04",
    "program-secret-sleep-day08-05",
    "program-secret-sleep-day09-01",
    "program-secret-sleep-day09-02",
    "program-secret-sleep-day09-03",
    "program-secret-sleep-day09-04",
    "program-secret-sleep-day10-01",
    "program-secret-sleep-day10-02",
    "program-secret-sleep-day10-03",
    "program-secret-sleep-day11-01",
    "program-secret-sleep-day11-03",
    "program-secret-sleep-day12-01",
] as const;

export type UuidVideoAsset = typeof UUID_VIDEO_ASSET[number];

export type FilenameVideoAsset<Uuid extends string> =
    `/${GroupVideoAsset}/${PrefixVideoAsset}_${Uuid}${ExtVideoAsset}`;

export const VIDEO_ASSET = UUID_VIDEO_ASSET.reduce(
    (acc, uuid) => ({
        ...acc,
        [uuid]: ["", GROUP_VIDEO_ASSET, `${PREFIX_VIDEO_ASSET}_${uuid}${EXT_VIDEO_ASSET}`].join(
            "/",
        ),
    }),
    {} as { readonly [K in UuidVideoAsset]: FilenameVideoAsset<K> },
);

export type VideoAsset = typeof VIDEO_ASSET;
export type ValueVideoAsset = VideoAsset[UuidVideoAsset];

export function GetterVideoAsset(url: string) {
    return UUID_VIDEO_ASSET.reduce(
        (acc, uuid) => ({ ...acc, [uuid]: `${url}${VIDEO_ASSET[uuid]}` as const }),
        {} as { [K in UuidVideoAsset]: `${typeof url}${FilenameVideoAsset<K>}` },
    );
}
