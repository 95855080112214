export const STATUS_DATA = {
    ENABLED: 1,
    DISABLED: 0,
};

export const STATUS = {
    ACTIVE: "active",
    CANCELLED: "cancelled",
    IN_PROGRESS: "in_progress",
};

export type StatusKey = keyof typeof STATUS_DATA;

export function isStatus(entry: unknown): entry is StatusKey {
    const status = String(entry);
    return Object.keys(STATUS_DATA).includes(status);
}

export const NUMBER = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    TEN: 10,
    TWELVE: 12,
    THIRTEEN: 13,
    SIXTEEN: 16,
    TUENTYTWO: 22,
    SIXTY: 60,
    ONE_HUNDRED: 100,
    ONE_THOUSAND: 1000,
    TWO_HUNDRED: 200,
    MEGATIVETWO: -2,
    SIXTY_THOUSAND: 60000,
};
