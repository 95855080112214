export const NAME_SLICE_ENROLLMENT = "enrollment";

export declare namespace NSSliceEnrollment {
    /** Determines if the user has enrolled */
    export type hasCreatedPassword = boolean;

    export type State = {
        hasEnrolled?: hasCreatedPassword;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_ENROLLMENT: NSSliceEnrollment.State = {
    hasEnrolled: false,
};
