// import type { PlanMC } from "@common/content/payment/plan";
import type { UuidDataText } from "@common/content/text";
import type { Movement } from "@common/content/payment/paymentMethod";
import type { SubscriptionByUser } from "./_actions";

export type PropPlanItem = {
    /** Title */
    title: UuidDataText;
    /** Subtitle */
    subtitle: UuidDataText;
    /** If this item is included on the plan */
    isIncluded: boolean;
};

export type Charge = {
    id: string;
    authorization: string;
    operation_type: string;
    transaction_type: string;
    status: string;
    conciliated: boolean;
    creation_date: string;
    operation_date: string;
    description: string;
    error_message: string;
    order_id: string;
    customer_id: string;
    due_date: string;
    amount: number;
    payment_method: {
        type: string;
        reference: string;
        barcode_url: string;
    };
    currency: string;
    method: string;
    url_pdf: string;
    url_web: string;
};

export type PlanMC = {
    id: string;
    title?: UuidDataText;
    subtitle?: UuidDataText;
    specifications: PropPlanItem[];
    amount?: string;
    currency?: string;
    name: string;
    type?: string;
};

export const NAME_SLICE_PAYMENT = "payment";

export declare namespace NSSlicePayment {
    export type Discount = {
        planValue: number;
        uuidPlan: string;
        idPlanOpenpay: string;
        description: string;
        discount: number;
        subtotal: number;
        discountValue: number;
        promoCode: unknown;
    };

    export type History = {
        plan: string;
        date: string;
        price: string;
        download: string;
    };

    export type CreditCard = {
        number: string;
        code: string;
        holder: string;
        expirationDate: string;
    };

    export type State = {
        creditCard?: CreditCard | null;
        uuidPlan: string | null;
        plan?: PlanMC;
        historyPayment: History[];
        subscription: SubscriptionByUser | null;
        idCardRemove?: string;
        uuidCreditCard: string | null;
        movements: Movement[];
        charge?: Charge;
        discount?: Discount;
    };

    /** Arguments for `set` action: any partial slice of the state */
    export type ArgSet = Partial<State>;

    /** Arguments for `clear` action */
    export type ArgClear = {
        /** Which property of the slice should be cleared. */
        key: keyof State;
    };
}

export const INIT_SLICE_PAYMENT: NSSlicePayment.State = {
    uuidPlan: null,
    historyPayment: [],
    uuidCreditCard: null,
    subscription: null,
    movements: [],
    charge: undefined,
    discount: undefined,
};
