import React from "@platform/react";
import { ButtonPlatform } from "@platform/react-storybook/Component/Button";
import { useFormContext } from "react-hook-form";

import { useText } from "@front/components/Element/Text";
import { IconAsset } from "@front/components/Element/Asset/Icon";
import { Tracker } from "@front/components/Element/Tracker";

import type { PropTypesButton } from "./types";
import { DEF_BUTTON, NAME_BUTTON, TYPE_BUTTON } from "./types";
import "./_rules.scss";

export function Button(props: PropTypesButton) {
    const { children, preset, text, icon, permittedDisabled = true, ...rest } = props;
    const { textGet } = useText();
    const formContext = useFormContext();
    const isDirty = permittedDisabled ? formContext && !formContext.formState.isDirty : false;
    return (
        <Tracker>
            <ButtonPlatform
                {...rest}
                data-name={NAME_BUTTON}
                data-preset={preset}
                data-type={TYPE_BUTTON}
                {...(formContext && { disabled: isDirty })}>
                {children || (
                    <React.Fragment>
                        {icon && <IconAsset uuid={icon} />}
                        {text && <span>{textGet({ uuid: text })}</span>}
                    </React.Fragment>
                )}
            </ButtonPlatform>
        </Tracker>
    );
}
Button.defaultProps = DEF_BUTTON;
