export const PrivacyEN = `
<h1 class="terms-title">PRIVACY POLICY</h1>
<p class="terms-paragraph">
    INTEGRAL PRIVACY POLICY FOR THE ONLINE PLATFORM: “MASTER CRACKS” (hereinafter, the “Privacy
    Policy”).
</p>
<p class="terms-paragraph">
    PLATAFORMA SOCCER CRACKS, S. DE R.L. DE C.V. (hereinafter, the “Company”), with its domicile in
    Av. Juan Salvador Agraz No. 97, 10th floor, office 1004, Santa Fe, Cuajimalpa de Morelos, Mexico
    City, P.C. 05300, is responsible for the treatment of the personal data provided through the
    Platforms (as said term is defined below), which will be protected in accordance with the
    Federal Personal Data in Possession of Private Persons Protection Act (Ley Federal de Protección
    de Datos Personales en Posesión de los Particulares), its Code and other applicable laws.
</p>
<p class="terms-paragraph">
    The object of this Privacy Policy is to establish the basis on which the personal data collected
    by or provided to us will be processed. The Company is not responsible for the privacy policies
    of other webpages or mobile applications, and we strongly encourage you to read all policies and
    privacy policies that might be applicable while using other webpages and mobile applications.
</p>
<ol>
    <li class="terms-paragraph">Personal data that we will use.</li>
</ol>
<p class="terms-paragraph">
    In order to carry out the purposes described in this Privacy Policy, we’ll be able to collect,
    through our internet site
    <a href="/">www.mastercracks.club</a>
    and through our mobile application Master Cracks (hereinafter, the “Platforms”) from you, the
    following data and information:
</p>
<ol>
    <li class="terms-paragraph">Name(s) and Surname(s);</li>
    <li class="terms-paragraph">E-mail;</li>
    <li class="terms-paragraph">Cellphone number;</li>
    <li class="terms-paragraph">Gender;</li>
    <li class="terms-paragraph">Date of birth;</li>
    <li class="terms-paragraph">Nationality;</li>
    <li class="terms-paragraph">Address (Postal Code, City and State);</li>
    <li class="terms-paragraph">Statistical information;</li>
    <li class="terms-paragraph">Information regarding the usage of our Platforms:</li>
</ol>
<ol>
    <li class="terms-paragraph">Information provided by completing questionnaires;</li>
    <li class="terms-paragraph">
        Information provided by partaking in contests, promotions and/or surveys; and;
    </li>
    <li class="terms-paragraph">
        Information provided while holding communications with the Company and/or its personnel; and
    </li>
</ol>
<ol>
    <li class="terms-paragraph">
        Information collected from you. We can automatically collect from you the following
        information:
    </li>
</ol>
<ol>
    <li class="terms-paragraph">
        Technical information, including your IP (internet protocol) address, search history,
        account information, mobile and network information, operating system, usage platform and
        information regarding your geographical location.
    </li>
    <li class="terms-paragraph">
        Information regarding your visits and access to our Platforms, including URL (Uniform
        Resource Locators), the digital products you had access to, response time of the site,
        downloads, operating errors, duration of the visits to each one of the pages, interaction
        information and resources used to exit the Platforms at hand.
    </li>
</ol>
<ol>
    <li class="terms-paragraph">Sensitive personal data.</li>
</ol>
<p class="terms-paragraph">
    You are hereby informed that, for the purposes foreseen on this Privacy Policy, no sensitive
    data will be collected from you.
</p>
<ol>
    <li class="terms-paragraph">Purposes for which your personal data will be used.</li>
</ol>
<p class="terms-paragraph">
    The personal data collected from you will be used, without limitation, for the following
    purposes:
</p>
<ol>
    <li class="terms-paragraph">Primary purposes:</li>
</ol>
<ol>
    <li class="terms-paragraph">For your registration and, therefore, usage of our Platforms.</li>
    <li class="terms-paragraph">
        For the effective operation and purveying of the best experience possible.
    </li>
    <li class="terms-paragraph">
        For the verification of your identity while accessing your registered account.
    </li>
    <li class="terms-paragraph">
        For the integration of your electronic file as user of our Platforms.
    </li>
    <li class="terms-paragraph">
        To provide answers and follow up with questions, suggestions and complaints made by you in
        relation with the services offered through the Platforms.
    </li>
    <li class="terms-paragraph">To provide the services offered through the Platforms.</li>
</ol>
<ol>
    <li class="terms-paragraph">Secondary purposes:</li>
</ol>
<ol>
    <li class="terms-paragraph">
        For conducting statistic studies regarding the usage of the Platforms by the users.
    </li>
    <li class="terms-paragraph">To offer specific services to the user.</li>
    <li class="terms-paragraph">
        To perform notices and/or notifications of any type regarding offers, changes to the terms
        and conditions of use and/or to this Privacy Policy.
    </li>
    <li class="terms-paragraph">To improve our services.</li>
    <li class="terms-paragraph">To communicate with you.</li>
    <li class="terms-paragraph">To understand your preferences.</li>
    <li class="terms-paragraph">To monitor the use and activity of our Platforms.</li>
    <li class="terms-paragraph">To comply with the applicable legal and administrative rules.</li>
</ol>
<ol>
    <li class="terms-paragraph">Communications.</li>
</ol>
<p class="terms-paragraph">
    Please direct any and all communications regarding this Privacy Policy to the following e-mail:
    <a href="mailto:{{EMAIL}}"> {{EMAIL}} </a>
     Your requests will be addressed by our personnel on workdays and business hours.
</p>
<ol>
    <li class="terms-paragraph">Consent.</li>
</ol>
<p class="terms-paragraph">
    By using any of our Platforms you consent to this Privacy Policy in its entirety and, therefore,
    to us using your personal data. Given the case that you do not agree, partially or completely,
    with this Privacy Policy, you must communicate said situation to us via the e-mail established
    in the immediate section above, to the contrary, it will be understood that you expressly and
    completely agree with this Privacy Policy.
</p>
<ol>
    <li class="terms-paragraph">Collection of the information.</li>
</ol>
<p class="terms-paragraph">
    You agree to us knowing your information, whether you provide said information or we collect it
    through your usage of our Platforms.
</p>
<ol>
    <li class="terms-paragraph">
        Third parties with access to the information and/or personal data.
    </li>
</ol>
<p class="terms-paragraph">
    You agree to us sharing and/or giving access, by any means, to your information to:
</p>
<ol>
    <li class="terms-paragraph">Our personnel;</li>
    <li class="terms-paragraph">Our affiliates;</li>
    <li class="terms-paragraph">
        Public law enforcement entities, such as state, federal or international police;
    </li>
    <li class="terms-paragraph">Service purveyors;</li>
    <li class="terms-paragraph">Our Partners;</li>
    <li class="terms-paragraph">
        Persons, individuals, or entities, which operate our Platforms; and
    </li>
    <li class="terms-paragraph">Third parties that you authorize for said effects.</li>
</ol>
<p class="terms-paragraph">
    The aforementioned persons must comply with the applicable law and regulations. The Company is
    not responsible in case any of these persons breaches the applicable law in relation with the
    user’s information.
</p>
<ol>
    <li class="terms-paragraph">Minors.</li>
</ol>
<p class="terms-paragraph">
    Minors, according with the applicable law, are not allowed to use the Platforms without parental
    consent and strict supervision of the parents and/or guardians. If a minor misuse any of the
    Platforms, the parents and/or guardians of said minor will be solely responsible and will answer
    before the Company for any damage that the minor under their care causes to it.
</p>
<p class="terms-paragraph">
    By using the Platforms, the minor and the minor’s parents and/or guardians free the Company of
    any and all responsibility and are to be responsible for carrying the Company to safety and
    soundness if the Company misuses the personal data and/or information of the minor, given that
    the Company, at all times, will conduct its business and activity as if all of its users are of
    legal age and/or rely with parental approval and strict supervision of the parents and/or
    guardians.
</p>
<ol>
    <li class="terms-paragraph">
        Access, Rectification, Cancellation or Opposition of Personal Data and Revocation of Consent
        Request (ARCO Request).
    </li>
</ol>
<p class="terms-paragraph">
    All of your personal data are to be treated in accordance with the current and applicable law,
    therefore, you’re hereby informed that either you or your legal representative have, at all
    times, the right to access, rectify, cancel or oppose the treatment given to your personal data,
    and the right to revoke the given consent for the treatment of the same; right that can be made
    valid through the following e-mail address:
    <a href="mailto:{{EMAIL}}"> {{EMAIL}} </a>
    , Through said e-mail your requests will be addresses, complying with all requirements, in its
    due time. In order for the Company to follow up with your request, either you or your legal
    representative, shall present any and all identification documents required, for each specific
    case, to verify your identity, to the contrary, the Company will not be obliged to carry your
    request, given that it is the Company’s intent to preserve your personal data at all times.
</p>
<ol>
    <li class="terms-paragraph">Changes to our Privacy Policy.</li>
</ol>
<p class="terms-paragraph">
    Any and all changes made to our Privacy Policy will be informed to the users through the e-mail
    with which they registered in our Platforms. Said changes will also be reflected in this
    document, which may be consulted at any times through any of the Platforms.
</p>
<p class="terms-paragraph">
    By virtue of the aforementioned, it is understood that the user will have, at all times, access
    to the changes and last versions of the Privacy Policy.
</p>
<ol>
    <li class="terms-paragraph">Cookies.</li>
</ol>
<p class="terms-paragraph">
    The user recognizes that the Company, by means of the Platforms, uses cookies and/or similar
    technologies. Cookies are information files stored in your computer or mobile device that
    contain anonymous information identifying the user characteristics while engaging with the
    Platforms. This technology allows the Platforms to “remember” things about the user and modify
    the experience accordingly.
</p>
<ol>
    <li class="terms-paragraph">More Information.</li>
</ol>
<p class="terms-paragraph">
    For more information, please address the following e-mail:
    <a href="mailto:{{EMAIL}}"> {{EMAIL}} </a>
</p>
<p class="terms-paragraph">LAST VERSION, DATED {{DATE}}</p>
`;
