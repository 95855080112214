/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable @typescript-eslint/no-use-before-define */

/// <reference types="@platform/types/react" />

import type React from "@platform/react";

import Namespace from "./_index.scss";
import type { PropElement, PropDefaults, PropTypes } from "../../Component";

/** Enable `window.dataLayer` definition. **/
declare global {
    interface Window {
        dataLayer: Record<string, unknown>[];
    }
}

export type ArgTrackerPlatform = never;

export type EventTrackerPlatform = {
    uuid: string;
    data?: string | number | Record<string, string | number>;
    // TODO: where should this be defined?
    platform: "ANDROID" | "IOS" | "WEB:MOBILE" | "WEB:DESKTOP";
};

export type StateTrackerPlatform = undefined;

export type ContextTrackerPlatform = React.Context<StateTrackerPlatform>;

type ArgCommonTrackerPlatform = {
    Context: ContextTrackerPlatform;
};

export type ArgProviderTrackerPlatform = ArgCommonTrackerPlatform;

export type PropProviderTrackerPlatform = {
    children: NonNullable<PropElement["children"]>;
    gtmID: string;
    gtmURL?: string;
};

export const DEF_PROVIDER_TRACKERPLATFORM: PropDefaults<PropProviderTrackerPlatform> = {
    gtmURL: "https://www.googletagmanager.com/gtm.js",
} as const;

export type PropTypesProviderTrackerPlatform = PropTypes<
    PropProviderTrackerPlatform,
    typeof DEF_PROVIDER_TRACKERPLATFORM
>;

export type ArgConsumerTrackerPlatform = ArgCommonTrackerPlatform;

export type PropConsumerTrackerPlatform = {
    children: NonNullable<PropElement["children"]>;
};

export const {
    NAME_TRACKERPLATFORM,
    TYPE_TRACKERPLATFORM,
    NAME_CONSUMER_TRACKERPLATFORM,
    NAME_PROVIDER_TRACKERPLATFORM,
    TYPE_CONSUMER_TRACKERPLATFORM,
    TYPE_PROVIDER_TRACKERPLATFORM,
} = Namespace;

export const DEF_TRACKERPLATFORM = {} as const;

export const ELEMENT_TRACKERPLATFORM = {
    HEAD: "head",
    SCRIPT: "script",
} as const;

export const ERROR_TRACKERPLATFORM = {
    NO_HEAD: `${NAME_TRACKERPLATFORM}: Expecting a ${ELEMENT_TRACKERPLATFORM.HEAD} element.`,
    NO_PROVIDER: `${NAME_TRACKERPLATFORM}: Expecting a <${NAME_PROVIDER_TRACKERPLATFORM} /> to be available.`,
    INVALID_ELEMENT: `${NAME_TRACKERPLATFORM}: Children must be a ReactElement`,
    INVALID_PROP: `${NAME_TRACKERPLATFORM}: Child must contain props`,
    INVALID_SELECT: `${NAME_TRACKERPLATFORM}: Child is not selectable`,
} as const;

export const DEF_CONSUMER_TRACKERPLATFORM: PropDefaults<PropConsumerTrackerPlatform> = {} as const;

export type FiberNode =
    | ComponentClassFiberNode
    | ComponentFunctionFiberNode
    | IntrinsicElementFiberNode
    | TextFiberNode;

type PropCommonFiberNode = {
    child: FiberNode | null;
    sibling: FiberNode | null;
};

export type ComponentFunctionFiberNode = PropCommonFiberNode & {
    elementType: React.FunctionComponent;
    type: React.FunctionComponent;
    stateNode: null;
};

export type ComponentClassFiberNode = PropCommonFiberNode & {
    elementType: React.ComponentClass;
    type: React.ComponentClass;
    stateNode: React.Component;
};

export type IntrinsicElementFiberNode = PropCommonFiberNode & {
    elementType: keyof JSX.IntrinsicElements;
    type: keyof JSX.IntrinsicElements;
    stateNode: HTMLElement;
};

export type TextFiberNode = PropCommonFiberNode & {
    child: null;
    elementType: null;
    type: null;
    stateNode: Text;
};

export type DOMContainerFiberNode = {
    _reactRootContainer: {
        _internalRoot: {
            current: FiberNode | null;
        };
    };
} & Element;

export type FiberNodeisHTMLLike = IntrinsicElementFiberNode | TextFiberNode;
