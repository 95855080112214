const TEN = 10;
const TWO = 2;

export type TimeFromSeconds = {
    seconds: number;
    minutes: number;
    hours: number;
    days: number;
};

export type TimeFromSecondsAsString = {
    seconds: string;
    minutes: string;
    hours: string;
    days: string;
};
export function getTimeFromSecondsFormatted(props: TimeFromSeconds): TimeFromSecondsAsString {
    const { seconds, minutes, hours, days } = props;
    const result = {
        _seconds: seconds.toString(),
        _minutes: minutes.toString(),
        _hours: hours.toString(),
        _days: days.toString(),
    };
    let { _seconds, _days, _hours, _minutes } = result;
    if (seconds < TEN) _seconds = `${seconds.toString().padStart(TWO, "0")}`;
    if (minutes < TEN) _minutes = `${minutes.toString().padStart(TWO, "0")}`;
    if (hours < TEN) _hours = `${hours.toString().padStart(TWO, "0")}`;
    if (days < TEN) _days = `${days.toString().padStart(TWO, "0")}`;

    return {
        seconds: _seconds,
        minutes: _minutes,
        hours: _hours,
        days: _days,
    };
}
