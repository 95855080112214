export const PREFIX_FONT_THEME = "font" as const;

export const UUID_FONT_THEME = [
    "default",
    "tarvos",
    "febe",
    "mimas",
    "amalthea",
    "janus",
    "narvi",
    "skadi",
    "lapetus",
    "dione",
    "atlas",
    "titan",
    "fenrir",
    "tethys",
] as const;

export type UuidFontTheme = typeof UUID_FONT_THEME[number];
