import { PREFIX_FONT_THEME } from ".";

export const PREFIX_ALIGN_FONT_THEME = `${PREFIX_FONT_THEME}_align` as const;

export const ALIGN_FONT_THEME = {
    default: "left",
    pencil: "right",
    pen: "center",
    brush: "justify",
} as const;

export type AlignFontTheme = typeof ALIGN_FONT_THEME;

export type UuidAlignFontTheme = keyof AlignFontTheme;

export type ValueAlignFontTheme = AlignFontTheme[UuidAlignFontTheme];

export const UUID_ALIGN_FONT_THEME = Object.keys(ALIGN_FONT_THEME) as readonly UuidAlignFontTheme[];
