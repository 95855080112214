import { CreateReducer } from "@platform/react/store";
import { ActionSliceHistoryVideo } from "./_actions";
import { INIT_SLICE_HISTORY_VIDEO } from "./_namespace";

export const ReducerSliceHistoryVideo = CreateReducer(INIT_SLICE_HISTORY_VIDEO, ({ addCase }) => {
    addCase(ActionSliceHistoryVideo.set, (state, { payload }) => ({ ...state, ...payload }));
    addCase(ActionSliceHistoryVideo.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_HISTORY_VIDEO;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });
    addCase(ActionSliceHistoryVideo.setHistoryVideo, (state, { payload }) => {
        const userHistory = state.history[payload.email];
        state.history = {
            ...state.history,
            [payload.email]: { ...userHistory, [payload["uuid-video"]]: payload },
        };
    });

    addCase(ActionSliceHistoryVideo.setLastVideo, (state, { payload }) => {
        state["last-video"] = {
            [payload.email]: payload,
        };
    });
    addCase(ActionSliceHistoryVideo.setCurrentTime, (state, { payload }) => {
        state.time = payload;
    });
});
