import React from "@platform/react";
import type { RouteDefs } from "@platform/react-storybook/Component/Router/types";
import { getPathHook, RoutesParse } from "@platform/react-storybook/Component/Router";

import { LoadingAnimation } from "@front/components/Element/LoadingAnimation";

import { PATH } from "../paths";
import { PAGE } from "../pages";

export const RoutesLoading = <LoadingAnimation />;

export const ROUTE_COMMON: RouteDefs = {
    COMMON_SUPPORT_TERMS: {
        path: PATH.COMMON.SUPPORT.TERMS,
        page: PAGE.COMMON_SUPPORT_TERMS,
    },
    COMMON_SUPPORT_PRIVACY: {
        path: PATH.COMMON.SUPPORT.PRIVACY,
        page: PAGE.COMMON_SUPPORT_PRIVACY,
    },
    COMMON_SIGN_UP: {
        path: PATH.COMMON.SIGNUP.ROOT,
        page: PAGE.COMMON_SIGN_UP_FORM,
    },
    COMMON_SIGN_UP_CODE: {
        path: PATH.COMMON.SIGNUP.CONFIRM.PARAM_CONFIRM,
        page: PAGE.COMMON_SIGN_UP_CODE,
    },
    COMMON_SIGN_UP_FORM: {
        path: PATH.COMMON.SIGNUP.FORM.PARAM_FORM,
        page: PAGE.COMMON_SIGN_UP_FORM,
    },
    // Must always be last
    COMMON_404: {
        path: PATH.WILD,
        page: PAGE.COMMON_INTERNAL_404,
    },
    COMMON_FAQS: {
        path: PATH.COMMON.FAQS,
        page: PAGE.COMMON_FAQS,
    },
};

export const usePathCommon = getPathHook(ROUTE_COMMON);

export const RoutesCommon = RoutesParse(ROUTE_COMMON, { loading: RoutesLoading });
