export type InAppPurchaseInfo = {
    id: string;
    duration: string;
    promo: boolean;
    plan: Plan;
};

export type PropPlanItem = {
    title: string;
    subtitle: string;
    isIncluded: boolean;
};

export type Plan = {
    id: string;
    name: string;
    type: string;
    "title-text": string;
    specifications: PropPlanItem[];
};

export const MONTHLY = "monthly";
export const ANNUALLY = "annually";

const monthPlan: Plan = {
    id: "",
    name: "",
    type: MONTHLY,
    "title-text": "",
    specifications: [
        {
            title: "plan-specification-1-title",
            subtitle: "plan-specification-1-subtitle",
            isIncluded: true,
        },
        {
            title: "plan-specification-2-title",
            subtitle: "plan-specification-2-subtitle",
            isIncluded: true,
        },
        {
            title: "plan-specification-3-title",
            subtitle: "plan-specification-3-subtitle",
            isIncluded: true,
        },
        {
            title: "plan-specification-4-title",
            subtitle: "plan-specification-4-subtitle",
            isIncluded: false,
        },
    ],
};

const annualPlan: Plan = {
    id: "",
    name: "",
    type: ANNUALLY,
    "title-text": "plan-annually-title",
    specifications: [
        {
            title: "plan-specification-1-title",
            subtitle: "plan-specification-1-subtitle",
            isIncluded: true,
        },
        {
            title: "plan-specification-2-title",
            subtitle: "plan-specification-2-subtitle",
            isIncluded: true,
        },
        {
            title: "plan-specification-3-title",
            subtitle: "plan-specification-3-subtitle",
            isIncluded: true,
        },
        {
            title: "plan-specification-4-title",
            subtitle: "plan-specification-4-subtitle",
            isIncluded: true,
        },
    ],
};

export const iosInAppPurchasesInfo: InAppPurchaseInfo[] = [
    {
        id: "club.mastercracks.premium.monthly",
        duration: MONTHLY,
        promo: false,
        plan: { ...monthPlan, id: "club.mastercracks.premium.monthly" },
    },
    {
        id: "club.mastercracks.premium.annually",
        duration: ANNUALLY,
        promo: false,
        plan: { ...annualPlan, id: "club.mastercracks.premium.annually" },
    },
    {
        id: "club.mastercracks.premium.monthly.15",
        duration: MONTHLY,
        promo: true,
        plan: { ...monthPlan, id: "club.mastercracks.premium.monthly.15" },
    },
    {
        id: "club.mastercracks.premium.annually.15",
        duration: ANNUALLY,
        promo: true,
        plan: { ...annualPlan, id: "club.mastercracks.premium.annually.15" },
    },
];
export const androidInAppPurchasesInfo: InAppPurchaseInfo[] = [];
export const receiptValidator =
    "https://validator.iaptic.com/v3/validate?appName=club.mastercracks.user&apiKey=cad88d65-9d05-4086-a517-c11fb9013ff8";
export const iosInAppPurchaseSetupObject = {
    inAppIds: iosInAppPurchasesInfo.map((item) => item.id),
    validatorId: receiptValidator,
};
export const androidInAppPurchaseSetupObject = {
    inAppIds: androidInAppPurchasesInfo.map((item) => item.id),
    validatorId: receiptValidator,
};
