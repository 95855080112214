import { PREFIX_FONT_THEME } from ".";

export const PREFIX_STYLE_FONT_THEME = `${PREFIX_FONT_THEME}_style` as const;

export const STYLE_FONT_THEME = {
    default: "normal",
    suv: "oblique",
    coupe: "italic",
} as const;

export type StyleFontTheme = typeof STYLE_FONT_THEME;

export type UuidStyleFontTheme = keyof StyleFontTheme;

export type ValueStyleFontTheme = StyleFontTheme[UuidStyleFontTheme];

export const UUID_STYLE_FONT_THEME = Object.keys(STYLE_FONT_THEME) as readonly UuidStyleFontTheme[];
