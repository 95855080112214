import type { PropDefaults, PropElement, PropTypes } from "@platform/react-storybook/Component";
import type { UuidBoxTheme } from "@common/content/theme/box";
import type { UuidBitmapAsset } from "@common/content/asset/bitmap";
import type { UuidVectorAsset } from "@common/content/asset/vector";

import Namespace from "./_index.scss";

export const {
    NAME_BOX,
    TYPE_BOX,
    NAME_ASSET_BOX,
    TYPE_ASSET_BOX,
    NAME_COVER_BOX,
    TYPE_COVER_BOX,
    NAME_CONTENT_BOX,
    TYPE_CONTENT_BOX,
    NAME_DISABLED_BOX,
    TYPE_DISABLED_BOX,
} = Namespace;

export type PrivKeyPropBox = "preset" | "bitmap" | "vector";

export type PropBox = PropElement & {
    /** Choose one of the available presets to use as base. */
    preset?: UuidBoxTheme;
    /**	If enabled, the box won’t allow its contents to be interacted with,
     * because a special layer will be placed above it all. */
    locked?: boolean;
    /**	Locked version without the icon. */
    blocked?: boolean;
    /** Whether the box will have a second background using a bitmap image */
    bitmap?: UuidBitmapAsset;
    /** Whether the box will have a second background using a vector image */
    vector?: UuidVectorAsset;
};

export type SharedPropBox = Omit<PropBox, PrivKeyPropBox>;

export const DEF_BOX: PropDefaults<
    Omit<PropBox, "bitmap" | "vector" | "locked" | "preset" | "blocked">
> = {};

export type PropTypesBox = PropTypes<PropBox, typeof DEF_BOX>;

export const ERROR_BOX = {
    DUPE_ASSET: `${NAME_BOX}: Box cannot contain both vector and bitmap`,
} as const;
