import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_HISTORY_VIDEO } from "./_namespace";
import type { NSSliceHistoryVideo } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSliceHistoryVideo(store: StoreState): NSSliceHistoryVideo.State {
    return store[NAME_SLICE_HISTORY_VIDEO];
}

SelectorSliceHistoryVideo.lastVideo = CreateSelector(
    SelectorSliceHistoryVideo,
    (state) => state["last-video"],
);

SelectorSliceHistoryVideo.historyVideo = CreateSelector(
    SelectorSliceHistoryVideo,
    (state) => state.history,
);

SelectorSliceHistoryVideo.time = CreateSelector(SelectorSliceHistoryVideo, (state) => state.time);

SelectorSliceHistoryVideo.playing = CreateSelector(
    SelectorSliceHistoryVideo,
    (state) => state.playing,
);
