import { PathCreate } from "@platform/react-storybook/Component/Router/utils";

import { paramAny } from "../../params/any";
import { paramLanguage } from "../../params/language";

export function PathGetBlog<R extends string>(ROOT: R) {
    return {
        ROOT,

        get BLOGS() {
            const ROOT_BLOG = PathCreate(ROOT, "blog");
            const ROOT_BLOG_LANG = PathCreate(ROOT_BLOG, paramLanguage);
            return {
                BLOG: {
                    ROOT: ROOT_BLOG,
                    CRACK: PathCreate(ROOT_BLOG_LANG, paramAny),
                },
            };
        },
    };
}
