import { ParamCreate } from "@platform/react-storybook/Component/Router/utils";

export const PARAM_VALUE_PROFILE = {
    PROGRESS: "progress",
    ACHIEVEMENTS: "achievements",
    SETTINGS: "settings",
} as const;

export type ParamValueProfile = typeof PARAM_VALUE_PROFILE[keyof typeof PARAM_VALUE_PROFILE];

export const PARAM_DEFAULT_PROFILE: ParamValueProfile = "progress";

export const { paramProfile, useParamProfile } = ParamCreate({
    name: "profile",
    values: Object.values(PARAM_VALUE_PROFILE),
});
