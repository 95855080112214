// import { ROUTE_TEXT } from "@common/content/text";
// import { ROUTE_LANGUAGUE } from "@common/content/language";
// import { ROUTE_SETTING } from "@common/content/setting";
import { isRecord } from "@platform/types/record";
// import type { RouteText } from "@common/content/text";
// import type { RouteLanguage } from "@common/content/language";
// import type { RouteSetting } from "@common/content/setting";

export const NAME_ENV = "env" as const;

// TODO: find a  way to determine if either NodeJS or Deno namespaces exist.
export function GetterEnv<Env extends Record<string, unknown>>(env: Env): Env {
    if (!isRecord<string>(env)) throw new Error(`${NAME_ENV}: Expecting a record`);
    return env;
    // return Object.entries({
    //     ...env,
    //     ...get_MS_STATIC(env),
    // }).reduce((acc, [key, val]) => {
    //     if (!val) return acc;
    //     return { ...acc, [key]: val };
    // }, {} as Env);
}

// function get_MS_STATIC(env: Record<string, string>) {
//     const PORT = env.REACT_APP_MS_STATIC_PORT ? env.REACT_APP_MS_STATIC_PORT : env.MS_STATIC_PORT;
//     const HOST = env.REACT_APP_MS_STATIC_HOST ? env.REACT_APP_MS_STATIC_HOST : env.MS_STATIC_HOST;
//     if (!PORT) throw new Error("MS_STATIC_PORT");
//     if (!HOST) throw new Error("MS_STATIC_HOST");

//     const PATH = {
//         ...ROUTE_TEXT,
//         ...ROUTE_LANGUAGUE,
//         ...ROUTE_SETTING,
//     } as const;

//     type UrlRecord<R extends Record<string, string>> = {
//         [K in keyof R]: `${typeof HOST}:${typeof PORT}${R[K]}`;
//     };

//     type Resp = UrlRecord<RouteText> & UrlRecord<RouteLanguage> & UrlRecord<RouteSetting>;

//     const URL = Object.entries(PATH).reduce(
//         (acc, [key, val]) => ({ ...acc, [key]: `${HOST}:${PORT}${val}` }),
//         {} as Resp,
//     );
//     return {
//         MS_STATIC: { PORT, HOST, PATH, URL },
//         // instruct parent to delete these
//         MS_STATIC_PORT: undefined,
//         MS_STATIC_HOST: undefined,
//         REACT_APP_MS_STATIC_PORT: undefined,
//         REACT_APP_MS_STATIC_HOST: undefined,
//     } as const;
// }
