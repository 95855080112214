import React from "@platform/react";

import { CreateProviderTextPlatform } from "./_Provider";
import { CreateConsumerTextPlatform } from "./_Consumer";
import { ERROR_TEXTPLATFORM, MATCHER_TEXTPLATFORM, NAME_TEXTPLATFORM } from "./types";
import type {
    ArgTextPlatform,
    StateTextPlatform,
    GetterTextPlatform,
    ArgCommonTextPlatform,
} from "./types";

export function CreateTextPlatform<G extends GetterTextPlatform>(arg: ArgTextPlatform<G>) {
    const { matcher, storageName, ...rest } = arg;
    const Context = React.createContext<StateTextPlatform<G>>(undefined);
    const common: ArgCommonTextPlatform<G> = {
        Context,
        matcher: matcher || MATCHER_TEXTPLATFORM,
        storageName: storageName || NAME_TEXTPLATFORM,
        ...rest,
    };
    const ProviderTextPlatform = CreateProviderTextPlatform(common);
    const TextPlatform = CreateConsumerTextPlatform(common);

    return {
        ContextTextPlatform: Context,
        ProviderTextPlatform,
        TextPlatform,
        useTextPlatform,
    };

    function useTextPlatform() {
        const context = React.useContext(Context);
        if (!context) throw new Error(ERROR_TEXTPLATFORM.NO_PROVIDER);
        return context;
    }
}
