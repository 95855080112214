import { useLocation } from "react-router-dom";

import React from "@platform/react";
import { getPlatform } from "@platform/utils-react";

import {
    DEF_CONSUMER_TRACKERPLATFORM,
    NAME_CONSUMER_TRACKERPLATFORM,
    ERROR_TRACKERPLATFORM,
} from "./types";
import type { PropTypes } from "../../Component";
import type { PropConsumerTrackerPlatform, FiberNode } from "./types";

export function CreateConsumerTrackerPlatform() {
    const STATE = { name: "", type: "", text: "" };
    type State = typeof STATE;

    function isStateEmpty(state: State) {
        return Object.values(state).every((val) => !val);
    }
    function TrackerPlatform(
        props: PropTypes<PropConsumerTrackerPlatform, typeof DEF_CONSUMER_TRACKERPLATFORM>,
    ): null | undefined | React.ReactChild[] {
        const { pathname = "/" } = useLocation();
        const ref = React.useRef<State>(STATE);

        const children = React.Children.map(props.children, handleChildren);

        const callbackDataLayer = React.useCallback(handleDataLayer, [pathname]);

        React.useEffect(() => {
            if (isStateEmpty(ref.current)) return handleUnmount;
            const { name } = ref.current;
            callbackDataLayer(`${name}:mount`);
            return handleUnmount;
            function handleUnmount() {
                callbackDataLayer(`${name}:unmount`);
            }
        }, [callbackDataLayer]);

        return children;

        async function handleDataLayer(event: string) {
            const win = window as unknown as Window & { dataLayer?: unknown[] };
            if (!win.dataLayer) return;
            const platform = await getPlatform();
            const { name, type, text } = ref.current;
            const data = { name, type, text, platform, pathname };
            win.dataLayer.push({ event, ...data });
        }

        function handleChildren(child: React.ReactNode): React.ReactChild {
            if (!React.isValidElement(child)) {
                throw new Error(ERROR_TRACKERPLATFORM.INVALID_ELEMENT);
            }
            if (!child.props) {
                throw new Error(ERROR_TRACKERPLATFORM.INVALID_PROP);
            }
            const { props: _props } = child;
            const name = _props["data-name"] || _props.name;
            const type = _props["data-type"] || _props.type;
            let text = "";
            if (!name || !type) {
                throw new Error(ERROR_TRACKERPLATFORM.INVALID_PROP);
            }
            if (typeof _props.children === "string") {
                text = _props.children;
            } else if (typeof _props.children === "function") {
                text = _props.children.name;
            } else {
                const json = JSON.stringify(
                    _props.children,
                    (
                        key: string,
                        value: { props?: Record<string, unknown>; _owner: FiberNode },
                    ) => {
                        if (Array.isArray(value)) return value;
                        if (!value || !value.props) return undefined;
                        if (typeof value.props.uuid === "string") return value.props.uuid;
                        return getFiberNode(value._owner);
                    },
                );
                try {
                    const parsed = JSON.parse(json);
                    if (typeof parsed === "string") text = parsed;
                    else if (Array.isArray(parsed)) text = parsed.join("-");
                    else text = String(parsed);
                } catch {
                    text = json;
                }
            }

            ref.current = { text, name, type };
            return React.cloneElement(child, {
                ..._props,
                id: _props._id ? _props.id : cyrb53([pathname, name, text].join("-")),
                onClick:
                    typeof _props.onClick !== "function"
                        ? _props.onClick
                        : (..._args: []) => {
                              _props.onClick.apply(null, _args);
                              callbackDataLayer("click");
                          },
            });
        }
    }
    TrackerPlatform.displayName = NAME_CONSUMER_TRACKERPLATFORM;
    TrackerPlatform.defaultProps = DEF_CONSUMER_TRACKERPLATFORM;

    return TrackerPlatform;
}

function getFiberNode(node: FiberNode) {
    let current: FiberNode | null = node;
    const nodes = [];
    while (current && current?.child) {
        try {
            const type = current.type as unknown as Record<string, string | undefined>;
            const name = type.displayName || type.name;
            if (name) nodes.push(name);
            current = current.child;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(`TrackerError: ${(err as Error).message}`);
            current = null;
        }
    }
    return nodes.join("-");
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
function cyrb53(str: string, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}
/* eslint-enable @typescript-eslint/no-magic-numbers */
