export const UUID_CRACK = [
    "pavel-pardo",
    "jo-canales",
    "rafa-marquez",
    "carlos-salcido",
    "norma-palafox",
    "oswaldo-sanchez",
    "charlyn-corral",
    "jared-borgetti",
    "gianluigi-buffon",
] as const;

export type UuidCrack = typeof UUID_CRACK[number];

export function getExpCrack() {
    return UUID_CRACK.join(" | " as const);
}
