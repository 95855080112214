/* eslint-disable no-promise-executor-return */
/// <reference types="@platform/cordova-plugin-info" />

export const IS_MOBILE = Boolean(window.cordova);

export function MobileReady<T>(data?: T): Promise<T | undefined> {
    if (!IS_MOBILE) return Promise.resolve(data);
    return new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.app = {
            initialize() {
                document.addEventListener("deviceready", this.onDeviceReady.bind(this), false);
            },
            onDeviceReady() {
                resolve(data);
            },
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.app.initialize();
    });
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface MobileInfoResponse extends CordovaPluginInfo.Info {} // eslint-disable-line @typescript-eslint/no-empty-interface

export function MobileInfo(): Promise<null | MobileInfoResponse> {
    return new Promise((resolve, reject) => {
        if (!IS_MOBILE) return resolve(null);
        MobileReady().then(() => {
            const { info } = (window.cordova as Cordova).plugins;
            if (!info) return reject(new Error("Info plugin unavailable"));
            info(
                (response) => resolve(response),
                () => reject(new Error("No info data available"))
            );
            return undefined;
        });
        return undefined;
    });
}
