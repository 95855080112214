import React from "@platform/react";

import type { PropDefaults, PropTypes } from "../../Component";
import type {
    PropProviderImagePlatform,
    StateImagePlatform,
    GetterImagePlatform,
    ArgProviderImagePlatform,
} from "./types";
import { ERROR_PROVIDER_IMAGE, NAME_PROVIDER_IMAGEPLATFORM } from "./types";

export function CreateProviderImagePlatform<G extends GetterImagePlatform>(
    arg: ArgProviderImagePlatform<G>,
) {
    const { Context, getter } = arg;

    const DEF_PROVIDER_IMAGEPLATFORM: PropDefaults<Omit<PropProviderImagePlatform, "onError">> = {
        loading: null,
        loadingImage: null,
    };

    type State = NonNullable<StateImagePlatform<G>>;
    type Data = State["imageData"];

    function ProviderImage(
        props: PropTypes<PropProviderImagePlatform, typeof DEF_PROVIDER_IMAGEPLATFORM>,
    ) {
        const { children, onError, loading, loadingImage } = props;
        const [data, setData] = React.useState<undefined | Data>(undefined);

        React.useEffect(() => {
            // data already obtained from the getter, do nothing.
            if (data) return;
            (async () => {
                const _data = await getter();
                if (!_data) {
                    if (onError) onError(new Error(ERROR_PROVIDER_IMAGE.DATA_INVALID));
                    return;
                }
                setData(_data as Data);
            })();
        }, [data, onError]);

        if (!data) return loading;

        return (
            <Context.Provider
                value={{
                    imageData: data,
                    imageLoader: loadingImage,
                    imageGet(uuid) {
                        return data[uuid];
                    },
                    imageReset() {
                        setData(undefined);
                    },
                    imageSet({ uuid, value }) {
                        setData({ ...data, [uuid]: value });
                    },
                }}>
                {children}
            </Context.Provider>
        );
    }
    ProviderImage.displayName = NAME_PROVIDER_IMAGEPLATFORM;
    ProviderImage.defaultProps = DEF_PROVIDER_IMAGEPLATFORM;

    return ProviderImage;
}
