import { useHref, useNavigate as useOriginalNavigate } from "react-router-dom";
import type { NavigateOptions as OrigNavigateOptions, To } from "react-router-dom";

import React from "@platform/react";
import { IS_MOBILE } from "@platform/utils-react/mobile";
import { OpenBrowserSystem, OpenBrowserInApp } from "@platform/utils-react/mobile/inappbrowser";

import type { Route, RouteDefs, RoutesParseProps, ValueTargetPlatformLink } from "./types";
import { PATH_ROOT, TARGET_PLATFORMLINK } from "./types";
import { isExternalLink } from "./utils";

export * from "react-router-dom";

export const BACK_ROUTER = -1;

/**
 * Returns a function that does the same as useHref
 * but allowing you to use it anywhere without eslint complaining about hook rules.
 * TODO: There must be a better way of doing this shit.
 */
export function useUrlResolver() {
    return (to: string) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const url = useHref(to);
        return url;
    };
}

export function RoutesParse(defs: RouteDefs, props: RoutesParseProps): Route[] {
    const { loading } = props;
    return Object.entries(defs).reduce((acc, entry) => {
        const { page, children, ...propsRoute } = entry[1];
        // TODO: Validate if we can get this to work without a server on Cordova. hint: I think not.
        const Page = React.lazy(() =>
            typeof page !== "string" ? page : import(`~/pages/${page}`),
        );
        return acc.concat({
            ...propsRoute,
            id: entry[0],
            children: children ? RoutesParse(children, props) : undefined,
            element: (
                <React.Suspense fallback={loading || null}>
                    <Page />
                </React.Suspense>
            ),
        });
    }, [] as Route[]);
}

export function getPathHook<Defs extends RouteDefs, KeyDefs extends keyof Defs>(defs: Defs) {
    return function usePath<K extends KeyDefs>(page: K): Defs[K]["path"] {
        const path = find(page, defs);
        // TODO REVISION HECTOR
        // if (!path) throw new Error(`Could not find a matching path for "${String(page)}".`);
        return path;
    };

    function find<K extends KeyDefs>(page: K, def: RouteDefs): string {
        for (const key in def) {
            if (!Object.prototype.hasOwnProperty.call(def, key)) continue;
            if (key === page) return def[key].path;
            if (!def[key].children) continue;
            const childPath = find(page, def[key].children as RouteDefs);
            if (!childPath) continue;
            return [def[key].path, childPath].join(PATH_ROOT);
        }
        return "";
    }
}

export type NavigateOptions = OrigNavigateOptions & {
    target?: ValueTargetPlatformLink;
};

export function useNavigate() {
    const navigate = useOriginalNavigate();

    /**
     * @param {string} url - The location you're going to navigate to, you can use external links!
     * @param {NavigateOptions} options - The same options as ReactRouter + `target`
     */
    return function PlatformNavigate(url: To | number, optionsPlatform?: NavigateOptions): void {
        const { target = "_self", ...options } = optionsPlatform || {};
        // when the url is a Path or an internal link, let navigate handle it.
        if (typeof url !== "string" || !isExternalLink(url)) {
            navigate(url as To, options);
            return;
        }
        switch (target) {
            case TARGET_PLATFORMLINK.self:
                window.location.href = url;
                break;
            case TARGET_PLATFORMLINK.blank:
                if (IS_MOBILE) OpenBrowserInApp(url);
                else window.open(url, "_blank");
                break;
            case TARGET_PLATFORMLINK.system:
                if (IS_MOBILE) OpenBrowserSystem(url);
                else window.open(url, "_blank");
        }
    };
}
