import { CreateReducer } from "@platform/react/store";
import { ActionSlicePayment } from "./_actions";
import { INIT_SLICE_PAYMENT } from "./_namespace";

export const ReducerSlicePayment = CreateReducer(INIT_SLICE_PAYMENT, ({ addCase }) => {
    addCase(ActionSlicePayment.set, (state, { payload }) => ({ ...state, ...payload }));

    addCase(ActionSlicePayment.clear, (state, { payload }) => {
        if (payload === null) return INIT_SLICE_PAYMENT;
        const { key } = payload;
        return { ...state, [key]: undefined };
    });

    addCase(ActionSlicePayment.setHistoryPayment, (state, { payload }) => {
        state.historyPayment = payload;
    });

    addCase(ActionSlicePayment.setCreditCard, (state, { payload }) => {
        state.creditCard = payload;
    });

    addCase(ActionSlicePayment.setDiscount, (state, { payload }) => {
        state.discount = payload;
    });

    addCase(ActionSlicePayment.setPlan, (state, { payload }) => {
        state.uuidPlan = payload.uuidPlan || "";
    });
    addCase(ActionSlicePayment.setPlanMc, (state, { payload }) => {
        state.plan = payload;
    });
    addCase(ActionSlicePayment.setUuidCreditCard, (state, { payload }) => {
        state.uuidCreditCard = payload.uuid || "";
    });

    addCase(ActionSlicePayment.setSubscription, (state, { payload }) => {
        state.subscription = payload.subscription;
    });
    addCase(ActionSlicePayment.setCharge, (state, { payload }) => {
        state.charge = payload.charge;
    });
});
