import { CreateSelector } from "@platform/react/store";

import { NAME_SLICE_PURCHASES } from "./_namespace";
import type { NSSlicePurchases } from "./_namespace";
import type { StoreState } from "../..";

export function SelectorSlicePurchases(store: StoreState): NSSlicePurchases.State {
    return store[NAME_SLICE_PURCHASES];
}

SelectorSlicePurchases.productToBePurchased = CreateSelector(
    SelectorSlicePurchases,
    (state) => state.productToBePurchased,
);
SelectorSlicePurchases.productsInfo = CreateSelector(
    SelectorSlicePurchases,
    (state) => state.productsInfo,
);
SelectorSlicePurchases.ownedProduct = CreateSelector(
    SelectorSlicePurchases,
    (state) => state.ownedProduct,
);
SelectorSlicePurchases.error = CreateSelector(SelectorSlicePurchases, (state) => state.error);
SelectorSlicePurchases.isLoading = CreateSelector(
    SelectorSlicePurchases,
    (state) => state.isLoading,
);
SelectorSlicePurchases.products = CreateSelector(SelectorSlicePurchases, (state) => state.products);
SelectorSlicePurchases.shouldShowChooseYourPlan = CreateSelector(
    SelectorSlicePurchases,
    (state) => state.shouldShowChooseYourPlan,
);
SelectorSlicePurchases.isSilentModeEnabled = CreateSelector(
    SelectorSlicePurchases,
    (state) => state.isSilentModeEnabled,
);
SelectorSlicePurchases.shouldOpenSubscriptions = CreateSelector(
    SelectorSlicePurchases,
    (state) => state.shouldOpenSubscriptions,
);
