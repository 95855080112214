import { ParamCreate } from "@platform/react-storybook/Component/Router/utils";
import { UUID_CUSTOM_LANDING, UUID_CUSTOM_LANDING_BUSINESS } from "@common/content/landing/uuid";
import type { UuidCustomLanding } from "@common/content/landing/uuid";

export type ParamValueProfile = UuidCustomLanding;

export const { paramCrack, useParamCrack } = ParamCreate({
    name: "crack",
    values: UUID_CUSTOM_LANDING,
});

export const { paramBusiness, useParamBusiness } = ParamCreate({
    name: "business",
    values: UUID_CUSTOM_LANDING_BUSINESS,
});
