import { CreateActions } from "@platform/react/src/store";
import { NAME_SLICE_PURCHASES } from "./_namespace";
import type { NSSlicePurchases } from "./_namespace";

export const ActionSlicePurchases = CreateActions<{
    /** Sets a give property errors */
    set: NSSlicePurchases.ArgSet;
    /** Cleans a key from the local storage */
    clear: NSSlicePurchases.ArgClear | null;
}>(NAME_SLICE_PURCHASES, ["set", "clear"]);
