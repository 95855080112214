export const NAME_SLICE_NETWORK = "network";

export const STATUS_SLICE_NETWORK = {
    ONLINE: "online",
    OFFLINE: "offline",
} as const;

export declare namespace NSSliceNetwork {
    export type status = typeof STATUS_SLICE_NETWORK[keyof typeof STATUS_SLICE_NETWORK];

    export type State = {
        status: status;
    };
}

export const INIT_SLICE_NETWORK: NSSliceNetwork.State = {
    status: STATUS_SLICE_NETWORK.ONLINE,
};
