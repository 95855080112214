import { MobileInfo } from "./mobile";

export * as mobile from "./mobile";

export * as base64 from "./base64";

export type ArgGetPlatform = {
    /** width used to determine the current device is small enough to be considered mobile */
    maxWidth?: number;
};

export const DEF_GET_PLATFORM = {
    maxWidth: 640,
} as const;

export const NAME_PLATFORM = {
    WEB: "www",
    MOBILE: "mobile",
    DESKTOP: "desktop",
    SEP: ":",
} as const;

export async function getPlatform(arg?: ArgGetPlatform) {
    const { maxWidth = DEF_GET_PLATFORM.maxWidth } = arg || {};
    const mobile = await MobileInfo();
    let platform: string | undefined = undefined;
    if (!mobile) {
        const hasTouch = "ontouchstart" in document.documentElement;
        const isSmall = window.matchMedia(`only screen and (max-width: ${maxWidth}px)`).matches;
        const { WEB, MOBILE, DESKTOP, SEP } = NAME_PLATFORM;
        platform =
            hasTouch && isSmall ? `${[WEB, MOBILE].join(SEP)}` : `${[WEB, DESKTOP].join(SEP)}`;
    } else {
        platform = mobile.osName;
    }
    return platform;
}
