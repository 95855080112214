import { PREFIX_COLOR_THEME } from "@common/content/theme/color";

export const PREFIX_BRIGHT_COLOR_THEME = `${PREFIX_COLOR_THEME}_bright` as const;

export const BRIGHT_COLOR_THEME = {
    russia: "-0.90",
    italy: "-0.50",
    france: "-0.15",
    default: "0",
    spain: "0.15",
    poland: "0.50",
    belgium: "0.90",
} as const;

export type BrightColorTheme = typeof BRIGHT_COLOR_THEME;

export type UuidBrightColorTheme = keyof BrightColorTheme;

export type ValueBrightColorTheme = BrightColorTheme[UuidBrightColorTheme];

export const UUID_BRIGHT_COLOR_THEME = Object.keys(
    BRIGHT_COLOR_THEME,
) as readonly UuidBrightColorTheme[];
